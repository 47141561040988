
import React, { useMemo, useState, useRef, useEffect,useCallback } from 'react'
import {

  useHistory,
  useParams,
} from "react-router-dom";
import { TC } from './data.js'
import ReactGA from 'react-ga'
import { makeStyles } from '@material-ui/core/styles'
import {theme} from './RouterSystem.js'
import {
  LinearProgress,

  Box,


  Grid,
  Card,
  CardContent,


  ThemeProvider,


  Container,
  Button,

} from "@material-ui/core";
import {
  Bookmark,
    SkipNext,
    HighlightOff,
    SkipPrevious,
    Replay
} from '@material-ui/icons'
import ArrowBack from "@material-ui/icons/Close";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
const useStyles = makeStyles(theme => ({
    cover: {
        height: 330,
        width: 330,
        float: 'left',
        margin: '5px'
    },
    circle: {
        color: 'blue',
        animation: `$blink infinite 1.5s ease`
    },

    explanation: {
      alignItems:'center',
      marginLeft:'auto',
      marginRight:'auto',

        fontSize:'3em',
        color: '#00cfe6',
        animation: `$blink2 infinite 1.5s ease`,

    },
    '@keyframes blink': {
        '0%': {
            opacity: 0
        },
        '50%': {
            opacity: 1
        },
        '100%': {
            opacity: 0
        }
    },
    '@keyframes blink2': {
        '0%': {
            opacity: 0.4
        },
        '50%': {
            opacity: 1
        },
        '100%': {
            opacity: 0.4
        }
    }
}))

function TypingForBeginers(props) {
    let{itemId,itemIndex,freeText}=useParams();

    let startTime = useRef(null)
    let [id ]= useState(parseInt(itemId));
    let [init,setInit]=useState(true);
    let [index,setIndex] = useState(parseInt(itemIndex))
    let [mojisuu, setMojisuu] = useState(0)
    let [inputstring, setInputstring] = useState('')
    let [okInputstring,setOkInputstring]=useState('')
    let [page, setPage] = useState(0)
    let [henkan, setHenkan] = useState('')
    let [dasuuResult,setDasuuResult]=useState(null);
    let [speedResult,setSpeedResult]=useState(null);
    let [resultMode, setResultMode] = useState(false)
    let [endResult,setEndResult]=useState(false);
    let [nextKeyValue,setNextKeyValue]=useState(null);
    let [ng,setNg]=useState(false);

    //let [arrAmari,setArrAmari]=useState([]);



    let isString=(obj)=> {
    return typeof (obj) == "string" || obj instanceof String;
};

const NG_SOUND = useMemo(()=>{return new Audio('/sound/maou44.mp3');},[]);

/*
    let [converted,setConverted]=useState("NG");
    let [converted2,setConverted2]=useState("");
    let genbun ="「終戦に向けた動き」8月10日の午前3時から行なわれた閣議で、降伏は正式に承認された。日本政府は、ポツダム宣言受諾により全日本軍が降伏を決定する事実を、海外向けのラジオの国営放送を通じ、日本語と英語で三回世界へ放送した。また同盟通信社から、モールス通信で交戦国に直接通知が行われた。これに対する返答、いわゆる「バーンズ回答」に対する英単語の解釈などで悶着があったものの、14日深夜には天皇による玉音放送が録音された。"


    useEffect(()=>{
      async function a(){
        let kuroshiro = await new Kuroshiro();
        let ana = new KuromojiAnalyzer({
        dictPath: '/dict/',
      })
        setConverted("OK1")
        await kuroshiro.init(ana);
        setConverted("OK2")
        let ret= await kuroshiro.convert(genbun, {mode:"okurigana", to:"hiragana"});
        setConverted(converted+ret);
      };

      a();
    },[]);
    Kuromoji.builder({ dicPath: "/dict/" }).build(function (err, tokenizer) {
    // tokenizer is ready
      var path = tokenizer.tokenize(genbun);
      let str="";
      let mojisuu=0;
      let kiri=0;
      let prev=undefined;
      let pprev=undefined;
      for(let o of path){
        mojisuu+=(o.surface_form).length
        if(o.pos==="接続詞"&&prev!==undefined&&prev.pos==="名詞"){
          if(mojisuu>10){str+="@";
          mojisuu=0
          }
        }
        if(o.pos==="名詞"&&prev!==undefined&&prev.pos==="助詞"){
          if(mojisuu>20){str+="@";
          mojisuu=0
          }
        }
        if(prev!==undefined&&prev.surface_form==="。"){
          if(mojisuu>5){str+="@";
          mojisuu=0
        }
        }
        if(prev!==undefined&&prev.surface_form==="、"){
          if(pprev!==undefined&&!(pprev.pos==="名詞"&&o.pos==="名詞")||!(pprev.pos==="形容詞"&&o.pos==="形容詞")){
          if(mojisuu>7){str+="@";
          mojisuu=0
        }
      }
        }

        if(prev!==undefined&&prev.surface_form==="」"&&o.pos!=="助詞"){
          if(mojisuu>1){str+="@";
          mojisuu=0
        }
        }
        /*
        if(prev!==undefined&&prev.pos==="記号"&&prev.surface_form!=="「"&&prev.surface_form!=="、"&&o.pos!=="記号"){
          if(mojisuu>2){str+="@";
          mojisuu=0
        }
        }
        if(prev!==undefined&&prev.pos==="記号"&&prev.surface_form!=="「"&&prev.surface_form!=="、"&&o.pos!=="記号"){
          if(mojisuu>2){str+="@";
          mojisuu=0
        }
        }

        str+=o.surface_form;
        pprev=prev;
        prev=o;
      }
      setConverted2(str);
    });
*/

    let maxIndex=useMemo(()=>{
      let max=0;
      for(let o of TC){
        if(o.id===id){
          if(o.index>max){
            max=o.index;
          }
        }
      }
      if(id===99999){
        return 1;
      }
      return max;
    },[id])


    let contents = useMemo(() => {
      if(freeText!=="st"){
        if(freeText.indexOf("@")!==-1){

          return freeText;

        }
      }
        let t
        for (t of TC) {

            if (t.id === id && t.index === index) {
                return t.text
            }
        }



        return ""
    }, [ id, index,freeText])
    let speed = useMemo(() => {
        /*
            if(completely_ended){
              return 0;
            }
            if(Date.now()-startTime<=0){
              return 0;
            }
            */

            let tmp_inputstring=okInputstring;

            if(mojisuu + tmp_inputstring.length<2){
              return 0;
            }
        return (
            ((mojisuu + tmp_inputstring.length) * 1000 * 60) / (Date.now() - startTime.current)
        )
    }, [mojisuu, okInputstring, startTime])
    //フリガナを取り出す：現在のページ数の
    let text = useMemo(() => {

        if(resultMode)
        {
            return undefined;
        }
        if (contents === undefined||contents==="") {
            return undefined;
        }

        let ret = contents.split('@').reduce(
          (p,c,i,a)=>{

            if(i===page*2){
              let add = c;
              return add;
            }else{
              return p;
            }
          },['']);

          if(!isString(ret)){
            return "";
          }
          return ret;

    }, [page, contents,resultMode])
    const genText = (page, contents) => {
        if (contents === undefined) {
            return ''
        }
        let ret = contents.split('@').reduce(
          (p,c,i,a)=>{

            if(i===page*2){
              let add = c;
              return add;
            }else{
              return p;
            }
          },[""]
        );

        if(ret===[""]){
          return "";
        }else{
          return ret;
        }


    }
    let kanji = useMemo(() => {
        if (contents === undefined) {
            return ''
        }
        let p = page
        let str = contents
        let t = str.split('@')
        let s = ''
        let i = false
        let j = 0
        for (const o of t) {
            if (i === true && j > p - 0.5 && j < p + 0.5) {
                s += o
            }
            if (i) {
                j++
                i = false
            } else {
                i = true
            }
        }
        return s
    }, [page, contents])

    let finalPage = useMemo(() => {
        if (contents === undefined) {
            return 0
        }
        let t = contents.split('@')
        return (t.length - (t.length % 2)) / 2
    }, [contents])

    let ended = useMemo(() => {
        if(resultMode){
          return true;
        }
        if ((text===undefined || text.length===0) && parseInt(page)>=parseInt(finalPage) ) {

            if(!endResult){
              setResultMode(true)
            }

            return true
        }

        return false
    }, [text, page, finalPage,endResult ,resultMode])
    let [nowtyping, setNowtyping] = useState(false)

    let [hitkey, setHitkey] = useState(true)

    let [amari, setAmari] = useState('')
    let [fingerAlpha, setFingerAlpha] = useState('Nasi')

    let callbackfunction = () => {


        let otoA = new Audio('/sound/otol01.mp3')
        let otoB = new Audio('/sound/otol02.mp3')
        let otoC = new Audio('/sound/otol03.mp3')

        if (hitkey && inputstring.length > 0) {

            if(ng){
              NG_SOUND.play();
              setNg(false);

            }else{

              switch (Math.floor(Math.random() * 6) + 1) {
                  case 1:
                      otoB.play()
                      break
                  case 2:
                      otoC.play()
                      break
                  case 3:
                      otoC.play()
                      break
                  default:
                      otoA.play()
                      break
              }
            }
            setHitkey(false);
        }

    }

    let [showExplanation, setShowExplanation] = useState(true)

    useEffect(callbackfunction, [ng,NG_SOUND,text, amari,inputstring, hitkey, contents, resultMode,])

    let level = useMemo(() => {
        const Level = [
            [' ', ' '],
            ['-', '-'],
            ['?', '?'],
            ['!', '!'],
            ['「', '['],
            ['」', ']'],
            ['（', '('],
            ['）', ')'],
            ['(', '('],
            [')', ')'],
            ['a', 'a'],
            ['b', 'b'],
            ['c', 'c'],
            ['d', 'd'],
            ['e', 'e'],
            ['f', 'f'],
            ['g', 'g'],
            ['h', 'h'],
            ['i', 'i'],
            ['j', 'j'],
            ['k', 'k'],
            ['l', 'l'],
            ['m', 'm'],
            ['n', 'n'],
            ['o', 'o'],
            ['p', 'p'],
            ['q', 'q'],
            ['r', 'r'],

            ['s', 's'],
            ['t', 't'],
            ['u', 'u'],
            ['v', 'v'],
            ['w', 'w'],

            ['x', 'x'],
            ['y', 'y'],
            ['z', 'z'],

            ['0', '0'],
            ['1', '1'],
            ['2', '2'],
            ['3', '3'],
            ['4', '4'],
            ['5', '5'],
            ['6', '6'],
            ['7', '7'],
            ['8', '8'],
            ['9', '9'],

            ['.', '.'],
            [',', ','],

            ['っら', 'lla'],
            ['っり', 'lli'],
            ['っる', 'llu'],
            ['っれ', 'lle'],
            ['っろ', 'llo'],

            ['ら', 'la'],
            ['り', 'li'],
            ['る', 'lu'],
            ['れ', 'le'],
            ['ろ', 'lo'],

            ['っぁ', 'xxa'],
            ['っぃ', 'xxi'],
            ['っぅ', 'xxu'],
            ['っぇ', 'xxe'],
            ['っぉ', 'xxo'],
            ['っっ', 'xxtu'],
            ['っっ', 'xxtsu'],

            ['ぁ', 'xa'],
            ['ぃ', 'xi'],
            ['ぅ', 'xu'],
            ['ぇ', 'xe'],
            ['ぉ', 'xo'],
            ['っ', 'xtu'],
            ['っ', 'xtsu'],

            ['にゃ', 'nya'],
            ['にぃ', 'nyi'],
            ['にゅ', 'nyu'],
            ['にぇ', 'nye'],
            ['にょ', 'nyo'],

            ['っびゃ', 'bbya'],
            ['っびぃ', 'bbyi'],
            ['っびゅ', 'bbyu'],
            ['っびぇ', 'bbye'],
            ['っびょ', 'bbyo'],

            ['びゃ', 'bya'],
            ['びぃ', 'byi'],
            ['びゅ', 'byu'],
            ['びぇ', 'bye'],
            ['びょ', 'byo'],

            ['っぴゃ', 'ppya'],
            ['っぴぃ', 'ppyi'],
            ['っぴゅ', 'ppyu'],
            ['っぴぇ', 'ppye'],
            ['っぴょ', 'ppyo'],

            ['ぴゃ', 'pya'],
            ['ぴぃ', 'pyi'],
            ['ぴゅ', 'pyu'],
            ['ぴぇ', 'pye'],
            ['ぴょ', 'pyo'],
            ['っひゃ', 'hhya'],
            ['っひぃ', 'hhyi'],
            ['っひゅ', 'hhyu'],
            ['っひぇ', 'hhye'],
            ['っひょ', 'hhyo'],

            ['っきゃ', 'kkya'],
            ['っきぃ', 'kkyi'],
            ['っきゅ', 'kkyu'],
            ['っきぇ', 'kkye'],
            ['っきょ', 'kkyo'],

            ['っしゃ', 'ssya'],
            ['っしぃ', 'ssyi'],
            ['っしゅ', 'ssyu'],
            ['っしぇ', 'ssye'],
            ['っしょ', 'ssyo'],

            ['っか', 'kka'],
            ['っき', 'kki'],
            ['っく', 'kku'],
            ['っけ', 'kke'],
            ['っこ', 'kko'],

            ['っが', 'gga'],
            ['っぎ', 'ggi'],
            ['っぐ', 'ggu'],
            ['っげ', 'gge'],
            ['っご', 'ggo'],

            ['っさ', 'ssa'],
            ['っし', 'ssi'],
            ['っす', 'ssu'],
            ['っせ', 'sse'],
            ['っそ', 'sso'],

            ['っずぁ', 'zzha'],
            ['っずぃ', 'zzhi'],
            ['っずぅ', 'zzhu'],
            ['っずぇ', 'zzhe'],
            ['っずぉ', 'zzho'],

            ['ずぁ', 'zha'],
            ['ずぃ', 'zhi'],
            ['ずぅ', 'zhu'],
            ['ずぇ', 'zhe'],
            ['ずぉ', 'zho'],

            ['っじゃ', 'zzya'],
            ['っじぃ', 'zzyi'],
            ['っじゅ', 'zzyu'],
            ['っじぇ', 'zzye'],
            ['っじょ', 'zzyo'],

            ['じゃ', 'zya'],
            ['じぃ', 'zyi'],
            ['じゅ', 'zyu'],
            ['じぇ', 'zye'],
            ['じょ', 'zyo'],

            ['っでゃ', 'ddha'],
            ['っでぃ', 'ddhi'],
            ['っでゅ', 'ddhu'],
            ['っでぇ', 'ddhe'],
            ['っでょ', 'ddho'],

            ['でゃ', 'dha'],
            ['でぃ', 'dhi'],
            ['でゅ', 'dhu'],
            ['でぇ', 'dhe'],
            ['でょ', 'dho'],

            ['っぢゃ', 'ddya'],
            ['っぢぃ', 'ddyi'],
            ['っぢゅ', 'ddyu'],
            ['っぢぇ', 'ddye'],
            ['っぢょ', 'ddyo'],

            ['ぢゃ', 'dya'],
            ['ぢぃ', 'dyi'],
            ['ぢゅ', 'dyu'],
            ['ぢぇ', 'dye'],
            ['ぢょ', 'dyo'],

            ['っざ', 'zza'],
            ['っじ', 'zzi'],
            ['っじ', 'jji'],
            ['っず', 'zzu'],
            ['っぜ', 'zze'],
            ['っぞ', 'zzo'],

            ['った', 'tta'],
            ['っち', 'tti'],
            ['っつ', 'ttu'],
            ['って', 'tte'],
            ['っと', 'tto'],

            ['っだ', 'dda'],
            ['っぢ', 'ddi'],
            ['っづ', 'ddu'],
            ['っで', 'dde'],
            ['っど', 'ddo'],

            ['んあ', 'nna'],
            ['んい', 'nni'],
            ['んう', 'nnu'],
            ['んえ', 'nne'],
            ['んお', 'nno'],

            ['っは', 'hha'],
            ['っひ', 'hhi'],
            ['っふ', 'hhu'],
            ['っへ', 'hhe'],
            ['っほ', 'hho'],
            ['っば', 'bba'],
            ['っび', 'bbi'],
            ['っぶ', 'bbu'],
            ['っべ', 'bbe'],
            ['っぼ', 'bbo'],
            ['っぱ', 'ppa'],
            ['っぴ', 'ppi'],
            ['っぷ', 'ppu'],
            ['っぺ', 'ppe'],
            ['っぽ', 'ppo'],

            ['っま', 'mma'],
            ['っみ', 'mmi'],
            ['っむ', 'mmu'],
            ['っめ', 'mme'],
            ['っも', 'mmo'],

            ['っや', ' yya'],

            ['っゆ', 'yyu'],

            ['っよ', 'yyo'],

            ['っら', 'rra'],
            ['っり', 'rri'],
            ['っる', 'rru'],
            ['っれ', 'rre'],
            ['っろ', 'rro'],

            ['っわ', 'wwa'],
            ['っを', 'wwo'],
            ['んｎ', 'nnn'],

            ['っじゃ', 'jja'],
            ['っじゅ', 'jju'],
            ['っじぇ', 'jje'],
            ['っじょ', 'jjo'],

            ['じゃ', 'ja'],
            ['じゅ', 'ju'],
            ['じぇ', 'je'],
            ['じょ', 'jo'],
            ///
            ['っちゃ', 'ttya'],
            ['っちぃ', 'ttyi'],
            ['っちぇ', 'ttye'],
            ['っちゅ', 'ttyu'],
            ['っちょ', 'ttyo'],

            ['ちゃ', 'tya'],
            ['ちぃ', 'tyi'],
            ['ちぇ', 'tye'],
            ['ちゅ', 'tyu'],
            ['ちょ', 'tyo'],

            ['っしゃ', 'ssya'],
            ['っしぃ', 'ssyi'],
            ['っしぇ', 'ssye'],
            ['っしゅ', 'ssyu'],
            ['っしょ', 'ssyo'],
            ['っしゃ', 'ssha'],
            ['っし', 'sshi'],
            ['っしぇ', 'sshe'],
            ['っしゅ', 'sshu'],
            ['っしょ', 'ssho'],

            ['しゃ', 'sya'],
            ['しぃ', 'syi'],
            ['しぇ', 'sye'],
            ['しゅ', 'syu'],
            ['しょ', 'syo'],
            ['しゃ', 'sha'],
            ['し', 'shi'],
            ['しぇ', 'she'],
            ['しゅ', 'shu'],
            ['しょ', 'sho'],

            ['っじゃ', 'jjya'],
            ['っじぃ', 'jjyi'],
            ['っじぇ', 'jjye'],
            ['っじゅ', 'jjyu'],
            ['っじょ', 'jjyo'],

            ['じゃ', 'jya'],
            ['じぃ', 'jyi'],
            ['じぇ', 'jye'],
            ['じゅ', 'jyu'],
            ['じょ', 'jyo'],

            ['きゃ', 'kya'],
            ['きぃ', 'kyi'],
            ['きゅ', 'kyu'],
            ['きぇ', 'kye'],
            ['きょ', 'kyo'],

            ['っぎゃ', 'ggya'],
            ['っぎぃ', 'ggyi'],
            ['っぎゅ', 'ggyu'],
            ['っぎぇ', 'ggye'],
            ['っぎょ', 'ggyo'],

            ['ぎゃ', 'gya'],
            ['ぎぃ', 'gyi'],
            ['ぎゅ', 'gyu'],
            ['ぎぇ', 'gye'],
            ['ぎょ', 'gyo'],

            ['っりゃ', 'rrya'],
            ['っりぃ', 'rryi'],
            ['っりゅ', 'rryu'],
            ['っりぇ', 'rrye'],
            ['っりょ', 'rryo'],

            ['りゃ', 'rya'],
            ['りぃ', 'ryi'],
            ['りゅ', 'ryu'],
            ['りぇ', 'rye'],
            ['りょ', 'ryo'],

            ['ひゃ', 'hya'],
            ['ひぃ', 'hyi'],
            ['ひゅ', 'hyu'],
            ['ひぇ', 'hye'],
            ['ひょ', 'hyo'],

            ///
            //////
            ['っふぁ', 'ffa'],
            ['っふぃ', 'ffi'],
            ['っふ', 'ffu'],
            ['っふぇ', 'ffe'],
            ['っふぉ', 'ffo'],

            ['ふぁ', 'fa'],
            ['ふぃ', 'fi'],
            ['ふ', 'fu'],
            ['ふぇ', 'fe'],
            ['ふぉ', 'fo'],

            ['っみゃ', 'mmya'],
            ['っみぃ', 'mmyi'],
            ['っみゅ', 'mmyu'],
            ['っみぇ', 'mmye'],
            ['っみょ', 'mmyo'],

            ['みゃ', 'mya'],
            ['みぃ', 'myi'],
            ['みゅ', 'myu'],
            ['みぇ', 'mye'],
            ['みょ', 'myo'],

            ['っゔぁ', 'vva'],
            ['っゔぃ', 'vvi'],
            ['っゔ', 'vvu'],
            ['っゔぇ', 'vve'],
            ['っゔぉ', 'vvo'],

            ['ゔぁ', 'va'],
            ['ゔぃ', 'vi'],
            ['ゔ', 'vu'],
            ['ゔぇ', 've'],
            ['ゔぉ', 'vo'],

            ['っう゛ぁ', 'vva'],
            ['っう゛ぃ', 'vvi'],
            ['っう゛', 'vvu'],
            ['っう゛ぇ', 'vve'],
            ['っう゛ぉ', 'vvo'],

            ['う゛ぁ', 'va'],
            ['う゛ぃ', 'vi'],
            ['う゛', 'vu'],
            ['う゛ぇ', 've'],
            ['う゛ぉ', 'vo'],

            ['か', 'ka'],
            ['き', 'ki'],
            ['く', 'ku'],
            ['け', 'ke'],
            ['こ', 'ko'],

            ['が', 'ga'],
            ['ぎ', 'gi'],
            ['ぐ', 'gu'],
            ['げ', 'ge'],
            ['ご', 'go'],

            ['さ', 'sa'],
            ['し', 'si'],
            ['す', 'su'],
            ['せ', 'se'],
            ['そ', 'so'],

            ['ざ', 'za'],
            ['じ', 'zi'],
            ['じ', 'ji'],
            ['ず', 'zu'],
            ['ぜ', 'ze'],
            ['ぞ', 'zo'],

            ['た', 'ta'],
            ['ち', 'ti'],
            ['つ', 'tu'],
            ['て', 'te'],
            ['と', 'to'],

            ['だ', 'da'],
            ['ぢ', 'di'],
            ['づ', 'du'],
            ['で', 'de'],
            ['ど', 'do'],

            ['な', 'na'],
            ['に', 'ni'],
            ['ぬ', 'nu'],
            ['ね', 'ne'],
            ['の', 'no'],

            ['は', 'ha'],
            ['ひ', 'hi'],
            ['ふ', 'hu'],
            ['へ', 'he'],
            ['ほ', 'ho'],
            ['ば', 'ba'],
            ['び', 'bi'],
            ['ぶ', 'bu'],
            ['べ', 'be'],
            ['ぼ', 'bo'],
            ['ぱ', 'pa'],
            ['ぴ', 'pi'],
            ['ぷ', 'pu'],
            ['ぺ', 'pe'],
            ['ぽ', 'po'],

            ['ま', 'ma'],
            ['み', 'mi'],
            ['む', 'mu'],
            ['め', 'me'],
            ['も', 'mo'],

            ['や', 'ya'],

            ['ゆ', 'yu'],

            ['よ', 'yo'],

            ['ら', 'ra'],
            ['り', 'ri'],
            ['る', 'ru'],
            ['れ', 're'],
            ['ろ', 'ro'],

            ['わ', 'wa'],
            ['を', 'wo'],

            /////

            ['あ', 'a'],
            ['い', 'i'],
            ['う', 'u'],
            ['え', 'e'],
            ['お', 'o'],
            ['　', ' '],
            ['１', '1'],
            ['２', '2'],
            ['３', '3'],
            ['４', '4'],
            ['５', '5'],
            ['６', '6'],
            ['７', '7'],
            ['８', '8'],
            ['９', '9'],
            ['０', '0'],
            ['、', ','],
            ['。', '.'],
            ['？', '?'],
            ['！', '!'],
            ['ー', '-'],

            ['[', '['],
            [']', ']'],
            ['ん', 'nn']
            /////
        ]
        return Level
    }, []);


    let nextKey = () => {
      if(!isString(text)){
        return;
      }
        /*
            let fingers = [
              ["a", "q", "1", "z"],
              ["2", "w", "s", "x"],
              ["3", "e", "d", "c"],
              ["4", "r", "f", "v", "5", "t", "g", "b"],
              [" "],
              [" "],
              ["6", "y", "h", "n", "7", "u", "j", "m"],
              ["8", "i", "k", ",", "("],
              ["9", "o", "l", ".", ")"],
              ["0", "p", "", "?", "[", "]", "-"],
            ];*/
        let i = 0
        let nextC = ''
        let end = false
        let smallTSU = false
        const small = ['ぁ', 'ぃ', 'ぅ', 'ぇ', 'ぉ', 'ゃ', 'ゅ', 'ょ']


        for (let char of text) {
            if (end && smallTSU) {
                i++
                nextC += char
                smallTSU = false
                continue
            }
            if (end) {
                for (let k of small) {
                    if (k.charAt(0) === char.charAt(0)) {
                        i++
                        nextC += char
                        break
                    }
                }
                break
            }
            if (end) {
                continue
            }
            if (!end && char === henkan.charAt(i)) {
                i++
            } else {
                i++
                end = true
                nextC += char
                if (char === 'っ') {
                    smallTSU = true
                }
                continue
            }
        }

        let ans = ''
        for (let len = 0; len < 5; len++) {
            for (let p of level) {
                if (p[0].length === len)
                    if (p[0] === nextC.substr(0, p[0].length)) {
                        ans = p[1]
                    }
            }
        }
        let j = 0

        let n = ans.charAt(0)
        for (j = 0; j < ans.length; j++) {
            if (ans.charAt(j) === amari.charAt(j)) {
                if (j + 1 < ans.length) n = ans.charAt(j + 1)
                continue
            } else {
                break
            }
        }

        //let finger = 0;
        /*
        let ansFinger = 0;
        for (let f1 of fingers) {
          for (let f2 of f1) {
            if (f2 === n) {
              ansFinger = finger;
            }
          }
          finger++;
        }
    */
        //setFinger(ansFinger);
        setFingerAlpha(n.toUpperCase())
        if (n === '-') {
            setFingerAlpha('Minus')
        }
        if (n === '.') {
            setFingerAlpha('Ten')
        }
        if (n === ',') {
            setFingerAlpha('Kanma')
        }
        if (n === '?') {
            setFingerAlpha('Hatena')
        }
        if (n === '[') {
            setFingerAlpha('Hidari')
        }
        if (n === ']') {
            setFingerAlpha('Migi')
        }
        if (n === '!') {
            setFingerAlpha('Bikkuri')
        }
        if (n === undefined || n === '') {
            setFingerAlpha('Nasi')
        }
        if(n && isString(n)){
        setNextKeyValue(n.toLowerCase());
        }
    };

    useCallback(nextKey, [text, henkan, amari,level]);

    useEffect(nextKey,[amari,henkan,text,level]);

    let callbackfunction2 = () => {
        if(text===undefined||text.charAt===undefined){
          return;
        }

        if (!nowtyping) {
            return
        }
        let tmp_inputstring=inputstring;
        let ok_inputstring='';
        let h = ''
        let amr = ''
        let j = 0
        let countN = 0
      //  let skipped = 0;
        for (let i = 0; i < text.length; i++, j++) {
            /*if(tmp_inputstring.length<j){
              break;
            }*/
            if (tmp_inputstring[j]==="#") {
                i--;
                continue;
            } else {
                let flagA = false

                if (tmp_inputstring.charAt(j) === 'n' && text.charAt(i) === 'ん') {
                    countN++
                    j++
                    i++
                    if (tmp_inputstring.charAt(j) === 'n') {
                        countN++
                        j++
                    }
                }

                for (let y = 0; y < level.length; y++) {
                    let L = level[y]
                    let check1 = true

                    for (let m = 0; m < L[0].length; m++) {
                        if (text.charAt(i + m) === L[0].charAt(m)) {
                        } else {
                            check1 = false
                            break
                        }
                    }
                    for (let n = 0; n < L[1].length; n++) {
                        if (tmp_inputstring.charAt(j + n) === L[1].charAt(n)) {
                        } else {
                            check1 = false

                            break
                        }
                    }
                    if (countN === 2) {
                        h += 'ん'
                        countN = 0
                        //setHenkan(h);
                    } //
                    if (check1) {
                        switch (countN) {
                            case 1:
                            case 2:
                                h += 'ん'
                                countN = 0
                                break
                            default:
                                break
                        }

                        for (let k = 0; k < L[0].length; k++) {
                            h += text.charAt(i + k)
                        }
                        flagA = true
                        i += L[0].length - 1
                        j += L[1].length - 1
                    }
                }
                if (flagA) {
                    ok_inputstring+=tmp_inputstring.charAt(j);
                    continue
                }else{


                  //１文字スキップしてみる
                  //tmp_inputstring=tmp_inputstring.slice(0,j)+"#"+tmp_inputstring.slice(j);
                  if(tmp_inputstring.length>j+1){

                    //skipped++;
                    i--;
                    flagA=true;
                    continue;
                  }else{
                    let iii = 0
                    for (let a of tmp_inputstring) {
                        iii++
                        if (iii > j - countN) {

                            amr += a
                        }
                    }
                    break;

                  }


                }

              //  break
            }
        }

        if(amr.length>0){
          setNg(true);
        }
        //setInputstring(tmp_inputstring);
        setHenkan(h)
        setAmari(amr)
        setOkInputstring(ok_inputstring);

    }
    useEffect(callbackfunction2, [
        amari,
        nowtyping,
        inputstring,
        henkan,
        text,
        level,


    ])

    let mapKeyboard=useRef(null);
    let catchReturn=useRef(null);

    useEffect(() => {

        if (resultMode) {
            window.removeEventListener('keydown', mapKeyboard.current)
            mapKeyboard.current=null;

            if(catchReturn.current!==null){
            }

              catchReturn.current = event => {
                let k = event.key
                if (k.match(/^Enter$/) ) {
                  startTime.current=null;
                  setEndResult(true);
                }
              }
              window.addEventListener('keydown', catchReturn.current);


        }else{


            if (mapKeyboard.current!==null) {
              return;
            }

            mapKeyboard.current = event => {
                let k = event.key








                const input = () => {
                    if(startTime.current===null){
                      startTime.current=Date.now();
                    }
                    setInputstring(prevInputstring => prevInputstring + k)
                }


                //Delete or Backspace で１文字削除
                if (k.match(/^Delete$/) || k.match(/^Backspace$/)) {

                    setInputstring(prevInputstring => prevInputstring.slice(0, -1));
                    //d.play();
                    setHitkey(false)

                    return
                } else {
                    setShowExplanation(false)
                    setHitkey(true)

                }
                if (k.match(/^Shift$/) || k.match(/^Control$/)) {
                }

                if (k.match(/^.$/)) {
                    if (k.match(/^[A-Z]$/)) {
                        input()
                        return
                    }
                    if (k.match(/^[a-z]$/)) {
                        input()
                        return
                    }
                    if (k.match(/^[0-9]$/)) {
                        input()
                        return
                    }
                    if (k.match(/^[-,.!?()]$/)) {
                        input()
                        return
                    }
                    if (k.match(/^[[]$/)) {
                        input()
                        return
                    }
                    if (k.match(/^[\]]$/)) {
                        input()
                        return
                    }
                    if (k.match(/^[ ]$/)) {
                        input()
                        return
                    }
                }
            }
            window.addEventListener('keydown', mapKeyboard.current);

        }

    }, [nextKeyValue,mapKeyboard, resultMode,init,inputstring,fingerAlpha])
    const startTyping = str => {
        setNowtyping(prevNowtyping => true)

        setPage(prevPage => 0)
    }
    let callbackfunction3 = () => {

        if (!nowtyping) {
            return
        }
        if (text === henkan && parseInt(page)>=parseInt(finalPage)) {
          return;
        }
        if (text === henkan) {
            setPage(page + 1)

            setHenkan('')
            setMojisuu(mojisuu + okInputstring.length)
            setInputstring('')


        }
    }
    useEffect(callbackfunction3, [
        okInputstring,
        init,
        startTime,
        nowtyping,
        contents,
        henkan,
        inputstring,
        page,
        text,
    ended,
    finalPage,
        mojisuu,
        speed,
        index
    ])

    const fetchText = str => {
        if (str === undefined) {
            return undefined
        }
        if (str === '') {
            return ''
        }
        let txt = str.split('@')
        let ret = ''
        let i = 0
        for (const t of txt) {
            if (0.1 > i % 2 && i % 2 > -0.1) {
                ret += t
            }
            i++
        }
        return ret
    }
    let [progress, setProgress] = useState(0)
    const progressbar = () => {
        let moji = 0
        for (let p = 0; p < page; p++) {
            moji += genText(p, contents).length
        }
        if (ended === true || text === '') {
            setProgress(100)
        } else {
            setProgress(((henkan.length + moji) * 100) / fetchText(contents).length)
        }
    }
    useEffect(progressbar, [
        text,
        ended,
        henkan,
        page,
        progress,
        inputstring,
        contents
    ])


    const resumeTyping=()=>{
      if(!endResult){
        return;
      }
      window.removeEventListener('keydown', catchReturn.current)
      catchReturn.current=null;
      startTime.current=null;
      let next_index=index+1;
      if(next_index>maxIndex){
        next_index=1;

      }

      setInputstring("");
      setMojisuu(0);
      setIndex(next_index);
      setPage(0);

      setInit(true);
      setResultMode(false);
      setEndResult(false);//順番重要

    }
    useEffect(resumeTyping,[endResult,index,maxIndex,page,init]);


    let ref = useRef(React.createRef())
    //キーボードの入力を受け取り、変換する





    const PrevCTS = str => {
        for (let t of TC) {
            if (t.text === str) {
                for (let t2 of TC) {
                    if (t2.index === t.index - 1 && t2.id === t.id) {
                        return t2.text
                    }
                }
            }
        }
        return false
    }
    const NextCTS = str => {
        for (let t of TC) {
            if (t.text === str) {
                for (let t2 of TC) {
                    if (t2.index === t.index + 1 && t2.id === t.id) {
                        return t2.text
                    }
                }
            }
        }
        return false
    }

    if (nowtyping === false) {
        setNowtyping(true)

        startTyping(contents)
    }
    //const nowContents = contents;
    const Comment = speed => {
        if (speed > 300) {
            return '分速300超えおめでとう！！かなりの強者ですね！'
        } else if (speed > 200) {
            return '分速200超えおめでとう！！がんばりました。'
        } else if (speed > 100) {
            return '分速100超えです。おめでとうございます。'
        } else if (speed <= 100) {
            return '最後までがんばりました。おめでとう。'
        }
    }
    const blink = useStyles()
    let buttons = []
    if (page !== 0) {
        if (PrevCTS(contents)) {
            buttons.push(
                <Button
                    key='modoru'
                    onClick={() => {
                        setPage(0)

                        setInputstring('')
                        setNowtyping(true)
                        if (PrevCTS(contents)) {
                            startTyping(PrevCTS(contents))
                        } else {
                            alert('最初のコンテンツです')
                        }
                    }}
                >
                    <SkipPrevious />
                </Button>
            )
        } else {
            buttons.push(
                <Button key='mouitido'>
                    <HighlightOff />
                </Button>
            )
        }
        buttons.push(
            <Button
                key='tugi'
                onClick={() => {
                    setPage(0)

                    setInputstring('')
                    startTyping(contents)
                    setNowtyping(true)
                    ReactGA.modalview(
                        '/' + props.itemId + '/' + props.itemIndex + '/type/'
                    )
                }}
            >
                <Replay />
            </Button>
        )
        if (NextCTS(contents)) {
            buttons.push(
                <Button
                    key='tugi2'
                    onClick={() => {
                        setPage(0)

                        setNowtyping(true)
                        if (NextCTS(contents)) {
                            startTyping(NextCTS(contents))
                        } else {
                            alert('X')
                        }
                    }}
                >
                    <SkipNext />
                </Button>
            )
        } else {
            buttons.push(
                <Button key='dekinai'>
                    <HighlightOff />
                </Button>
            )
        }
    }

    const showResultFunc = () => {

        if(resultMode){
        setDasuuResult(Math.floor(mojisuu))
        setSpeedResult(Math.floor(speed))
      }

    }
    useEffect(showResultFunc, [resultMode, mojisuu, speed ])





    let showHenkan = useMemo(() => {
        let h = henkan.replace(/ /g, '_')
        return h
    }, [henkan])

    let showAmari = useMemo(() => {
        let h = amari.replace(/ /g, '_')
        return h
    }, [amari])
    let h=useHistory();
/*文字がタイプされたかの確認関数*/
    let moji = useMemo(()=>{
      let ret=[];
      let a="";
    //  ret.push(<div className="box14_outer">)
      if(amari.length>0){
        a = showAmari;



//        let str = inputstring.slice(0,-amari.length);
        //backputstring(str);


      }else{

        a="";
      }
      let once = true;
      let henkan = showHenkan.split('');


      for(const charactor of kanji){
        let h = henkan.shift();

        if(h===undefined&&once){



          once=false;
          ret.push(<div className="box14_typing"><b className="box14_inside"><Bookmark className="typing"/><br /><b className="charactor">{charactor}</b><b className="very">{a}</b></b></div>)
        }else if(charactor.toLowerCase()===h){
          ret.push(<div className="box14"><b className="box14_inside"><Bookmark className="checked"/><br /><b className="charactor">{charactor}</b><b className="very">{}</b></b></div>)
        }else{
          ret.push(<div className="box14"><b className="box14_inside"><Bookmark className="notchecked"/><br /><b className="charactor">{charactor}</b><b className="very">{}</b></b></div>)
        }

      }

      //ret.push(</div>);

      return ret;
    },[kanji,showHenkan,showAmari,amari]);


    let TypingGrid=useMemo(()=>{

      let grid=[];
      if(resultMode){
        grid.push(

          <Card >
          <Box width="1200px" height="720px" margin="auto">

          <Button
          color="primary"
          onMouseDown={() => {
            setEndResult(true);
          }}
          >
          <KeyboardReturnIcon />
          タイピングにもどる ( Enter↩︎ )

          </Button>

          <div style={{position:"relative"}}>
          <img src="/img/result2.webp" alt="結果発表" />
          <p style={{position:"absolute" ,top:150, left:867}}><font size ="4"><b>タイプした文字</b></font></p>
          <p style={{position:"absolute" ,top:170, left:860}}><font size ="10"><b>{dasuuResult}</b><b>打</b></font></p>
          <p style={{position:"absolute" ,top:230, left:867}}><font size ="4"><b>タイプする速度</b></font></p>
          <p style={{position:"absolute" ,top:250, left:860}}><font size ="10"><b>{speedResult}</b><b>打/分</b></font></p>

          </div>


          </Box>
          </Card>);
      }else{

        grid.push(

        <Card key='typing'  >
            <Box bgcolor="#eeeeee" height="380px">

                進捗
                <LinearProgress
                    key='progress'
                    color='secondary'
                    variant='determinate'
                    value={progress}
                />
                Speed(Key / 分): {Math.floor(speed)}
                <LinearProgress
                    key='speed'
                    color='primary'
                    variant='determinate'
                    value={speed * 0.1}
                />

                <br />

                {ended && Comment(speed)}




                <CardContent key='typing_contents' >

                    {nowtyping && (
                        <article key='typing_article'>

                            <b>
<div className="box14_outer">
                            {moji}
                            </div>
                            </b>



                                    {showExplanation && (
                                        <center>
                                        <br/><br/>
                                            <b className={blink.explanation} id="center">

                                                {'キーボードを押してタイピングを開始しましょう。'}
                                            </b>

                                        </center>
                                    )}

                                    {!showExplanation && !ended && ' '}
                        </article>
                    )}
                    {!nowtyping && (
                        <article key='article2'>
                            <div key='div2' ref={ref}>

                                {kanji} {text}
                            </div>
                            {inputstring}

                        </article>
                    )}
                </CardContent>
            </Box>
            <div
                style={{
                    position: 'relative'
                }}
            >
                <center>
                    <img
                        src={'/img/key/' + fingerAlpha + '.webp'}
                        width='1100px'
                        alt='指先'
                    />
                    <div
                        style={{
                            position: 'absolute',
                            top: 300,
                            left: 540
                        }}
                    >

                    </div>
                </center>
            </div>
        </Card>
      );

      }

      return grid;

    },[moji,dasuuResult,speedResult,resultMode,inputstring,kanji,fingerAlpha,progress,ended,nowtyping,text,speed,showExplanation,blink,])

      //  {TypingGrid}
    return (
      <ThemeProvider theme={theme}>


        <Container  key="container" width="1200px" m={12}>
                      <Grid container spacing={1}>
                      <Grid item xs={12} >
                      <Card>
                      <Button onMouseDown={()=>{

                        h.goBack();
                      }}>
                      <ArrowBack />
                      タイピングをやめてメニューにもどる

                      </Button>
                    </Card>
                      </Grid>
        <Grid lg={12}>
{TypingGrid}
        </Grid>
          </Grid>
        </Container>
        </ThemeProvider>
    )
}

export default TypingForBeginers
