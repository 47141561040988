import "./App.css";
import Item from "./Item";

import React, { useRef,useMemo, useEffect, useState } from "react";
import ReactGA, {  } from "react-ga";
import { TC } from "./data.js";
import {theme} from './RouterSystem.js'

import {

  Container,
  Grid,
  Card,
  ThemeProvider,


  Button,

} from "@material-ui/core";
import {



} from "react-router-dom";
let dialogRef;
export{dialogRef};
let ctsContexts;
export { ctsContexts};


function App() {

  dialogRef=useRef(null);
  let [url, setUrl] = useState("/");

  let [id, setId] = useState(-1);
  let [index, setIndex] = useState(-1);


  ctsContexts = React.createContext();

  let text = useMemo(() => {
    for (let contents of TC) {
      if (contents.id === id && contents.index === index) {
        return contents.text;
      }
    }
    return "";
  }, [id, index]);

  let pickTopic = () => {
    switch (id) {
      case 1:
        setUrl("/1/");
        ReactGA.pageview(url);
        break;
      case 2:
        setUrl("/2/");
        ReactGA.pageview(url);
        break;
      case 3:
        setUrl("/3/");
        ReactGA.pageview(url);
        break;
      case 4:
        setUrl("/4/");
        ReactGA.pageview(url);
        break;
      case 5:
        setUrl("/5/");
        ReactGA.pageview(url);
        break;
      case 6:
        setUrl("/6/");
        ReactGA.pageview(url);
        break;
      case 7:
        setUrl("/7/");
        ReactGA.pageview(url);
        break;
      case 8:
        setUrl("/8/");
        ReactGA.pageview(url);
        break;
      case 9:
        setUrl("/9/");
        ReactGA.pageview(url);
        break;
      case 99999:
        setUrl("/99999/");
        ReactGA.pageview(url);
        break;

      default:
        setUrl("/");
        ReactGA.pageview(url);
        break;
    }
  };

  useEffect(pickTopic, [url, id, index]);
  const grids = useMemo(() => {

    let tags = [];
    let i = 1;
    let flag=false;
    for (let obj of TC) {

      flag=false;

      if(id===-1&&index===-1){
        if(obj.index===-1){
          flag=true;
        }
      }
      if(id===obj.id){
        flag=true;
      }

      let img = obj.img;
      if (obj.index >= 0) {
        img = "nothing";
      }
      if(flag){
        tags.push(
          <Grid key={"grid" + i.toString()} item xs={12} sm={6} md={4} lg={3}>
            <Item
              itemId={obj.id}
              itemIndex={obj.index}
              id={id}
              index={index}
              setId={setId}
              setIndex={setIndex}
              key={i.toString()}
              img={img}
              imgForTyping={obj.img}
              contents={text}
              tag1={obj.tag1}
              tag2={obj.tag2}
              title={obj.title}
              description={obj.description}
              text={obj.text}
            />
          </Grid>
        );
      }
      i++;
    }
    return tags;
  }, [id,index,text]);
  /*
  const fetchImg = (id) => {
    for (let obj of TC) {
      if (obj.id === id) {
        return obj.img;
      }
    }
  };
*/
  ReactGA.initialize("UA-12519886-24");
  ReactGA.pageview("/");
  document.title = "【タイピング・カフェ】Typing Cafe で楽しく練習";
  document.description =
    "ライトノベルなどを題材にしたタイピングサイト。四字熟語・ことわざ・野球用語などもあります。";


  let containerRef =useRef();




  return (
    <ThemeProvider theme={theme}>
      <Container ref={containerRef} key="container" width="1200px" m={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Card style={{margin:3,padding:3}}>


                <Button
                  onClick={() => {
                    setId(-1);
                    setIndex(-1);

                  }}
                >
                  <font>Home</font>
                </Button>
                <Button
                  onClick={() => {
                    setId(10);
                  }}
                >
                  <font>はじめてのタイピング</font>
                </Button>
                <Button
                  onClick={() => {
                    setId(1);
                  }}
                >
                  <font>四字熟語</font>
                </Button>
                <Button
                  onClick={() => {
                    setId(2);
                  }}
                >
                  <font>ことわざ</font>
                </Button>
                <Button
                  onClick={() => {
                    setId(3);
                  }}
                >
                  <font>「婚約破棄」</font>
                </Button>
                <Button
                  onClick={() => {
                    setId(4);
                  }}
                >
                  <font>「絶対守護剣」</font>
                </Button>
                <Button
                  onClick={() => {
                    setId(5);
                  }}
                >
                <font>「婚約破棄から始まる第２の青春」</font>
                </Button>
                <Button
                  onClick={() => {
                    setId(6);
                  }}
                >
                  <font>１年の特別な日</font>
                </Button>
                <Button
                  onClick={() => {
                    setId(7);
                  }}
                >
                  <font>野球用語</font>
                </Button>
                <Button
                  onClick={() => {
                    setId(8);
                  }}
                >
                  <font>花と植物</font>
                </Button>

                <Button
                  onClick={() => {
                    setId(9);
                  }}
                >
                <font>幼馴染が爵位を継ぐことになると婚約破棄されました</font>
                </Button>


            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card style={{margin:3,padding:3}}>

                このタイピング練習サイトはＰＣでのみ動作致します。
                ジャンルを選んだあと、タイプしたいテキストの左上の 「
                <font color="red">TYPE</font>」
                という文字をクリックして練習を始めよう。<br/>
                ※「<font color="red">GO</font>」というテキストをクリックすると、そのシリーズのテキストの一覧が見れます。※webp画像フォーマットに対応したブラウザでお願いします。





            </Card>
          </Grid>



{grids}







          <Grid item xs={12} />
          <Grid item xs={12}>
            <Card style={{margin:3,padding:3}}>

                このサイトの独自コンテンツの無断転載を禁止いたします。
                <h3>音声素材について</h3>
                <article>
                  魔王魂・Otologic 様より提供して頂いております。
                </article>
                <h3>画像素材について</h3>
                <article>いらすとや様に提供して頂いております。</article>
                <h3>プライバシーポリシー</h3>
                <article>
                  アクセス解析ツールについて
                  当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。このGoogleアナリティクスはトラフィックデータの収集のためにクッキー（Cookie）を使用しております。トラフィックデータは匿名で収集されており、個人を特定するものではありません。
                  また、第三者配信の広告サービス（Googleアドセンス、A8.net）を利用しており、ユーザーの興味に応じた商品やサービスの広告を表示するため、クッキー（Cookie）を使用しております。
                  クッキーを使用することで当サイトはお客様のコンピュータを識別できるようになりますが、お客様個人を特定できるものではありません。
                  Cookieを無効にする方法やGoogleアドセンスに関する詳細は「広告 –
                  ポリシーと規約 – Google」を検索しご確認ください。
                </article>
                <hr></hr>
                <section>
                運営：株式会社ソリスト合唱団<br/>
                代表者:沖井広行<br/>
                所在地:東京都立川市若葉町4-5-10<br/>
                連絡先:okinoi@sorisuto.com<br/>
                電話番号:070-4036-4649<br/>
                </section>

            </Card>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

export default App;
