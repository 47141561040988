import React, {useState,useMemo } from "react";
import {
  BrowserRouter as Router,

  Route,
  Link
} from "react-router-dom";
import {
  Chip,
  Button,
  Card,

  CardActions,
  CardContent,
} from "@material-ui/core";





import { PlayArrow } from "@material-ui/icons";
import Typing from './Typing.js'
import TypingForBeginers from './TypingForBeginers.js'

function Item(props) {

  let [freeStyle, setFreeStyle] = useState("");
  let [text1,setText1]=useState("");
  let [text2,setText2]=useState("");

let go_=useMemo( ()=>{
  let g=[];
  if(props.id!==-1){
    /*トップ画面以外で画像をクリックしたらタイプがはじまる。*/
    if(props.itemId===10){
    g.push(
      <Link to={"/TypingForBeginers/"+props.itemId+"/"+1+"/st"}>
      <PlayArrow />
      <font>Go</font>
    </Link>
    );
  }else{
    g.push(
      <Link to={"/Typing/"+props.itemId+"/"+1+"/st"}>
      <PlayArrow />
      <font>Go</font>
    </Link>
    );
  }



  }else{
    g.push(

    <Button
      onMouseDown={() => {
        if(props.id===-1){
          props.setId(props.itemId);


        }else{


          props.setId(props.itemId);
          props.setIndex(1);
          //props.open(true);


        }
      }}
    >

    <PlayArrow />
    <font>Go</font>
    </Button>
  );
  }
  return g;
},[props]);

  let go = useMemo( ()=>{
    let g=[];
    if(props.id!==-1){
      /*トップ画面以外で画像をクリックしたらタイプがはじまる。*/
      if(props.itemId===10){
        g.push(
          <Link to={"/TypingForBeginers/"+props.itemId+"/1/st"}>
        <img
          src={"/img/" + props.img}
          height={265}
          width={265}
          alt={props.title}
        ></img>
        </Link>
        );
      }else{
        g.push(
          <Link to={"/Typing/"+props.itemId+"/1/st"}>
        <img
          src={"/img/" + props.img}
          height={265}
          width={265}
          alt={props.title}
        ></img>
        </Link>
        );
      }

    }else{
      g.push(

      <Button
        onMouseDown={() => {
          if(props.id===-1){
            props.setId(props.itemId);


          }else{


            props.setId(props.itemId);
            props.setIndex(1);
            //props.open(true);


          }
        }}
      >

        <img
          src={"/img/" + props.img}
          height={265}
          width={265}
          alt={props.title}
        ></img>
      </Button>
    );
    }
    return g;
  },[props]);
  let getContents = (str) => {
    if (str === undefined) {
      return undefined;
    }
    let txt = str.split("@");
    let ret = "";
    let i = 0;
    for (const t of txt) {
      if (1.1 > i % 2 && i % 2 > 0.9) {
        ret += t;
      }
      i++;
    }
    return ret;
  };
  const handle = (e) => {
    setFreeStyle(e.target.value);
  };
  const handle2 = (e) => {
    setText1(e.target.value);
  };
  const handle3 = (e) => {
    setText2(e.target.value);
  };
  if (props.itemId === 99999 && props.itemIndex===1) {
    return (
      <React.Fragment>
      <Router>
        <Card>
          <div className="grid">
          <CardActions>

          <Link to={"/Typing/"+props.itemId+"/"+props.itemIndex+"/st"}>
          <PlayArrow />
          <font>Type</font>

          </Link>


            <Chip size="small" label={props.tag1} />
            <Chip size="small" label={props.tag2} />
          </CardActions>
          <CardContent>
            <form>
              <input type="text" style={{width:260}} value={freeStyle} onChange={handle}></input>
            </form>
            <center>
            <Button onMouseDown={()=>{setFreeStyle("")}}>
            上のテキストをクリア
              </Button>
              </center>
              <br/>
            <b>ふりがな@表記文字＠ふりがな＠表記文字＠ふりがな＠表記文字</b>
            <br/>
            のようにデータを作成して、テキストフィールドに入れて練習してね。
            ※@は半角英数でお願い
              <br/>
            <b>例) まいくてすと@マイクテスト@ほんじつはせいてんなり@本日は晴天なり</b>
            <center>
            <Button onMouseDown={()=>{setFreeStyle("まいくてすと@マイクテスト@ほんじつはせいてんなり@本日は晴天なり")}}>
            例を入力
              </Button>
              </center>
              入力を終えたら「TYPE」しよう。


          </CardContent>
          </div>
        </Card>
        <Route path="/Typing/">
          <Typing />
        </Route>
        <Route path="/TypingForBeginers/">
          <TypingForBeginers/>
        </Route>


        </Router>
      </React.Fragment>
    );
  }

if (props.itemId === 99999 && props.itemIndex===2) {
    return (
      <React.Fragment>
        <Card>
          <div className="grid">
          <CardActions>
            <Button color="primary" onClick={() => {
              props.setId(99999);
              props.setIndex(1);
              props.open(true);}
              }>
              <PlayArrow />
              合成
            </Button>

            <Chip size="small" label={props.tag1} />
            <Chip size="small" label={props.tag2} />
          </CardActions>
          <CardContent>

            <form>
              ふりがな@区切り
              <input type="text" style={{width:260}} value={text1} onChange={handle2}></input>
              漢字入り表記@区切り
              <input type="text" style={{width:260}} value={text2} onChange={handle3}></input>
            </form>
            <center>
            <Button onMouseDown={()=>{
              let t1 = text1.split("@");
              let t2 = text2.split("@");

              let result=""
              for(let o1 of t1){
                result+=o1+"@";
                result+=t2.shift()+"@";



              }
              result=result.slice(0,-1);
              setFreeStyle(result);
              navigator.clipboard.writeText(result);

            }}>
            上の二つを合成し、下に書き出す(クリップボードにもコピー)
              </Button>
              </center>
            <form>
              <input type="text" style={{width:260}} value={freeStyle} onChange={handle}></input>
            </form>

            <center>
            <Button onMouseDown={()=>{setFreeStyle("");setText1("");setText2("");}}>
            上のテキストをすべてクリア
              </Button>
              </center>

          </CardContent>
          </div>
        </Card>

      </React.Fragment>
    );
  } else if (props.img === "nothing") {
    return (
      <React.Fragment>
        <Card>
        <div className="grid">
          <CardActions>
            {props.itemId===10 && <Link to={"/TypingForBeginers/"+props.itemId+"/"+props.itemIndex+"/st"}>  <PlayArrow />
              <font>Type</font>
      </Link>}
            {props.itemId!==10 && <Link to={"/Typing/"+props.itemId+"/"+props.itemIndex+"/st"}>  <PlayArrow />
              <font>Type</font>
            </Link>}





            <font>
            <Chip size="small" label={props.tag1} />
            <Chip size="small" label={props.tag2} />
            </font>
          </CardActions>

          <CardContent >

          <font>
            <b>
              {props.itemIndex}
            </b>
            {getContents(props.text)}
            {props.description}
            </font>

          </CardContent>
          </div>
        </Card>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Card>
          <div className="grid">
          <CardActions>
            {go_}
            <font>
            <Chip size="small" label={props.tag1}></Chip>
            <Chip size="small" label={props.tag2}></Chip>
            </font>
          </CardActions>

          <CardActions>
            {go}
            <font>
            {props.description}
            </font>
          </CardActions>
          </div>
        </Card>
      </React.Fragment>
    );
  }
  //
  /*
          <form>
          <input type="text" value={debugtext} onChange={handle}></input>
          </form>*/
}
export default Item;

//<CardMedia component="img" image={'img/'+props.img} title='eyecatch'>
//</CardMedia>
//<article>
//            <h3>{props.title}</h3>
//           {props.description}
//       </article>
