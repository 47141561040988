let TC = [
  {
    id: 10,
    index: -1,
    img: "ASDFJKL.webp",
    title: "ホームポジション",
    text: "ホームポジション",
    tag1: "はじめて",
    tag2: "ローマ字入力",
  },
  {
    id: 11,
    index: -1,
    img: "gramcom40.webp",
    title: "Gramcom40",
    text: "英語を体系的に40の法則で学ぶ",
    tag1: "英語学習",
    tag2: "ローマ字入力",

  },
  {
    id: 11,
    index: 1,
    img: "gramcom40.webp",
    title: "Gramcom40",
    text: "このぺんはよくかける@このペンはよく書ける@This pen writes well.@This pen writes well.",
    tag1: "英語学習",
    tag2: "ローマ字入力",

  },
  {
    id: 1,
    index: -1,
    img: "4ji.webp",
    title: "四字熟語",
    text: "楽しくタイピング練習しよう",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: -1,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "@ことわざでタイピング練習",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 9,
    index: -1,
    img: "tougoku.webp",
    title: "東国不動",
    text: "東国不動",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },

  {
    id: 3,
    index: -1,
    img: "leo.webp",
    title: "婚約破棄",
    text: "@ラノベでタイピング練習をしよう。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: -1,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "ライトノベルでタイピング練習をしよう。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: -1,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "ライトノベルでタイピング練習をしよう。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: -1,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "特別な日、祝日でタイピング練習をしよう。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: -1,
    img: "yakyu.webp",
    title: "野球用語",
    text: "野球用語でタイピング練習をしよう。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: -1,
    img: "hana.webp",
    title: "花と植物",
    text: "花と植物でタイピング練習をしよう。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },

  {
    id: 10,
    index: 1,
    img: "ASDFJKL.webp",
    title: "ホームポジション",
    text: "ffffffff@FFFFFFFF@jjjjjjjj@JJJJJJJJ@ffffffff@FFFFFFFF@jjjjjjjj@JJJJJJJJ",
    tag1: "はじめて",
    tag2: "ローマ字入力",
  },
  {
    id: 10,
    index: 2,
    img: "ASDFJKL.webp",
    title: "ホームポジション",
    text: "jfjfjfjf@JFJFJFJF@fjfjfjfj@FJFJFJFJ@",/*"aiueo@あいうえお@ai@愛@ue@上@aoi@青い@oi@おい@oiaiue@おい愛上@aoiie@青い家",*/
    tag1: "はじめて",
    tag2: "ローマ字入力",
  },
  /*
  {
    id: 10,
    index: 3,
    img: "ASDFJKL.webp",
    title: "ホームポジション",
    text: "kakikukeko@かきくけこ@kokukaki@濃く書き(こくかき)@kakikaki@柿書き(かきかき)@kakuko@書く子(かくこ)@kike@聞け(きけ)@kikaku@企画(きかく)",
    tag1: "はじめて",
    tag2: "ローマ字入力",
  },
  {
    id: 10,
    index: 4,
    img: "ASDFJKL.webp",
    title: "ホームポジション",
    text: "sasisuseso@さしすせそ@susi@寿司(すし)@sasusosi@指す阻止(さすそし)@sasesusi@刺せ寿司@souka@そうか@ukauka@うかうか@sika@鹿(しか)@akaiie@赤い家@aoiie@青い家",
    tag1: "はじめて",
    tag2: "ローマ字入力",
  },
  {
    id: 10,
    index: 5,
    img: "ASDFJKL.webp",
    title: "ホームポジション",
    text: "tatituteto@たちつてと@takaiie@高い家@kataiie@堅い家@tikaiie@近い家@totitoie@土地と家@sikasi@しかし@tatiuo@太刀魚(たちうお)@saka@坂（さか）@asita@明日(あした)@tetu@鉄(てつ)",
    tag1: "はじめて",
    tag2: "ローマ字入力",
  },
  {
    id: 10,
    index: 6,
    img: "ASDFJKL.webp",
    title: "ホームポジション",
    text: "naninuneno@なにぬねの@nanisonoie@何その家@nekonoie@猫の家@sikasi@しかし@tatiuonoie@太刀魚の家(たちうおのいえ)@souka@そうか@konoaoiietoakaiie@この青い家と赤い家@nekonoesa@猫の餌@nununununu@ぬぬぬぬぬ@nani@何@sikatanai@しかたない",
    tag1: "はじめて",
    tag2: "ローマ字入力",
  },
  {
    id: 10,
    index: 7,
    img: "ASDFJKL.webp",
    title: "ホームポジション",
    text: "gagigugego@がぎぐげご@aoiiegasuki@青い家が好き@akaiienokagi@赤い家の鍵@konototinogaki@この土地のガキ@gugugegego@ぐぐげげご@kagitogaki@鍵とガキ@gugugegego@ぐぐげげご@kagutokagi@家具と鍵@gekonoie@ゲコの家@saketogeko@酒と下戸@kagitokagu@鍵と家具",
    tag1: "はじめて",
    tag2: "ローマ字入力",
  },
  {
    id: 10,
    index: 8,
    img: "ASDFJKL.webp",
    title: "ホームポジション",
    text: "yayuyo@やゆよ@yaaneko@やあ猫@yooneko@よお猫@yoyuunaneko@余裕な猫@aoiieyo@青い家よ@akaiieyo@赤い家よ@yoyuugaaruie@余裕がある家@yayusuruneko@揶揄する猫@yakeigaiiyo@夜景がいいよ@yuuyogaaruyo@猶予があるよ@yuuyogayoyuuyo@猶予が余裕よ",
    tag1: "はじめて",
    tag2: "ローマ字入力",
  },
*/

  {
    id: 1,
    index: 1,
    img: "4ji.webp",
    title: "四字熟語",
    text: "こぐんふんとう@「孤軍奮闘」@えんじょするみかたや、@援助する味方や、@たすけのてをさしのべるものが@助けの手を差し伸べる者が@だれもいないじょうきょうでも、@誰もいない状況でも、@ひとりでけんめいにがんばること。@一人で懸命に頑張ること。@また、こんなんなじょうきょうに@また、困難な状況に@おかれているなかでも、@置かれている中でも、@おこたることなく@怠ることなく@ひとりでどりょくすること。@一人で努力すること。@こぐんとは、@「孤軍」とは、@そのなのとおり@その名の通り@こりつしたぐんたいのことをあらわし、@孤立した軍隊のことをあらわし、@えんぐんがのぞめないような@援軍が望めないような@せんきょうのさなか、@戦況のさなか、@こりつしたぐんが@孤立した軍が@ひっしにたたかうといういみから、@必死に戦うという意味から、@このよじじゅくごがうまれた。@この四字熟語が生まれた。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 2,
    img: "4ji.webp",
    title: "四字熟語",
    text: "けんこんいってき@「乾坤一擲」@てんにまかせて、@天にまかせて、@うんめいをかけたいちかばちかの@運命を賭けたいちかばちかの@おおきなしょうぶをすること。@大きな勝負をすること。@けんこんとは、てんとち、いんとようをしめすが、@「乾坤」とは、天と地、陰と陽を示すが、@このばあいはてんじてさいころの、@この場合は転じてサイコロの、@ぐうすうときすうのめをいみする。@偶数と奇数の目を意味する。@いってきとは、さいころをいちどなげて、@「一擲」とは、サイコロを一度投げて、@そのでめでしょうはいをきめること。@その出目で勝敗を決めること。@しゅってんはかんゆの@出典は韓愈の@こうこうをすぐというしから。@「鴻溝を過ぐ」という詩から。@げんぶんは@原文は@まことにいってきをなしてけんこんをとす。@「真に一擲を成して乾坤を賭す」。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 3,
    img: "4ji.webp",
    title: "四字熟語",
    text: "ひゃっかりょうらん@「百花繚乱」@いろいろなはなが、@色々な花が、@はなやかにさきみだれること。@華やかに咲き乱れること。@てんじて、のうりょくにすぐれたじんぶつが、@転じて、能力にすぐれた人物が、@さまざまなぶんやにおいてかずおおくはいしゅつされ、@さまざまな分野において数多く輩出され、@そのけっかとしてじだいをだいひょうするような、@その結果として時代を代表するような、@すばらしいさくひんやぎょうせきが、@すばらしい作品や業績が、@おなじじきにたすうあらわれて、@同じ時期に多数あらわれて、@にぎやかにはんえいすることをいみする。@賑やかに繁栄することを意味する。@ひゃっかはさまざまなしゅるいのおおくのはな、@「百花」はさまざまな種類の多くの花、@りょうらんはいりみだれるといういみで、@「繚乱」は入り乱れるという意味で、@このばあいははなばなが、@この場合は花々が、@たくさんさきみだれているようすをしめす。@たくさん咲き乱れている様子を示す。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 4,
    img: "4ji.webp",
    title: "四字熟語",
    text: "けいこうぎゅうご@「鶏口牛後」@おおきなだんたいやしゅうだん、そしきで@大きな団体や集団、組織で@したのほうのちいについているよりも、@下のほうの地位に就いているよりも、@しょうきぼなあつまりでもかまわないから、@小規模な集まりでも構わないから、@そのりーだーやだいひょうとなっておもんじられたほうがよい、@そのリーダーや代表となって重んじられたほうがよい、@というかんがえかたのこと。@という考え方のこと。@むしろけいこうとなるも、ぎゅうごとなるなかれ@「寧しろ鶏口と為るも、牛後と為る無かれ」@というしきのいっせつをりゃくしたもの。@という史記の一節を略したもの。@けいこうはにわとりのくちばしのいみで、@「鶏口」はにわとりのくちばしの意味で、@てんじてじゃくしょうなそしきのおさやかしらをいみする。@転じて弱小な組織の長や頭を意味する。@ぎゅうごはうしのおしりのいみで、@「牛後」は牛のお尻の意味で、@てんじてきょうだいなそしきにしたがうものをいみする。@転じて強大な組織に従う者を意味する。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 5,
    img: "4ji.webp",
    title: "四字熟語",
    text: "せいてんはくじつ@「青天白日」@よくすんでくもひとつない、@よく澄んで雲一つない、@はれわたったあおぞらのこと。@晴れ渡った青空のこと。@てんじて、こころのなかにやましいことや、@転じて、心の中にやましいことや、@りょうしんにはじるようなうしろぐらいきもちがなにもなく、@良心に恥じるような後ろ暗い気持ちが何もなく、@けっぱくであること。@潔白であること。@さらにてんじて、@さらに転じて、@ひとにかけられたうたがいがはれることや、@人にかけられた疑いが晴れることや、@さいばんなどでむじつやむざいであることがはんめいすることもしめす。@裁判などで無実や無罪であることが判明することも示す。@はくじつはあおいそらのもと、@「白日」は青い空のもと、@しろくかがやくたいようをいみする。@白く輝く太陽を意味する。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 6,
    img: "4ji.webp",
    title: "四字熟語",
    text: "えこひいき@「依怙贔屓」@じぶんのおきにいりのものだけにたいして、@自分のお気に入りの者だけに対して、@かたいれをしたりみかたすること。@肩入れをしたり味方すること。@えこはほんらいはぶっきょうようごで、@「依怙」は本来は仏教用語で、@あるものをたよりにしてよりかかる@「あるものを頼りにして依りかかる」@といういみであるが、@という意味であるが、@てんじてげんざいではあるものだけをたよる@転じて現在では「ある者だけを頼る」@というふこうへいのいみとなった。@という不公平の意味となった。@ひいきはがんらいひきとよみ、@「贔屓」は元来「ひき」と読み、@ごげんはちゅうごくにおけるかめににた@語源は中国における亀に似た@でんせつじょうのせいぶつ。@伝説上の生物。@ほんらいのいみは@本来の意味は@はないきをあらだててどりょくすることであるが、@鼻息を荒立てて努力することであるが、@やはりてんじてげんざいでは@やはり転じて現在では@きにいったひとのみをえんじょするといういみとなった。@「気に入った人のみを援助する」という意味となった。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 7,
    img: "4ji.webp",
    title: "四字熟語",
    text: "がんこうしゅてい@「眼高手低」@かいがやぶんしょうといった、@絵画や文章といった、@げいじゅつなどのぶんやについてみるめはあるが、@芸術などの分野について見る目はあるが、@いざじぶんでそうさくするとへたであり、@いざ自分で創作すると下手であり、@じしんがもつじっさいののうりょくやぎじゅつはひくいこと。@自身が持つ実際の能力や技術は低いこと。@きょうようやちしきはあり、@教養や知識はあり、@あれこれとかんそうをのべたりひひょうはできるのだが、@あれこれと感想を述べたり批評はできるのだが、@じっさいにさくひんなどをつくらせてみると、@実際に作品などを作らせてみると、@めがこえているわりにちせつであるさま。@目が肥えているわりに稚拙であるさま。@てんじて、りそうはたかいものの、@転じて、理想は高いものの、@じつりょくやじっこうりょくがともなわないことをいみする。@実力や実行力が伴わないことを意味する。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 8,
    img: "4ji.webp",
    title: "四字熟語",
    text: "てんいむほう@「天衣無縫」@てんいはてんにんやてんにょのきもののことであり、@「天衣」は天人や天女の着物のことであり、@そのころもはむほう、@その衣は「無縫」、@すなわちぬいめのあとがいっさいないことから、@すなわち縫い目の跡が一切ないことから、@しいかやぶんしょうなどに@詩歌や文章などに@よけいなぎこうをこらしたけいせきがなく、@余計な技巧を凝らした形跡がなく、@しぜんでいてわざとらしくなく、@自然でいてわざとらしくなく、@かんせいされていること。@完成されていること。@てんじて、ひとがらがじゅんしんであり、@転じて、人柄が純真であり、@かざりけがなくすなおでいやみがないさま。@飾り気がなく素直で嫌味がないさま。@さらにてんじて、@さらに転じて、@ものごとがかんぺきであることを@物事が完璧であることを@けいようするばあいにももちいられる。@形容する場合にも用いられる。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 9,
    img: "4ji.webp",
    title: "四字熟語",
    text: "けんけんふくよう@「拳拳服膺」@きょうしやじょうし、りょうしんなどの@教師や上司、両親などの@そんけいするじんぶつのことばやおしえなどを、@尊敬する人物の言葉や教えなどを、@きもにめいじてけっしてわすれずにいること。@肝に命じて決して忘れずにいること。@けんけんは、@「拳拳」は、@りょうてでたいせつにものをささげもつこと。@両手で大切に物を捧げ持つこと。@てんじて、つつしみぶかくうやうやしいようすをあらわす。@転じて、慎み深く恭しい様子をあらわす。@ふくようは、@「服膺」は、@むねにくっつけてはなさないことであり、@胸にくっつけて離さないことであり、@てんじて、こころにとどめてつねにおぼえておく@転じて、心に留めて常に覚えておく@といういみとなった。@という意味となった。@しゅってんは@出典は@じゅきょうのきほんてきなきょうてんである@儒教の基本的な経典である@らいきのいっぺんちゅうようから。@「礼記」の一篇「中庸」から。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 10,
    img: "4ji.webp",
    title: "四字熟語",
    text: "りゅうとうだび@「竜頭蛇尾」@さいしょのほうはいきおいがよくさかんであるが、@最初の方は勢いが良く盛んであるが、@おわりはしっそくしてしまい、@終わりは失速してしまい、@いきおいがおとろえてしまうこと。@勢いが衰えてしまうこと。@あたまのぶぶんはおそろしいりゅうのようにりっぱだが、@頭の部分は恐ろしい竜のように立派だが、@しっぽはへびのようにほそくひんじゃくで、@尻尾は蛇のように細く貧弱で、@ぜんごのつりあいがとれないといういみから。@前後の釣り合いが取れないという意味から。@しゅってんはちゅうごくのぜんしょししょである@出典は中国の禅宗史書である@けいとくでんとうろくより。@「景徳伝灯録」より。@がんらいはそうが、@元来は僧が、@いっけんするとさとりをひらいているかにおもえるが、@「一見すると悟りを開いているかに思えるが、@じつのところはみかけだおしである@実のところは見掛け倒しである」@というぶんみゃくでつかわれていたが、@という文脈で使われていたが、@そこからてんじてげんざいのいみになった。@そこから転じて現在の意味になった。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 11,
    img: "4ji.webp",
    title: "四字熟語",
    text: "たざんのいし@「他山之石」@ほかのやまからさいくつされた、@他の山から採掘された、@とるにたらないようなそまつないしでも、@取るに足らないような粗末な石でも、@じぶんのいしをきれいにみがくためにはやくにたつ、@自分の石を綺麗に磨くためには役に立つ、@といういみからてんじて、@という意味から転じて、@たにんのあやまちや@他人の誤ちや@じぶんよりおとったつまらないげんどうでも、@自分より劣ったつまらない言動でも、@じぶんのちしきやきょうよう、@自分の知識や教養、@じんかくをみがくためにはやくだつこともある、@人格を磨くためには役立つこともある、@というたとえ。@というたとえ。@しゅってんはきげんぜんにへんさんされた、@出典は紀元前に編纂された、@ちゅうごくさいこのしへんであるしきょうより。@中国最古の詩篇である「詩経」より。@げんぶんは、@原文は、@たざんのいしもってぎょくをみがくべし。@「他山之石以って玉を攻くべし」。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 12,
    img: "4ji.webp",
    title: "四字熟語",
    text: "かんこつだったい@「換骨奪胎」@せんじんがもちいた、@先人が用いた、@しやぶんしょうのひょうげん、@詩や文章の表現、@けいしきをもとに、@形式を基に、@みずからのそういをくわえることによって、@自らの創意を加えることによって、@どくじのさくひんをうみだすこと。@独自の作品を生み出すこと。@もともとは、@元々は、@しゅうれんをつみせんにんになることをりそうとする、@修練を積み仙人になることを理想とする、@どうきょうのようご。@道教の用語。@かんこつはじゅつをつかい、@「換骨」は術を使い、@にんげんのほねをせんにんのほねにとりかえる@人間の骨を仙人の骨に取り換える@といういみで、@という意味で、@てんじてこじんのしぶんのいみはそのままに、@転じて古人の詩文の意味はそのままに、@じくをかえること。@字句を変えること。@だったいはたいばんをうばい、@「奪胎」は胎盤を奪い、@うまれかわらせるといういみで、@生まれ変わらせるという意味で、@てんじてこじんのしぶんのないようをてほんにして、@転じて古人の詩文の内容を手本にして、@つくりかえること。@作り変えること。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 13,
    img: "4ji.webp",
    title: "四字熟語",
    text: "こううんりゅうすい@「行雲流水」@こううんは、@「行雲」は、@そらにうかんでながれていくくものこと。@空に浮かんで流れて行く雲のこと。@りゅうすいはながれるみずのこと。@「流水」は流れる水のこと。@それらのようにきまったかたちをもたず、@それらのように決まった形を持たず、@よどみなくうつりかわるしぜんのすがたをあらわす。@淀みなく移り変わる自然の姿をあらわす。@てんじて、@転じて、@なにごとにもふかくこしつせず、@何事にも深く固執せず、@しぜんななりゆきにまかせて@自然な成り行きに任せて@こうどうするようすのたとえ。@行動する様子のたとえ。@さらにてんじて、@さらに転じて、@しょこくをあんぎゃししゅぎょうとしてまわる、@諸国を行脚し修行して回る、@ぜんそうのことをしめすばあいもある。@禅僧のことを示す場合もある。@りゅうすいこううんともいい、@「流水行雲」ともいい、@いみはおなじである。@意味は同じである。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 14,
    img: "4ji.webp",
    title: "四字熟語",
    text: "かんかんがくがく@「侃侃諤諤」@じぶんのかんがえをどうどうとえんりょなくいうこと。@自分の考えを堂々と遠慮なく言うこと。@また、おおぜいのひとがあつまって、@また、大勢の人が集まって、@さかんにぎろんをすること。@盛んに議論をすること。@そっちょくでみのりのあるぎろんだけでなく、@率直で実りのある議論だけでなく、@さわがしくてまとまりのないはなしあいや、@騒がしくてまとまりのない話し合いや、@やかましいだけのろんそう、@やかましいだけの論争、@てんじていけんがたいりつしているようすを@転じて意見が対立している様子を@きょうちょうするばあいにももちいられる。@強調する場合にも用いられる。@かんかんはろんごがしゅってんで、@「侃侃」は「論語」が出典で、@ごうちょくでまっすぐにひるまないさま。@剛直でまっすぐにひるまないさま。@がくがくはしきがしゅってんで、@「諤諤」は「史記」が出典で、@えんりょすることなくじぶんのいけんをしゅちょうするさま。@遠慮することなく自分の意見を主張するさま。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 15,
    img: "4ji.webp",
    title: "四字熟語",
    text: "りゅうちょうこが@「竜跳虎臥」@ふでづかいがなんのそくばくもなくのびのびとしており@筆使いが何の束縛もなくのびのびとしており、@さまざまなぎこうをくししていて、@様々な技巧を駆使していて、@いきおいがかんじられること。@勢いが感じられること。@もとはちゅうごくのなんぼくちょうじだいに、@元は中国の南北朝時代に、@りょうのぶていがしんのおうぎしのしょのことを、@梁の武帝が晋の王羲之の書のことを、@ひょうしてしょうさんしたことば。@評して称賛した言葉。@りゅうがいきおいよくてんにむかってとびあがったり、@竜が勢いよく天に向かって飛び上がったり、@とらがだいちにからだをふしたりするようすを、@虎が大地に体を伏したりする様子を、@ふでのいきおいにひゆしたごである。@筆の勢いに比喩した語である。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 16,
    img: "4ji.webp",
    title: "四字熟語",
    text: "いちごいちえ@「一期一会」@いっしょうにいちどきりのであいやえんのこと。@一生に一度きりの出会いや縁のこと。@しょうがいにいちどしかないできごとやきかいを、@生涯に一度しかない出来事や機会を、@さししめすばあいにももちいられる。@指し示す場合にも用いられる。@もともとは@元々は@さどうのこころえをしめしたようごで、@茶道の心得を示した用語で、@せんのりきゅうのことばを@千利休の言葉を@でしがぶんけんにのこしたもの。@弟子が文献に残したもの。@どんなちゃかいでも、@どんな茶会でも、@そのせきはにどとくりかえされることはない、@その席は二度と繰り返されることはない、@いっしょうにいちどのものであるとこころえて、@一生に一度のものであると心得て、@もてなすがわもきゃくもおたがいに、@もてなす側も客もお互いに、@せいいをつくすべき@誠意を尽くすべき@というこころがまえをといたことば。@という心構えを説いた言葉。@いちえはぶっきょうようごで、@「一期」は仏教用語で、@ひとがうまれてからしぬまでのあいだのこと。@人が生まれてから死ぬまでの間のこと。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 17,
    img: "4ji.webp",
    title: "四字熟語",
    text: "いちようらいふく@「一陽来復」@ふゆがおわってはるがくること。@冬が終わって春が来ること。@あたらしいとしがおとずれること。@新しい年が訪れること。@もとはえきのようごである。@元は易の用語である。@げんざいのこよみではじゅうがつげじゅんから、@現在の暦では十月下旬から、@じゅうにがつじょうじゅんごろにあたる@十二月上旬ごろに当たる@きゅうれきのじゅうがつは、@旧暦の十月は、@いんきがきわまるとされているが、@陰気が極まるとされているが、@とうじをむかえる@冬至を迎える@きゅうれきのじゅういちがつには、@旧暦の十一月には、@ようのきがしょうじるといういみ。@陽の気が生じるという意味。@そこからてんじて、@そこから転じて、@わるいできごとがつづいたあとに、@悪い出来事が続いたあとに、@ようやくじたいがこうてんしたり、@ようやく事態が好転したり、@こううんがおとずれることをしめすばあいもある。@幸運が訪れることを示す場合もある。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 18,
    img: "4ji.webp",
    title: "四字熟語",
    text: "おんこちしん@「温故知新」@いぜんにがくしゅうしたことや、@以前に学習したことや、@むかしのじじつやできごとなどを@昔の事実や出来事などを@あらためてしらべなおしたり、@改めて調べ直したり、@かんがえなおしたりして、@考え直したりして、@そこからあたらしいどうりやちしき、@そこから新しい道理や知識、@あいでぃあなどをさぐりあてて、@アイディアなどを探り当てて、@じぶんのけんかいをふかめること。@自分の見解を深めること。@このばあいのおんは、@この場合の「温」は、@さがしもとめるといったいみでもちいられる。@探し求めるといった意味で用いられる。@しゅってんはこうしや、@出典は孔子や、@そのでしのことばをきろくしたろんごで、@その弟子の言葉を記録した「論語」で、@くんどくすると@訓読すると@ふるきをたずねてあたらしきをしるとなる。@「故きを温ねて新しきを知る」となる。@こをことかくのは@「故」を「古」と書くのは@ごひょうきなのでちゅういすること。@誤表記なので注意すること。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 19,
    img: "4ji.webp",
    title: "四字熟語",
    text: "かんじんたいど@「寛仁大度」@こころがおおらかでなさけぶかく、@心がおおらかで情け深く、@にんげんとしてのうつわがおおきいこと。@人間としての器が大きいこと。@かんじんはこころがひろくて、@「寛仁」は心が広くて、@じょうにあついさまをしめす。@情に厚いさまを示す。@たいどはひととしてどりょうがおおきく、@「大度」は人として度量が大きく、@こまごまとしたことやさじにこだわらないさまをしめす。@細々としたことや些事にこだわらないさまを示す。@しゅってんはちゅうごくのれきししょである@出典は中国の歴史書である@かんじょのこうていきで、@「漢書」の高帝紀で、@ぜんかんおうちょうのしょだいこうていである@前漢王朝の初代皇帝である@りゅうほうのひとがらをあらわしたものである。@劉邦の人柄を表したものである。@かんだいということばは、@「寛大」という言葉は、@このよじじゅくごをりゃくしてうまれた。@この四字熟語を略して生まれた。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 20,
    img: "4ji.webp",
    title: "四字熟語",
    text: "ちょうれいぼかい@「朝令暮改」@せいさくやめいれいなどが@政策や命令などが@たえまなくへんこうされて、@絶え間なく変更されて、@そのけっかとして@その結果として@ほうしんなどがころころとかわってしまい、@方針などがころころと変わってしまい、@いっていしないこと。@一定しないこと。@しゅってんはちゅうごくのれきししょ@出典は中国の歴史書@かんじょのしょっかしで、@「漢書」の「食貨志」で、@あさにだしたほうれいが、@朝に出した法令が、@そのひのゆうがたにはもうあらためられてしまい、@その日の夕方にはもう改められてしまい、@とうじののうみんがあっせいにくるしみ、@当時の農民が圧政に苦しみ、@ほんろうされているようすをあらわしたもの。@翻弄されている様子を表したもの。@げんぶんは@原文は@あさにれいしてくれにあらたむとくんどくする。@「朝に令して暮れに改む」と訓読する。@ちょうかいぼれいやちょうかいぼへんもおなじいみ。@「朝改暮令」や「朝改暮変」も同じ意味。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 21,
    img: "4ji.webp",
    title: "四字熟語",
    text: "しきそくぜくう@「色即是空」@ぶっきょうのゆうめいなきょうてんである、@仏教の有名な経典である、@はんにゃしんぎょうのいっせつにあることば。@「般若心経」の一節にある言葉。@このよにそんざいする@この世に存在する@すべてのげんしょうやぶっしつてきなもの、@すべての現象や物質的なもの、@すなわちしきは、@すなわち「色」は、@ほんしつてきにはじったいのない@本質的には実体のない@くうくうむであり、@「空」「空無」であり、@そのことにしゅうちゃくしてはいけないという、@そのことに執着してはいけないという、@ぶっきょうのこんぽんをなすおしえをといたもの。@仏教の根本をなす教えを説いたもの。@てんじて、せけんのひとびとがいだくよくぼうは@転じて、世間の人々が抱く欲望は@ときとしてむいみなものである、@時として無意味なものである、@といったいみにももちいられる。@といった意味にも用いられる。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 22,
    img: "4ji.webp",
    title: "四字熟語",
    text: "くうそくぜしき@「空即是色」@ぶっきょうのおしえをといたよじじゅくご。@仏教の教えを説いた四字熟語。@このよのすべてのぶっしつてきなもの、@この世のすべての物質的なもの、@すなわちしきは、@すなわち「色」は、@こていされたじったいのないくうであるが、@固定された実体のない「空」であるが、@そのじょうたいこそがあらゆるげんしょうをせいりつさせる、@その状態こそがあらゆる現象を成立させる、@しんりであるといういみ。@真理であるという意味。@このばあいのくうとは、@この場合の「空」とは、@すべてをきょむとしてとらえるような@すべてを虚無として捉えるような@ひていてきないみではなく、@否定的な意味ではなく、@くうであるというじょうたいこそがしぜんだ、@「空」であるという状態こそが自然だ、@といったかいしゃくとなることがおおい。@といった解釈となることが多い。@しゅってんであるはんにゃしんぎょうでは、@出典である「般若心経」では@しきそくぜくう、くうそくぜしきとつづく。@「色即是空、空即是色」と続く。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 23,
    img: "4ji.webp",
    title: "四字熟語",
    text: "ごえつどうしゅう@「呉越同舟」@たがいにてきたいするせいりょくや@互いに敵対する勢力や@なかのわるいものどうしが、@仲の悪い者同士が、@おなじところやきょうぐうにいあわせること。@同じ所や境遇に居合わせること。@てきどうしでもきょうつうのこんなんにおちいれば、@敵同士でも共通の困難に陥れば、@りがいのためにきょうりょくすることもいみする。@利害のために協力することも意味する。@ごもえつも、きげんぜんのちゅうごくにおける@「呉」も「越」も、紀元前の中国における@しゅんじゅうじだいのくにのなまえ。@春秋時代の国の名前。@たたかいがさんじゅうはちねんもつづいた@戦いが三十八年も続いた@とされるしゅくてきどうしだが、@とされる宿敵同士だが、@そのりょうこくのひとがおなじふねにのり、@その両国の人が同じ船に乗り、@そのふねがてんぷくしそうになれば、@その船が転覆しそうになれば、@ふだんのにくしみもかんけいなくたすけあうだろう、@普段の憎しみも関係なく助け合うだろう、@とたとえたそんしのこじにゆらいする。@と例えた「孫子」の故事に由来する。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 24,
    img: "4ji.webp",
    title: "四字熟語",
    text: "しょぎょうむじょう@「諸行無常」@このよのなかのすべてのものは、@この世の中のすべてのものは、@つねにへんかしてうつりかわり、@常に変化して移り変わり、@うまれてはきえるというしぜんのせつりをくりかえし、@生まれては消えるという自然の摂理を繰り返し、@えいえんにふへんなものはないということ。@永遠に不変なものはないということ。@じんせいはむなしい@人生は虚しい@といったいみでつかわれることもあるが、@といった意味で使われることもあるが、@しゅってんはぶっきょうのおしえである@出典は仏教の教えである@さんぽういんのひとつで、@「三法印」の一つで、@じぶんのかんきょうがへんかしたり、@自分の環境が変化したり、@としをとっておいたりするのはしかたのないことで、@年を取って老いたりするのは仕方のないことで、@それをあるがままにうけいれて@それをあるがままに受け入れて@こころをおだやかにしなさいという、@心を穏やかにしなさいという、@おしゃかさまのおしえをといたもの。@お釈迦様の教えを説いたもの。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 25,
    img: "4ji.webp",
    title: "四字熟語",
    text: "きんかぎょくじょう@「金科玉条」@とてもじゅうようなきそくやほうりつのこと。@とても重要な規則や法律のこと。@じぶんじしんのかんがえかたやたちばなどにとって、@自分自身の考え方や立場などにとって、@そのよりどころとなるしそうやしんねん、@その拠りどころとなる思想や信念、@るーるなどをいみするばあいもある。@ルールなどを意味する場合もある。@しゅってんはちゅうごくのなんぼくちょうじだいに、@出典は中国の南北朝時代に、@へんさんされたしぶんしゅうであるもんぜんより。@編纂された詩文集である「文選」より。@きんとぎょくはともに、@「金」と「玉」はともに、@きちょうでたいせつなもののこと。@貴重で大切なもののこと。@かとじょうはきまりやほうれいのじょうこうをしめす。@「科」と「条」は決まりや法令の条項を示す。@げんざいではてんじて、@現在では転じて、@きまりにしばられゆうずうがきかないたとえとして、@決まりに縛られ融通がきかない例えとして、@もちいられることもある。@用いられることもある。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 26,
    img: "4ji.webp",
    title: "四字熟語",
    text: "おかめはちもく@「岡目八目」@あるものごとにおいて、とうじしゃよりも@ある物事において、当事者よりも@だいさんしゃであるたちばのもののほうが、@第三者である立場の者のほうが、@しんそうやじょうせい、@真相や情勢、@そんとくなどをただしくはんだんできること。@損得などを正しく判断できること。@いごからしょうじたことばで、@囲碁から生じた言葉で、@じっさいにごをうっているものは、@実際に碁を打っている者は、@かつことにやっきになっているが、@勝つことに躍起になっているが、@かんせんしているがわはよゆうがあり、@観戦している側は余裕があり、@れいせいにばんめんをみて@冷静に盤面を見て@はちもくもさきまでてをよみ、@「八目」も先まで手を読み、@てきかくにちゃくしゅできることもある、@的確に着手できることもある、@といういみから。@という意味から。@おかめはわきからたにんがなにかしているようすをみること。@「岡目」は脇から他人が何かしている様子を見ること。@はためはちもくともいう。@「傍目八目」ともいう。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 27,
    img: "4ji.webp",
    title: "四字熟語",
    text: "いっしゅくいっぱん@「一宿一飯」@とおりすがってひとばんしゅくはくさせてもらい、@通りすがって一晩宿泊させてもらい、@しょくじをいっかいごちそうになること。@食事を一回ごちそうになること。@それぐらいのちょっとしたたすけをうけること。@それぐらいのちょっとした助けを受けること。@てんじて、@転じて、@すこしのこういもわすれてはならないという、@少しの好意でも忘れてはならないという、@きょうくんとしてのことばとして@教訓としての言葉として@もちいられることがある。@用いられることがある。@かつてばくとのあいだでは、@かつて博徒の間では、@たびのとちゅうでいちどでもとめてもらったり、@旅の途中で一度でも泊めてもらったり、@ごはんをふるまわれたりすると、@ご飯を振る舞われたりすると、@それをしょうがいのおんぎとしてうけとめ、@それを生涯の恩義として受け止め、@ぎりをつくすじんぎがあったことから@義理を尽くす仁義があったことから@しょうじたことば。@生じた言葉。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 28,
    img: "4ji.webp",
    title: "四字熟語",
    text: "いんがおうほう@「因果応報」@ひとがよいおこないをすればよいむくいが、@人が良い行ないをすれば良い報いが、@わるいおこないをすればわるいむくいが、@悪い行ないをすれば悪い報いが、@それぞれかえってくるということ。@それぞれ返ってくるということ。@もとはぶっきょうのことばで、@元は仏教の言葉で、@いんはいんねん、すなわちげんいんのこと。@「因」は因縁、すなわち原因のこと。@ぶっきょうようごとしてもちいるさいは、@仏教用語として用いる際は、@かこだけでなくぜんせでのこういもふくまれる。@過去だけでなく前世での行為も含まれる。@がはかほう、@「果」は果報、@すなわちけっかやむくいのこと。@すなわち結果や報いのこと。@いんがとはあることがげんいんとなって、@「因果」とはあることが原因となって、@しょうじたけっかのことをいみする。@生じた結果のことを意味する。@げんざいではあくじをはたらいて、@現在では悪事を働いて、@わるいけっかがでたいみとしてつかわれることがおおい。@悪い結果が出た意味として使われることが多い。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 29,
    img: "4ji.webp",
    title: "四字熟語",
    text: "いんぎんぶれい@「慇懃無礼」@ひとにたいすることばづかいやたいどがていねいすぎて、@人に対する言葉遣いや態度が丁寧すぎて、@かえっていやみにうけとめられたり、@かえって嫌味に受け止められたり、@せいいがこもってないとかんじられたりして、@誠意がこもってないと感じられたりして、@けっかてきにれいをうしなうということ。@結果的に礼を失うということ。@てんじて、ひょうめんてきには@転じて、表面的には@れいぎただしいようなたいどをとっているが、@礼儀正しいような態度を取っているが、@そのじつはそんだいで@その実は尊大で@あいてのことをみくだしているといった、@相手のことを見下しているといった、@ようすのこともしめす。@様子のことも示す。@いんぎんびろうもおなじいみ。@「慇懃尾籠」も同じ意味。@いんぎんななどともちいたばあいは、@「慇懃な」などと用いた場合は、@ひじょうにものごしがやわらかく、@非常に物腰が柔らかく、@れいぎただしいといういみになるのでようちゅうい。@礼儀正しいという意味になるので要注意。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 30,
    img: "4ji.webp",
    title: "四字熟語",
    text: "ふえきりゅうこう@「不易流行」@まつおばしょうのはいくにおける、@松尾芭蕉の俳句における、@しょうふうはいかいのりねんをしめすことば。@蕉風俳諧の理念を示す言葉。@ふえきはいつまでもかわらないもの。@「不易」はいつまでも変わらないもの。@りゅうこうはげんざいとおなじく、@「流行」は現在と同じく、@いちじてきにはやるなどして、@一時的にはやるなどして、@じだいとともにうつりかわるものや、@時代とともに移り変わるものや、@へんかするじょうたいをいみする。@変化する状態を意味する。@このふたつはあいはんするようだが、@この二つは相反するようだが、@へんかしないふえきのなかに、@変化しない「不易」の中に、@あたらしいりゅうこうのしゅほうをとりいれることが@新しい「流行」の手法を取り入れることが@ふえきのほんしつで、@「不易」の本質で、@りゅうこうがきぞんのしゅこうとして@「流行」が既存の趣向として@ていちゃくすればふえきとなることから、@定着すれば「不易」となることから、@ふたつのがいねんはどういつであるというかんがえ。@二つの概念は同一であるという考え。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 31,
    img: "4ji.webp",
    title: "四字熟語",
    text: "いちれんたくしょう@「一蓮托生」@ほんらいはぶっきょうにゆらいすることば。@本来は仏教に由来する言葉。@よいおこないをせいぜんにしたものは、@良い行ないを生前にした者は、@しごにくるしみのないせかいとされる、@死後に苦しみのない世界とされる、@ごくらくじょうどにいくことができ、@極楽浄土に行くことができ、@ほとけさまやかんのんぼさつとおなじように、@仏様や観音菩薩と同じように、@はすのはなのうえにうまれかわること。@蓮の花の上に生まれ変わること。@てんじて、ものごとやけっかのぜんあくにかんけいなく、@転じて、物事や結果の善悪に関係なく、@なかまとうんめいやこうどうをともにすること。@仲間と運命や行動をともにすること。@いちれんはいちりんのはすのはなのこと。@「一蓮」は一輪の蓮の花のこと。@たくしょうはこのよにみをよせて、@「托生」はこの世に身を寄せて、@いきながらえること。@生き長らえること。@たくしょうとかくばあいもあり、おなじいみである。@「託生」と書く場合もあり、同じ意味である。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 32,
    img: "4ji.webp",
    title: "四字熟語",
    text: "そうこうのつま@「糟糠之妻」@まずしいじだいからともに@貧しい時代からともに@くろうをかさねてきたつまのこと。@苦労を重ねてきた妻のこと。@そうこうはさけかすとこめぬかのことで、@「糟糠」は酒かすと米ぬかのことで、@そまつなしょくじでまずしいせいかつのようすをあらわす。@粗末な食事で貧しい生活の様子をあらわす。@てんじて、そのようなくらしをしていたつまを、@転じて、そのような暮らしをしていた妻を、@じぶんがゆうふくになってもだいじにするといういみ。@自分が裕福になっても大事にするという意味。@しゅってんはちゅうごくの@出典は中国の@ごかんおうちょうについてかかれたごかんじょで、@後漢王朝について書かれた「後漢書」で、@こうぶていがぶかのそうこうと@光武帝が部下の宋弘と@じぶんのあねをけっこんさせようとしたが、@自分の姉を結婚させようとしたが、@そうこうがくるしいときもささえてくれたいまのつまと、@宋弘が苦しい時も支えてくれた今の妻と、@りえんはできません、@離縁はできません、@とことわったとされるこじにゆらいする。@と断ったとされる故事に由来する。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 33,
    img: "4ji.webp",
    title: "四字熟語",
    text: "ぜんとようよう@「前途洋洋」@これからのじんせいがあかるいみとおしで@これからの人生が明るい見通しで@おおきくひらけており、@大きく開けており、@きぼうにみちあふれていること。@希望に満ち溢れていること。@ゆきさきがかぎりなくひろくせんたくしなどもおおく、@行き先が限りなく広く選択肢なども多く、@みらいがゆうぼうであること。@未来が有望であること。@ぜんとはもくひょうや、@「前途」は目標や、@しょうらいまでのこんごのみちのりをあらわす。@将来までの今後の道のりをあらわす。@ようようはみずがあふれんばかりに、@「洋洋」は水が溢れんばかりに、@いちめんにみちみちているようすをあらわす。@一面に豊富に満ち満ちている様子をあらわす。@るいぎごにぜんとゆうぼうぜんていばんりなど、@類義語に「前途有望」「前提万里」など、@たいぎごにぜんとたなんなどがある。@対義語に「前途多難」などがある。”",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 34,
    img: "4ji.webp",
    title: "四字熟語",
    text: "しゃくしじょうぎ@「杓子定規」@すべてのものごとをひとつのけいしきではんだんしたり、@すべての物事を一つの形式で判断したり、@いっていのきじゅんでしかものごとをみることができず、@一定の基準でしか物事を見ることができず、@ゆうずうがきかなかったり@融通がきかなかったり@あたまがかたかったりすること。@頭が固かったりすること。@しゃくしはごはんやしるものをすくうときにつかう、@「杓子」はご飯や汁物をすくうときに使う、@えがついているどうぐのこと。@柄がついている道具のこと。@ふるくはそのえがまがっていたことから、@古くはその柄が曲がっていたことから、@わんきょくしているしゃくしのえを@湾曲している杓子の柄を@じょうぎのかわりにつかって、@定規の代わりに使って、@まちがったほうほうでながさをはかろうとしたことにゆらいする。@間違った方法で長さをはかろうとしたことに由来する。@えどじだいのはいかいこんざんしゅうや、@江戸時代の俳諧「崑山集」や、@じょうるりかなでほんちゅうしんぐらに、@浄瑠璃「仮名手本忠臣蔵」に、@このよじじゅくごがみられる。@この四字熟語が見られる。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 35,
    img: "4ji.webp",
    title: "四字熟語",
    text: "ふうりんかざん@「風林火山」@ちゅうごくのそんしぐんそうがしゅってんのひょうほうのひとつで、@中国の「孫子」軍争が出典の兵法の一つで、@いくさにおけるりそうてきなしんぐんのこころがまえをのべたく。@戦における理想的な進軍の心構えを述べた句。@かぜのようにすばやくうごき、@風のように素早く動き、@はやしのようにしずかにかまえて、@林のように静かに構えて、@ひのようにはげしくせめうばい、@火のように激しく攻め奪い、@やまのようにどっしりとかまえてうごかない@山のようにどっしりと構えて動かない@というよっつのいみ。@という四つの意味。@せんごくじだいのゆうめいなぶしょうであるたけだしんげんが、@戦国時代の有名な武将である武田信玄が、@はたじるしにもちいたことでゆうめいである。@旗印に用いたことで有名である。@げんぶんは@原文は@そのはやきことかぜのごとく、@「其の疾きこと風の如く、@そのしずかなることはやしのごとく、@其の徐かなること林の如く、@しんりゃくすることひのごとく、@侵掠すること火の如く、@うごかざることやまのごとし@動かざること山の如し」",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 36,
    img: "4ji.webp",
    title: "四字熟語",
    text: "さこうべん@「左顧右眄」@あれこれとしゅういのことをきにして、@あれこれと周囲のことを気にして、@ああでもない、こうでもないとおもいなやんで、@ああでもない、こうでもないと思い悩んで、@なかなかけつだんをくださないこと。@なかなか決断を下さないこと。@たにんのようすなどをうかがって、@他人の様子などをうかがって、@じぶんのたいどをきめることができないでいるさま。@自分の態度を決めることができないでいるさま。@べんはちらりとよこめでみたり、@「眄」はちらりと横目で見たり、@かえりみたりするといういみ。@顧みたりするという意味。@しゅってんはさんごくしでゆうめいなそうそうの@出典は三国志で有名な曹操の@むすこであるそうしょくのもんぜんごしつにあたうるのしょ。@息子である曹植の「文選」「呉質に与うるの書」。@もとはひだりをみたりみぎをみたりするといういみであるので、@元は左を見たり右を見たりするという意味であるので、@うこさべんとひょうきするのもただしいもちいかたである。@「右顧左眄」と表記するのも正しい用い方である。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 37,
    img: "4ji.webp",
    title: "四字熟語",
    text: "こうがんむち@「厚顔無恥」@たいどなどがあつかましく、@態度などが厚かましく、@ずうずうしくてはじしらずなようすのこと。@ずうずうしくて恥知らずな様子のこと。@たにんのめいわくをかえりみることなく、@他人の迷惑を省みることなく、@じぶんのつごうやかんがえだけでかってにこうどうをするさま。@自分の都合や考えだけで勝手に行動をするさま。@しゅってんはちゅうごくさいこのしへんしゅうであるしきょう。@出典は中国最古の詩篇集である「詩経」。@げんぶんにこうげんくわうのごとく、かおのあつきやとあり、@原文に「巧言くわうの如く、顔の厚きや」とあり、@ことばをたくみにあやつってがいけんをとりつくろい、@言葉を巧みに操って外面を取り繕い、@ないめんのはじやみにくさをかくすいみとして、@内面の恥や醜さを隠す意味として、@こうがんはにほんでもへいあんじだいからもちいられるようになった。@「厚顔」は日本でも平安時代から用いられるようになった。@むちをむちとかくのは、@「無恥」を「無知」と書くのは、@ごようであるのでちゅういすること。@誤用であるので注意すること。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 38,
    img: "4ji.webp",
    title: "四字熟語",
    text: "どうしょういむ@「同床異夢」@おなじとこにまくらをならべてねていても、@同じ床に枕を並べて寝ていても、@ひとはそれぞれちがったゆめをみるということ。@人はそれぞれ違った夢を見るということ。@てんじて、おなじたちばにあったり@転じて、同じ立場にあったり@いっしょにしごとをしているものどうしでも、@一緒に仕事をしている者同士でも、@かんがえかたやもくてきはひとそれぞれで、@考え方や目的は人それぞれで、@おもわくやもくひょうなどがことなることをいみする。@思惑や目標などが異なることを意味する。@しゅってんはちゅうごくなんそうの@出典は中国南宋の@じゅがくしゃであるちんりょうの@儒学者である陳亮の@しゅげんかいにあたうるのしょより。@「朱元晦に与うるの書」より。@ねどこをおなじくしてもべつのゆめをみたならば、@「寝床を同じくしても別の夢を見たならば、@たとえしゅうのくんしゅであるしゅうこうたんであっても、@たとえ周の君主である周公旦であっても、@おたがいのいけんやかんがえはりかいできない@お互いの意見や考えは理解できない」@といういみのげんぶんにゆらいする。@という意味の原文に由来する。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 39,
    img: "4ji.webp",
    title: "四字熟語",
    text: "ちみもうりょう@「魑魅魍魎」@ひとにがいをあたえるような、@人に害を与えるような、@さまざまなしゅるいのようかいやばけもののこと。@様々な種類の妖怪や化け物のこと。@ちみはさんりんからしょうずるかいぶつや、@「魑魅」は山林から生ずる怪物や、@やまのかみのこと。@山の神のこと。@もうりょうはかわやしょうたくからしょうずるかいぶつや、@「魍魎」は川や沼沢から生ずる怪物や、@みずのかみのこと。@水の神のこと。@しゅってんはきげんぜんちゅうごくのれきしをえがいた@出典は紀元前中国の歴史を描いた@こうしのしゅんじゅうのちゅうやくしょである@孔子の「春秋」の注釈書である@しゅんじゅうさしでんより。@「春秋左氏伝」より。@とくのあるおうちょうがしはいしていたころは、@「徳のある王朝が支配していた頃は、@やまやかわでひとがちみもうりょうにであうことはなかった@山や川で人が魑魅魍魎に出会うことはなかった」@というきじゅつにゆらいする。@という記述に由来する。@げんだいではてんじて、@現代では転じて、@しよくのためわるだくみをするものの@私欲のため悪巧みをする者の@ひゆとしてももちいられる。@比喩としても用いられる。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 40,
    img: "4ji.webp",
    title: "四字熟語",
    text: "ぎょくせきこんこう@「玉石混淆」@すぐれているものとおとっているもの、@優れているものと劣っているもの@かちのあるものとないものが、@価値のあるものとないものが、@いりまじっているじょうたいのことを@入り混じっている状態のことを@あらわしたよじじゅくご。@あらわした四字熟語。@ぎょくせきとはほうぎょくといしころのこと。@「玉石」は宝玉と石ころのこと。@そこからてんじて、よいものとわるいもの、@そこから転じて、良いものと悪いもの、@けんじゃとぐしゃのたとえ。@賢者と愚者のたとえ。@こんこうはさまざまなものがまざっていること。@「混淆」は様々なものが混ざっていること。@こうはこうともかくが、@「淆」は「交」とも書くが、@ごうとひょうきするのはあやまりである。@「合」と表記するのは誤りである。@しゅってんはちゅうごくしんおうちょうじだいの@出典は中国晋王朝時代の@どうきょうけんきゅうかであるかっこうがしるしたほうぼくしの@道教研究科である葛洪が記した「抱朴子」の@しんぎてんとうし、ぎょくせきこんこうす@「真偽顛倒し、玉石混淆す」@といういちぶんより。@という一文より。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 41,
    img: "4ji.webp",
    title: "四字熟語",
    text: "りゅうしょうおうし@「竜章鳳姿」@でんせつじょうのせいぶつであり、@伝説上の生物であり、@ともにめでたいとされるりゅうやほうおうのように、@ともにめでたいとされる竜や鳳凰のように、@どうどうとしたようしをしていること。@堂々とした容姿をしていること。@りゅうのようにいさましくいきさかんで、@竜のように勇ましく意気盛んで、@おおとりのようにけだかいすがたをしているようすのこと。@鳳のように気高い姿をしている様子のこと。@このばあいのしょうとは、@この場合の「章」とは、@もようのことをいみする。@模様のことを意味する。@がいけんがりっぱであることのみならず、@外見が立派であることのみならず、@ないめんがじゅうじつしており、@内面が充実しており、@そのえいきょうががいめんにもあらわれたけっか、@その影響が外面にもあらわれた結果、@りっぱなふうさいにみえることを@立派な風采に見えることを@あらわしたよじじゅくごである。@あらわした四字熟語である。@しゅってんはちゅうごくのれきししょである@出典は中国の歴史書である@しんしょのけいこうでんより。@「晋書」の「嵆康伝」より。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 42,
    img: "4ji.webp",
    title: "四字熟語",
    text: "しんらばんしょう@「森羅万象」@てんち、すなわちうちゅうにそんざいする、@天地、すなわち宇宙に存在する、@かぎりないかずのすべてのじぶつやげんしょうのこと。@限りない数のすべての事物や現象のこと。@てんじて、このせかいにそんざいする@転じて、この世界に存在する@すべてのものごとのことをしめす。@すべての物事のことを示す。@しんらはもりのじゅもくがかぎりなくおいしげり、@「森羅」は森の樹木が限りなく生い茂り、@むげんにつらなっているようすのこと。@無限に連なっている様子のこと。@ばんしょうはかたちあるもの、@「万象」は形あるもの、@ゆうけいのものすべてのことをいみする。@有形のものすべてのことを意味する。@しゅってんはぶっきょうのおしえを@出典は仏教の教えを@みじかいしせつでつたえたほっくきょうより。@短い詩節で伝えた「法句経」より。@もともとはぶっきょうようごだったものが、@元々は仏教用語だったものが、@いっぱんにももちいられるようになった。@一般にも用いられるようになった。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 43,
    img: "4ji.webp",
    title: "四字熟語",
    text: "ふわらいどう@「付和雷同」@しっかりとしたかんがえがじぶんになく、@しっかりとした考えが自分になく、@たにんのいけんにたいしてあんいにどうちょうすること。@他人の意見に対して安易に同調すること。@ふわはさだまったいけんをもたず、@「付和」は定まった意見を持たず、@かんたんにたにんのげんどうにさんせいすること。@簡単に他人の言動に賛成すること。@らいどうはかみなりがなると@「雷同」は雷が鳴ると@それにたいしてほかのものがひびくこと。@それに対して他のものが響くこと。@てんじてやはりあんいにたにんにさんどうすることをしめす。@転じてやはり安易に他人に賛同することを示す。@しゅってんはちゅうごくじゅきょうのきょうてんであるらいきの@出典は中国儒教の経典である「礼記」の@そうせつすることなかれ、らいどうすることなかれ@「勦説することなかれ、雷同することなかれ」@といういっせつより。@という一説より。@じしゅせいがなくひとについじゅうするという、@自主性がなく人に追従するという、@まいなすのいめーじでのみつかわれるよじじゅくご。@マイナスのイメージでのみ使われる四字熟語。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 44,
    img: "4ji.webp",
    title: "四字熟語",
    text: "しくはっく@「四苦八苦」@ひじょうにくろうすること。@非常に苦労すること。@たびかさなるくろうをうけること。@度重なる苦労を受けること。@もともとはぶっきょうようごであり、@元々は仏教用語であり、@しくとはしょうろうびょうし、@「四苦」とは生老病死、@すなわちひとがうまれ、@すなわち人が生まれ、@おいたりやまいにたおれたりして、@老いたり病に倒れたりして、@なくなってしまうというよっつのくるしみのこと。@亡くなってしまうという四つの苦しみのこと。@はっくはしくにさらに、@「八苦」は「四苦」にさらに、@したしいものやあいするものとわかれるくるしみ、@親しい者や愛する者と別れる苦しみ、@うらんだりにくんだりするものにあうくるしみ、@恨んだり憎んだりする者に会う苦しみ、@ほしいものがえられないくるしみ、@欲しいものが得られない苦しみ、@ひとのしんしんをけいせいするごうんというようそが@人の心身を形成する「五蘊」という要素が@あることによってしょうじるくるしみをくわえたもの。@あることによって生じる苦しみを加えたもの。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 45,
    img: "4ji.webp",
    title: "四字熟語",
    text: "ないゆうがいかん@「内憂外患」@こくないではっせいしたしんぱいごとと、@国内で発生した心配事と、@がいこくとのあいだでしょうじたもんだいのこと。@外国との間で生じた問題のこと。@ないがいぶにかだいをかかえており、@内外部に課題を抱えており、@ゆうりょするざいりょうがおおいこと。@憂慮する材料が多いこと。@もとはちゅうごくのしんのせいじかであるはんぶんしが@元は中国の晋の政治家である范文子が@てきたいしているそこくをあえてがいぶのしんぱいごととしてのこし、@「敵対している楚国をあえて外部の心配事として残し、@こくないをだんけつすることによって、@国内を団結することによって、@ないせいのもんだいをはっせいさせないようにせよ@内政の問題を発生させないようにせよ」@とかたったことばにゆらいするが、@と語った言葉に由来するが、@のちにこくないがいにもんだいがさんせきしているじょうたいを@のちに国内外に問題が山積している状態を@あらわすいみにへんかした。@あらわす意味に変化した。@げんだいではかいしゃなど、@現代では会社など、@そしきのようすをしめすいみでももちいられる。@組織の様子を示す意味でも用いられる。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 46,
    img: "4ji.webp",
    title: "四字熟語",
    text: "かいろうどうけつ@「偕老同穴」@ふうふがなかむつまじく、@夫婦が仲睦まじく、@すえながくそいとげること。@末永く添い遂げること。@としをかさねてもなかのよいかんけいをつづけ、@歳を重ねても仲の良い関係を続け、@しごはおなじはかにまいそうされること。@死後は同じ墓に埋葬されること。@かいろうどうけつのちぎりともちいられることがおおい。@「偕老同穴の契り」と用いられることが多い。@かいろうどうけつはともに、@「偕老」「同穴」はともに、@ちゅうごくさいこのしへんである@中国最古の詩篇である@しきょうがしゅってんのことばで、@「詩経」が出典の言葉で、@それらをつづけていうことによってうまれたよじじゅくご。@それらを続けて言うことによって生まれた四字熟語。@かいはともに、いっしょにといういみ。@「偕」はともに、一緒にという意味。@けつはどうけつが@「穴」は「同穴」が@しすればすなわちあなをおなじくすにゆらいすることから、@「死すればすなわち穴を同じくす」に由来することから、@おはかのことをしめす。@お墓のことを示す。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 47,
    img: "4ji.webp",
    title: "四字熟語",
    text: "はちめんろっぴ@「八面六臂」@ひとりでなんにんぶんものはたらきやかつやくをすること。@一人で何人ぶんもの働きや活躍をすること。@またたほうめんやさまざまなぶんやにわたって、@また多方面や様々な分野に渡って、@きわだったかつどうをせいりょくてきにおこなうこと。@際立った活動を精力的に行なうこと。@めんはかお、@「面」は顔、@ぴはひじやうでのことをそれぞれしめす。@「臂」はひじや腕のことをそれぞれ示す。@もともとはみっつのかおとむっつのうでをもつ、@元々は三つの顔と六つの腕を持つ、@あしゅらのぶつぞうのことを@阿修羅の仏像のことを@さんめんろっぴとよんでいたことにゆらいする。@「三面六臂」と呼んでいたことに由来する。@それがやがてたほうめんにといういみをつよめるために、@それがやがて多方面にという意味を強めるために、@はちめんろっぴといいかえるようになった@「八面六臂」と言い換えるようになった@というせつがいっぱんてき。@という説が一般的。@じっさいにはやっつのかおをもつぶつぞうはそんざいしない。@実際には八つの顔を持つ仏像は存在しない。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 48,
    img: "4ji.webp",
    title: "四字熟語",
    text: "しゅんしゅうしゅうし@「春愁秋思」@はるのひにふとかんじるものういと、@春の日にふと感じる物憂いと、@あきにあたまのなかをよぎるものさみしいおもいのこと。@秋に頭の中をよぎる物寂しい思いのこと。@はるとあきはいっぱんてきに、@春と秋は一般的に、@すごしやすいきせつとされているが、@過ごしやすい季節とされているが、@そんなよいきこうのときに、@そんな良い気候のときに、@りゆうもなしになんとなくきぶんがおちこむこと。@理由もなしに何となく気分が落ち込むこと。@てんじて、こころのなかにいつもかなしみや @転じて、心の中にいつも悲しみや@なやみをかかえていることをしめす。@悩みを抱えていることを示す。@しゅってんはちゅうごくとうのじだいのしじんである@出典は中国唐の時代の詩人である@はくきょいのりょうえんのしょうという、@白居易の「陵園妾」という、@こうていのはかもりやくとしてゆうへいされた@皇帝の墓守役として幽閉された@にょかんのことをあわれんだしにゆらいする。@女官のことを哀れんだ詩に由来する。@げんぶんはしゅんしゅうしゅうししらずなんのかぎりぞ。@原文は「春愁秋思知らず何の限りぞ」。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 49,
    img: "4ji.webp",
    title: "四字熟語",
    text: "じんしゃらくざん@「仁者楽山」@じんしゃとはじゅきょうにおけるじんのみちをきわめた、@「仁者」とは儒教における仁の道を極めた、@どうとくてきにひじょうにすぐれたひとのこと。@道徳的に非常にすぐれた人のこと。@おもいやりがあり、なさけぶかいひとのこと。@思いやりがあり、情け深い人のこと。@そのようにじんとくがそなわったものは、@そのように人徳が備わった者は、@きもちがおちついていて、@気持ちが落ち着いていて、@しょうしょうのことではどうようしない@少々のことでは動揺しない@ものであるということ。@ものであるということ。@よくぼうにまどわされることなく、@欲望に惑わされることなく、@こころがおだやかであることから、@心が穏やかであることから、@おなじようにゆうぜんとだいちにねづいている、@同じように悠然と大地に根付いている、@やまをあいするというたとえ。@山を愛するというたとえ。@しゅってんはろんごで、@出典は「論語」で、@げんぶんはちしゃはみずをたのしみ、@原文は「知者は水を楽しみ、@じんしゃはやまをたのしむとくんどくする。@仁者は山を楽しむ」と訓読する。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 50,
    img: "4ji.webp",
    title: "四字熟語",
    text: "うかとうせん@「羽化登仙」@うかははねがはえて、@「羽化」は羽が生えて、@そらをじゆうにとびまわることのできるせんにんになること。@空を自由に飛び回ることのできる仙人になること。@とうせんはせんにんとなって、@「登仙」は仙人となって、@てんにのぼること。@天に登ること。@ここからてんじて、@ここから転じて、@おさけをのんでよいがまわり、@お酒を飲んで酔いが回り、@てんにものぼるようなここちよいきぶんになった@天にも登るような心地よい気分になった@じょうたいのことをさししめすよじじゅくごとなった。@状態のことを指し示す四字熟語となった。@しゅってんはちゅうごくのほくそうのせいじか、@出典は中国の北宋の政治家、@およびぶんがくしゃやしじんとしても@及び文学者や詩人としても@じだいをだいひょうするさくひんをのこしたそしょくが、@時代を代表する作品を残した蘇軾が、@るけいのちでのこしたとされる@流刑の地で残したとされる@ぜんせきへきのふより。@「前赤壁賦」より。@げんぶんはうかしてとうせんするがごとし。@原文は「羽化して登仙するがごとし」。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 51,
    img: "4ji.webp",
    title: "四字熟語",
    text: "いっぱいとち@「一敗塗地」@にどとたちあがることができないほど、@二度と立ち上がることができないほど、@たいはいすること。@大敗すること。@かんぱいをきっすること。@完敗を喫すること。@とちはせんじょうでやぶれ、@「塗地」は戦場で敗れ、@せんししたもののぞうもつなどがだいちにさんらんして、@戦死した者の臓物などが大地に散乱して、@どろにまみれてむざんなすがたをさらすこと。@泥にまみれて無残な姿をさらすこと。@しゅってんはしばせんによってへんさんされた@出典は司馬遷によって編纂された@ちゅうごくのれきししょであるしきより。@中国の歴史書である「史記」より。@しんのしこうていがなくなったあと、@秦の始皇帝が亡くなった後、@ゆうのうなじんぶつをたいしょうにしなければ、@有能な人物を大将にしなければ、@いっぱい、ちにまみることになる、@「一敗、地に塗る」ことになる、@というきじゅつがげんぶんである。@という記述が原文である。@このときにすいきょされたのが、@このときに推挙されたのが、@のちにごかんのしょだいこうていとなるりゅうほう。@のちに前漢の初代皇帝となる劉邦。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 52,
    img: "4ji.webp",
    title: "四字熟語",
    text: "いちねんほっき@「一念発起」@じぶんのそれまでのかんがえをおもいきってあらため、@自分のそれまでの考えを思い切って改め、@あることをなしとげようとけっしんすること。@あることを成し遂げようと決心すること。@またそのもくひょうのために、@またその目標のために、@ねっしんにどりょくをすること。@熱心に努力をすること。@しゅってんはしんらんのでしによってかかれたとされる、@出典は親鸞の弟子によって書かれたとされる、@かまくらじだいのぶっきょうしょであるたんにしょうより。@鎌倉時代の仏教書である「歎異抄」より。@もともとはぞくせをすててぶつどうにきえし、@もともとは俗世を捨てて仏道に帰依し、@さとりをえたいとかたくねんずる@悟りを得たいと固く念ずる@というぶっきょうようごだったものが、@いう仏教用語だったものが、@いっぱんてきないみとしてひろくもちいられるようになった。@一般的な意味として広く用いられるようになった。@いちねんはひたむきにおもいこむこと、@「一念」はひたむきに思い込むこと、@いっしんにねんぶつをとなえることのいみ。@一心に念仏を唱えることの意味。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 53,
    img: "4ji.webp",
    title: "四字熟語",
    text: "いちびょうそくさい@「一病息災」@じびょうなどもなくまったくけんこうであるひとよりも、@持病などもなくまったく健康である人よりも、@なにかひとつくらいかるいびょうきをもっているひとのほうが、@何か一つくらい軽い病気を持っている人のほうが、@けんこうにきをくばるようになるため、@健康に気を配るようになるため、@かえってながいきをするということ。@かえって長生きをするということ。@そくさいはけんこうであること。@「息災」は健康であること。@ほんらいはほとけのちからによって、@本来は仏の力によって、@さいがいやびょうきなどのわざわいをとりのぞくといういみ。@災害や病気などの災いを取り除くという意味。@びょうきもなくけんこうであることをいみする@病気もなく健康であることを意味する@むびょうそくさいというよじじゅくごがあるが、@「無病息災」という四字熟語があるが、@それをもじってあらたにいみをくわえたものが@それをもじって新たに意味を加えたものが@いちびょうそくさいということばである、@「一病息災」という言葉である、@といわれている。@と言われている。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 54,
    img: "4ji.webp",
    title: "四字熟語",
    text: "がいしゅういっしょく@「鎧袖一触」@かんたんにあいてをうちまかすことができることのたとえ。@簡単に相手を打ち負かすことができることのたとえ。@よろいのそでがかるくふれただけで、@鎧の袖が軽く触れただけで、@てきがそくざにたおれてしまうといういみから。@敵が即座に倒れてしまうという意味から。@しゅってんはえどじだいのこうきに@出典は江戸時代の後期に@らいさんようがあらわしたとされるにほんがいしより。@頼山陽が著したとされる「日本外史」より。@へいあんじだいにみなもとのためともが@平安時代に源為朝が@ほげんのらんにさんせんしたさいに、@保元の乱に参戦した際に、@たいらのきよもりのごときにいたりては、@「平清盛の如きに至りては、@しんのがいしゅうひとたびふるれば、@臣の鎧袖一たび触るれば、@みなみずからたおれんのみ@皆自ら倒れんのみ」@つまりへいけのぐんぜいなど、@つまり平家の軍勢など、@わたしのよろいがすこしふれればしぜんとたおれるだろう、@私の鎧が少し触れれば自然と倒れるだろう、@とじょうこうにしゅちょうしたとされるこじにゆらいする。@と上皇に主張したとされる故事に由来する。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 55,
    img: "4ji.webp",
    title: "四字熟語",
    text: "いちじつのちょう@「一日之長」@いちにちはやくうまれたこと。@一日早く生まれたこと。@ねんれいがわずかにうえであること。@年齢がわずかに上であること。@そこからてんじて、@そこから転じて、@ひとよりもほんのすこしだけけいけんをつんでおり、@人よりもほんの少しだけ経験を積んでおり、@ぎじゅつなどがわずかにすぐれていることを@技術などがわずかにすぐれていることを@しめすことばとなった。@示す言葉となった。@しゅってんはろんごで、@出典は「論語」で、@げんぶんはわがいちにちもなんじよりちょうじるをもって、@原文は「吾が一日もなんじより長じたるを以って、@われをもってすることなかれ。@吾れを以てすることなかれ」。@わたしがとしうえだからといって@私が年上だからといって@えんりょはしないでくれといういみから、@遠慮はしないでくれという意味から、@じぶんののうりょくやけいけん、@自分の能力や経験、@ちしきなどをけんそんしていうよじじゅくごとなった。@知識などを謙遜して言う四字熟語となった。@じつはにちとよんでもよい。@「日」はにちと読んでも良い",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 56,
    img: "4ji.webp",
    title: "四字熟語",
    text: "しゅつらんのほまれ@「出藍之誉」@おしえをうけたでしが、@教えを受けた弟子が、@せんせいやししょうよりも@先生や師匠よりも@すぐれたぎじゅつやちしきをみにつけること。@すぐれた技術や知識を身につけること。@あおいろのせんりょうは@青色の染料は@あいというしょくぶつからとるものであるが、@「藍」という植物から取るものであるが、@そのはをはっこうさせるとげんりょうのあいいろよりも、@その葉を発酵させると原料の藍色よりも、@あざやかなあおにそまることから。@あざやかな青に染まることから。@あおはあいよりいでてあいよりあおし@「青は藍より出でて藍より青し」@というきげんぜんちゅうごくのこじがゆらいのことわざを、@という紀元前中国の故事が由来のことわざを、@みじかくまとめてよじじゅくごにしたもの。@短くまとめて四字熟語にしたもの。@しがでしをほめることばなので、@師が弟子をほめる言葉なので、@でしがじがじさんのためにいったり、@弟子が自画自賛のために言ったり、@ぶかがじょうしにたいしてつかうのはごようである。@部下が上司に対して使うのは誤用である。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 57,
    img: "4ji.webp",
    title: "四字熟語",
    text: "きじせんもく@「貴耳賤目」@ひとからきいたはなしや@人から聞いた話や@うわさだけをかるがるとしんじて、@噂だけを軽々と信じて、@じぶんのめでじっさいにみたものごとをしんじないこと。@自分の目で実際に見た物事を信じないこと。@そこからてんじて、@そこから転じて、@つたえきいたとおくのことや@伝え聞いた遠くのことや@かこのことはじゅうようしして、@過去のことは重要視して、@それをりそうかするが、@それを理想化するが、@みぢかなことやげんざいのことはけいししたり、@身近なことや現在のことは軽視したり、@ひなんすることをしめす。@非難することを示す。@せんはいやしむ、かろんじるといったいみ。@「賤」はいやしむ、軽んじるといった意味。@しゅってんはちゅうごくなんぼくちょうじだいの@出典は中国南北朝時代の@しへんしゅうであるもんぜんで、@詩文集である「文選」で、@げんぶんはみみをたっとびめをいやしむとくんどくする。@原文は「耳を貴び目を賎しむ」と訓読する。@たっとびはとうとびともよむ。@「貴び」は「貴び」とも読む。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 58,
    img: "4ji.webp",
    title: "四字熟語",
    text: "うちまたこうやく@「内股膏薬」@しっかりとしたしゅちょうをもたず、@しっかりとした主張を持たず、@そのばのつごうであっちこっちにつきしたがうこと。@その場の都合であっちこっちに付き従うこと。@きぶんしだいでいけんがすぐにかわり、@気分次第で意見がすぐに変わり、@せっそうのないこと。@節操のないこと。@てんじてそのような、@転じてそのような、@あてにできないひとをあざけっていうことば。@あてにできない人をあざけって言う言葉。@こうやくはあぶらなどでねったがいようやくのこと。@「膏薬」は油などで練った外用薬のこと。@うちまたにはったこうやくが、@内股にはった膏薬が、@うごくたびにひだりについたりみぎについたりすることから、@動くたびに左についたり右についたりすることから、@このいみとなった。@この意味となった。@しゅってんはえどじだいのにんぎょうじょうるり@出典は江戸時代の人形浄瑠璃@きいちほうげんさんりゃくのまきの、@「鬼一法眼三略巻」の、@ひよりみなとうじょうじんぶつをひょうしたごより。@日和見な登場人物を評した語より。@ごうやくともよむ。@「膏薬」とも読む。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 59,
    img: "4ji.webp",
    title: "四字熟語",
    text: "かろとうせん@「夏炉冬扇」@なつのいろりやふゆのおうぎのように、@夏の囲炉裏や冬の扇のように、@じきはずれでやくにたたないもののたとえ。@時期はずれで役に立たないもののたとえ。@てんじていっぱんてきにむようなものや、@転じて一般的に無用なものや、@いみのないげんろんなどをしめす。@意味のない言論などを示す。@しゅってんはちゅうごくごかんじだいの@出典は中国後漢時代の@しそうしょであるろんこうより。@思想書である「論衡」より。@やくにたたないいけんをくんしゅにていしゅつするのは、@役に立たない意見を君主に提出するのは、@なつをもってろをすすめ、@「夏を以て炉を進め、@ふゆをもっておうぎをすすむるがごとし@冬を以て扇を奏むるが如し」@とあるのにゆらいする。@とあるのに由来する。@くんしゅのしんぼうをうしなったしんかや、@君主の信望を失った臣下や、@ちょうあいをうしなったきゅうじょにたいして@寵愛を失った宮女に対して@もちいられることもある。@用いられることもある。@とうせんかろともいい、@「冬扇夏炉」とも言い、@いみはおなじである。@意味は同じである。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 60,
    img: "4ji.webp",
    title: "四字熟語",
    text: "とうじょうしゅうこ@「蹈常襲故」@じゅうらいのしきたりや@従来のしきたりや@むかしからのほうほうをうけついで、@昔からの方法を受け継いで、@そのとおりにものごとをとりおこなうこと。@そのとおりに物事を執り行うこと。@とうはふむ、ふみおこなうといったいみ。@「蹈」は踏む、踏み行なうといった意味。@しゅうはうけつぐといういみ。@「襲」は受け継ぐという意味。@しゅってんはちゅうごくほくそうじだいのせいじか、@出典は中国北宋時代の政治家、@およびしじんやしょか、がかなどとしてもかつやくした@及び詩人や書家、画家などとしても活躍した@そしょくのいいんろんより。@蘇軾の「伊尹論」より。@つねをふんでこをおそうとくんどくし、@「常を蹈んで故を襲う」と訓読し、@とうしゅうとりゃくする。@「蹈襲」と略する。@このよじじゅくごがげんざいももちいられる@この四字熟語が現在も用いられる@とうしゅうのごげんとなった。@「踏襲」の語源となった。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 61,
    img: "4ji.webp",
    title: "四字熟語",
    text: "しんしょうひつばつ@「信賞必罰」@こうせきをあげたものにはそれそうおうのほうびをあたえ、@功績を上げた者にはそれ相応の褒美を与え、@しっぱいしたりつみをおかしたりしたものには、@失敗したり罪を犯したりした者には、@それそうおうのばつをあたえること。@それ相応の罰を与えること。@このようにしょうばつを、@このように賞罰を、@そのこういやげんどうにてらしあわせてかならず、@その行為や言動に照らし合わせて必ず、@げんかくにおこなうこと。@厳格に行なうこと。@しゅってんはちゅうごくきげんぜんにおける、@出典は中国紀元前における、@せんごくじだいのしそうしょであるかんぴしより。@戦国時代の思想書である「韓非子」より。@そのなかにくんしゅがただしいとうちをおこなうための@その中に君主が正しい統治を行なうための@こころえをしめしたいちぶんである@心得を示した一文である@ひつばつしていをあきらかにす@「必罰して威を明らかにす」@しんしょうしてのうをつくさしむ@「信賞して能を尽くさしむ」@にゆらいする。@に由来する。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 62,
    img: "4ji.webp",
    title: "四字熟語",
    text: "えいえいいっすい@「盈盈一水」@えいえいはみずがみちあふれているようすのこと。@「盈盈」は水が満ちあふれている様子のこと。@いっすいはひとすじのかわのこと。@「一水」は一筋の川のこと。@しゅってんはちゅうごくなんぼくちょうじだいの@出典は中国南北朝時代の@しぶんしゅうであるもんぜんより。@詩文集である「文選」より。@そのなかのこしじゅうきゅうしゅのひとつに、@その中の「古詩十九首」の一つに、@たなばたでんせつでゆうめいな@七夕伝説で有名な@ひこぼしとおりひめをだいざいにしたうたがあり、@彦星と織姫を題材にした歌があり、@あまのがわでへだてられているため、@天の川で隔てられているため、@みつめあうだけでかいわをすることができない、@見つめ合うだけで会話をすることができない、@ふたりのせつなさをしめした@二人の切なさを示した@えいえいたるいっすいといういっせつにゆらいする。@「盈盈たる一水」という一節に由来する。@てんじておもいをかわしただんじょが、@転じて思いを交わした男女が、@あうことのできないつらさやくるしさのたとえ。@会うことのできないつらさや苦しさのたとえ。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 63,
    img: "4ji.webp",
    title: "四字熟語",
    text: "きゅうえんとうりん@「窮猿投林」@こまったじょうきょうのときには、@困った状況のときには、@あれこれとよりごのみをしているよゆうはないということ。@あれこれと選り好みをしている余裕はないということ。@まずしくきゅうしているときには、@貧しく窮しているときには、@きゅうりょうやしょくぎょうをえらんでいるよゆうはないというたとえ。@給料や職業を選んでいる余裕はないというたとえ。@しゅってんはちゅうごくのれきししょであるしんじょより。@出典は中国の歴史書である「晋書」より。@しんのりじゅうというおとこが@晋の李充という男が@しょうぐんのさんぼうにすいきょされたとき、@将軍の参謀に推挙されたとき、@ぐんじんではいえのひんきゅうがすくえず@軍人では家の貧窮が救えず@せいかつがなりたたないとかんがえ、@生活が成り立たないと考え、@きゅうよはおおいがちいのひくいちほうかんとなったとき@給与は多いが地位の低い地方官となったとき@きゅうえんはやしにとうずるに@「窮猿林に投ずるに@あにきをえらぶにいとまあらんや@豈に木を択ぶに暇あらんや」@といったとされるこじにゆらいする。@と言ったとされる故事に由来する。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 64,
    img: "4ji.webp",
    title: "四字熟語",
    text: "しかいけいてい@「四海兄弟」@せかいじゅうのひとびとが、@世界中の人々が、@じんしゅやこくせき、みんぞくなどをとわず、@人種や国籍、民族などを問わず、@おたがいをそんちょうして、@お互いを尊重して、@せいいやまごころをもってせっしていれば、@誠意や真心を持って接していれば、@あたかもきょうだいであるかのようになかよくなるということ。@あたかも兄弟であるかのように仲良くなるということ。@また、そのようなしたしいかんけいをきずくべきだというたとえ。@また、そのような親しい関係を築くべきだというたとえ。@しかいはくにをとりかこむしほうのうみのこと。@「四海」は国を取り囲む四方の海のこと。@てんじてせかいじゅうのことをしめす。@転じて世界中のことを示す。@しゅってんはろんごがんえんより。@出典は「論語」顔淵より。@くんしはけいしてしつなく、@「君子は敬して失なく、@ひととうやうやしくてれいあらば、@人と恭しくて礼あらば、@しかいのうちみなけいていなり@四海の内皆兄弟なり」@といういちぶんにゆらいする。@という一文に由来する。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 65,
    img: "4ji.webp",
    title: "四字熟語",
    text: "ろっこんしょうじょう@「六根清浄」@すべてのまよいやよくぼうをたちきって、@すべての迷いや欲望を断ち切って、@しんしんがきよらかになること。@心身が清らかになること。@ろっこんとはぶっきょうようごで、@「六根」とは仏教用語で、@かんかくやいしきなどをしょうじさせ、@感覚や意識などを生じさせ、@そのことによってまよいのもととなるむっつのきかんのこと。@そのことによって迷いのもととなる六つの器官のこと。@いっぱんてきなごかんに、@一般的な五感に、@いをくわえたものをいう。@「意」を加えたものを言う。@やままいりをするしゅぎょうしゃたちがとざんのさいに、@山参りをする修行者たちが登山の際に、@しんしんのけがれをはらいきよめ、@心身のけがれを祓い清め、@ちからをふりしぼってやまみちをのぼるための@力を振り絞って山道を登るための@となえもんくやかけごえでもあり、@唱え文句や掛け声でもあり、@このよじじゅくごがねんぱいのかたがすわるときなどにつかう@この四字熟語が年配の方が座るときなどに使う@よっこいしょのごげんというせつもある。@「よっこいしょ」の語源という説もある。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 66,
    img: "4ji.webp",
    title: "四字熟語",
    text: "せいうんのこころざし@「青雲之志」@こうみょうをたてて、@功名を立てて、@りっしんしゅっせをなしとげたいとおもうこころのこと。@立身出世を成し遂げたいと思う心のこと。@がくもんのせかいでこうせきをのこし、@学問の世界で功績を残し、@たかいちいにつこうとすること。@高い地位につこうとすること。@また、ぞくせけんからちょうえつしてとくをみがき、@また、俗世間から超越して徳を磨き、@りっぱでこうけつなじんぶつになりたい@立派で高潔な人物になりたい@というのぞみをしめすいみにももちいられる。@という望みを示す意味にも用いられる。@せいうんはあおぞらをながれるくもや、@「青雲」は青空を流れる雲や、@はれてすみきったそらのこと。@晴れて澄み切った空のこと。@てんじてこういこうかんや、@転じて高位高官や、@どうとくのあるりっぱなじんぶつをしめす。@道徳のある立派な人物を示す。@しゅってんはちゅうごくのとうだいしょきのしじんである@出典は中国の唐代初期の詩人である@おうぼつのとうおうかくのじょより。@王勃の「滕王閣序」より。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 67,
    img: "4ji.webp",
    title: "四字熟語",
    text: "せいこううき@「晴好雨奇」@はれのひでもあめのひでも、@晴れの日でも雨の日でも、@けしきがすばらしいこと。@景色がすばらしいこと。@しぜんのふうけいがせいてんのときに@自然の風景が晴天のときに@うつくしくみえるのはもちろんのこと、@美しく見えるのはもちろんのこと、@うてんのばあいであっても、@雨天の場合であっても、@はれのひとはちがっておもむきがあってすばらしいこと。@晴れの日とは違って趣があって素晴らしいこと。@きはこのばあい、@「奇」はこの場合、@めずらしいやおかしいといったいみではなく、@珍しいやおかしいといった意味ではなく、@ふつうとはちがってすばらしく、@普通とは違ってすばらしく、@ふぜいがあってすぐれていることをしめす。@風情があってすぐれていることを示す。@しゅってんはちゅうごくほくそうじだいのせいじか、@出典は中国北宋時代の政治家、@およびしじんとしてもゆうめいなそしょくの@及び詩人としても有名な蘇軾の@こじょうにてのむはじめはれのちあめふる@「湖上にて飲む初め晴れ後雨降る」@というしのいっせつより。@という詩の一節より。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 68,
    img: "4ji.webp",
    title: "四字熟語",
    text: "りゅうあんかめい@「柳暗花明」@りゅうあんはやなぎがおいしげって、@「柳暗」は柳が生い茂って、@そのかげがほのぐらいようすのこと。@その陰がほの暗い様子のこと。@かめいははながさきみだれて、@「花明」は花が咲き乱れて、@あかるいいろにみちあふれているようすのこと。@明るい色に満ち溢れている様子のこと。@はるのけしきがみどりやはなにいろどられて、@春の景色が緑や花に彩られて、@うつくしいことをひょうげんするよじじゅくご。@美しいことを表現する四字熟語。@てんじてものごとがいきづまったとたん、@転じて物事が行き詰まった途端、@あたらしいてんかいがはっせいすることを、@新しい展開が発生することを、@しめすさいにももちいられることがある。@示す際にも用いられることがある。@しゅってんはふくすうあるが、@出典は複数あるが、@ちゅうごくなんそうじだいのしじんであるりくゆうの@中国南宋時代の詩人である陸遊の@さんせいのむらにあそぶというしの@「山西の村に遊ぶ」という詩の@りゅうあんかめいまたいっそん@「柳暗花明又一村」@といういっせつがゆうめいである。@という一節が有名である。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 1,
    index: 69,
    img: "4ji.webp",
    title: "四字熟語",
    text: "ういてんぺん@「有為転変」@このよのすべてのげんしょうやそんざいは、@この世の中のすべての現象や存在は、@さまざまなじょうけんやげんいんなどによって@さまざまな条件や原因などによって@つねにうつりかわるものであって、@常に移り変わるものであって、@すこしのあいだもとどまることがなく、@少しの間もとどまることがなく、@けっしていっていしていないということ。@決して一定していないということ。@しゅってんはあきらかになっていないが、@出典は明らかになっていないが、@もともとはぶっきょうようごで、@元々は仏教用語で、@このよははかないものであるというたとえにももちいられる。@この世は儚いものであるというたとえにも用いられる。@ういはじょうけんやげんいん、@「有為」は条件や原因、@すなわちいんねんによってしょうじた、@すなわち因縁によって生じた、@このよのいっさいのげんしょうのこと。@この世の一切の現象のこと。@てんぺんはへんかすることで、@「転変」は変化することで、@だくおんでてんべんともよむ。@濁音で「転変」とも読む。",
    tag1: "四字熟語",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 1,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "りかにかんむりをたださず@「李下に冠を正さず」@ちゅうごくのりくちょうじだいいぜん、@中国の六朝時代以前、@すなわちごかんのころにつくられたとされる、@すなわち後漢のころに作られたとされる、@こがくふくんしこうが@「古楽府」君子行が@しゅってんとなっていることわざ。@出典となっていることわざ。@がくふとはちょうていやみんかんじんのあいだで、@楽府とは朝廷や民間人の間で、@うたわれていたしのことである。@歌われていた詩のことである。@すもものみがなっているきのしたで、@すももの実がなっている木の下で、@おうがかんむりのずれをなおそうとしてかがみこんだり、@王が冠のずれを直そうとしてかがみ込んだり、@てをあたまにあげたりすると、@手を頭に上げたりすると、@かじつをぬすもうとしているのでは@果実を盗もうとしているのでは@とおもわれることから、@と思われることから、@たにんにうたがいをかけられるようなこうどうは、@他人に疑いをかけられるような行動は、@きょくりょくさけるべきであるといういましめのたとえのことば。@極力避けるべきであるという戒めのたとえの言葉。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 2,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "がりょうてんせいをかく@「画竜点睛を欠く」@ものごとをかんせいさせるときに、@物事を完成させるときに、@いちばんさいごにつけくわえるべき@一番最後に付け加えるべき@かんじんなぶぶんやかしょが、@肝心な部分や箇所が、@かけてしまっていることのたとえ。@欠けてしまっていることのたとえ。@せいはひとみのことで、@「睛」は瞳のことで、@てんはえがくことのいみ。@「点」は描くことの意味。@ちゅうごくのりくちょうじだいに、@中国の六朝時代に、@りょうのえしがりゅうのえをかき、@梁の絵師が竜の絵を描き、@そのさいにめまでかきいれてしまうと、@その際に目まで描き入れてしまうと、@りゅうがとびさってしまうといった。@竜が飛び去ってしまうと言った。@それをでたらめだとしんじなかったものが、@それをでたらめだと信じなかった者が、@むりやりひとみをかかせたところ、@無理やり瞳を描かせたところ、@りゅうがてんにのぼったというこじから、@竜が天に昇ったという故事から、@がりょうてんせいはだいじなしあげのことをいみする。@「画竜点睛」は大事な仕上げのことを意味する。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 3,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "あきなすはよめにくわすな@「秋茄子は嫁に食わすな」@かまくらじだいのわかしゅう@鎌倉時代の和歌集@ふぼくわかしょうがゆらいとされていることわざ。@「夫木和歌抄」が由来とされていることわざ。@いみはふくすうあり、@意味は複数あり、@あきのなすはおいしいため、@秋の茄子は美味しいため、@なかのよくないよめにたいしては、@仲の良くない嫁に対しては、@たべさせるのはもったいないという、@食べさせるのは勿体ないという、@しゅうとめのたちばからみた@姑の立場から見た@よめいびりのことばがいっぱんてき。@嫁いびりの言葉が一般的。@ほかには、@他には、@あきのなすをたべるとからだがひえることから、@秋の茄子を食べると体が冷えることから、@たいせつなよめにたべさせてはだめという、@大切な嫁に食べさせては駄目という、@いたわりのことばとしてももちいられる。@労りの意味としても用いられる。@また、なすはしゅしがすくないことから、@また、茄子は種子が少ないことから、@よめにたべさせるとこだからにめぐまれなくなる、@嫁に食べさせると子種に恵まれなくなる、@というかいしゃくもある。@という解釈もある。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 4,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "あおはあいよりいでてあいよりあおし@「青は藍より出でて藍より青し」@しゅってんはきげんぜんよんせいきごろ、@出典は紀元前四世紀ごろ、@ちゅうごくのしゅんしにしるされている@中国の「筍子」に記されている@いちぶんからゆらいすることわざ。@一文から由来することわざ。@さくしゃもおなじくしゅんしというなまえである。@作者も同じく筍子という名前である。@このばあいのあいとは@この場合の「藍」とは@せんりょうにつかうあいぐさのことで、@染料に使う藍草のことで、@そのはをはっこうさせてしろいぬのなどをそめると、@その葉を発酵させて白い布などを染めると、@げんりょうのあいよりもあざやかなあおいろになる。@原料の「藍」よりも鮮やかな「青」色になる。@このかんけいから、@この関係から、@でしがおしえをうけたししょうやせんせいの、@弟子が教えを受けた師匠や先生の、@ちしきやぎじゅつをこえることをいみする。@知識や技術を超えることを意味する。@てんじてたゆまぬどりょくにより、@転じてたゆまぬ努力により、@うまれもったししつをこえることが@生まれ持った資質を超えることが@かのうであるということ。@可能であるということ。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 5,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "ぬれぬさきこそつゆをもいとえ@「濡れぬ先こそ露をも厭え」@はじめはおそろしいとおもっていて、@はじめは恐ろしいと思っていて、@つつしんでいたようなあやまちでも、@慎んでいたような過ちでも、@それをいちどおかしてしまうと@それを一度犯してしまうと@きょうふしんやきひかんをいだかなくなり、@恐怖心や忌避感を抱かなくなり、@もっとひどいあやまちを@もっとひどい過ちを@へいきでするようになってしまうというたとえ。@平気でするようになってしまうというたとえ。@からだがかわいていてまったくぬれていないときは、@体が乾いていてまったく濡れていないときは、@こまかいつゆがつくこともきにして@細かい露がつくことも気にして@さけたりはらいおとしたりするが、@避けたり払い落としたりするが、@いったんぬれてしまうと@いったん濡れてしまうと@そのことにかまわなくなることからゆらいする。@そのことに構わなくなることから由来する。@おもにだんじょかんけいのあやまりについて、@主に男女関係の誤りについて、@このことわざがもちいられる。@このことわざが用いられる。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 6,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "あくじせんりをはしる@「悪事千里を走る」@ちゅうごくそうだいのがくしゃであるそうこうけんが、@中国宋代の学者である孫光憲が、@とうじのゆうめいないつわをまとめた@当時の有名な逸話をまとめた@ほくぼうさげんがしゅってんのことわざ。@「北夢瑣言」が出典のことわざ。@げんぶんはこうじもんをいでず、@原文は「好事門を出でず、@あくじせんりをいく。@悪事千里を行く」。@よいおこないはひとになかなかつたわらないが、@良い行ないは人になかなか伝わらないが、@わるいことをしてしまったというひょうばんは@悪いことをしてしまったという評判は@またたくまにせけんにしれわたってしまい、@またたく間に世間に知れ渡ってしまい、@せんりほどのえんぽうまでにひろがってしまうというたとえ。@千里ほどの遠方までに広がってしまうというたとえ。@このばあいのあくじとははんざいではなく、@この場合の「悪事」とは犯罪ではなく、@つみにはとわれないようなあくぎょうや@罪には問われないような悪行や@りんりかんにかけるげんどうをただしくはいみする。@倫理観に欠ける言動を正しくは意味する。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 7,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "えんじゃくいずくんぞこうこくのこころざしをしらんや@「燕雀安んぞ鴻鵠の志を知らんや」@しゅってんはしばせんによってへんさんされた、@出典は司馬遷によって編纂された、@ちゅうごくのれきししょであるしきより。@中国の歴史書である「史記」より。@えんじゃくはつばめやすずめのようなちいさなとり。@「燕雀」はつばめやすずめのような小さな鳥。@てんじてしょうじんぶつのことをしめす。@転じて小人物のことを示す。@こうはおおとり、こくははくちょうで、@「鴻」は大鳥、「鵠」は白鳥で、@いずれもおおきなとり。@いずれも大きな鳥。@てんじてだいじんぶつのことをしめす。@転じて大人物のことを示す。@のちにはんらんをおこし@のちに反乱を起こし@おういにまでのぼりつめたちんしょうが、@王位にまで登り詰めた陳勝が、@まだやとわれのみだったころ、@まだ雇われの身だったころ、@じぶんのたいしをちょうしょうしたやといぬしにたいして、@自分の大志を嘲笑した雇い主に対して、@しょうじんぶつはおおじんぶつのかんがえをりかいできない@小人物は大人物の考えを理解できない@といういみで、このことばをのこしたとされる。@という意味で、この言葉を残したとされる。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 8,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "いずれあやめかかきつばた@「いずれ菖蒲か杜若」@あやめもかきつばたも@菖蒲も杜若も@よくにたうつくしいはなをさかすので、@よく似た美しい花を咲かすので、@みわけるのがむずかしいことから、@見分けるのが難しいことから、@ふくすうあるものがいずれもすぐれており、@複数あるものがいずれもすぐれており、@せんたくするのをまよってしまうことのたとえ。@選択するのを迷ってしまうことのたとえ。@しゅってんはにほんの@出典は日本の@こてんぶんがくさくひんであるたいへいきより。@古典文学作品である「太平記」より。@みなもとのよりまさが@源頼政が@ようかいのぬえをたいじしたときに、@妖怪の鵺を退治したときに、@そのほうびとしてあやめのまえという@その褒美として菖蒲の前という@びじょをたまわろうとしたさいに、@美女を賜わろうとした際に、@じゅうににんのびじんたちのなかから@十二人の美人たちの中から@あやめのまえをえらびだしてみせよとめいじられ、@菖蒲の前を選び出してみせよと命じられ、@そのときによんだとされる@そのときに詠んだとされる@くのいっせつがもとになっている。@句の一節が元になっている。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 9,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "かちゅうのくりをひろう@「火中の栗を拾う」@じぶんのりえきにはならないのに、@自分の利益にはならないのに、@そそのかされてたしゃのために@そそのかされて他者のために@きけんをおかすことのたとえ。@危険をおかすことのたとえ。@あえてこんなんなことに、@あえて困難なことに、@じぶんのみをのりだすさいにももちいられる。@自分の身を乗り出す際にも用いられる。@じゅうしちせいきのふらんすの@十七世紀のフランスの@しじんらふぉんてぃーぬが、@詩人ラ・フォンテーヌが、@いそっぷものがたりをもとにしたぐうわのなかで、@「イソップ物語」をもとにした寓話の中で、@ずるいさるにおだてられてしまったねこが、@ずるい猿におだてられてしまった猫が、@だんろのなかでやけているくりをひろったが、@暖炉の中で焼けている栗を拾ったが、@そのくりはさるにたべられ、@その栗は猿に食べられ、@ねこはおおやけどをしただけだったというはなしからうまれた、@猫は大やけどをしただけだったという話から生まれた、@ふらんすのことわざがゆらいとされる。@フランスのことわざが由来とされる。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 10,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "わをもってとうとしとなす@「和をもって貴しとなす」@いかなるばあいもひとびとがたがいになかがよく、@いかなる場合も人々が互いに仲が良く、@あらそいやたいりつをうむことがなく、@争いや対立を生むことがなく、@ちょうわしたじょうたいや@調和した状態や@きょうちょうせいをおもんじることがだいじである、@協調性を重んじることが大事である、@といういみのことわざ。@という意味のことわざ。@しょうとくたいしがせいていした、@聖徳太子が制定した、@じゅうななじょうけんぽうのだいいちじょうに@十七条憲法の第一条に@とうじょうすることばである。@登場する言葉である。@じゅきょうのだいひょうてきなきょうてんのひとつである@儒教の代表的な経典の一つである@ちゅうごくのらいきにも@中国の「礼記」にも@れいはこれわをもってとうとしとなす@「礼はこれ和を以て貴しと為す」@といういっせつがある。@という一節がある。@とうとしをとうとし@「貴し」を「尊し」@とひょうきしてもごようではないが、@と表記しても誤用ではないが、@しゅってんにちゅうじつにしたがうならば@出典に忠実に従うならば@とうとしがせいかく。@「貴し」が正確。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 11,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "なせばなる、なさねばならぬなにごとも@「為せば成る、為さねば成らぬ何事も」@えどじだいこうきのよねざわはんしゅで@江戸時代後期の米沢藩主で@めいくんとしてなだかいうえすぎようざんがかしんにたいして、@名君として名高い上杉鷹山が家臣に対して、@きょうくんとしてうたのなかでよみあげた@教訓として歌の中で詠み上げた@とされることばがもとになったことわざ。@とされる言葉が元になったことわざ。@さらにじだいをさかのぼると、たけだしんげんが@さらに時代を遡ると、武田信玄が@なせばなる、なさねばならぬなるわざを、@為せば成る、為さねば成らぬ成る業を、@ならぬとすつるひとのはかなさという、@成らぬと捨つる人の儚さ」という、@ひじょうによくにたくをよんでいる。@非常によく似た句を詠んでいる。@いずれのばあいも、@いずれの場合も、@どんななんじでもつよいいしをもってとりくめば、@どんな難事でも強い意志を持って取り組めば、@かならずなしとげられるといういみで、@必ず成し遂げられるという意味で、@やるきをたもつことのたいせつさをといたもの。@やる気を保つことの大事さを説いたもの。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 12,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "にんげんばんじさいおうがうま@「人間万事塞翁が馬」@じんせいにおけるこうふこうはよそくしづらく、@人生における幸不幸は予測しづらく、@いつしあわせがふこうに、@いつ幸せが不幸に、@ふこうがしあわせにてんじるかわからず、@不幸が幸せに転じるかわからず、@あんいにいっきいちゆうすべきではないというたとえ。@安易に一喜一憂すべきではないというたとえ。@しゅってんはちゅうごくぜんかんじだいの@出典は中国前漢時代の@しそうしょであるえなんじにんげんくんより。@思想書である「淮南子」「人間訓」より。@じぶんのうまににげられる@自分の馬に逃げられる@というふこうにあったろうじんだが、@という不幸に合った老人だが、@うまはすうかげつごにしゅんめをつれてもどってきた。@馬は数カ月後に駿馬を連れて戻って来た。@そのうまにのったろうじんのむすこがらくばし、@その馬に乗った老人の息子が落馬し、@あしをこっせつした。@足を骨折した。@だがそのけがによりへいえきをまぬがれ、というように、@だがその怪我により兵役を免れ、というように、@こうふこうはいれかわるというこじにもとづく。@幸不幸は入れ替わるという故事に基づく。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 13,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "かいよりはじめよ@「隗より始めよ」@おおきなじぎょうやけいかくなどをはじめるさいには、@大きな事業や計画などを始める際には、@まずみぢかやてぢかなところからてをつけるべきである、@まず身近や手近なところから手を付けるべきである、@というたとえのことわざ。@というたとえのことわざ。@しゅってんはちゅうごくきげんぜんにおける、@出典は中国紀元前における、@せんごくじだいのいつわなどをまとめた@戦国時代の逸話などをまとめた@せんごくさくより。@「戦国策」より。@えんのくにのしょうおうが、@燕の国の昭王が、@どうすればけんじゃをじこくにまねくことができるかを@どうすれば賢者を自国に招くことができるかを@はいかのかくかいにたずねたところ、@配下の郭隗に尋ねたところ、@まずはじぶんのようなぼんじんをちょうようせよ、@まずは自分のような凡人を重用せよ、@そうすればじぶんよりすぐれたものがしぜんとあつまる、@そうすれば自分より優れた者が自然と集まる、@とかくかいがこたえたとされるこじにゆらいする。@と郭隗が答えたとされる故事に由来する。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 14,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "いしょくたりてれいせつをしる@「衣食足りて礼節を知る」@ちゅうごくのどうきょうなどのしそうてきたちばから、@中国の道教などの思想的立場から、@とうじのせいじやぶんかなどをきじゅつした@当時の政治や文化などを記述した@かんしというしょがしゅってんのことわざ。@「管子」という書が出典のことわざ。@げんぶんはいしょくたりてすなわちえいじょくをしる。@原文は「衣食足りて即ち栄辱を知る」。@いるいとたべものは、@衣類と食べ物は、@にんげんがいきるうえでかかせないものであり、@人間が生きる上で欠かせないものであり、@それらにふそくがなくなってせいかつがみちてくれば、@それらに不足がなくなって生活が満ちてくれば、@こころにもゆとりをもつことができ、@心にもゆとりを持つことができ、@れいぎやせつどにもこころをむけることができる、@礼儀や節度にも心を向けることができる、@というかんがえかたのこと。@という考え方のこと。@てんじてせいかつがまずしくなると、@転じて生活が貧しくなると、@こころもすさんでしまうこともいみする。@心もすさんでしまうことも意味する。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 15,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "かぶをまもりてうさぎをまつ@「株を守りて兎を待つ」@ふるいしゅうかんや@古い習慣や@かこのせいこうたいけんにこだわりすぎて、@過去の成功体験にこだわりすぎて、@あらたなこうどうができずしんぽがなかったり、@新たな行動ができず進歩がなかったり、@ゆうずうがきかなかったりすることのたとえ。@融通がきかなかったりすることのたとえ。@しゅってんはちゅうごくのしそうかの@出典は中国の思想家の@ちょしょであるかんぴしより。@著書である「韓非子」より。@そうのくにののうふがはたけをたがやしていると、@宋の国の農夫が畑を耕していると、@うさぎがとびだしてきて、@兎が飛び出してきて、@きりかぶにあたりしんでしまうというできごとが@切り株に当たり死んでしまうという出来事が@ぐうぜんにおきた。@偶然に起きた。@それをひろってからというもの、@それを拾ってからというもの、@のうふははたけしごとをやめてしまい、@農夫は畑仕事をやめてしまい、@きりかぶのばんをしてくらし、@切り株の番をして暮らし、@うさぎをとろうとしていた@兎を捕ろうとしていた@というこじにゆらいする。@という故事に由来する。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 16,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "せいてんのへきれき@「青天の霹靂」@よそくもできないようなじけんやへんかが、@予測もできないような事件や変化が、@とっぱつてきにおこることをしめしたことわざ。@突発的に起こることを示したことわざ。@へきもれきもともにかみなりのことで、@「霹」も「靂」もともに雷のことで、@あおくはれわたったそらにとつぜん、@青く晴れ渡った空に突然、@はげしいらいめいがなりひびくことをいみする。@激しい雷鳴が鳴り響くことを意味する。@もとはちゅうごくのそうのじだいに@元は中国の宋の時代に@おおくのさくひんをのこしたしじんである、@多くの作品を残した詩人である、@りくゆうのしのいっせつせいてんにへきれきをとばす@陸游の詩の一節「青天に霹靂を飛ばす」@にもとづいたことばで、@に基づいた言葉で、@じしんがしょをしたためるさいに@自身が書をしたためる際に@そのおどるようでゆうだいなふでづかいの、@その踊るようで雄大な筆使いの、@はげしさをけいようしたものである。@激しさを形容したものである。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 17,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "ふくすいぼんにかえらず@「覆水盆に返らず」@しゅってんはちゅうごくの@出典は中国の@かいきしょうせつしゅうであるしゅういきより。@怪奇小説集である「拾遺記」より。@のちにしゅうのぐんしとなり、@のちに周の軍師となり、@たいこうぼうとよばれるようになったりょしょうは、@太公望と呼ばれるようになった呂尚は、@わかいころまずしいのに@若いころ貧しいのに@しごともせずどくしょばかりしており、@仕事もせず読書ばかりしており、@つまにあいそをつかされてりえんされた。@妻に愛想を尽かされて離縁された。@のちにしゅっせしたさい、@のちに出世した際、@もとのつまがもどってきてふくえんをもうしでたが、@元の妻が戻ってきて復縁を申し出たが、@りょしょうはおぼんのみずをこぼして@呂尚はお盆の水をこぼして@このみずをぼんにもどしてみせよといった。@「この水を盆に戻してみせよ」と言った。@このこじから、@この故事から、@いちどおきてしまったことはけっしてとりかえしがつかない、@一度起きてしまったことは決して取り返しがつかない、@といういみをしめすことわざとなった。@という意味を示すことわざとなった。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 18,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "かなえのけいちょうをとう@「鼎の軽重を問う」@かなえとはこだいちゅうごくで、@「鼎」とは古代中国で、@にたきにもちいられたせいどうきのこと。@煮炊きに用いられた青銅器のこと。@しゅうのおうちょうには@周の王朝には@くにじゅうのどうをあつめてつくられたきゅうていが、@国中の銅を集めて作られた「九鼎」が、@こくほうとしてつたわっていた。@国宝として伝わっていた。@おなじじだいに、@同じ時代に、@てんかをとろうというやしんをもっていたそのそうおうが、@天下を取ろうという野心を持っていた楚の荘王が、@しゅうのおうにたいして@周の王に対して@かなえのおおきさやおもさをたずねた。@「鼎」の大きさや重さを尋ねた。@すなわちしゅうをあなどって、@すなわち周をあなどって、@てんかをうばういしをあからさまにしめしたわけで、@天下を奪う意思をあからさまに示したわけで、@このこじからけんりょくしゃのちからや@この故事から権力者の力や@のうりょくをうたがい、@能力を疑い、@かわりにちいをうばおうとするたとえのいみとなった。@代わりに地位を奪おうとするたとえの意味となった。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 19,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "もうきのふぼく@「盲亀の浮木」@あるできごとにそうぐうするのが@ある出来事に遭遇するのが@ひじょうにむずかしいことや、@非常に難しいことや、@めったにないこううんにめぐりあうことをたとえたことわざ。@めったにない幸運に巡り合うことをたとえたことわざ。@しゅってんはぞうあごんきょうで、@出典は「雑阿含経」で、@がんらいはぶっきょうようごである。@元来は仏教用語である。@おおうなばらにすむめのみえないかめが、@大海原に住む目の見えない亀が、@ひゃくねんにいちどだけすいめんにうかびあがり、@百年に一度だけ水面に浮かび上がり、@そこにただよっているりゅうぼくの@そこに漂っている流木の@たったひとつのあなにもぐりこもうとするが、@たった一つの穴に潜り込もうとするが、@かんたんにははいることができない@簡単には入ることができない@というせつわにゆらいする。@という説話に由来する。@ほんらいはほとけさまやほとけのおしえに@本来は仏様や仏の教えに@めぐりあうことのこんなんさをいみすることば。@巡り合うことの困難さを意味する言葉。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 20,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "すずめひゃくまでおどりわすれず@「雀百まで踊り忘れず」@すずめはしぬまでおどるように@雀は死ぬまで踊るように@とびはねるしゅうせいをもつことから、@飛び跳ねる習性を持つことから、@にんげんもおさないころにみについたしゅうかんや、@人間も幼いころに身についた習慣や、@わかいころにおぼえたどうらくは、@若いころに覚えた道楽は、@なんさいになってもなおることがない、というたとえ。@何歳になっても直ることがない、というたとえ。@きょうとでよまれていたかみがたいろはかるたの@京都で詠まれていた上方いろはかるたの@すのふだにかかれていることばである。@「す」の札に描かれている言葉である。@すずめがちょんちょんとはねるどうさを、@雀がちょんちょんと跳ねる動作を、@ちょうやくうんどうをおもなものとした、@跳躍運動を主なものとした、@にほんのでんとうげいのうであるおどりになぞらえたもの。@日本の伝統芸能である踊りになぞらえたもの。@よいしゅうかんにたいしてもちいるのは、@良い習慣に対して用いるのは、@ごようなのでちゅういすること。@誤用なので注意すること。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 21,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "とんびにあぶらあげをさらわれる@「鳶に油揚げをさらわれる」@じぶんのだいじなものや、@自分の大事なものや、@てにはいることがみこまれていたものを、@手に入ることが見込まれていたものを、@ふいによこからうばわれることのたとえ。@不意に横から奪われることのたとえ。@てんじてものをとられてしまい、@転じてものを取られてしまい、@ぼうぜんとしているようすのことをあらわす。@呆然としている様子のことをあらわす。@ふだんはゆうぜんとそらをとんでいるとんびが、@普段は悠然と空を飛んでいる鳶が、@えものをみつけるとすばやくまいおりてきて、@獲物を見つけると素早く舞い降りてきて、@ひょうてきをさらっていくことにゆらいする。@標的をさらっていくことに由来する。@うばわれるものがなぜあぶらあげなのかは、@奪われるものがなぜ油揚げなのかは、@とんびのこうぶつというわけではなく、@鳶の好物というわけではなく、@いなりじんじゃにおそなえするものがあぶらあげだから、@稲荷神社にお供えするものが油揚げだから、@というせつがゆうりょくである。@という説が有力である。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 22,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "さんじゅうろっけいにげるにしかず@「三十六計逃げるに如かず」@けいせいがふりになったり、@形勢が不利になったり、@じょうきょうがあっかしたりしてはたいろがわるいときは、@状況が悪化したりして旗色が悪いときは、@なまじあれこれとさくをめぐらすのではなく、@なまじあれこれと策を巡らすのではなく、@きをみはからってなにもかんがえずににげだし、@機を見計らって何も考えずに逃げ出し、@ひとまずみのあんぜんをかくほしたり、@ひとまず身の安全を確保したり、@ごじつあらためてたたかうためにさいきをはかるのがよい、@後日改めて戦うために再起を図るのが良い、@というかんがえかたのこと。@という考え方のこと。@さんじゅうろっけいのしゅってんは、@「三十六計」の出典は、@ちゅうごくなんぼくちょうじだいの@中国南北朝時代の@へいほうをしるしたなんせいしょに、@兵法を記した「南斎書」に、@さんじゅうろくしゅるいのけいりゃくが@三十六種類の計略が@きさいされていることにゆらいする。@記載されていることに由来する。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 23,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "ばきゃくをあらわす@「馬脚を現す」@ばきゃくとはしばいで、@「馬脚」とは芝居で、@うまのあしをえんじるふたりひとくみのやくしゃのこと。@馬の脚を演じる二人一組の役者のこと。@そのやくしゃがうっかり@その役者がうっかり@じぶんほんらいのあしをみせるなどして、@自分本来の足を見せるなどして、@そのすがたをあらわしてしまうことから、@その姿を現してしまうことから、@かくしていたことがおもてにでてしまうことを@隠していたことが表に出てしまうことを@しめすようになったことわざ。@示すようになったことわざ。@てんじて、ひとのほんしょうや@転じて、人の本性や@わるだくみなどがあきらかになる、@悪だくみなどが明るみに出る、@というわるいいみでもちいられる。@という悪い意味で用いられる。@しゅってんはちゅうごくげんのじだいのこだいげきで、@出典は中国元の時代の古典劇で、@われらのばきゃくがあらわれたから、@「我らの馬脚が露われたから、@ほうたいせいというせいぎのやくにんがきたのだろう@包待制という正義の役人が来たのだろう」@といういっせつにもとずく。@という一節に基づく。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 24,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "てんもうかいかいそにしてもらさず@「天網恢恢疎にして漏らさず」@しゅってんはろうしおよびぎしょ。@出典は「老子」及び「魏書」。@てんがあくにんをつかまえるために@天が悪人を捕まえるために@はりめぐらせたあみはきょだいでひろく、@張り巡らせた網は巨大で広く、@そのめはあらいようであるが、@その目は粗いようであるが、@じっさいにはあくじやあくにんを@実際には悪事や悪人を@あみのめからもらすことはない。@網の目から漏らすことはない。@すなわちてんのさだめたひとのみちはげんせいで、@すなわち天の定めた人の道は厳正で、@わるいことをしたものはかならずつかまり、@悪いことをした者は必ず捕まり、@そのむくいやばつをうけることになる、@その報いや罰を受けることになる、@といういみのことわざ。@という意味のことわざ。@かいかいはひろくておおきなようすのこと。@「恢恢」は広くて大きな様子のこと。@そはあみのめがあらいことをこのばあいはあらわす。@「疎」は網の目が粗いことをこの場合はあらわす。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 25,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "みずきよければさかなすまず@「水清ければ魚棲まず」@みずがあまりにもすんでいてきよらかすぎると、@水があまりにも澄んでいて清らかすぎると、@えさになるはずのぷらんくとんもはんしょくせず、@餌になるはずのプランクトンも繁殖せず、@かくれるばしょもないことからさかながすみつかない。@隠れる場所もないことから魚が住み着かない。@ここからてんじて、けっぱくすぎるにんげんは、@ここから転じて、潔白すぎる人間は、@かえってたにんにけいえんされてしまい@かえって他人に敬遠されてしまい@こりつしてしまうことをたとえたことわざ。@孤立してしまうことをたとえたことわざ。@しゅってんはちゅうごくのこうしけご。@出典は中国の「孔子家語」。@かんせいのかいかくをおこなったまつだいらさだのぶの、@寛政の改革を行なった松平定信の、@きびしいきていをふうしした@厳しい規定を風刺した@しらかわのきよきながれにすみかねて@「白河の清き流れに住みかねて@もとのにごりのたぬまこいしき@元の濁りの田沼恋しき」@というにたようないみのくもある。@という似たような意味の句もある。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 26,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "もとのもくあみ@「元の木阿弥」@いったんよいじょうたいになったものが、@いったん良い状態になったものが、@ふたたびもとどおりになること。@再び元通りになること。@せんごくじだいのやまとこくのぶしょう、@戦国時代の大和国の武将、@つついじゅんしょうがびょうししたときに、@筒井順昭が病死したときに、@あとつぎのじゅんけいはまだおさなかったため、@跡継ぎの順慶はまだ幼かったため、@じゅんしょうがなくなったのをかくすために、@順昭が亡くなったのを隠すために、@かえだまとしてこえがにていた@替え玉として声が似ていた@もくあみというおとこをとこにねかせて、@木阿弥という男を床に寝かせて、@がいらいするものをあざむいていた。@外来する者を欺いていた。@やがてじゅんけいがげんぷくし、@やがて順慶が元服し、@じゅんしょうがしんだことをこうひょうしたため、@順昭が死んだことを公表したため、@もくあみはおやくごめんとなり、@木阿弥はお役御免となり、@もとのみぶんであるへいみんにもどった。@元の身分である平民に戻った。@しょせつあるなかで、@諸説ある中で、@いじょうのこじがごげんのさいゆうりょく。@以上の故事が語源の最有力。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 27,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "しょうじんかんきょしてふぜんをなす@「小人閑居して不善をなす」@しゅってんはちゅうごくじゅきょうの@出典は中国儒教の@だいひょうてきなきょうてんである@代表的な経典である@ししょごきょうのだいがくより。@「四書五経」の「大学」より。@しょうじんとはくんしのたいぎごで、@「小人」とは「君子」の対義語で、@きょうようやとくがなく、@教養や徳がなく、@ひんせいがいやしいどりょうのせまいじんぶつのこと。@品性が卑しい度量の狭い人物のこと。@そのようなものがかんきょ、@そのような者が「閑居」、@すなわちひまをもてあましてなにもすることがないと、@すなわち暇を持て余して何もすることがないと、@きまってよくないことをするといういましめのことわざ。@決まって良くないことをするという戒めのことわざ。@てんじてじぶんがかんきょしたときにこそ、@転じて自分が「閑居」したときにこそ、@おのれをりっするきびしいたいどをとり、@己を律する厳しい態度を取り、@たにんがみていなくともげんどうをつつしむべき、@他人が見ていなくとも言動を慎むべき、@といういみにももちいられる。@という意味にも用いられる。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 28,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "じかやくろうちゅうのもの@「自家薬籠中の物」@やくろうはくすりばこのこと。@「薬籠」は薬箱のこと。@じぶんのくすりばこにはいっているくすりのように、@自分の薬箱に入っている薬のように、@いつでもおもいどおりにつかえるひとやもののたとえ。@いつでも思い通りに使える人や物のたとえ。@てんじてみずからがみにつけ、@転じて自らが身につけ、@じゆうにつかいこなせるちしきやぎじゅつのことをしめす。@自由に使いこなせる知識や技術のことを示す。@しゅってんはとうじょで、@出典は「唐書」で、@とうのてきじんけつというせいじかがちょうようしていた、@唐の狄仁傑という政治家が重用していた、@げんこうちゅうというはくがくしゃが@元行沖という博学者が@りようされているくすりのなかに、@「利用されている薬の中に、@わたしもくわえておつかいください@私も加えてお使い下さい」@といったところ、てきじんけつが@と言ったところ、狄仁傑が@きみはもうわたしのやくろうちゅうのもので、@「君はもう私の薬籠中のもので、@かかせないじんざいだとこたえたというこじにゆらいする。@欠かせない人材だ」と答えたという故事に由来する。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 29,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "げこのたてたくらはない@「下戸の建てた蔵はない」@しゅってんはしょみんのあいだでつたわっていた@出典は庶民の間で伝わっていた@わらいばなしなどをまとめて、@笑い話などをまとめて、@えどじだいにへんさんされたせいすいしょうより。@江戸時代に編纂された「醒睡笑」より。@おさけをのまないひとは、さかだいのぶん@お酒を飲まない人は、酒代のぶん@おかねをためることができてざいをのこしそうなものだが、@お金を貯めることができて財を残しそうなものだが、@かならずしもそうではなく、@必ずしもそうではなく、@たとえばくらをたてたというはなしもきかない。@例えば蔵を立てたという話も聞かない。@ならばてきりょうのおさけをのんで、@ならば適量のお酒を飲んで、@ほどほどにたのしんだほうがよいという、@ほどほどに楽しんだほうが良いという、@さけのみがげこをからかっていうことわざ。@酒飲みが下戸をからかって言うことわざ。@さけのみがじこべんごのために、@酒飲みが自己弁護のために、@しゅちょうしてできたことばだというせつもある。@主張してできた言葉だという説もある。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 30,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "しょうねんおいやすくがくなりがたし@「少年老い易く学成り難し」@わかいころはまださきがながいとおもっていて、@若いころはまだ先が長いと思っていて、@ほんきでべんがくにはげむことができないが、@本気で勉学に励むことができないが、@すぐにねんげつがすぎてとしをとってしまい、@すぐに年月が過ぎて歳を取ってしまい、@おうおうにしてなかなかまなべないままになってしまう。@往々にしてなかなか学べないままになってしまう。@がくもんというものはただでさえ、@学問というのはただでさえ、@よういにおさめることができないものなのだから、@容易に修めることができないものなのだから、@わかいころからじかんをおしむぐらいに、@若いうちから時間を惜しむぐらいに、@ひっしにべんきょうするべきというきょうくんのことわざ。@必死に勉強するべきという教訓のことわざ。@しゅってんはじゅうにせいきのちゅうごくの@出典は十二世紀の中国の@じゅがくしゃであるしゅきのしだとされてきたが、@儒学者である朱熹の詩だとされてきたが、@きんねんではしょせつありいけんがわかれている。@近年では諸説あり意見が分かれている。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 31,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "すいぎょのまじわり@「水魚の交わり」@みずがないとさかなはせいそくできないように、@水がないと魚は生息できないように、@きってもきることができないようなしんみつで、@切っても切ることができないような親密で、@したしいあいだがらをもったにんげんかんけいのこと。@親しい間柄を持った人間関係のこと。@しゅってんはさんごくしのじだいをえがいた@出典は三国志の時代を描いた@ちゅうごくのれきししょである@中国の歴史書である@しょくしのしょかつりょうでんより。@「蜀志」の諸葛亮伝より。@りゅうびがぐんしとしてまねき、@劉備が軍師として招き、@しんしょくをともにするほどになった@寝食をともにするほどになった@こうめいとのかんけいについて、@孔明との関係について、@ほかのしんかがいけんをのべたときに、@他の臣下が意見を述べた時に、@このこうめいあるは、なおさかなのみずあるがごときなり@「この孔明あるは、なお魚の水有るが如きなり」@とりゅうびがいったとされるこじにゆらいする。@と劉備が言ったとされる故事に由来する。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 32,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "どくしょひゃっぺんぎおのずからみる@「読書百遍義自ずから見る」@どんなにむずかしく、@どんなに難しく、@よんでいていみのわからないようなしょもつでも、@読んでいて意味のわからないような書物でも、@なんどもくりかえしじゅくどくしていれば、@何度も繰り返し熟読していれば、@しぜんとないようがりかいできるようになる@自然と内容が理解できるようになる@といういみのことわざ。@という意味のことわざ。@しゅってんはぎしのとうぐうでんより。@出典は「魏志」の董遇伝より。@さんごくしのじだいにぎのがくしゃであったとうぐうが、@三国志の時代に魏の学者であった董遇が、@でしいりをもうしこんだものにたいして@弟子入りを申し込んだ者に対して@どくしょせんばん、そのぎおのずからみるといい、@「読書千遍、其の義自ら見る」と言い、@なんどもほんをよむことのひつようせいを@何度も本を読むことの必要性を@といたこじにゆらいする。@説いた故事に由来する。@おおくのほんにてをださず、@多くの本に手を出さず、@りょうしょをじゅくどくせよという@良書を熟読せよという@らんどくをいましめるいみもある。@乱読を戒める意味もある。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 33,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "りょうやくはくちににがし@「良薬は口に苦し」@こうしやそのいちもんのせつわをおさめたとされる、@孔子やその一門の説話を収めたとされる、@こうしけごにゆらいすることわざ。@「孔子家語」に由来することわざ。@りょうやくはくちににがけれどもやまいにりあり。@「良薬は口に苦けれども病に利あり。@ちゅうげんはみみにさからえどもおこないにりありと、@忠言は耳に逆らえども行いに利あり」と、@こうしがいったとされている。@孔子が言ったとされている。@すなわちよいくすりはにがくてのみにくいが@すなわち良い薬は苦くて飲みにくいが@ききめはばつぐんである。@効き目は抜群である。@てんじてそれとおなじようによいちゅうこくは、@転じてそれと同じように良い忠告は、@じぶんのみみにいたくすなおにききづらいが、@自分の耳に痛く素直に聞きづらいが、@うけいれればみのためになるというたとえ。@受け入れれば身のためになるというたとえ。@のちにえどいろはかるたにも、@のちに江戸いろはかるたにも、@このことわざがもちいられた。@このことわざが用いられた。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 34,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "わざわいてんじてふくとなす@「災い転じて福と為す」@じぶんのみにふりかかってしまった@自分の身に降りかかってしまった@こんなんやさいなんも、@困難や災難も、@それをぎゃくようしてうまくたちふるまい、@それを逆用してうまく立ち振る舞い、@てんじてみずからのりえきやしあわせといった、@転じて自らの利益や幸せといった、@ゆうりなじょうきょうとなるよう@有利な状況となるよう@とりはからったほうがよいといういみのことわざ。@取り計らったほうが良いという意味のことわざ。@しゅってんはふくすうあり、@出典は複数あり、@きげんぜんちゅうごくのせんごくじだいの@紀元前中国の戦国時代の@いつわをまとめたせんごくさく、@逸話をまとめた「戦国策」、@またしばせんによってへんさんされたれきししょである@また司馬遷によって編纂された歴史書である@ししょのそしんれつでんのいずれにも、@「史書」の蘇秦列伝のいずれにも、@わざわいをてんじてふくとなし、@「禍を転じて福と為し、@はいによりてこうをなすといういちぶんがある。@敗に因りて功を為す」という一文がある。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 35,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "とらのいをかるきつね@「虎の威を借る狐」@せんごくじだいのちゅうごくのいつわをまとめた@戦国時代の中国の逸話をまとめた@せんごくさくそさくの、ぐうわにゆらいすることわざ。@「戦国策」楚策の、寓話に由来することわざ。@とらがきつねをたべようとしたところ、@虎が狐を食べようとしたところ、@きつねがわたしはてんていのつかいであり、@狐が「私は天帝の使いであり、@じぶんをくうとてんていにそむくことになる。@自分を食うと天帝に背くことになる。@うそだとおもうならあとをついてきなさいといった。@嘘だと思うなら後をついてきなさい」と言った。@とらがそのとおりにすると、@虎がその通りにすると、@ほかのけものたちはみなにげだした。@他の獣たちはみな逃げ出した。@とらはじぶんをおそれていたことにきづかず、@虎は自分を恐れていたことに気づかず、@きつねをみてにげたのだとおもいこんだ。@狐を見て逃げたのだと思い込んだ。@ここからてんじて、@ここから転じて、@たにんのけんいをたよっていばる、@他人の権威を頼って威張る、@しょうじんぶつのたとえとなった。@小人物のたとえとなった。",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 2,
    index: 36,
    img: "kotowaza.webp",
    title: "ことわざ",
    text: "ちりもつもればやまとなる@「塵も積もれば山となる」@ちりのようにちいさなことやわずかなものでも、@塵のように小さなことやわずかなものでも、@つもりつもってやまのようになるということから、@積もり積もって山のようになるということから、@ささいなことでもおろそかにしてはいけない、@些細なことでも疎かにしてはいけない、@といういましめのいみをもつことわざ。@という戒めの意味を持つことわざ。@もとはぶっきょうにゆらいすることばであり、@元は仏教に由来する言葉であり、@しゅってんはまかはんにゃはらみつきょうの@出典は摩訶般若波羅蜜経の@ちゅうやくしょであるだいちろどんの、@注釈書である「大智度論」の、@わるいおこないをつんでいると@悪い行ないを積んでいると@そこからぬけだせなくなることのこんなんさをといた、@そこから抜け出せなくなることの困難さを説いた、@いかのいっせつより。@以下の一節より。@たとえばみじんをつみてやまとなし、@「例えば微塵を積みて山と成し、@いどうするをうべきことかたきがごとし@移動するを得べきこと難きがごとし」",
    tag1: "ことわざ",
    tag2: "ローマ字入力",
  },
  {
    id: 3,
    index: 1,
    img: "leo.webp",
    title: "婚約破棄",
    text: "こんやくはきしたおうじへ。@婚約破棄した王子へ。@こんやくはきありがとう。@婚約破棄ありがとう。@いまはやさしいいけめんと@今は優しいイケメンと@しあわせにくらしています。@幸せに暮らしてます。@え？ゆるしてほしい？@え？ 許してほしい？ @いまさらもうおそいです。@今更もう遅いです。@あとはかってにおしあわせに。@後は勝手にお幸せに。@あれ？わたしいまもてき？@あれ？ 私今モテ期？ @いろんないけめんに、@色んなイケメンに、@つきあってほしいって@付き合ってほしいって@いわれるんですけど？@言われるんですけど？ @でもわたしはかれいがいいや！@でも私は彼以外嫌！ @あ、おうじいたんだ。@あ、王子いたんだ。@こんやく？いつのこと？@婚約？ いつのこと？",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 3,
    index: 2,
    img: "leo.webp",
    title: "婚約破棄",
    text: "え？かれしうばうために@え？ 彼氏奪うために@ともだちのふりしてた？@友達のふりしてた？ @こんやくしゃをりゃくだつされて、@婚約者を略奪されて、@ぶじにこんやくはきせいこう！@無事に婚約破棄成功！ @せいりゃくけっこんいやだった。@政略結婚嫌だった。@そのおうじ、でぃーぶいだけど@その王子、DVだけど@まぁどうぞおしあわせに！@まぁどうぞお幸せに！ @え？わたしってそんなもててたの？@え？ 私ってそんなモテてたの？ @おうじのこいびとだからあきらめてた？@王子の恋人だから諦めてた？ @えこまるよ@え～困るよ～@そんなすきっていわないで！@そんな好きって言わないで！ @え？おうじがゆるしてほしいって？@え？ 王子が許してほしいって？ @いまさら？@今更？",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 3,
    index: 3,
    img: "leo.webp",
    title: "婚約破棄",
    text: "もうがまんできない！@もう我慢できない！ @うわきでぃーぶいかじまるなげ、@浮気DV家事丸投げ、@こんやくはきよだめおとこ！@婚約破棄よダメ男！ @いえをとびでてあめのなか、@家を飛び出て雨の中、@はしるわたしを、よぶこえが。@走る私を、呼ぶ声が。@ってせんせい！？@――って先生！？ @あこがれのせんせいとさいかいしたわたし。@憧れの先生と再会した私。@あれ？これってこいのよかん？@あれ？ これって恋の予感？ @いちどはあきらめたはずなのに。@一度は諦めたはずなのに。@しかも、せんせいもまんざらでもない！？@しかも、先生も満更でもない！？@ここからわたしとせんせいのきんだんのこい、@ここから私と先生の禁断の恋、@うしなったせいしゅんがうごきはじめちゃうの！？@失った青春が動き始めちゃうの！？",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 3,
    index: 4,
    img: "leo.webp",
    title: "婚約破棄",
    text: "かれにこんやくはきされた。@彼に婚約破棄された。@おうじがまさか、@王子がまさか、@あんなぼうげんはくなんてさいてい。@あんな暴言吐くなんて最低。@しつじさんなぐさめてくれてありがとう。@執事さん慰めてくれてありがとう。@でももうわたし、いきてても。@でももう私、生きてても――。@え？しぬまえに、おれとつきあってみないか？@え？ 死ぬ前に、俺と付き合ってみないか？ @おやしきをぬけだして@お屋敷を抜け出して@ちょっとおれさまけいしつじと@ちょっと俺様系執事と@あまあますろーらいふ！？@甘々スローライフ！？ @おうじにいまさらあやまられてもおそい、@王子に今更謝られても遅い、@ふたりのこいはもうとまらないんだから！@二人の恋はもう止まらないんだから！",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 3,
    index: 5,
    img: "leo.webp",
    title: "婚約破棄",
    text: "よわいにんげんはきらいと@「弱い人間は嫌い」と@おうじょからのこんやくはき。@王女からの婚約破棄。@おれがあえて@俺が敢えて@まりょくをかげんしたのにもきづかず？@魔力を加減したのにも気づかず？ @そうか、おれはりんごくでさいきょうになるから、@そうか、俺は隣国で最強になるから、@あとでゆるしてとかいうなよ？@後で許してとか言うなよ？ @ってあれ、なんでおれ@ってあれ、なんで俺@こんなにもててるの？@こんなにモテてるの？ @きづけばまわりは@気づけば周りは@きんぱつきょにゅうのびしょうじょばかり。@金髪巨乳の美少女ばかり。@おうじょごめんね、@王女ごめんね、@ざまぁとはいわないからさ、@ざまぁとは言わないからさ、@おれのことは、あきらめて？@俺のことは、諦めて？",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 3,
    index: 6,
    img: "leo.webp",
    title: "婚約破棄",
    text: "まさか、ほんきでわたくしと@「まさか、本気で私と@けっこんできるとでも？@結婚できるとでも？」@きぞくのおんなにあそばれて、@貴族の女に遊ばれて、@こんやくをはきされたおれ。@婚約を破棄された俺。@でもおれがゆうしゃののうりょくに@でも俺が勇者の能力に@めざめたとたん、@目覚めた途端、@まわりにびしょうじょがわらわらと？@周りに美少女がわらわらと？ @まってくれおれはひとりしかいない、@待ってくれ俺は一人しかいない、@はーれむのつくりかたもしらない、@ハーレムの作り方も知らない、@やばいちょうたのしいかも！@やばい超楽しいかも！ @あ、おれをふったきぞくは@あ、俺を振った貴族は@こないでください。@来ないでください。@あなたのせきないです。@あなたの席ないです。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 3,
    index: 7,
    img: "leo.webp",
    title: "婚約破棄",
    text: "きみはもうようずみだ@「君はもう用済みだ」@とこんやくはきされたわたし。@と婚約破棄された私。@さんざんあそぶだけあそばれ、@散々遊ぶだけ遊ばれ、@すてられたわたしのいえに、@捨てられた私の家に、@とつじょはくばのおうじさまが。@突如白馬の王子様が。@ぼくにはきみがひつようだ、@「僕には君が必要だ、@やくそくどおりむかえにきた@約束通り迎えに来た」@こどものころあそんだかれは、@子供の頃遊んだ彼は、@じつはりんごくのおうじさま！？@実は隣国の王子様！？ @おそくなってごめん@「遅くなってごめん」@わたしどうなっちゃうの！？@私どうなっちゃうの！？ @うんめいにあらがうふたりが、@運命に抗う二人が、@ふたたびであい、そして、@再び出会い、そして、@ふたたびであうものがたり。@再び出逢う物語――。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 3,
    index: 8,
    img: "leo.webp",
    title: "婚約破棄",
    text: "このくつにあうじょせいを@「この靴に合う女性を@くにじゅうからさがしてこい@国中から探してこい」@それわたしですけど。@……それ私ですけど。@あなたがこんやくはきしたわたしですけど。@あなたが婚約破棄した私ですけど。@まぁわたしはもうきょうみないので。@まぁ私はもう興味はないので。@それよりめしつかいのかれ、@それより召使いの彼、@わたしをなぐさめてくれたかれ、@私を慰めてくれた彼、@さいきんかれとめがあうと、@最近彼と目が合うと、@きゅうにどきどきするの、@急にドキドキするの、@なぜでしょう？@なぜでしょう？ @これはもしかして？@これはもしかして……？ @しかもなんかおとこっぽくみえるしどうしよう！？@しかもなんか男っぽく見えるしどうしよう！？",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 3,
    index: 9,
    img: "leo.webp",
    title: "婚約破棄",
    text: "ぼくはまほうをつかえずに、@僕は魔法を使えずに、@こんやくはきされました。@婚約破棄されました。@むらにかえったぼくをなぐさめるおさななじみは、@村に帰った僕を慰める幼馴染は、@でもずっとぼくがすきだったんだ、@でもずっと僕が好きだったんだ、@とないてくれました。@と泣いてくれました。@こんなにそばにこんなにいいひとがいたなんて。@こんなに傍にこんなにいい人がいたなんて。@そのひぼくは、@その日僕は、@このこをいっしょうしあわせにするんだときめました。@この子を一生幸せにするんだと決めました。@もういまさらあやまらないでください。@もう今更謝らないでください。@ぼくはこのこを、@僕はこの子を、@こころからあいするときめたので。@心から愛すると決めたので。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: 1,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "おまえをついほうする@「お前を追放する」@それがおれにつげた@それが俺に告げた@ちちのさいごのことばだった。@父の最後の言葉だった。@いちじかんまえまではいつもの@一時間前まではいつもの@しあわせなせいかつだったのに。@幸せな生活だったのに。@たんじょうびのきょうは@誕生日の今日は@おさななじみやせんぞくめいどと@幼馴染や専属メイドと@ごちそうをたべたり@ご馳走を食べたり@いっしょにあそんだりするはずだった。@一緒に遊んだりするはずだった。@でもこくおうでもあるちちは、@でも国王でもある父は、@じゅうはちでまほうをつかえぬおれがわるい、@十八で魔法を使えぬ俺が悪い、@じきこくおうはあにだとつげ、@次期国王は兄だと告げ、@そのままおれは@そのまま俺は@とつじょついほうされた。@突如追放された。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: 2,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "さいあくなたんじょうびだ@「最悪な誕生日だ」@ほんとうはいまも@本当は今も@いつもどおりのにちじょうだったのに。@いつも通りの日常だったのに。@なんで、なんでこんなことに。@何で、何でこんなことに。@まほうがつかえないだけで@魔法が使えないだけで@ついほうはさすがに@追放は流石に@やめて@「やめて！！」@このこえは。@この声は。@むいしきにからだがうごく。@無意識に体が動く。@ろじうらには@路地裏には@しょうじょがひとりとふりょうがさんにん。@少女が一人と不良が三人。@このていど、けんがなくても。@この程度、剣が無くても。@ふりょうたちにしゅとうをいれ、@不良達に手刀を入れ、@かのじょをたすける。@彼女を助ける。@こわかったというかのじょは、@怖かったと言う彼女は、@おさななじみだった。@幼馴染だった。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: 3,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "ついほうされたってきいたから@「追放されたって聞いたから」@そういうかのじょをみて、@そう言う彼女を見て、@おれはついめがしらがあつくなる。@俺はつい目頭が熱くなる。@ついほうされたとき@追放された時@みおくりはだれもこなかったから。@見送りは誰も来なかったから。@でもごめんなさい@「でもごめんなさい」@みると、なぜかかのじょがないている。@見ると、なぜか彼女が泣いている。@あしもとにはつぶれたはこ。@足元には潰れた箱。@どうやらかのじょはおれに@どうやら彼女は俺に@てづくりけーきをもってきたようだ。@手作りケーキを持ってきたようだ。@ありがとうというおれにかのじょはいう。@ありがとうと言う俺に彼女は言う。@つきあって、と。@付き合って、と。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: 4,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "つきあう@「付き合う！？」@あたまのなかがまっしろになる。@頭の中が真っ白になる。@たしかにかのじょといっしょだとたのしいし、@確かに彼女と一緒だと楽しいし、@すごくかわいいこだ。@すごく可愛い子だ。@せがちいさいぶんおおきさがめだつ。@背が小さい分大きさが目立つ。@どこがとはいわないけど。@どこがとは言わないけど。@みずぎだとこしのくびれとのぎゃっぷで@水着だと腰のくびれとのギャップで@とてもめのやりばにこまる。@とても目のやり場に困る。@えがおもかわいい。@笑顔も可愛い。@でもきゅうにそんなこと@でも急にそんなこと@つきあって、@「付き合って、@わたしもきみといっしょにいきたい、@私も君と一緒に行きたい、@どこへだってね@どこへだってね！」",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: 5,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "わかったわかったうれしいよ@「分かった分かった嬉しいよ」@つきあって、というのは、@付き合って、というのは、@ついほうされたおれをきづかって、だろう。@追放された俺を気遣って、だろう。@そのきづかいはむだにできない。@その気遣いは無駄にできない。@でもつきあってなんてこと、@「でも付き合ってなんてこと、@ほんきにしたらどうするんだ@本気にしたらどうするんだ？」@ちがうもんほんきだもん、@違うもん本気だもん、@といったきがしたが@と言った気がしたが@きっときのせいだ。@きっと気のせいだ。@とりあえずこんごどうするかかんがえ@とりあえず今後どうするか考え@な、なんだあれは@「な、何だあれは！？ @にげろ@逃げろ！！」",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: 6,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "ふりかえると、@振り返ると、@おおきなばくはつおんとともに@大きな爆発音と共に@しろのいちぶがばくはつした。@城の一部が爆発した。@いくらついほうされたとはいえ、@いくら追放されたとはいえ、@しろにはおれのせんぞくめいどがいる。@城には俺の専属メイドがいる。@みすてるわけにはいかない。@見捨てる訳にはいかない。@しろのなかでは、@城の中では、@おおきなりゅうがあばれていた。@大きな龍が暴れていた。@そのからだはほのおにつつまれ、@その体は炎に包まれ、@ちかづくことさえむずかしそうだ。@近づくことさえ難しそうだ。@きしだんがまほうこうげきをするもひるまない。@騎士団が魔法攻撃をするも怯まない。@そしてりゅうのあしもとには、@そして龍の足元には、@せんぞくめいどがいた。@専属メイドがいた。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: 7,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "だいじょうぶか@「大丈夫か！！」@さけんでもへんじはない。@叫んでも返事はない。@わたしがまほうをつかうから、@「私が魔法を使うから、@そのあいだにたすけて@その間に助けて」@みるとおさななじみがきていた。@見ると幼馴染が来ていた。@あぶないからもどれ@「危ないから戻れ@いやだ、わたしもいっしょにたたかわせて@「嫌だ、私も一緒に戦わせて」@そういうとかのじょは@そう言うと彼女は@りゅうにめくらましのまほうをとなえる。@龍に目眩ましの魔法を唱える。@かんがえるじかんはなさそうだ。@考える時間はなさそうだ。@りゅうのちゅういがかのじょにむいたすきに@龍の注意が彼女に向いた隙に@すばやくめいどのもとにはしる。@素早くメイドの元に走る。@でもつぎはかのじょがあぶない。@でも次は彼女が危ない。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: 8,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "りゅうがおさななじみにぶれすをはこうと@龍が幼馴染にブレスを吐こうと@おおきなくちをあける。@大きな口を開ける。@そのいあつにまけてか、@その威圧に負けてか、@かのじょはこしがひけて@彼女は腰が引けて@あしがふるえていた。@足が震えていた。@でもおれもめいどをかかえて@でも俺もメイドを抱えて@はやくはしれない。@速く走れない。@めいどをゆかにおけばはしれるが、@メイドを床に置けば走れるが、@こんどはめいどがあぶない。@今度はメイドが危ない。@どちらをすくうなんてえらべない。@どちらを救うなんて選べない。@おれはふたりともまもりたいんだ。@俺は二人とも守りたいんだ。@かみよ、いるならおれにちからをかせ@神よ、いるなら俺に力を貸せ！！@いまだけでいい@今だけでいい！！",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: 9,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "ばん@バン！！@ごうおんがなりひびく。@轟音が鳴り響く。@ぶれすがはなたれるちょくぜん、@ブレスが放たれる直前、@おれはめいどをかかえたまま@俺はメイドを抱えたまま@かのうなかぎりはやくはしり@可能な限り速く走り@なんとかおさななじみのもとへとついた。@何とか幼馴染の元へと着いた。@そのままかのじょたちに@そのまま彼女達に@おいかぶさるようにたおれこむ。@覆いかぶさるように倒れこむ。@おれはたぶんしぬけど、@俺は多分死ぬけど、@かのじょたちだけはまもりたい。@彼女達だけは守りたい。@あれ@「あれ」@おそるおそるめをあける。@恐る恐る目を開ける。@ばしょはしろだ。@場所は城だ。@そこにはおどろくかのじょたちのすがたと、@そこには驚く彼女達の姿と、@りゅうのしがいがあった。@龍の死骸があった。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: 10,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "えぇ@「えぇ」@おもわずきのぬけたこえがでる。@思わず気の抜けた声が出る。@たしかにどうにか@確かにどうにか@ふたりのたてにはなれた。@二人の盾にはなれた。@でもあのぶれすがちょくげきしたのだ、@でもあのブレスが直撃したのだ、@ほんらいちめいしょうでは@本来致命傷では@すまないはずだ。@済まないはずだ。@おさななじみによると、@幼馴染によると、@きゅうにひかりのたてがでてきて、@急に光の盾が出てきて、@りゅうのぶれすをはねかえしたらしい。@龍のブレスを跳ね返したらしい。@でもそんなまほうきいたことないし、@でもそんな魔法聞いたことないし、@はねかえして@跳ね返して@ぎゃくにいちげきでたおすって、@逆に一撃で倒すって、@いったいなんだったんだろう。@一体何だったんだろう。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: 11,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "あの、@「あの、@たすけてくれてうれしいんだけど、@助けてくれて嬉しいんだけど、@そろそろはずかしい@そろそろ恥ずかしい」@おさななじみのこえがした。@幼馴染の声がした。@かおがまっかだ。@顔が真っ赤だ。@でもはずかしいってなんだ。@でも恥ずかしいって何だ。@ふとなにかてにやわらかいかんしょくが。@ふと何か手に柔らかい感触が。@むにっ。@むにっ。@んっ@「んっ」@かわいらしいこえがする。@可愛らしい声がする。@めせんをしたへ。@目線を下へ。@な、なにみてんのよ@「な、何見てんのよ！！」@おれのては@俺の手は@かのじょのたわわなむねをもんでいる。@彼女のたわわな胸を揉んでいる。@おれはつまりかのじょのむねをもんで。@俺はつまり彼女の胸を揉んで――。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: 12,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "へんたい@「変態」@かのじょがそうつぶやく。@彼女がそう呟く。@でもふかこうりょくだよね@でも不可抗力だよね？@それより@それより@りょうてでむねをかくそうとして、@両手で胸を隠そうとして、@ぎゃくにたにまがきょうちょうされてる@逆に谷間が強調されてる@きがするけど。@気がするけど。@おれたちがさわいでるあいだに、@俺達が騒いでる間に、@めいどがめをさました。@メイドが目を覚ました。@だいじょうぶか@「大丈夫か！？」@かのじょはおれたちと@彼女は俺達と@むかしからのともだちだ。@昔からの友達だ。@けがはあるがぶじでよかった。@怪我はあるが無事でよかった。@ごしんぱいをおかけしすみませんでした、@「ご心配をお掛けしすみませんでした、@おからだはごぶじですか@お体はご無事ですか」",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: 13,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "とりあえずかのじょをよこにねかせ、@とりあえず彼女を横に寝かせ、@はなしをきくことにした。@話を聞くことにした。@きくと、@聞くと、@おれをおってしろをでたい@俺を追って城を出たい@といったかのじょをこうそく、@と言った彼女を拘束、@となりのちかじっけんしつで@隣の地下実験室で@あにだいいちおうじが@兄第一王子が@しょうかんまほうにしっぱい、@召喚魔法に失敗、@りゅうがしゅつげんし@龍が出現し@このさんじょうだそうだ。@この惨状だそうだ。@なんでおまえみたいなやつが@「なんでお前みたいな奴が@ここにいるんだよ@ここにいるんだよ」@きゅうにいやみなこえがとどく。@急に嫌味な声が届く。@わすれもしないこのこえ。@忘れもしないこの声。@おれをついほうしたときに@俺を追放した時に@あざわらったあにだ。@嘲笑った兄だ。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: 14,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "こんにちはあにうえ@「こんにちは兄上」@いやなところはあるが@嫌なところはあるが@いちおうあにきだ。@一応兄貴だ。@はなして、めいどにあやまってもらおう。@話して、メイドに謝ってもらおう。@なんのさわぎだ@「何の騒ぎだ」@おくれてきたのがちち、こくおうだ。@遅れてきたのが父、国王だ。@おちちうえ、@「御父上、@おとうとがしろにりゅうをはなったのです。@弟が城に龍を放ったのです。@ついほうのさかうらみかと。@追放の逆恨みかと。@ごあんしんを、@ご安心を、@りゅうはわたしがみごとたおしました@龍は私が見事倒しました」@ちがうとくいさがるおさななじみとめいどに@違うと食い下がる幼馴染とメイドに@あにはつげた。@兄は告げた。@おんなどももりゅうをはなったなかまだろ@「女共も龍を放った仲間だろ」",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: 15,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "あにき、それだけはゆるさない@「兄貴、それだけは許さない」@おれのなかでなにかがきれた。@俺の中で何かが切れた。@ひっしにおれをおもってかばった@必死に俺を思って庇った@かのじょたちをぐろうするのだけは@彼女達を愚弄するのだけは@ゆるせない。@許せない。@たとえきょうだいでも。@たとえ兄弟でも。@それがあににたいするくちのききかたか@「それが兄に対する口の利き方か」@おこったあにがなぐりかかる。@怒った兄が殴りかかる。@おれもおうせんしようとしたが。@俺も応戦しようとしたが。@ばこーん@バコーン！！@おおきなおととともにふっとんだ。@大きな音と共に吹っ飛んだ。@あにが。@兄が。@おれがなぐるまえに@俺が殴る前に@あにがかってにふっとんだのだ。@兄が勝手に吹っ飛んだのだ。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: 16,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "てめぇなにをした@「てめぇ何をした！！」@さいどあにがなぐりかかる。@再度兄が殴りかかる。@さっきよりいきおいがすごい。@さっきより勢いが凄い。@でも。@でも。@ばっごぉぉぉん@バッゴォォォン！！@あにがまんがみたいにふっとんで@兄が漫画みたいに吹っ飛んで@かべにげきとつ、@壁に激突、@そのままかべをかんつうしてきえた。@そのまま壁を貫通して消えた。@かべにおおきなあながあいている。@壁に大きな穴が開いている。@なぐられるすんぜん、@殴られる寸前、@ひかりのたてがてんかいし、@光の盾が展開し、@それをなぐったあには@それを殴った兄は@ぎゃくにふっとんだのだ。@逆に吹っ飛んだのだ。@えっと、@えっと、@あれおれなんかやっちゃいましたか@あれ俺なんかやっちゃいましたか？",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: 17,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "あまりのふっとびようにひょうしぬけする。@あまりの吹っ飛び様に拍子抜けする。@いったいなにがおこった@一体何が起こった？@そのときどこからか@その時どこからか@すんだこえがした。@澄んだ声がした。@それは@「それは@あらゆるこうげきからじゅつしゃをまもり、@あらゆる攻撃から術者を守り、@ばいにしてはんしゃする@倍にして反射する@ひかりのたてのまほう、@光の盾の魔法、@だいけんじゃしかつかえない@大賢者しか使えない@だいまほうです@大魔法です」@わたしはめがみ。@「私は女神。@かれはすぐれたそしつがあったので@彼は優れた素質があったので@だいまほうをさずけたのですが、@大魔法を授けたのですが、@まさかおうけからついほうするとは、@まさか王家から追放するとは、@ざんねんです@残念です」",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 4,
    index: 18,
    img: "Crensai01.webp",
    title: "ライトノベル",
    text: "めがみ@女神？@このじっさいくらいのおんなのこが@この十歳くらいの女の子が？@たしかにういてるけど。@確かに浮いてるけど。@いまちびとかおもったよね、@「今チビとか思ったよね、@なによたすけをもとめたくせに@何よ助けを求めた癖に」@くちょうがかわる。@口調が変わる。@おほん、@「オホン、@とにかくあやまちをみとめ@とにかく過ちを認め@ついほうをてっかいしなさい@追放を撤回しなさい！」@こくおうはわらう。@国王は笑う。@わたしはまちがっていない。@「私は間違っていない。@が、@が、@もしおまえがかえりたいのならみとめよう@もしお前が帰りたいのなら認めよう」@こたえはただひとつだ。@答えはただ一つだ。@いまさらとめても、もうおそい@「今さら止めても、もう遅い」",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 1,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "おまえとはもうこんやくはきする@「お前とはもう婚約破棄する」@とつじょそういわれたみのりは、@突如そう言われた実莉は、@おどろきをかくせない。@驚きを隠せない。@きょしきまであとはんとしのだんかいで、@挙式まであと半年の段階で、@なにをいいだすのか。@何を言い出すのか。@まえからおもっていたが@「前から思っていたが@おまえにはあいそがたりない。@お前には愛想が足りない。@しゅじんにたいする@主人に対する@ほうしせいしんがたりない。@奉仕精神が足りない。@ぼくには、@僕には、@おまえのようなだめおんなではなく、@お前のような駄目女ではなく、@ひしょのえいふぁがふさわしい@秘書のエイファがふさわしい」@こうしてみのりはおいだされた。@こうして実莉は追い出された。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 2,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "みつるとは、@満とは、@だいがくいちねんのときにしりあった。@大学一年のときに知り合った。@みのりははじめ@実莉ははじめ@かれにきょうみはなかったが、@彼に興味はなかったが、@かれのもうれつなおしにまけて@彼の猛烈な押しに負けて@こうさいをかいし。@交際を開始。@きょうまでろくねんかんものあいだ@今日まで六年間もの間@つきあってきた。@付き合ってきた。@かれはすこし@彼は少し@ていしゅかんぱくぎみなところはあれど、@亭主関白気味なところはあれど、@みのりもじょじょにすきになっていた。@実莉も徐々に好きになっていた。@なのに。@なのに。@しょっくでめまいが。@ショックで目眩が。@ほーむでたおれかけるみのり、@ホームで倒れかける実莉、@そのうでをだれかがつかんだ。@その腕を誰かが掴んだ。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 3,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "だいじょうぶか@「大丈夫か」@こえをかけられて、@声をかけられて、@みのりはおもわずはっとした。@実莉は思わずハッとした。@たおれかけたことに、ではない。@倒れかけたことに、ではない。@このつめたそうで、@この冷たそうで、@どこかあたたかいこのこえは。@どこか温かいこの声は。@てつやせんせい@「鉄也先生！？」@おもわずこえをだす。@思わず声を出す。@はるかわか。きぐうだな@「春川か。奇遇だな」@こわいてつやせんせいは、@声井鉄也先生は、@こうこうのときのたんにんのせんせいだ。@高校のときの担任の先生だ。@つめたそうで@冷たそうで@じつはやさしいぎゃっぷがあり、@実は優しいギャップがあり、@じょしのなかでふぁんくらぶまであった。@女子の中でファンクラブまであった。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },

  {
    id: 5,
    index: 4,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "はるかわ、だいじょうぶか@「春川、大丈夫か」@さいどせんせいがとう。@再度先生が問う。@はるかわ@「春川」@は、はい@「は、はい！！」@さんどめでようやくしょうきにもどる。@三度目でようやく正気に戻る。@とりあえずちかくのかふぇで@とりあえず近くのカフェで@はなしをきいてもらえることになった。@話を聞いてもらえることになった。@あらためてせんせいのかおをみる。@改めて先生の顔を見る。@こわい、というなのとおりの@声井、という名の通りの@こわそうなかお、@怖そうな顔、@でもほんとうはそんなことなくて、@でも本当はそんなことなくて、@いつもやさしいせんせいだ。@いつも優しい先生だ。@はるかわ、はなしをつづけて@「春川、話を続けて」@せんせいにうながされる。@先生に促される。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 5,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "つまり、@「つまり、@そのかなもりみつるというおとこに@その金森満という男に@こんやくはきされて、@婚約破棄されて、@しょっくで@ショックで@ほーむでたおれかかっていたと@ホームで倒れかかっていたと」@せんせいはしばしまをあけて、@先生はしばし間をあけて、@ただひとこと@ただ一言@そうか@「そうか」@とだけいった。@とだけ言った。@そのあとはなにもいわない。@その後は何も言わない。@なぐさめのことばもない。@慰めの言葉もない。@なにかひとことくらい。@何か一言くらい。@みのりのこころをよんだかのように、@実莉の心を読んだかのように、@せんせいはつげる。@先生は告げる。@ひとのいたみは、@「人の痛みは、@ほかのひとにはわからないからな@他の人には分からないからな」",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },

  {
    id: 5,
    index: 6,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "なにそれ@「何それ」@みのりはすこしいらっとする。@実莉は少しイラッとする。@なぐさめのことばくらいくれてもいいのに。@慰めの言葉くらいくれてもいいのに。@うわべだけのどうじょうは@「上辺だけの同情は@ぎゃくにしつれいだ、ちがうか。@逆に失礼だ、違うか。@それではるかわはどうしたいんだ@それで春川はどうしたいんだ」@みのりはすこしかんがえてきめた。@実莉は少し考えて決めた。@もういちどみつるとあってはなしがしたい@「もう一度満と会って話がしたい」@なるほど、かれとどこにすんでいた@「なるほど、彼とどこに住んでいた」@びるのなかの、あのおしろ@「ビルの中の、あのお城」@これにはせんせいもめをみひらいていた。@これには先生も目を見開いていた。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 7,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "かなもりみつる。@金森満。@だいきぎょうのおんぞうしにして@大企業の御曹司にして@おおがねもち。@大金持ち。@そのざいりょくで、@その財力で、@としんにせいようふうのしろをたてるという@都心に西洋風の城を建てるという@ぼうきょをなしとげていた。@暴挙を成し遂げていた。@みのりたちがしろにつくと、@実莉たちが城につくと、@うんよくみつるとでくわした。@運よく満と出くわした。@でくわしたのだが。@出くわしたのだが。@なにこれ@「何これ」@おもわずつぶやく。@思わず呟く。@みつるは、@満は、@じゅうにんいじょうのじょせいたちと@十人以上の女性達と@ともにいたのだ。@共にいたのだ。@なんだ、おまえまだいたのか@「何だ、お前まだいたのか」@みつるがさげすむようなめではなしかけた。@満が蔑むような目で話しかけた。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 8,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "おまえ@「お前@ぼくがおんなあそびするのいやがるだろ。@僕が女遊びするの嫌がるだろ。@ああいうのいやなんだよ。@ああいうの嫌なんだよ。@ぼくはとみもけんりょくもある。@僕は富も権力もある。@すべてはぼくのおもうままであるべきだ。@全ては僕の思うままであるべきだ。@おまえはだいがくでいちばんかわいいと@お前は大学で一番可愛いと@うわさだからかのじょにしてやったのに、@噂だから彼女にしてやったのに、@はんこうてきにもほどがある@反抗的にも程がある」@みのりはくらくらとめまいがした。@実莉はくらくらと目眩がした。@だめなところがあるとはしっていたけど、@ダメな所があるとは知っていたけど、@そんな。@そんな。@おい@「おい」@うしろからこえがひびいた。@後ろから声が響いた。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 9,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "なんだおっさん@「何だおっさん」@おれははるかわのもとたんにんだ。@「俺は春川の元担任だ。@こじんのれんあいにくちだしはしないが、@個人の恋愛に口出しはしないが、@もとおしえごを@元教え子を@あまりきずつけてほしくないな@あまり傷つけて欲しくないな」@みのりはふりかえっておどろいた。@実莉は振り返って驚いた。@すごいけんまくでせんせいがにらんでいる。@すごい剣幕で先生が睨んでいる。@こんなかおははじめてみた。@こんな顔は初めて見た。@それにけおされてみつるがたじろぐ。@それに気おされて満がたじろぐ。@な、なんだよおまえら。@「な、何だよお前ら。@かえれ@帰れ」@そういうと、@そう言うと、@しろへにげるようにかえっていった。@城へ逃げるように帰っていった。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 10,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "みのりはおどろく。@実莉は驚く。@せんせいがおこったことは@先生が怒ったことは@いままでなかった。@今まで無かった。@あんなこわいかおもするなんて。@あんな怖い顔もするなんて。@みると、せんせいはめをそらす。@見ると、先生は目を逸らす。@はるかわ、その、わるかった@「春川、その、悪かった」@なんであやまられたかがわからない。@なんで謝られたかが分からない。@はるかわは@「春川は@あいつとなかなおりしにきたのに、@あいつと仲直りしにきたのに、@おれのせいでそれがかなわなかった、@俺のせいでそれが叶わなかった、@ほんとうにもうしわけない@本当に申し訳ない」@せんせいはみのりのほうをむくと、@先生は実莉のほうを向くと、@ふかぶかとあたまをさげた。@深々と頭を下げた。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 11,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "とつぜんのことにみのりはこんらんする。@突然のことに実莉は混乱する。@せんせいはわるくない。@先生は悪くない。@わるいのはかれのほうなのに。@悪いのは彼の方なのに。@なのにせんせいはあやまる。@なのに先生は謝る。@と、とりあえず、@「と、とりあえず、@あたまをあげてください。@頭を上げてください。@きょうはいちど、いえに@今日は一度、家に」@そこまでいってみのりはきづく。@そこまで言って実莉は気づく。@どうせいしていたかれにおいだされたいま、@同棲していた彼に追い出された今、@みのりはいえがなかったのだ。@実莉は家が無かったのだ。@なら、せんせいのいえにとまるか@「なら、先生の家に泊まるか」@みのりは、あたまがまっしろになった。@実莉は、頭が真っ白になった。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 12,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "もう、なんなのよ@「もう、なんなのよ」@えいふぁはあたまをかかえていた。@エイファは頭を抱えていた。@やっとみのりをおいだせたのに。@やっと実莉を追い出せたのに。@みのりはみつるのけいえいするかいしゃで@実莉は満の経営する会社で@せっきゃくをてつだっていた。@接客を手伝っていた。@それもたんににこにこしているだけの、@それも単ににこにこしているだけの、@かんたんなてつだいだ。@簡単な手伝いだ。@それくらいわたしでもできる。@それくらい私でもできる。@でもみつるは@でも満は@そんなみのりをひつようとした。@そんな実莉を必要とした。@わたしのほうがゆうのうなのに。@私の方が有能なのに。@きっと、@きっと、@みのりのえがおにだまされてるんだ。@実莉の笑顔に騙されてるんだ。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 13,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "そうおもって、@そう思って、@みのりはじつはせいかくがわるい、@実莉は実は性格が悪い、@このかいしゃをのっとろうとしている、@この会社を乗っ取ろうとしている、@そんなうそをみつるにすこしずつふきこみ@そんな嘘を満に少しずつ吹き込み@みつるをうばったのだ。@満を奪ったのだ。@それなのに。@それなのに。@なんで、くるひとくるひと@「なんで、来る人来る人@みのりのことをきにしてるのよ@実莉のことを気にしてるのよ」@みのりのいんしょうはよほどいいらしい。@実莉の印象はよほどいいらしい。@らいきゃくは、@来客は、@みのりがいないとしるとざんねんがる。@実莉がいないと知ると残念がる。@おぼえてろ、@覚えてろ、@とえいふぁのさけびごえがひびいた。@とエイファの叫び声が響いた。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },

  {
    id: 5,
    index: 14,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "えっと、せんせいのいえにおとまり@「えっと、先生の家にお泊り」@かおがあつい。@顔が熱い。@いまにもひがでそう。@今にも火が出そう。@たしかせんせいはどくしんだったはずだ。@確か先生は独身だったはずだ。@つまり、どくしんのおとこのひとのいえに、@つまり、独身の男の人の家に、@わたしはこれからおとまりに@私はこれからお泊りに@すむいえがないんだろう、@「住む家がないんだろう、@それしかあるまい@それしかあるまい」@せんせいはいにかいせずというようすで@先生は意に介せずという様子で@はなしをすすめる。@話を進める。@そのときのせんせいのかおが、@そのときの先生の顔が、@すこしあかかったことを、@少し赤かったことを、@みのりはまだしらなかった。@実莉はまだ知らなかった。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 15,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "せ、せんせい、なにかつくりますよ@「せ、先生、何か作りますよ」@ひとりぐらしのだんせい、@一人暮らしの男性、@しかもせんせいのいえというじじつに@しかも先生の家という事実に@きんちょうしていることをごまかしたくて、@緊張していることを誤魔化したくて、@みのりはていあんする。@実莉は提案する。@いったいわたしは、@一体私は、@なにをいしきしてるんだろ。@何を意識してるんだろ。@むこうはそんなきあるはずないのに。@向こうはそんな気あるはずないのに。@いや、そとでたべるからもんだいない@「いや、外で食べるから問題ない」@ちんもくがうまれる。@沈黙が生まれる。@かいわがつづかない。@会話が続かない。@こんやだが@「今夜だが」@とつぜんせんせいがいう。@突然先生が言う。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 16,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "こんや。@今夜。@みのりはふたたびあたまがまっしろになる。@実莉は再び頭が真っ白になる。@みのりももうおとなだ。@実莉ももう大人だ。@でもさすがに、あたまがおいつかない。@でもさすがに、頭が追い付かない。@せんせいはゆかでねる。@「先生は床で寝る。@はるかわはそこのべっどをつかえ@春川はそこのベッドを使え」@ほんと、なにをかんがえてたんだろう。@ほんと、何を考えてたんだろう。@そのよるみのりはねむれなかった。@その夜実莉は眠れなかった。@ふとんからせんせいのにおいがする。@布団から先生の匂いがする。@それでどきどきしてるじぶんがいる。@それでドキドキしてる自分がいる。@いったい、わたしはいま@一体、私は今@だれのことがすきなんだろう。@誰のことが好きなんだろう。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 17,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "みのりをおいだしてからいっかげつ。@実莉を追い出してから一か月。@みつるはいわかんをいだいていた。@満は違和感を抱いていた。@みのりよりえいふぁのほうがいいおんなだ。@実莉よりエイファの方がいい女だ。@みのりとちがいえいふぁは@実莉と違いエイファは@ぼくのきげんをとってくれる。@僕の機嫌を取ってくれる。@しかもみのりはぼくをだまして@しかも実莉は僕を騙して@かいしゃをうばうつもりだという。@会社を奪うつもりだという。@えいふぁがいうのだ、@エイファが言うのだ、@まちがいない。@間違いない。@しかしきゃくのひょうばんは@しかし客の評判は@みのりのほうがいい。@実莉の方がいい。@それいじょうに。@それ以上に。@みつるは@満は@そうしつかんをかんじはじめていた。@喪失感を感じ始めていた。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 18,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "かねもちのみつるは@金持ちの満は@むかしからおんなにはこまらなかった。@昔から女には困らなかった。@おんなはいつもぼくのきげんをとってくる。@女はいつも僕の機嫌を取ってくる。@でもみのりだけはちがった。@でも実莉だけは違った。@ぼくとけんかをするおんななど@僕と喧嘩をする女など@はじめてだった。@初めてだった。@ぼくはそれがふまんだったし、@僕はそれが不満だったし、@かわいげがたりないとおもっていた。@可愛げが足りないと思っていた。@いっぽうでえいふぁはいいおんなだ。@一方でエイファはいい女だ。@いつもぼくにじゅうじゅんだ。@いつも僕に従順だ。@でも。@でも。@このそうしつかんはなぜだ@「この喪失感はなぜだ」@みつるはなぞのきもちにあたまをかかえた。@満は謎の気持ちに頭を抱えた。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 19,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "えいふぁはあせっていた。@エイファは焦っていた。@みのりがじゅうじゅんなおんなではないことに@実莉が従順な女ではないことに@ふまんをかかえるみつるを@不満を抱える満を@たらしこむのはかんたんだった。@たらしこむのは簡単だった。@せっきゃくのときのえがおも、@接客のときの笑顔も、@かいしゃをのっとるためだといえば@会社を乗っ取るためだと言えば@しんじてくれた。@信じてくれた。@たまのこしにのるのはわたしだ。@玉の輿に乗るのは私だ。@そのはずなのに。@そのはずなのに。@なぜみつるはみのりをきにしているのか。@なぜ満は実莉を気にしているのか。@いいことかんがえたわ@「いいこと考えたわ」@えいふぁのかおに@エイファの顔に@とうとつにえみがうかんだ。@唐突に笑みが浮かんだ。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 20,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "きょうはたのむぞ、えいふぁ@「今日は頼むぞ、エイファ」@みつるがふあんそうにいう。@満が不安そうに言う。@きょうはねんにいちどの@今日は年に一度の@おとくいさまとのしょうだんのひ。@お得意様との商談の日。@しっぱいはゆるされない。@失敗は許されない。@しかしぎゃくに@しかし逆に@みのりいじょうにだいせいこうさせれば、@実莉以上に大成功させれば、@みつるもわたしのゆうのうさにきづくはずだ。@満も私の有能さに気づくはずだ。@そしてえいふぁにはそのじしんがある。@そしてエイファにはその自信がある。@そのためのおんなのぶきなのだ。@そのための「女の武器」なのだ。@わたしっててんさいかしら@「私って天才かしら」@はなうたまじりのえいふぁだった。@鼻歌まじりのエイファだった。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 21,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "すこしふたりだけでおはなしがしたいですわ@「少し二人だけでお話がしたいですわ」@よけいなひとをたいしゅつさせ、@余計な人を退出させ、@えいふぁはしょうぶにでる。@エイファは勝負に出る。@あついですわね@「暑いですわね」@そういいつつうわぎをぬいでむねをあてる。@そう言いつつ上着を脱いで胸を当てる。@すたいるにはじしんあり。@スタイルには自信あり。@そのままうわめづかいでしょうだんを@そのまま上目遣いで商談を@きみ@「君」@きゃくのこえ。@客の声。@なにをしてるのかね@「何をしてるのかね」@でもおすきでしょゆうわくも@「でもお好きでしょ？誘惑も」@やめろ@「やめろ！！」@いろじかけをさえぎったのは@色仕掛けを遮ったのは@みつるだった。@満だった。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 22,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "みつるさんなんで@「満さんなんで」@なんでとめるの@なんで止めるの？？@おとこならいろじかけで@男なら色仕掛けで@いいじょうけんのしょうだんがとれたのに。@いい条件の商談が取れたのに。@どうしてじゃまなんかするの@どうして邪魔なんかするの？？@かなもりさんこれはなんですかな@「金森さんこれは何ですかな」@しっぱいした、@失敗した、@それだけはわかる。@それだけは分かる。@でもなんで。@でも何で。@どうせはるかわもいろじかけしてたんでしょ@「どうせ春川も色仕掛けしてたんでしょ！！」@えいふぁはさけぶ。@エイファは叫ぶ。@ただわらってるだけ、@「ただ笑ってるだけ、@それできゃくのひょうばんいいわけないじゃない@それで客の評判いい訳ないじゃない！！」",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 23,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "ちがいますぞえいふぁさん@「違いますぞエイファさん」@きゃくがくちをひらく。@客が口を開く。@はるかわさんはれいぎただしいかたでした。@「春川さんは礼儀正しい方でした。@あなたは@あなたは@はるかわさんをおきらいのようですがね、@春川さんをお嫌いのようですがね、@はるかわさんはけっして@春川さんは決して@いろじかけでひとをゆうわくしたり@色仕掛けで人を誘惑したり@ひとをけなしたりする@人を貶したりする@かたではありませんでした。@方ではありませんでした。@かのじょがいなくなってざんねんです@彼女がいなくなって残念です」@きゃくがかえっていく。@客が帰っていく。@そしていれかわりにきたのは@そして入れ替わりに来たのは@なんとはるかわだった。@なんと春川だった。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 24,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "あれ、いまのほうはたしかおとくいさまの@「あれ、今の方は確かお得意様の」@そうはなすみのりにみつるはうなずく。@そう話す実莉に満は頷く。@ぼくにはなしがあるらしいからきてもらった@「僕に話があるらしいから来てもらった」@そう、きょうはみつるにはなしをしにきた。@そう、今日は満に話をしに来た。@このいっかげつ、@この一か月、@みのりなりにかんがえたけっか、@実莉なりに考えた結果、@こんごのことをつたえるために。@今後のことを伝えるために。@はるかわぁ@「春川ぁ！！」@とつぜんえいふぁがさけぶ。@突然エイファが叫ぶ。@そのてにはかびん。@その手には花瓶。@きゃあ@「きゃあ！！」@ちゅうをまったかびんが、@宙を舞った花瓶が、@おとをたててわれた。@音をたてて割れた。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 25,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "みのりはとっさのことに@実莉はとっさのことに@からだがうごかなかった。@体が動かなかった。@ぶつかる。@ぶつかる。@めをとじてあたまをかかえる。@目を閉じて頭を抱える。@まだいたくない。@まだ痛くない。@まだ、いたくない。@まだ、痛くない。@まだ。@まだ。@まだ@まだ？@おそるおそるめをあける。@恐る恐る目を開ける。@めのまえには、@目の前には、@せんせいがいた。@先生がいた。@せんせいっ@「先生っ！！」@はるかわ、ぶじか@「春川、無事か」@せんせいちが@「先生血が」@みのりはあわてつつ、@実莉は慌てつつ、@かばんからばんそうこうをとりだす。@鞄から絆創膏を取り出す。@かわらないな、はるかわは@「変わらないな、春川は」@せんせいがしずかにつぶやいた。@先生が静かに呟いた。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 26,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "あれは、おれがたんにんだったとき。@あれは、俺が担任だったとき。@けんかちゅうさいちゅう、せいとが@喧嘩仲裁中、生徒が@きょうしつのかびんをなげたことがあった。@教室の花瓶を投げたことがあった。@ちかくのせいとはまもれたが、@近くの生徒は守れたが、@かわりにおれはかるいけがをした。@かわりに俺は軽い怪我をした。@すると、そのせいとはあわてつつ@すると、その生徒は慌てつつ@ばんそうこうをもってきてはってくれた。@絆創膏を持ってきて貼ってくれた。@いじめをむかしうけていたおれには、@いじめを昔受けていた俺には、@そのささいなきづかいすらはじめてだった。@その些細な気遣いすら初めてだった。@そのせいとのなは、@その生徒の名は、@はるかわみのりという。@春川実莉という。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 5,
    index: 27,
    img: "rensaiA01.webp",
    title: "婚約破棄から始まる第２の青春",
    text: "けっきょく@結局@えいふぁはひしょをくびになるそうだ。@エイファは秘書を首になるそうだ。@みつるからは、@満からは、@こんやくはきをふくめて@婚約破棄を含めて@いままでのことをあやまられたが、@今までのことを謝られたが、@わかれたいとつげた。@別れたいと告げた。@ろくねんかんもいっしょにいたのだ、@六年間も一緒にいたのだ、@きゅうにはわすれられない。@急には忘れられない。@でもいまはせんせいといっしょにいたい。@でも今は先生と一緒にいたい。@みのりはそうねがった。@実莉はそう願った。@こわいというみょうじなのにやさしい@こわいという苗字なのに優しい@こわいみのり@声井実莉@というわかおくさまがゆうめいになるのは、@という若奥様が有名になるのは、@もうすこしあとのはなしだ。@もう少し後の話だ。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 1,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "がんじつ@「元日」@めいじじだいからたいしょう、@明治時代から大正、@しょうわしょきにかけては、@昭和初期にかけては、@きゅうちゅうでてんのうが@宮中で天皇が@てんちしほうのくにつかみをはいするぎしき@天地四方の国津神を拝する儀式@しほうはいにちなんで、@「四方拝」にちなんで、@しほうせつとよばれる@「四方節」と呼ばれる@しゅくさいじつのうちのしだいせつのひとつ、@祝祭日のうちの四大節の一つ、@とされていた。@とされていた。@1948ねん@1948年@しょうわ23ねん@(昭和23年)@にこくみんのしゅくじつにかんするほうりつがかいせいされ、@に国民の祝日に関する法律が改正され、@げんざいのようないちねんのさいしょのひを@現在のような一年の最初の日を@いわうしゅくじつとなった。@祝う祝日となった。@がんたんもるいぎごであるが、@「元旦」も類義語であるが、@たんはあさやよあけのことであり、@「旦」は朝や夜明けのことであり、@そのばあいはがんじつのあさ、@その場合は元日の朝、@すなわちはつひのでやがんたんのごぜんちゅうを@すなわち初日の出や元日の午前中を@げんみつにはいみする。@厳密には意味する。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 2,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "せいじんのひ@「成人の日」@おとなになったことをじかくし、@「おとなになったことを自覚し、@みずからいきぬこうとするせいねんをいわいはげます@みずから生き抜こうとする青年を祝い励ます」@ことをしゅしとして、@ことを趣旨として、@せいていされたしゅくじつ。@制定された祝日。@こしょうがつにゆらいして、@小正月に由来して、@1999ねんまでは1がつ15にちだったが、@1999年までは1月15日だったが、@はっぴーまんでーせいどのどうにゅうにより、@ハッピーマンデー制度の導入により、@2000ねんからは1がつのだい2げつようび@2000年からは1月の第2月曜日@にへんこうされた。@に変更された。@このひはかくしちょうそんで、@この日は各市町村で、@しんせいじんをまねいてせいじんしきがおこなわれ、@新成人を招いて成人式が行なわれ、@じょせいはふりそでやきもの、@女性は振り袖や着物、@だんせいはすーつやはおりはかまなどの@男性はスーツや羽織袴などの@せいそうすがたでしゅっせきして、@正装姿で出席して、@しちょうなどからしゅくふくのことばをおくられる。@市長などから祝福の言葉を贈られる。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 3,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "けんこくきねんのひ@「建国記念の日」@せかいでけんこくきねんびを@世界で「建国記念日」を@ほうりつでさだめしゅくじつとするくにはおおいが、@法律で定め祝日とする国は多いが、@なにをもってけんこくきねんとするかは、@何をもって建国記念とするかは、@とうぜんくにによってことなる。@当然国によって異なる。@にほんはけんこくのひがめいかくでないため、@日本は建国の日が明確でないため、@しんわをもとにさだめられている。@神話をもとに定められている。@2がつ11にちは@2月11日は@にほんしんわのとうじょうじんぶつで、@日本神話の登場人物で、@こじきやにほんしょきでしょだいてんのうとされる@古事記や日本書紀で初代天皇とされる@じんむてんのうがそくいしたひであり、@神武天皇が即位した日であり、@めいじじだいにきげんせつとしてきゅうじつとなった。@明治時代に「紀元節」として休日となった。@1948ねんにいちどはいしされたものの、@1948年に一度廃止されたものの、@1966ねんにあらためて@1966年に改めて@こくみんのしゅくじつとなり、@国民の祝日となり、@よくねんからてきようされた。@翌年から適用された。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 4,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "てんのうたんじょうび@「天皇誕生日」@そのなのとおり、@その名の通り、@てんのうのたんじょうびをいわうことをしゅしとした、@天皇の誕生日を祝うことを趣旨とした、@こくみんのしゅくじつ。@国民の祝日。@きゅうちゅうではかくしゅのぎや、@宮中では各種の儀や、@いっぱんさんががおこなわれる。@一般参賀が行なわれる。@1948ねんしょうわ23ねんまでは@1948年(昭和23年)までは@てんちょうせつとよばれていた。@「天長節」と呼ばれていた。@それいこうの1988ねん@それ以降の1988年@しょうわ63ねんまでは、@(昭和63年)までは、@しょうわてんのうのたんじょうびである4がつ29にち、@昭和天皇の誕生日である4月29日、@げんごうがへいせいにかわった@元号が平成に変わった@1989ねんから2018ねんまでは、@1989年から2018年までは、@つぎのてんのうであるあきひとの@次の天皇である明仁の@たんじょうびである12がつ23にち、@誕生日である12月23日、@げんざいはなるひとのたんじょうびである@現在は徳仁の誕生日である@2がつ23にちが、@2月23日が、@それぞれがいとうする。@それぞれ該当する。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 5,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "しゅんぶんのひ@「春分の日」@1948ねんにしゅくじつほうによってせいていされた、@1948年に「祝日法」によって制定された、@しぜんをたたえ、せいぶつをいつくしむ@「自然をたたえ、生物をいつくしむ」@ことをしゅしとした、こくみんのしゅくじつ。@ことを趣旨とした、国民の祝日。@このぜんごがはるのおひがんで、@この前後が春のお彼岸で、@おはかまいりをするものもおおい。@お墓参りをする者も多い。@ひるとよるのながさが、@昼と夜の長さが、@ひとしくなるひともいわれる。@等しくなる日とも言われる。@ちきゅうがたいようのまわりをまわる@地球が太陽のまわりを回る@こうてんにかかるにっすうが、@（公転）にかかる日数が、@365にちちょうどではないつごうじょう、@365日ちょうどではない都合上、@としによってずれがしょうじる。@年によってずれが生じる。@たとえば、@例えば、@2021ねんれいわ3ねんは3がつはつかだったが、@2021年(令和3年)は3月20日だったが、@2022ねんは3がつ21にちである。@2022年は3月21日である。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 6,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "しょうわのひ@「昭和の日」@しゅくじつほうのいちぶかいせいによって、@祝日法の一部改正によって、@2007ねんにせいていされた4がつ29にちのしゅくじつ。@2007年に制定された4月29日の祝日。@げきどうのひびをへて、@「激動の日々を経て、@ふっこうをとげたしょうわのじだいをかえりみ、@復興を遂げた昭和の時代を顧み、@くにのしょうらいにおもいをいたす@国の将来に思いをいたす」@という趣旨。@という趣旨。@1948ねんから1988ねんまでは、@1948年から1988年までは、@しょうわてんのうのてんのうたんじょうびであった。@昭和天皇の「天皇誕生日」だった。@1989ねんへいせいがんねん@1989年(平成元年)@から2006ねんまでは、@2006年までは、@しょくぶつにぞうけいがふかかったてんのうにちなみ、@植物に造詣が深かった天皇にちなみ、@みどりのひという@「みどりの日」という@しぜんにしたしむとともに@「自然に親しむとともに@そのおんけいにかんしゃし、@その恩恵に感謝し、@ゆたかなこころをはぐくむひ@豊かな心を育む日」@というしゅしのしゅくじつだった。@という趣旨の祝日だった。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 7,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "けんぽうきねんび@「憲法記念日」@947ねんしょうわ22ねんの5がつ3かに@1947年(昭和22年)の5月3日に@にほんこくけんぽうがせこうされたことをきねんして、@日本国憲法が施行されたことを記念して、@よくねんの1948ねんからせいていされた@翌年の1948年から制定された@こくみんのきゅうじつ。@国民の休日。@とうじはけんぽうきねんびについて、@当時は「憲法記念日」について、@しゅうぎいんではけんぽうのせこうびである5がつ3か、@衆議院では憲法の施行日である5月3日、@さんぎいんではけんぽうのこうふびである@参議院では憲法の公布日である@11がつ3かとするいけんが、@11月3日とする意見が、@それぞれおおかった。@それぞれ多かった。@さきにしんぎをおこなったしゅうぎいんのいけんを、@先に審議を行なった衆議院の意見を、@さんぎいんがそんちょうするかたちで@参議院が尊重する形で@ぜんしゃのほうあんがかけつされ、@前者の法案が可決され、@こうしゃはぶんかのひとなった。@後者は「文化の日」となった。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 8,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "みどりのひ@「みどりの日」@1989ねんにげんごうがしょうわからへいせいになり、@1989年に元号が昭和から平成になり、@てんのうたんじょうびが12がつ23にちにいこうした。@天皇誕生日が12月23日に移行した。@それをうけて、@それを受けて、@ごーるでんういーくをこうせいするきゅうじつをはいしすると、@ゴールデンウイークを構成する休日を廃止すると、@こくみんのせいかつにえいきょうがでるため、@国民の生活に影響が出るため、@4がつ29にちをあらためて@4月29日を改めて@みどりのひとしてしゅくじつとした。@「みどりの日」として祝日とした。@2007ねんからは、@2007年からは、@げんざいの5がつ4かにいどう。@現在の5月4日に移動。@しぜんにしたしむとともに@「自然にしたしむとともに@そのおんけいにかんしゃし、@その恩恵に感謝し、@ゆたかなこころをはぐくむ@豊かな心をはぐくむ」@ことをしゅしとする。@ことを趣旨とする。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 9,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "こどものひ@「こどもの日」@5がついつかのたんごのせっくにせいていされた、@5月5日の端午の節句に制定された、@こくみんのしゅくじつ。@国民の祝日。@えどじだいいこうはおとこのこのきねんびだったが、@江戸時代以降は男の子の記念日だったが、@こどものひはだんじょのこのくべつはない。@「こどもの日」は男女の子の区別はない。@こどものじんかくをおもんじ、@「こどもの人格を重んじ、@こどものこうふくをはかるとともに、@こどもの幸福をはかるとともに、@ははにかんしゃすることをしゅしとする。@母に感謝する」ことを趣旨とする。@ごーるでんういーくをこうせいするきゅうじつのひとつで、@ゴールデンウイークを構成する休日の一つで、@1948ねんにこうふされそくじつしこうされた。@1948年に公布され即日施行された。@こどもをこどもとかんじでひょうきするのはあやまり。@「こども」を「子供」と漢字で表記するのは誤り。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 10,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "うみのひ@「海の日」@1996ねんへいせい7ねんからせこうされた、@1996年(平成7年)から施行された、@うみのきねんびにゆらいするこくみんのしゅくじつ。@海の記念日に由来する国民の祝日。@せいていとうしょは@制定当初は@7がつ20にちにこていされていたが、@7月20日に固定されていたが、@かいせいされたしゅくじつほうの@改正された祝日法の@はっぴーまんでーせいどによって、@ハッピーマンデー制度によって、@2003ねんからは7がつのだいさんげつようびとなった。@2003年からは7月の第三月曜日となった。@とうきょうおりんぴっくのえいきょうで、@東京オリンピックの影響で、@2020ねんおよび2021ねんにかぎって@2020年及び2021年に限って@ひづけがそれぞれことなる。@日付がそれぞれ異なる。@うみのおんけいにかんしゃするとともに、@「海の恩恵に感謝するとともに、@かいようこくにほんのはんえいをねがう@海洋国日本の繁栄を願う」@ことをしゅしとしている。@ことを趣旨としている。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 11,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "やまのひ@「山の日」@2014ねんにかいせいしゅくじつほうがかけつされ、@2014年に改正祝日法が可決され、@2016ねんへいせい28ねんよりてきようされた、@2016年(平成28年)より適用された、@あたらしいこくみんのしゅくじつ。@新しい国民の祝日。@ひづけは8がつ11にちである。@日付は8月11日である。@とうしょは8がつ12にちがけんとうされていたが、@当初は8月12日が検討されていたが、@にほんこうくう123びんついらくじことどうじつであり、@日本航空123便墜落事故と同日であり、@ついらくしたばしょがおすたかのおね、@墜落した場所が御巣鷹の尾根、@つまりやまであるというじじょうから、@つまり山であるという事情から、@へんこうされたけいいがある。@変更された経緯がある。@やまにしたしむきかいをえて、@「山に親しむ機会を得て、@やまのおんけいにかんしゃする@山の恩恵に感謝する」@ことをしゅしとする。@ことを趣旨とする。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 12,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "けいろうのひ@「敬老の日」@たねんにわたりしゃかいにつくしてきたろうじんをけいあいし、@「多年にわたり社会につくしてきた老人を敬愛し、@ちょうじゅをいわうことをしゅしとした、@長寿を祝う」ことを趣旨とした、@こくみんのしゅくじつ。@国民の祝日。@かつてはとしよりのひやろうじんのひ@かつては「としよりの日」や「老人の日」@というめいしょうであったが、@という名称であったが、@ごろがわるいなどのりゆうにより、@語呂が悪いなどの理由により、@げんざいのなまえとなった。@現在の名前となった。@1966ねんしょうわ41ねんから2002ねんまでは、@1966年(昭和41年)から2002年までは、@9がつ15にちにこていされていた。@9月15日に固定されていた。@はっぴーまんでーせいどのじっしによって、@ハッピーマンデー制度の実施によって、@2003ねんからは9がつの@2003年からは9月の@だいさんげつようびにへんこうされている。@第三月曜日に変更されている",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 13,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "しゅうぶんのひ@「秋分の日」@そせんをうやまい、@「祖先をうやまい、@なくなったひとびとをしのぶ。@なくなった人々をしのぶ。」@ことをしゅしとした、@ことを趣旨とした、@こくみんのしゅくじつにかんするほうりつによって@国民の祝日に関する法律によって@1948ねんにせいていされたしゅくじつ。@1948年に制定された祝日。@しゅんぶんのひとどうように、@「春分の日」と同様に、@ひるとよるのながさがひとしくなるひといわれている。@昼と夜の長さが等しくなる日と言われている。@こよみのえいきょうで、@暦の影響で、@しゅうぶんびがいちねんごとにずれこむため、@秋分日が一年ごとにずれ込むため、@なんにちがしゅうぶんのひになるか@何日が「秋分の日」になるか@はこていされない。@は固定されない。@たとえば、@例えば、@2020ねんれいわ2ねんは9がつ22日だったが、@2020年(令和2年)は9月22日だったが、@2021ねんは9がつ23にちである。@2021年は9月23日である。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 14,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "すぽーつのひ@「スポーツの日」@1964ねんにかいさいされたとうきょうおりんぴっくの、@1964年に開催された東京オリンピックの、@かいかいしきがおこなわれた10がつとうかをきねんして、@開会式が行なわれた10月10日を記念して、@1966ねんからさだめられたしゅくじつ。@1966年から定められた祝日。@とうしょはたいいくのひというめいしょうだった。@当初は「体育の日」という名称だった。@2000ねんからは@2000年からは@はっぴーまんでーせいどのどうにゅうにより、@ハッピーマンデー制度の導入により、@10がつのだいにげつようびとなる。@10月の第2月曜日となる。@2020ねんからげんざいの@2020年から現在の@すぽーつのひというなまえにへんこうされた。@「スポーツの日」という名前に変更された。@にどめのとうきょうおりんぴっくかいさいのえいきょうで、@二度目の東京オリンピック開催の影響で、@2020ねんおよび2021ねんにかぎって@2020年及び2021年に限って@7がつにいどう。@7月に移動。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 15,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ぶんかのひ@「文化の日」@ひづけは11がつみっか。@日付は11月3日。@めいじてんのうのたんじょうびにゆらいする、@明治天皇の誕生日に由来する、@だいにじたいせんまえからせいていされていた@第二次大戦前から制定されていた@ふるくからあるきゅうじつ。@古くからある休日。@かつてはてんちょうせつや@かつては天長節や@めいじせつというなまえだった。@明治節という名前だった。@にほんこくけんぽうが@日本国憲法が@へいわとぶんかをじゅうししていることによって、@平和と文化を重視していることによって、@1948ねんから@1948年から@ぶんかのひとしてあらためてさだめられた。@「文化の日」として改めて定められた。@じゆうとへいわをあいし、@「自由と平和を愛し、@ぶんかをすすめることをしゅしとする。@文化をすすめる」ことを趣旨とする。@ぶんかのはってんやこうじょうに@文化の発展や向上に@めざましいこうせきをあげたものに、@めざましい功績を上げた者に、@このひぶんかくんしょうがじゅよされる。@この日文化勲章が授与される。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 16,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "きんろうかんしゃのひ@「勤労感謝の日」@ひづけは11がつ23にち。@日付は11月23日。@きんろうをたつとび、せいさんをいわい、@「勤労をたつとび、生産を祝い、@こくみんがたがいにかんしゃしあうことをしゅしとする。@国民がたがいに感謝しあう」ことを趣旨とする。@ふるくからてんのうが、@古くから天皇が、@おこめなどのしゅうかくぶつをかみがみにおそなえしてかんしゃし、@お米などの収穫物を神々にお供えして感謝し、@みずからもしょくするにいなめさい@自らも食する「新嘗祭」@というさいじがおこなわれており、@という祭事が行なわれており、@めいじじだいいこうもこのひはきゅうじつとなっていた。@明治時代以降もこの日は休日となっていた。@1947ねんにしゅくじつほうがせこうされ、@1947年に祝日法が施行され、@しゅくじつからこっかしんとうをふっしょくするほうしんにより、@祝日から国家神道を払拭する方針により、@きんろうかんしゃのひとめいしょうがあらためられた。@「勤労感謝の日」と名称が改められた。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 17,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "じんじつ@「人日」@こだいちゅうごくのいんようごぎょうせつをゆらいとする、@古代中国の陰陽五行説を由来とする、@ごせっくのひとつ。@五節句の一つ。@1がつなのかにななくさがゆをたべることから、@1月7日に七草粥を食べることから、@ななくさのせっくともよばれる。@七草の節句とも呼ばれる。@いちねんのむびょうそくさいをねがうとともに、@一年の無病息災を願うとともに、@しょうがつのおせちりょうりやおとそなどのさけで、@正月のおせち料理やお屠蘇などの酒で、@よわったいをやすめるため、@弱った胃を休めるため、@ななしゅるいのやさいをいれた@七種類の野菜を入れた@あつものをたべるしゅうかんがあり、@羹を食べる習慣があり、@これがにほんにつたわってななくさがゆとなった。@これが日本に伝わって七草粥となった。@せり、なずな、ごぎょう、はこべら、@芹、薺、御形、繁縷、@ほとけのざ、すずな、すずしろがはるのななくさである。@仏の座、菘、蘿蔔が春の七草である。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 18,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "せつぶん@「節分」@ほんらいはかくきせつのはじまりのひをいみするが、@本来は各季節の始まりの日を意味するが、@げんざいはりっしゅんのぜんじつである@現在は立春の前日である@はるのせつぶんをしめすことがほとんど。@「春の節分」を示すことがほとんど。@ひづけは2がつみっかがつうれいだが、@日付は2月3日が通例だが、@りっしゅんがてんもんかんそくによってへんどうするため、@立春が天文観測によって変動するため、@2021ねんは37ねんぶりに2がつふつかであった。@2021年は37年ぶりに2月2日であった。@きせつのかわりめにしょうじるといわれる@季節の変わり目に生じると言われる@じゃきをはらうため、@邪気を払うため、@おにはそと、ふくはうちでおなじみのまめをまく、@「鬼は外、福は内」でおなじみの豆をまく、@としのかずだけまめをたべる、@歳の数だけ豆を食べる、@ひいらぎのえだにいわしのあたまをさしてかざる、@柊の枝に鰯の頭を刺して飾る、@とくていのほうがくをむいてえほうまきをたべる、@特定の方角を向いて恵方巻を食べる、@などのふうしゅうがかずおおい。@などの風習が数多い。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 19,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ばれんたいんでーのきげん@バレンタインデーの起源@ばれんたいんでーのれきしは、@バレンタインデーの歴史は、@こだいろーまていこくのじだいに@古代ローマ帝国の時代に@さかのぼるというせつがいっぱんてき。@さかのぼるという説が一般的。@とうじは2がつ14にちが、@当時は2月14日が、@すべてのかみがみのじょおうであり、@すべての神々の女王であり、@かていとけっこんをつかさどる@家庭と結婚を司る@めがみゆーのーのしゅくじつだった。@女神ユーノーの祝日だった。@そのよくじつからはじまる、@その翌日から始まる、@ほうさくをきがんするるぺるかーりあさいで、@豊作を祈願するルペルカーリア祭で、@ぜんじつにじょせいたちがじぶんのなまえをかいたかみを、@前日に女性たちが自分の名前を書いた紙を、@だんせいはおけからいちまいひき、@男性は桶から一枚引き、@そのふたりはまつりのあいだぱーとなーとなった。@その二人は祭の間パートナーとなった。@とうじわかいだんじょは@当時若い男女は@せいかつがべつべつだったため、@生活が別々だったため、@おおくのぱーとなーはそのままこいなかとなりけっこんした。@多くのパートナーはそのまま恋仲となり結婚した。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 20,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ばれんたいんでーのゆらい@バレンタインデーの由来@さんせいきのろーまていこくこうてい@三世紀のローマ帝国皇帝@くらうでぃうすにせいは、@クラウディウス二世は、@あいするものをこきょうにのこすと@愛する者を故郷に残すと@しきがさがるというりゆうで、@士気が下がるという理由で、@へいしたちのこんいんをきんししたといわれる。@兵士たちの婚姻を禁止したといわれる。@これにはんぱつした@これに反発した@きりすときょうのしさいうぁれんてぃぬす、@キリスト教の司祭ウァレンティヌス、@えいごめいでばれんたいんは、@英語名でバレンタインは、@へいしのためひそかにけっこんしきをおこなっていたが、@兵士のため密かに結婚式を行なっていたが、@やがてこうていにしられ、@やがて皇帝に知られ、@さいしゅうてきにしょけいされた。@最終的に処刑された。@しょけいびに2がつ14にちがあえてえらばれ、@処刑日に2月14日があえて選ばれ、@いけにえとされたため、@生贄とされたため、@きりすときょうとにとってもこのひは@キリスト教徒にとってもこの日は@こいびとたちのひとなったというせつがいっぱんろん。@恋人たちの日となったという説が一般論。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 21,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "にほんにおけるばれんたいんでーのれきし@日本におけるバレンタインデーの歴史@にほんしゃかいにばれんたいんでーがていちゃくしたのは、@日本社会にバレンタインデーが定着したのは、@1970ねんだいこうはんごろのこと。@1970年代後半ごろのこと。@まいとし2がつにうりあげがおちることを@毎年2月に売上が落ちることを@かだいとしていたいくつかのせいかがいしゃが、@課題としていたいくつかの製菓会社が、@じょせいがだんせいにたいして、@「女性が男性に対して、@しんあいのきもちをこめてちょこれーとをおくる@親愛の気持ちを込めてチョコレートを送る」@というきかくをはつあんした。@という企画を発案した。@げんざいではともだちにたいしておくるぎりちょこや、@現在では友達に対して送る義理チョコや、@どうせいかんのともちょこ、@同性間の友チョコ、@じぶんでたべるじこちょこなど、@自分で食べる自己チョコなど、@そのようしきはたようかした。@その様式は多様化した。@ちょこれーとのねんかんしょうひりょうの2わりていどが、@チョコレートの年間消費量の2割程度が、@2がつ14にちにしゅうちゅうしているといわれる。@2月14日に集中していると言われる。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 22,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ひなまつり@「雛祭り」@じょしのすこやかなせいちょうをいのる、@女子の健やかな成長を祈る、@せっくのねんちゅうぎょうじ。@節句の年中行事。@にほんどくじのものである。@日本独自のものである。@ひづけは3がつみっかがつうれいだが、@日付は3月3日が通例だが、@せきせつのおおいかんれいちのいちぶなどでは@積雪の多い寒冷地の一部などでは@じきがずれるばあいもある。@時期がずれる場合もある。@えどじだいまでは、きゅうれきの3がつみっか、@江戸時代までは、旧暦の3月3日、@すなわちげんざいの4がつごろにおこなわれていた。@すなわち現在の4月ごろに行なわれていた。@このころはもものはながさくじきであるため、@このころは桃の花が咲く時期であるため、@もものせっくとよばれることがおおい。@「桃の節句」と呼ばれることが多い。@ひなにんぎょうをかざり、@雛人形を飾り、@そこにもものはなやひなあられ、@そこに桃の花や雛あられ、@ひしもちなどをおそなえして、@菱餅などをお供えして、@あまざけやちらしずしなどの@甘酒やちらし寿司などの@いんしょくをたのしむまつりである。@飲食を楽しむ祭りである。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 23,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ひなまつりのれきし@「雛祭りの歴史」@ひなまつりがいつごろからはじまったのかは@「雛祭り」がいつごろから始まったのかは@れきしてきにはっきりしておらず、@歴史的にはっきりしておらず、@きげんはしょせつある。@起源は諸説ある。@へいあんじだいのきょうとですでに、@平安時代の京都ですでに、@きぞくのじょしのみやびなあそびとして、@貴族の女子の雅な遊びとして、@おこなわれていたというきろくもある。@行なわれていたという記録もある。@とうじはにゅうようじのしぼうりつが@当時は乳幼児の死亡率が@げんだいにくらべてひじょうにたかく、@現代に比べて非常に高く、@せいちょうとそくさいをいのるおやは@成長と息災を祈る親は@このまくらもとににんぎょうなどのかたしろをおき、やくよけとした。@子の枕元に人形などの形代を置き、厄除けとした。@かみでつくったものをふくむ@紙で作ったものを含む@それらのにんぎょうをかわへながすながしびなというぎょうじで、@それらの人形を川へ流す「流し雛」という行事で、@いちねんのわざわいをはらうのが@一年の災いを祓うのが@ひなまつりのもとになったとされている。@雛祭りのもとになったとされている。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 24,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ひなにんぎょうについて@「雛人形について」@ひなにんぎょうはかみやれいがこうりんするための、@「雛人形」は神や霊が降臨するための、@にんげんのかたちをもしたよりしろで、@人間の形を模した依り代で、@むすめのかわりにびょうきやけがれなどの@娘の代わりに病気や穢れなどの@さいやくをうけとめてくれる、@災厄を受け止めてくれる、@みがわりのそんざいでもある。@身代わりの存在でもある。@えどじだいにはいってせいさんぎじゅつがはってんし、@江戸時代に入って製作技術が発展し、@さまざまなかたちのにんぎょうがつくられるようになった。@様々な形の人形が作られるようになった。@おおくはわらせいのどだいにいしょうをきせつけて、@多くは藁生の土台に衣装を着せ付けて、@そこにべっこにつくられたとうぶを@そこに別個に作られた頭部を@がったいさせてかんせいさせる。@合体させて完成させる。@ふるくからよめいりどうぐのひとつであり、@古くから嫁入り道具の一つでもあり、@ちいきによってにんぎょうのかざりかたやかたちがことなる。@地域によって人形の飾り方や形が異なる。@とくにかんとうとかんさいではちがいがけんちょ。@特に関東と関西では違いが顕著。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 25,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ひなにんぎょうのしゅるい@「雛人形の種類」@ひなだんはしゅるいがおおく@雛壇は種類が多く@ななだんやそれいじょうのものもあるが、@七段やそれ以上のものもあるが、@ここではごだんかざりのばあいをしょうかいする。@ここでは五段飾りの場合を紹介する。@いちだんめにおかれるのがおびなとめびなで、@一段目に置かれるのが「男雛」と「女雛」で、@てんのうとこうごうをもしておりいっついでだいりびなという。@天皇と皇后を模しており一対で「内裏雛」と言う。@にだんめにきゅうちゅうにつかえるにょかんをもしたかんじょ、@二段目に宮中に仕える女官を模した「官女」、@さんだんめにのうをかなでるがくしのごにんばやし、@三段目に能を奏でる楽士の「五人囃子」、@よんだんめにぶかんのすがたをし@四段目に武官の姿をし@つうしょううだいじんとさだいじんとよばれるずいしん、@通称右大臣と左大臣と呼ばれる「随身」、@ごだんめにじゅうしゃやざつよう、@五段目に従者や雑用、@かんさいではそうじのやくめをあらわすしちょうを、@関西では掃除の役目をあらわす「仕丁」を、@それぞれじゅんにはいちする。@それぞれ順に配置する。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 26,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ねこのひ@「猫の日」@ねこといっしょにくらせるしあわせにかんしゃし、@「猫と一緒に暮らせる幸せに感謝し、@ねことともにこのよろこびをかみしめるきねんびを@猫とともにこの喜びをかみしめる記念日を」@というしゅしで1987ねんに、@という趣旨で1987年に、@ねこのひじっこういいんかいによってせいていされた、@猫の日実行委員会によって制定された、@にほんどくじのきねんび。@日本独自の記念日。@ねこのなきごえであるにゃー、またはにゃんと@猫の鳴き声である「にゃー」、または「にゃん」と@にほんごの2のごろあわせにちなんで、@日本語の「2」の語呂合わせにちなんで、@こうぼによって2がつ22にちがえらばれた。@公募によって2月22日が選ばれた。@ねこのひはせかいかっこくでひづけがことなり、@猫の日は世界各国で日付が異なり、@あめりかがっしゅうこくでは10がつ29にち、@アメリカ合衆国では10月29日、@ロシアでは3がつついたち、@ロシアでは3月1日、@よーろっぱのおおくのくにでは2がつ17にちである。@ヨーロッパの多くの国では2月17日である。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 27,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ほわいとでー@「ホワイトデー」@ばれんたいんでーにちょこれーとなどをもらっただんせいが、@バレンタインデーにチョコレートなどをもらった男性が、@そのおれいのおかえしとして、@そのお礼のお返しとして、@ましゅまろやきゃんでぃ、くっきー、@マシュマロやキャンディ、クッキー、@ほわいとちょこれーとなどのおかしるいを@ホワイトチョコレートなどのお菓子類を@じょせいにおくるひ。@女性に贈る日。@ひづけは3がつ14にちである。@日付は3月14日である。@にほんがはっしょうのしゅうかんであり、@日本が発祥の習慣であり、@おうべいしょこくなどではほわいとでーはそんざいしない。@欧米諸国などではホワイトデーは存在しない。@げんざいはにほんのえいきょうで、@現在は日本の影響で、@ちゅうごくやたいわん、かんこくなど@中国や台湾、韓国など@ひがしあじあのいちぶのくににもひろまっている。@東アジアの一部の国にも広まっている。@きんねんではしょくひんいがいの、@近年では食品以外の、@ねっくれすやけしょうひんなどのぷれぜんともにんきがある。@ネックレスや化粧品などのプレゼントも人気がある。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 28,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ほわいとでーのれきし@「ホワイトデーの歴史」@ほわいとでーのきげんにはしょせつある。@ホワイトデーの起源には諸説ある。@おくるおかしのしゅるいがまちまちなのは、@贈るお菓子の種類がまちまちなのは、@ぷれぜんととされたものが@プレゼントとされた物が@せいかがいしゃによってちがうなごり。@製菓会社によって違う名残り。@おかえしがましゅまろだったいちじきは@お返しがマシュマロだった一時期は@ましゅまろでーとよばれていた。@「マシュマロデー」と呼ばれていた。@ぜんこくあめがしこうぎょうきょうどうくみあいが、@全国飴菓子工業協同組合が、@おくりものにはおかえしをする@「贈り物にはお返しをする」@というにほんのしゅうかんにちゃくもくし、@という日本の習慣に着目し、@1980ねんにおおてでぱーとや@1980年に大手デパートや@こうこくぎょうかいのきょうりょくをえて、@広告業界の協力を得て、@きゃんぺーんがすたーとした。@キャンペーンがスタートした。@にほんであめがはじめてせいぞうされたのも@日本で飴が初めて製造されたのも、@3がつ14にちぜんごとされている。@3月14日前後とされている。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 29,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "えいぷりるふーる@「エイプリルフール」@まいとし4がつついたちには、@毎年4月1日には、@つみやあくいのないうそをついてもよいという、@罪や悪意のない嘘をついても良いという、@たいしょうじだいにおうべいからつたわった@大正時代に欧米から伝わった@とされるふうしゅうのこと。@とされる風習のこと。@にほんごにちょくやくするとしがつばか、@日本語に直訳すると「四月馬鹿」、@またばんぐせつともよばれる。@また「万愚節」とも呼ばれる。@ちゅうごくごではぐじんせつという。@中国語では「愚人節」という。@このひには、しんぶんや@この日には、新聞や@ほうどうばんぐみなどのかくしゅめでぃあが、@報道番組などの各種メディアが、@せかいかっこくできょぎのきじや@世界各国で虚偽の記事や@にゅーすをながしたり、@ニュースを流したり、@いんたーねっとがふきゅうしたさっこんでは、@インターネットが普及した昨今では、@とうじつげんていのじょーくさいとや@当日限定のジョークサイトや@こんてんつがよういされるなど、@コンテンツが用意されるなど、@だいきぼなうそがとびかうのがかんれい。@大規模な嘘が飛び交うのが慣例。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 30,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "えいぷりるふーるのきげん@「エイプリルフールの起源」@ゆうりょくなせつがふくすうあるが、@有力な説が複数あるが、@どれもかくしょうがなく@どれも確証がなく@かせつのいきをでていない。@仮説の域を出ていない。@むかしよーろっぱでは@昔ヨーロッパでは@しんねんを3がつ25にちとしていたが、@新年を3月25日としていたが、@1564ねんにふらんすのしゃるる9せいが@1564年にフランスのシャルル9世が、@1がつついたちをしんねんをするこよみをさいようしたため、@1月1日を新年とする暦を採用したため、@4がつついたちをうそのしんねんとし、@4月1日を「嘘の新年」とし、@ばかさわぎをするようになったのがはじまりとされる。@馬鹿騒ぎをするようになったのがはじまりとされる。@またいんどでは、@またインドでは、@さとりのしゅぎょうが3がつすえまでおこなわれていたが、@悟りの修行が3月末まで行なわれていたが、@すぐまよいがしょうじるため、@すぐ迷いが生じるため、@4がつついたちをやゆせつとよび@4月1日を「揶揄節」と呼び@からかったとされるせつなどもある。@からかったとされる説などもある。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 31,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ははのひ@「母の日」@ひごろのははのくろうをねぎらい、@日頃の母の苦労をねぎらい、@ははおやへのかんしゃをしめすひのこと。@母親への感謝を表す日のこと。@にほんでは1931ねんに、@日本では1931年に、@だいにほんれんごうふじんかいがけっせいされ、@大日本連合婦人会が結成され、@こうごうのたんじょうびである@皇后の誕生日である@3がつむいかをははのひとしたが、@3月6日を母の日としたが、@これはていちゃくしなかった。@これは定着しなかった。@せんごの1949ねんごろから、@戦後の1949年ごろから、@あめりかにならって5がつのだい2にちようびを、@アメリカに倣って5月の第2日曜日を、@ははのひとするようになった。@「母の日」とするようになった。@かーねーしょんなどをははにおくるのが@カーネーションなどを母に贈るのが@いっぱんてきである。@一般的である。@せかいじゅうでさまざまなきげんがあるため、@世界中でさまざまな起源があるため、@くにによってははのひは@国によって母の日は@ひづけもことなるばあいがある。@日付も異なる場合がある。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 32,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ははのひのきげん@「母の日」の起源@にほんのははのひはべいこくにならっているため、@日本の「母の日」は米国に倣っているため、@あめりかのきげんをしょうかいする。@アメリカの起源を紹介する。@なんぼくせんそうちゅうに@南北戦争中に@ははのしごとのひとして、@「母の仕事の日」として、@てきみかたをとわずふしょうへいの@敵味方を問わず負傷兵の@えいせいじょうたいをかいぜんするため、@衛生状態を改善するため、@ちいきのじょせいをしゅうけつさせた@地域の女性を集結させた@あん・じゃーびすというじょせいがいた。@アン・ジャービスという女性がいた。@そのむすめであるあんなが、@その娘であるアンナが、@1907ねん5がつ12にちになきははをしのんで、@1907年5月12日に亡き母を偲んで、@ははがにちようがっこうのきょうしをしていた@母が日曜学校の教師をしていた@きょうかいできねんかい@教会で記念会@ついとうぎょうじをもち、@（追悼行事）を持ち、@しろいかーねーしょんをおくった。@白いカーネーションを贈った。@これがあめりかやにほんの@これがアメリカや日本の@ははのひのきげんとされる。@「母の日」の起源とされる。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 33,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "いーすたー@「イースター」@じゅうじかにかけられ@十字架にかけられ@しょけいされたいえすきりすとが、@処刑されたイエス・キリストが、@みっかめにふっかつしたことをきねんする、@三日目に復活したことを記念する、@きりすときょうにおいてもっともじゅうようなまつり。@キリスト教において最も重要な祭。@ふっかつさいともいう。@復活祭とも言う。@たいいんれきによってきめられたひであるうえに、@太陰暦によって決められた日である上に、@しゅうはによってひづけがことなるため、@宗派によって日付が異なるため、@としによって3がつげじゅんから@年によって3月下旬から@5がつじょうじゅんまでまちまち。@5がつじょうじゅんまでまちまち。@にほんでもせんごくじだい、@日本でも戦国時代、@きりしたんによっておこなわれたきろくがあるが、@キリシタンによって行なわれた記録があるが、@はるのしんねんどのじきとかさなる、@春の新年度の時期と重なる、@しゅうきょうてきようそがつよいなどのよういんで、@宗教的要素が強いなどの要因で、@げんだいではいーすたーのいべんとがていちゃくしていない。@現代ではイースターのイベントが定着していない。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 34,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "めーでー@「メーデー」@せかいかっこくで5がつついたちにおこなわれる、@世界各国で5月1日に行なわれる、@ろうどうしゃのさいてん。@労働者の祭典。@ほんらいはごがつさいをいみし、@本来は五月祭を意味し、@なつのおとずれをいわうまつりが@夏の訪れを祝う祭が@よーろっぱのかくちでもよおされていた。@ヨーロッパの各地で催されていた。@これがきんだいにはいって、@これが近代に入って、@とうじのがっしゅうこくかなだしょくのうろうどうくみあいれんめいが、@当時の合衆国カナダ職能労働組合連盟が、@1886ねんにとういつすとらいきを@1886年に統一ストライキを@おこなったのがきげんとなり、@行なったのが起源となり、@ろうどうしゃのひであるげんざいのめーでーとなった。@「労働者の日」である現在の「メーデー」となった。@こくれんなどのこくさいきかんによって@国連などの国際機関によって@さだめられたこくさいでーとなっており、@定められた国際デーとなっており、@せかいで5がつついたちを@世界で5月1日を@しゅくじつとしているくにはかずおおくそんざいする。@祝日としている国は数多く存在する。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 35,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "にほんにおけるめーでーのれきし@「日本におけるメーデーの歴史」@にほんでも20せいきしょとうから、@日本でも20世紀初頭から、@しょうきぼなめーでーはおこなわれていた。@小規模なメーデーは行なわれていた。@せかいきょうこうがはっせいした1920ねん、@世界恐慌が発生した1920年、@だいいっかいのめーでーがとうじのうえのこうえんでおこなわれ、@第一回のメーデーが当時の上野公園で行なわれ、@およそいちまんにんのろうどうしゃたちが、@およそ一万人の労働者たちが、@さいていちんぎんほうのせいていなど@最低賃金法の制定など@ろうどうじょうけんのかいぜんをうったえた。@労働条件の改善を訴えた。@しかし1936ねんにかいげんれいのえいきょうで、@しかし1936年に戒厳令の影響で、@めーでーのかいさいそのものがきんしされ、@メーデーの開催そのものが禁止され、@せんごの1952ねんにも、@戦後の1952年にも、@ししょうしゃがでたちのめーでーじけん@死傷者が出た「血のメーデー事件」@がはっせいするなどのえいきょうで、@が発生するなどの影響で、@こくないではいまのところしゅくじつとはなっていない。@国内では今のところ祝日とはなっていない。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 36,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "げし@「夏至」@きげんぜんのちゅうごくで、@紀元前の中国で、@きせつをしゅんかしゅうとうよりもさらに@季節を春夏秋冬よりもさらに@こまかくくぶんするこよみのようなもの@細かく区分する暦のようなもの@としてこうあんされた、@として考案された、@にじゅうしせっきの10ばんめにあたるめいしょう。@二十四節気の10番目に当たる名称。@にほんのだいぶぶんではつゆのさいちゅうである、@日本の大部分では梅雨の最中である、@6がつ21にちないしは22にちをさす。@6月21日ないしは22日を指す。@ぐれごりおれきのえいきょうで、@グレゴリオ暦の影響で、@とくにうるうどしはひづけがずれこむことがおおい。@特に閏年は日付がずれ込むことが多い。@きたはんきゅうではいちねんじゅうで、@北半球では一年中で、@いちばんひるがながくよるがみじかいひとなる。@一番昼が長く夜が短い日となる。@ひのでがもっともはやく、@日の出が最も早く、@ひのいりがもっともおそいひとこんどうされがちであるが、@日の入りが最も遅い日と混同されがちであるが、@それらはげしのひとはいっちしない。@それらは夏至の日とは一致しない。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 37,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ちちのひ@「父の日」@そのなのとおり、ちちおやにかんしゃをしめすひ。@その名の通り、父親に感謝を表す日。@1909ねんに@1909年に@あめりかのどっどふじんが、@アメリカのドッド夫人が、@おとこでひとつでじぶんをそだててくれたちちをたたえて、@男手一つで自分を育ててくれた父を讃えて、@ちちのたんじょうげつである6がつに、@父の誕生月である6月に、@きょうかいでれいはいをしたのがきっかけといわれている。@教会で礼拝をしたのがきっかけと言われている。@とうじすでにははのひがそんざいしたこともあり、@当時すでに「母の日」が存在したこともあり、@そのごあめりかだいとうりょうによって@その後アメリカ大統領によって@ちちのひのえんぜつがおこなわれ、@父の日の演説が行なわれ、@にんちされるようになった。@認知されるようになった。@1972ねんにせいしきに@1972年に正式に@くにのきねんびにせいていされている。@国の記念日に制定されている。@にほんでも6がつのだい3にちようにがそうとうし、@日本でも6月の第3日曜日が相当し、@しろいばらなどがおくられる。@白いバラなどが贈られる。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 38,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "うみびらき@「海開き」@かくとしにおいて@各年において@かいすいよくじょうをかいせつするひのこと。@海水浴場を開設する日のこと。@うみびらきのしょにちにはかくしゅぎょうじや、@海開きの初日には各種行事や、@かんぬしによるしんじやあんぜんきがんさいを@神主による神事や安全祈願祭を@とりおこなうことがおおく、@執り行なうことが多く、@そのことをしめすことばでもある。@そのことを示す言葉でもある。@にほんのほんしゅうでは@日本の本州では@7がつついたちにおこなわれることがおおかったが、@7月1日に行なわれることが多かったが、@きんねんでは7がつじょうじゅんから、@近年では7月上旬から、@ちゅうじゅんにおこなわれるようになった。@中旬に行なわれるようになった。@おんだんなおきなわけんでは、@温暖な沖縄県では、@3がつや4がつにかけて@3月や4月にかけて@うみびらきをするかいすいよくじょうがおおい。@海開きをする海水浴場が多い。@2020ねんはころなういるすのえいきょうで、@2020年はコロナウイルスの影響で、@うみびらきそのものをだんねんするちいきがぞうかした。@海開きそのものを断念する地域が増加した。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 39,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "たなばた@「七夕」@たなばたはしちせきともよむ、@「七夕」は「しちせき」とも読む、@ふるくからおこなわれている@古くから行なわれている@にほんのおまつりぎょうじのこと。@日本のお祭り行事のこと。@ほしまつりともいう。@「星祭り」ともいう。@いちねんかんのじゅうようなせっくをあらわす、@一年間の重要な節句をあらわす、@ごせっくのひとつにもかぞえられている。@五節句の一つにも数えられている。@こらいにちゅうごくからつたわったぎょうじであり、@古来に中国から伝わった行事であり、@かんこくやたいわん、べとなむなどにも@韓国や台湾、ベトナムなどにも@どうようのしゅうかんがある。@同様の習慣がある。@まいとし7がつなのかのよる（ごぜん1じごろ）に、@毎年7月7日の夜（午前1時ごろ）に、@ねがいごとをかいたいろとりどりのたんざくやかざりを、@願いごとを書いた色とりどりの短冊や飾りを、@ささのはにつるしてほしにいのる。@笹の葉に吊るして星に祈る。@せんだいたなばたまつりなど、@仙台七夕まつりなど、@つきおくれでおこなわれるばあいもおおい。@月遅れで行なわれる場合も多い。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 40,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ちゅうごくにおけるたなばたのれきし@「中国における七夕の歴史」@しょくじょとけんぎゅう、@織女と牽牛、@にほんごでおりひめとひこぼしのでんせつは、@日本語で織姫と彦星の伝説は、@きげんぜんのちゅうごくのぶんけんが@紀元前の中国の文献が@しょしゅつとされているが、@初出とされているが、@そこでは7がつ7にちとのかかわりはあきらかではない。@そこでは7月7日との関わりは明らかではない。@きげんごのなんぼくちょうじだいの@紀元後の南北朝時代の@けいそさいじきには、@『荊楚歳時記』には、@7がつ7にちのよるに@7月7日の夜に@ふたりがかいごうするひであるとめいきされており、@二人が会合する日であると明記されており、@さらにふじんたちがななほんのはりのあなに、@さらに夫人たちが七本の針の穴に、@うつくしいいろどりのいとをとおし、@美しい彩りの糸を通し、@ささげものをにわにならべて@捧げものを庭に並べて@はりしごとのじょうたつをいのったという@針仕事の上達を祈ったという@きこうでんというぎょうじと@「乞巧奠」という行事と@かんれんづけられていることが、はっきりとわかる。@関連付けられていることが、はっきりと分かる。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 41,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "おりひめとひこぼしがいちねんにいちどしかあえないりゆう@「織姫と彦星が一年に一度しか会えない理由」@おりひめとひこぼしは、@織姫と彦星は、@それぞれべがとあるたいるといういっとうせいを、@それぞれベガとアルタイルという一等星を、@こだいのちゅうごくじんがわかいだんじょにみたてて、@古代の中国人が若い男女に見立てて、@ふたりにまつわるでんせつをかたっていた。@二人にまつわる伝説を語っていた。@しょくじょとけんぎゅうのものがたりがそれで、@「織女」と「牽牛」の物語がそれで、@やすむまもなくはたおりのしごとに、@休む間もなく機織りの仕事に、@しょくじょがせいをだしているのをみたてんていが、@織女が精を出しているのを見た天帝が、@うしかいのけんぎゅうとふうふにさせた。@牛飼いの牽牛と夫婦にさせた。@ところがけっこんご、@ところが結婚後、@しょくじょがまったくはたおりをかえりみなくなったため、@織女がまったく機織りを顧みなくなったため、@てんていがばつとしてふたりをはなればなれにし、@天帝が罰として二人を離れ離れにし、@7がつなのかにしかあえないようにした@7月7日にしか会えないようにした@というでんせつがもとになっている。@という伝説が元になっている。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 42,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "にほんにおけるたなばたのれきし@「日本における七夕の歴史」@にほんにもこらいから、@日本にも古来から、@たなばたとよばれるふるいみそぎのぎょうじがあった。@「棚機」と呼ばれる古い禊の行事があった。@おとめがかわなどのきよいみずべにあるはたやにこもって、@乙女が川などの清い水辺にある機屋にこもって、@かみさまのためにこころをこめてきものをおりたなにそなえ、@神様のために心をこめて着物を織り棚に供え、@あきのほうさくをいのり@秋の豊作を祈り@ひとびとのけがれをはらうというものである。@人々の穢れを祓うというものである。@このときのおりきのことをたなばた、@このときの織り機のことを「棚機」、@えらばれたおとめのことをたなばたつめと@選ばれた乙女のことを「棚機津女」と@それぞれよんだ。@それぞれ呼んだ。@これにちゅうごくからつたわったきこうでん、@これに中国から伝わった「乞巧奠」、@およびおりひめとひこぼしのでんせつがくわわり、@及び織姫と彦星の伝説が加わり、@げんざいたなばたとよばれるぎょうじの@現在「七夕」と呼ばれる行事の@ゆらいになったとかんがえられる。@由来になったと考えられる。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 43,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "どようのうしのひ@「土用の丑の日」@どようとはこだいちゅうごくのごぎょうせつにゆらいする、@土用とは古代中国の五行説に由来する、@きせつのうつりかわりをよりてきかくに@季節の移り変りをより適確に@つかむためにもうけられた、@掴むために設けられた、@とくべつなれきじつであるざっせつのこと。@特別な暦日である雑節のこと。@いちねんのうちしゅんかしゅうとうに@一年のうち春夏秋冬に@それぞれそんざいするが、@それぞれ存在するが、@げんざいではいっぱんに@現在では一般に@なつのどようのうしのひのことを、@夏の土用の丑の日のことを、@たんにしめすばあいがほとんど。@単に示す場合がほとんど。@どようはやく18にちかんつづき、@土用は約18日間続き、@そのうちじゅうにしがうしのひをしめすため、@そのうち十二支が丑の日を示すため、@としごとにひづけや、@年ごとに日付や、@1にちか2にちあるかもことなる。@1日か2日あるかも異なる。@たとえば2020ねんは、@例えば2020年は、@7がつ21にちと8がつ2にちがそうとうしたが、@7月21日と8月2日が相当したが、@2021ねんは7がつ28にちだけ。@2021ねんは7がつ28にちだけ。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 44,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "どようのうしのひのうなぎ@「土用の丑の日の鰻」@あついじきをのりきるために、@「暑い時期を乗り切るために、@えいようかのたかいうなぎをたべる@栄養価の高い鰻を食べる」@というにほんのしゅうかんは、@という日本の習慣は、@まんようしゅうにもよまれているように@万葉集にも詠まれているように@こだいにたんをはっするとされるが、@古代に端を発するとされるが、@どようのうしのひにたべるようになったのは、@土用の丑の日に食べるようになったのは、@えどじだいこうきごろからのふうしゅうといわれている。@江戸時代後期ごろからの風習と言われている。@ゆらいはしょせつあり、@由来は諸説あり、@ひらがげんないがはつあんしたというせつが@平賀源内が発案したという説が@もっともよくしられているが、@最もよく知られているが、@しゅってんはふめい。@出典は不明。@じっさいにうなぎはなつばてぼうしなどのこうかがきたいできるが、@実際に鰻は夏バテ防止などの効果が期待できるが、@えいようかのたかいしょくひんがおおいげんだいにおいて、@栄養価の高い食品が多い現代において、@いがくてきこんきょはとぼしいとされる。@医学的根拠は乏しいとされる。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 45,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "げんばくのひ@「原爆の日」@だいにじせかいたいせんがしゅうけつするちょくぜんの、@第二次世界大戦が終結する直前の、@1945ねん8がつむいかにひろしましにたいして、@1945年8月6日に広島市に対して、@あめりかはせかいはつのげんしばくだんである、@アメリカは世界初の原子爆弾である、@りとるぼーいをとうかした。@リトルボーイを投下した。@つづいて8がつここのかに、@続いて8月9日に、@こんどはながさきしにたいしてふぁっとまんをとうかした。@今度は長崎市に対してファットマンを投下した。@りょうしはかいめつてきひがいをうけ、@両市は壊滅的被害を受け、@おおぜいのししょうしゃがでたにほんは、@大勢の死傷者が出た日本は、@かくへいきにたいするゆいいつのひばくこくとよばれている。@核兵器に対する唯一の被爆国と呼ばれている。@げんざいでもこのりょうじつは、@現在でもこの両日は、@それぞれひろしまげんばくき@それぞれ「広島原爆忌」@ながさきげんばくきといい、@「長崎原爆忌」と言い、@いれいしきやへいわきねんしき、@慰霊式や平和祈念式、@ならびにもくとうなどがまいとしおこなわれている。@並びに黙祷などが毎年行なわれている。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 46,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ぼうさいのひ@「防災の日」@ひづけは9がつついたちであり、@日付は9月1日であり、@これはかんとうだいしんさいがはっせいしたひにちなみ、@これは関東大震災が発生した日にちなみ、@1960ねんにせいていされたもの。@1960年に制定されたもの。@ぼうさいのひは、@防災の日は、@せいふ、ちほうこうきょうだんたいなど@「政府、地方公共団体等@かんけいしょきかんをはじめ、@関係諸機関をはじめ、@ひろくこくみんがたいふう、たかしお、つなみ、じしんなどの@広く国民が台風、高潮、津波、地震等の@さいがいについてのにんしきをふかめ、@災害についての認識を深め、@これにたいしょするこころがまえをじゅんびする@これに対処する心構えを準備する」@こととしてせいていされたけいはつでー。@こととして制定された啓発デー。@まいとしこのひをちゅうしんに@毎年この日を中心に@ぼうさいしそうのふきゅう、こうろうしゃのひょうしょう、@「防災思想の普及、功労者の表彰、@ぼうさいくんれんなどこれにふさわしいぎょうじ@防災訓練等これにふさわしい行事」@がじっしされる。@が実施される。@ぼうさいのひをふくむいっしゅうかんが@防災の日を含む一週間が@ぼうさいしゅうかんとなる。@防災週間となる。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 47,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "しゅうせんのひ@「終戦の日」@だいにじせかいたいせんがしゅうけつ、@第二次世界大戦が終結、@すなわちしゅうせんしたひのこしょうのこと。@すなわち終戦した日の呼称のこと。@にほんではいっぱんてきに、@日本では一般的に、@しゅうせんきねんびはぎょくおんほうそうがしょうごにながれ、@終戦記念日は玉音放送が正午に流れ、@せんそうこういがていしされた@戦争行為が停止された@8がつ15にちとにんしきされている。@8月15日と認識されている。@ほうりつでもどうじつをしゅうせんびとよび、@法律でも同日を終戦日と呼び、@せんぼつしゃをついとうしへいわをきねんするひとされている。@「戦没者を追悼し平和を祈念する日」とされている。@いっぽうであめりかがっしゅうこくなどおおくのくにでは、@一方でアメリカ合衆国など多くの国では、@たいせんはにほんがぽつだむせんげんのりこうなどをさだめた@大戦は日本がポツダム宣言の履行などを定めた@こうふくぶんしょ、きゅうせんきょうていにちょういんした、@降伏文書、休戦協定に調印した、@1945ねん9がつ2にちあるいは3にちに@1945年9月2日あるいは3日に@しゅうけつしたとにんしきされている。@終結したと認識されている。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 48,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "しゅうせんにいたるかてい@「終戦に至る過程」@ひろしまにつづきながさきにも@広島に続き長崎にも@げんばくがとうかされるというじたいをうけ、@原爆が投下されるという事態を受け、@8がつとおかのごぜん0じごろから@8月10日の午前0時ごろから@ごぜんかいぎがおこなわれた。@御前会議が行なわれた。@とうしょはぽつだむせんげんを@当初はポツダム宣言を@じゅだくするべきとしゅちょうするがわと、@受諾するべきと主張する側と、@じゅだくにたすうのじょうけんをつけ、@受諾に多数の条件をつけ、@それがきょひされたらほんどけっせんもじさず@それが拒否されたら本土決戦も辞さず@としゅちょうするがわにわかれた。@と主張する側に分かれた。@だがこのじてんで、@だがこの時点で、@にほんのどうめいこくであったどいつはむじょうけんこうふくし、@日本の同盟国であったドイツは無条件降伏し、@れんごうぐんやそれんが@連合軍やソ連が@ほんどにせまっているじたいをうけ、@本土に迫っている事態を受け、@てんのうがわへいをのぞんでいることをくちにしたため、@天皇が和平を望んでいることを口にしたため、@ぎろんはこうふくへとしゅうそくした。@議論は降伏へと収束した。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 49,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "しゅうせんにむけたうごき@「終戦に向けた動き」@8がつ10にちのごぜん3じからおこなわれたかくぎで、@8月10日の午前3時から行なわれた閣議で、@こうふくはせいしきにしょうにんされた。@降伏は正式に承認された。@にほんせいふは、ぽつだむせんげんじゅだくにより@日本政府は、ポツダム宣言受諾により@ぜんにほんぐんがこうふくをけっていするじじつを、@全日本軍が降伏を決定する事実を、@かいがいむけのらじおのこくえいほうそうをつうじ、@海外向けのラジオの国営放送を通じ、@にほんごとえいごでさんかいせかいへほうそうした。@日本語と英語で三回世界へ放送した。@またどうめいつうしんしゃから、@また同盟通信社から、@もーるすつうしんでこうせんこくにちょくせつつうちがおこなわれた。@モールス通信で交戦国に直接通知が行われた。@これにたいするへんとう、@これに対する返答、@いわゆるばーんずかいとうにたいする@いわゆる「バーンズ回答」に対する@えいたんごのかいしゃくなどでもんちゃくがあったものの、@英単語の解釈などで悶着があったものの、@14かしんやには@14日深夜には@てんのうによるぎょくおんほうそうがろくおんされた。@天皇による玉音放送が録音された。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 50,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ぎょくおんほうそう@「玉音放送」@ぜんぶんはひじょうにながいためばっすいする。@全文は非常に長いため抜粋する。@おもうにこんご、ていこくのうくべきくなんは@「思うに今後、帝国の受くべき苦難は@もとよりじんじょうにあらず。@もとより尋常にあらず。@なんじしんみんのちゅうじょうも@汝臣民の衷情も@ちんよくこれをしる。@朕よく是れを知る。@しかれどもちんはじうんのおもむくところ、@然れども朕は時運の赴く所、@たえがたきをたえ、しのびがたきをしのび、@堪え難きを堪え、忍び難きを忍び、@もってばんせいのために@もって万世の為に@たいへいをひらかんとほっす。@太平を開かんと欲す。@ちんはここにこくたいをごじしえて、@朕はここに国体を護持し得て、@ちゅうりょうなるなんじしんみんの@忠良なる汝臣民の@せきせいにしんいし、@赤誠に信倚し、@つねになんじしんみんとともにあり@常に汝臣民と共に在り」@たえがたきをたえ、しのびがたきをしのび@堪え難きを堪え、忍び難きを忍び@のぶぶんがせんじちゅうのこんくと@の部分が戦時中の困苦と@せんりょうされることへのふあんをかんきさせ、@占領されることへの不安を喚起させ、@いんしょうづけられてゆうめい。@印象づけられて有名。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 51,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "おぼん@「お盆」@にほんでかきにおこなわれる、@日本で夏季に行なわれる、@そせんのれいをまつるいちれんのぎょうじのこと。@祖先の霊を祀る一連の行事のこと。@すでになくなったそせんが、@既に亡くなった祖先が、@いきているもののせいかつに@生きている者の生活に@えいきょうをあたえている、@影響を与えている、@あるいはあたえることができる@あるいは与えることができる@というかんがえにもとづいた、@という考えに基づいた、@にほんこらいのそれいしんこうと、@日本古来の祖霊信仰と、@ぶっきょうがゆうごうしたぎょうじである。@仏教が融合した行事である。@かつては7月15日ごろに行なわれていたが、@かつては7月15日ごろに行なわれていたが、@めいじきのたいようれき、@明治期の太陽暦、@すなわちしんれきのさいようごは、@すなわち新暦の採用後は、@そのじきがのうはんきとかさなって@その時期が農繁期と重なって@ししょうがでるばあいがおおかったため、@支障が出る場合が多かったため、@しんれきの8がつ15にちをおぼんとするちいきがおおくなった。@新暦の8月15日をお盆とする地域が多くなった。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 52,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "おぼんのゆらい@「お盆の由来」@ぶっきょうようごのうらぼんえ@仏教用語の「盂蘭盆会」@のしょうりゃくけいとしてぼん、@の省略形として「盆」、@いっぱんにおぼんとよばれるようになった。@一般に「お盆」と呼ばれるようになった。@ほんらいはもじどおり、@本来は文字通り、@れいにたいするくもつをおくようきをいみする。@霊に対する供物を置く容器を意味する。@てんじてくもつをそなえまつられる@転じて供物を供え祀られる@せいれいのこしょうとなり、@精霊の呼称となり、@うらぼんとこんどうされてしゅうごうしたというせつもある。@盂蘭盆と混同されて習合したという説もある。@おぼんのめいかくなきげんはふめいだが、@お盆の明確な起源は不明だが、@こらいからしょしゅんとしょしゅうのまんげつのひに、@古来から初春と初秋の満月の日に、@そせんのれいがしそんのもとをおとずれて、@祖先の霊が子孫のもとを訪れて、@こうりゅうするぎょうじがあった。@交流する行事があった。@にほんでははちせいきごろに、@日本では八世紀頃に、@なつにそせんくようをおこなうふうしゅうが@夏に祖先供養を行う風習が@かくりつされたといわれている。@確立されたと言われている。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 53,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "おぼんにおこなわれるかくしゅぎょうじ@「お盆に行なわれる各種行事」@おぼんのがいねんはにほんぜんこくにひろまっているため、@お盆の概念は日本全国に広まっているため、@そのぎょうじのないようやふうしゅうはさまざまなようしきがある。@その行事の内容や風習は様々な様式がある。@13にちのゆうこくにのびをたき、@13日の夕刻に野火をたき、@せんぞのれいをむかえいれるぎょうじをむかえびという。@先祖の霊を迎え入れる行事を「迎え火」という。@15にちないしは16にちにのびをたき、@15日ないしは16日に野火をたき、@ししゃのたましいをふたたびあのよへとおくりだす、@死者の魂を再びあの世へと送り出す、@きょうとのだいもんじやきでゆうめいなおくりび、@京都の大文字焼きで有名な「送り火」、@じしゃのけいだいなどにろうにゃくなんにょがあつまっておどる@寺社の境内などに老若男女が集まって踊る@ぼんおどりなどがゆうめい。@「盆踊り」などが有名。@れいこんがこのよとあのよをいききするのりものとして、@霊魂がこの世とあの世を行き来する乗り物として、@きゅうりやなすでしょうりょううまがつくられる。@きゅうりや茄子で「精霊馬」が作られる。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 54,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "おぼんやすみ@「お盆休み」@おぼんやすみはえどじだいには@お盆休みは江戸時代には@すでにていちゃくしていたやすみであったが、@既に定着していた休みであったが、@めいじしょきいこうは@明治初期以降は@れんきゅうからおぼんがじょがいされた。@連休からお盆が除外された。@そのごもげんざいにいたるまで、@その後も現在に至るまで、@おぼんはくにがさだめたきゅうじつとはなっていない。@お盆は国が定めた休日とはなっていない。@いっぽう、みんかんではしんれきの@一方、民間では新暦の@8がつ15にちぜんごはへいじつであっても@8月15日前後は平日であっても@きゅうぎょうするかいしゃや、@休業する会社や、@きゅうかをしゅとくするものがめだつ。@休暇を取得する者が目立つ。@がっこうのなつやすみとかさなるかたちで、@学校の夏休みと重なる形で、@いちぶのきぎょうもぼんやすみとなるばあいがある。@一部の企業も盆休みとなる場合がある。@このじきはじもとにかえるものもおおいため、@この時期は地元に帰る者も多いため、@きせいらっしゅとよばれる@「帰省ラッシュ」と呼ばれる@こうきょうこうつうきかんなどのこんざつもはっせいする。@公共交通機関などの混雑も発生する。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 55,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ちょうようのせっく@「重陽の節句」@いんようごぎょうせつをゆらいとして、@陰陽五行説を由来として、@にほんにていちゃくしたこよみのうちごせっくのひとつである、@日本に定着した暦のうち五節句の一つである、@9がつここのかのこと。@9月9日のこと。@いんようしそうではきすうはようのかずであり、@陰陽思想では奇数は陽の数であり、@そのきょくである9がかさなるひであるため@その極である9が重なる日であるため@ちょうようとよばれる。@「重陽」と呼ばれる。@きすうのすうじがかさなるつきひは、@奇数の数字が重なる月日は、@ようのきがつよすぎるためふきつとされ、@陽の気が強すぎるため不吉とされ、@それをはらうぎょうじとしてせっくがおこなわれていた。@それを祓う行事として節句が行なわれていた。@のちにようのかさなりをきちじょうとするかんがえにてんじ、@のちに陽の重なりを吉祥とする考えに転じ、@きくをうかべたさけをのむなどしていわった。@菊を浮かべた酒を飲むなどして祝った。@げんざいはほかのせっくとくらべて、@現在は他の節句と比べて、@いわいごとなどがじっしされていない。@祝い事などが実施されていない。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 56,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ごせっく@「五節句」@こだいちゅうごくのいんようごぎょうせつをゆらいとして@古代中国の陰陽五行説を由来として@にほんにていちゃくしたこよみであり、@日本に定着した暦であり、@でんとうてきなねんちゅうぎょうじをおこなう@伝統的な年中行事を行なう@きせつのふしめとなるひ。@季節の節目となる日。@ななくさがゆをたべるじんじつが@七草粥を食べる「人日」が@1がつなのか、@1月7日、@もものせっくでありひなまつりをおこなう@桃の節句であり雛祭りを行なう@じょうしが3がつみっか、@「上巳」が3月3日、@しょうぶのせっくであり@菖蒲の節句であり@げんざいはこどものひとなっているたんご、@現在はこどもの日となっている「端午」@おりひめとひこぼしのでんせつでゆうめいな@織姫と彦星の伝説で有名な@7がつなのかがしちせき、@7月7日が「七夕」、@9がつここのかの@9月9日の@きくのせっくがちょうようである。@菊の節句が「重陽」である。@こらいはにほんのきゅうていで@古来は日本の宮廷で@せちえとよばれるえんかいがひらかれ、@節会と呼ばれる宴会が開かれ、@えどじだいはごせっくがこうてきなしゅくじつであった。@江戸時代は五節句が公的な祝日であった。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 57,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "はろうぃん@「ハロウィン」@まいとし10がつ31にちにおこなわれる、@毎年10月31日に行われる、@こだいけるとじんがきげんとかんがえられているまつりのこと。@古代ケルト人が起源と考えられている祭のこと。@げんだいではとくにあめりかがっしゅうこくで@現代では特にアメリカ合衆国で@みんかんぎょうじとしてていちゃくし、@民間行事として定着し、@しゅくさいほんらいのしゅうきょうてきないみあいは@祝祭本来の宗教的な意味合いは@ほとんどなくなっている。@ほとんどなくなっている。@かぼちゃのなかみをくりぬいて@カボチャの中身をくりぬいて@じゃっくおーらんたんをつくってかざる、@「ジャック・オー・ランタン」を作って飾る、@こどもたちがまじょやおばけにかそうして@子供たちが魔女やお化けに仮装して@ちかくのいえいえをおとずれ、@近くの家々を訪れ、@おかしをもらうふうしゅうなどがある。@お菓子をもらう風習などがある。@きりすときょうにとっては@キリスト教にとっては@いきょうとのまつりであるため、@異教徒の祭であるため、@きょうかいやしゅうはごとにさまざまなけんかいがある。@教会や宗派ごとに様々な見解がある。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 58,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "こだいけるとにおけるはろうぃん@「古代ケルトにおけるハロウィン」@けるとじんのいちねんのおわりは@ケルト人の一年の終わりは@10がつ31にちで、@10月31日で、@このよるはあきのおわりとふゆのはじまりをいみし、@この夜は秋の終わりと冬の始まりを意味し、@ししゃのれいがかぞくをたずねてくる@死者の霊が家族を訪ねてくる@としんじられていた。@と信じられていた。@またこのじきにあらわれる@またこの時期に現れる@ゆうがいなせいれいやまじょから、@有害な精霊や魔女から、@みをまもるためにかめんをかぶり、@身を守るために仮面を被り、@まよけのひをたいていた。@魔除けの火を焚いていた。@これにちなみ、@これにちなみ、@かぼちゃをくりぬいたなかにろうそくをたてた@カボチャをくりぬいた中に蝋燭を立てた@じゃっくおーらんたんがつくられる。@「ジャック・オー・ランタン」が作られる。@よく11がつついたちは@翌11月1日は@かとりっくきょうかいのしょせいじんのひであり、@カトリック教会の「諸聖人の日」であり、@きょうかいがいきょうのまつりをとりこんだというせつもある。@教会が異教の祭を取り込んだという説もある。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 59,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "はろうぃんのぎょうじ@「ハロウィンの行事」@おれんじいろのかぼちゃをくりぬき、@オレンジ色のカボチャをくり抜き、@ないふなどでめやはな、くちをつけ、@ナイフなどで目や鼻、口をつけ、@うちがわにひのついたろうそくをたて@内側に火のついたロウソクを立て@じゃっくおーらんたんをつくる。@ジャック・オー・ランタンを作る。@これをいえのとぐちにおくのは@これを家の戸口に置くのは@うぃるおーうぃすぷをしょうちょうしたもの。@「ウィルオーウィスプ」を象徴したもの。@ほかにはかそうをするしゅうかんがあるが、@他には仮装をする習慣があるが、@かならずしもはろうぃんにちなんだものではなく、@必ずしもハロウィンにちなんだものではなく、@あめりかではいっぱんてきにおそろしいとされる@アメリカでは一般的に恐ろしいとされる@きゅうけつきやおおかみおとこ、@吸血鬼や狼男、@きんねんではおひめさまやかいぞくといった@近年ではお姫様や海賊といった@るいけいてきなとうじょうじんぶつ、@類型的な登場人物、@あめこみのきゃらくたーにもふんするようになった。@アメコミのキャラクターにも扮するようになった。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 60,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "とりっくおあとりーと@「トリック・オア・トリート」@えいごけんではまじょやゆうれい、@英語圏では魔女や幽霊、@おそろしいがいけんのもんすたーなど@恐ろしい外見のモンスターなど@さまざまなきゃらくたーにかそうして、@様々なキャラクターに仮装して、@こどもたちがちかくのいえをいっけんずつたずねては@子供たちが近くの家を一軒ずつ訪ねては@とりっくおあとりーとととなえるしゅうかんがある。@「トリック・オア・トリート」と唱える習慣がある。@ちょくやくではいたずらかおかしか、@直訳では「悪戯かお菓子か」、@あらかじめおかしをよういしたきくがわとしては@あらかじめお菓子を用意した聞く側としては@おかしをくれないといたずらしちゃうぞというないよう。@「お菓子をくれないと悪戯しちゃうぞ」という内容。@これはそうりんぐという、@これはソウリングという、@かめんのこどもたちがそうるけーきをもらい、@仮面の子供たちがソウルケーキをもらい、@こじんをくようしたという@故人を供養したという@けるとのふうしゅうがるーつというせつもある。@ケルトの風習がルーツという説もある。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 61,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "てつどうのひ@「鉄道の日」@めいじ5ねん9がつ12にち、@明治5年9月12日、@しんれきの1872ねん10がつ14にちに、@新暦の1872年10月14日に、@しんばしえきとよこはまえきとをむすんだ@新橋駅と横浜駅とを結んだ@にほんはつのてつどうがかいぎょうしたことおよび、@日本初の鉄道が開業したこと及び、@1921ねんどうじつに@1921年同日に@てつどうかいぎょう50しゅうねんをきねんして、@鉄道開業50周年を記念して、@とうきょうえきのまるのうちきたぐちに@東京駅の丸の内北口に@しょだいてつどうはくぶつかんをひらいたことをきねんする、@初代鉄道博物館を開いたことを記念する、@てつどうのきねんび。@鉄道の記念日。@とうしょはこくてつや@当初は国鉄や@みんえいかごのJRでのみいわわれていたが、@民営化後のJRでのみ祝われていたが、@1994ねんにとうじのうんゆしょうからていあんがあり、@1994年に当時の運輸省から提案があり、@してつなどをふくめたすべてのてつどうじぎょうしゃが@私鉄などを含めたすべての鉄道事業者が@いわうきねんびとなった。@祝う記念日となった。@きねんじょうしゃけんなどがはっこうされる。@記念乗車券などが発行される。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 62,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "とうじ@「冬至」@にじゅうしせっきのひとつであり、@二十四節気の一つであり、@こだいではとうじをいちねんのはじまりとしていた。@古代では冬至を一年の始まりとしていた。@きたはんきゅうではいちねんのうちでもっともひる、@北半球では一年のうちで最も昼、@すなわちひのでからにちぼつまでのじかんがみじかくなる。@すなわち日の出から日没までの時間が短くなる。@たいようとちきゅうの@太陽と地球の@いちかんけいによってていぎされるため、@位置関係によって定義されるため、@としによってひづけがことなる。@年によって日付が異なる。@たとえば2020ねんは@例えば2020年は@12がつ21にちであったが、@12月21日であったが、@2021ねんは12がつ22にちとなる。@2021年は12月22日となる。@これはにほんのはなしであって、@これは日本の話であって、@じさがあるちゅうごくでは@時差がある中国では@12がつ21にちのままである。@12月21日のままである。@つぎに12がつ21にちがとうじとなるのは、@次に12月21日が冬至となるのは、@2024ねんのよてい。@2024年の予定。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 63,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "しちごさん@「七五三」@7さい、5さい、3さいのこどものせいちょうを@7歳、5歳、3歳の子どもの成長を@いわうにほんのねんじゅうぎょうじのこと。@祝う日本の年中行事のこと。@じんじゃやてらなどでしちごさんもうでをおこない、@神社や寺などで七五三詣でを行ない、@ほうこくやかんしゃ、きがんをおこなうほうこくさいである。@報告や感謝、祈願を行なう奉告祭である。@1681ねんのきゅうれき11がつ15にちに、@1681年の旧暦11月15日に、@えどばくふだいごだいしょうぐんである@江戸幕府第五代将軍である@とくがわつなよしのちょうなん、@徳川綱吉の長男、@とくがわとくまつのけんこうをいのってはじまり、@徳川徳松の健康を祈って始まり、@ぜんこくにつたわったとされるせつがゆうりょく。@全国に伝わったとされる説が有力。@このためしんれきとなっためいじいこうも、@このため新暦となった明治以降も、@しちごさんは11がつ15にちにおこなわれる。@「七五三」は11月15日に行なわれる。@げんざいでは11がつのどにちやしゅくじつなど、@現在では11月の土日や祝日など、@ひにちにこだわらわないばあいもおおい。@日にちにこだわらわない場合も多い。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 64,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "しちごさんのぎょうじ@「七五三の行事」@めいしょうからはそのねんれいにおこなう@名称からはその年齢に行なう@おなじぎょうじととらえられ、@同じ行事と捉えられ、@じっさいそうなりつつあるが、@実際そうなりつつあるが、@げんみつにはそれぞれのねんれいでおこなう、@厳密にはそれぞれの年齢で行なう、@べつべつのことなったぎょうじ。@別々の異なった行事。@かぞえどしの3さい、まんねんれい2さいになるとしを@数え年の3歳、満年齢2歳になる年を@かみおきとし、おもにじょしがおこなう。@「髪置き」とし、主に女児が行なう。@えどじだいまであったかみをそるしゅうかんを、@江戸時代まであった髪を剃る習慣を、@しゅうりょうするぎ。@終了する儀。@かぞえどし5さいになるとしを@数え年5歳になる年を@はかまぎとし、だんしがおこなう。@「袴着」とし、男児が行なう。@もじどおりはかまをちゃくようしはじめるぎ。@文字通り袴を着用し始める儀。@かぞえどし7さいになるとしを@数え年7歳になる年を@おびときとし、じょしがおこなう。@「帯解き」とし、女児が行なう。@おとなとおなじはばのひろいおびをむすびはじめるぎ。@大人と同じ幅の広い帯を結び始める儀。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 65,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "ちとせあめ@「千歳飴」@さいきんではせいべつをとわず、@最近では性別を問わず、@しちごさんをさんかいすべておこなうばあいもおおい。@「七五三」を三回すべて行なう場合も多い。@じぶんのこにちょうじゅのねがいをこめた@自分の子に長寿の願いを込めた@ちとせあめを、おやがあたえたべていわう。@「千歳飴」を、親が与え食べて祝う。@このめいしょうはせんねん、@この名称は千年、@つまりながい、ながいきといういみがあるとともに、@つまり長い、長生きという意味があると共に、@ほそくながくなっている。@細く長くなっている。@えんぎがよいとされる@縁起が良いとされる@こうはくのいろにちゃくしょくされており、@紅白の色に着色されており、@つるかめやしょうちくばいなどの@鶴亀や松竹梅などの@えんぎがよいずあんのえがかれたふくろにいれられている。@縁起が良い図案の描かれた袋に入れられている。@えどじだいのげんろくごろ、@江戸時代の元禄頃、@あさくさのあめうりしちべえがうりだしてりゅうこうした@浅草の飴売り七兵衛が売り出して流行した@せんねんあめからはじまったとされている。@「千年飴」から始まったとされている。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 66,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "くりすます@「クリスマス」@きりすとのみさといういみで、@「キリストのミサ」という意味で、@いちぶのきょうはがおこなう@一部の教派が行なう@いえすきりすとのこうたんさい。@イエス・キリストの降誕祭。@あくまでたんじょうをいわうひであって、@あくまで誕生を祝う日であって、@いえすきりすとのたんじょうびとはことなる。@イエス・キリストの誕生日とは異なる。@まいとし12がつ25にちにいわわれるが、@毎年12月25日に祝われるが、@ゆだやきょうやろーまていこくのこよみ、@ユダヤ教やローマ帝国の暦、@およびこれらをひきついだ、@およびこれらを引き継いだ、@きりすときょうでもちいられるきょうかいれきでは、@キリスト教で用いられる教会暦では、@にちぼつをいちにちのさかいめとしている。@日没を一日の境目としている。@このため、くりすますいぶとよばれる@このため、クリスマス・イブと呼ばれる@12がつ24にちゆうこくから@12月24日夕刻から@12がつ25にちあさまでもふくめ、@12月25日朝までも含め、@くりすますとおなじひにかぞえられる。@クリスマスと同じ日に数えられる。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 67,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "くりすますのきげん@「クリスマスの起源」@こだいろーまのしゅうきょうのひとつ、@古代ローマの宗教の一つ、@みとらきょうでは12がつ25にちは@ミトラ教では12月25日は@ふめつのたいようがうまれるひとされ、@「不滅の太陽が生まれる日」とされ、@たいようしんみとらをいわうとうじのまつりがあったが、@太陽神ミトラを祝う冬至の祭があったが、@これをひきついだろーまのたいようしんのまつりを@これを引き継いだローマの太陽神の祭りを@てんようしたものがくりすますではないか、@転用したものがクリスマスでははないか、@といわれている。@と言われている。@12がつ25にちのせいたんさいは、@12月25日の生誕祭は、@よんせいきにははじまったとされている。@四世紀には始まったとされている。@これとはべつに、@これとは別に、@せいほうきょうかいでは1がつむいかに@西方教会では1月6日に@きりすとのこうげんをいわう。@キリストの公現を祝う。@ごげんはきりすとのみさ@語源は「キリスト（Christ）のミサ（mass）」@をつなげたものである。@をつなげたものである。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 68,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "さんたくろーす@「サンタクロース」@きりすときょうけんにおけるでんせつのじんぶつ。@キリスト教圏における伝説の人物。@いっぱんてきにはあかいいしょうをきたろうじんで、@一般的には赤い衣装を着た老人で、@となかいのそりにのり、@トナカイの橇に乗り、@くりすますいぶのよるに@クリスマスイブの夜に@こどもにぷれぜんとをわたしてまわる。@子供にプレゼントを渡して回る。@もでるはよんせいきごろのひがしろーまていこくのしきょう、@モデルは四世紀ごろの東ローマ帝国の司教、@せいにこらおすではないかといわれている。@聖ニコラオスではないかと言われている。@まずしさのあまり@貧しさの余り@むすめをみうりすることになったいっかのじたくに、@娘を身売りすることになった一家の自宅に、@まよなかにおとずれまどからくつしたにきんかをなげいれてたすけた。@真夜中に訪れ窓から靴下に金貨を投げ入れて助けた。@このいつわがゆらいとなり、@この逸話が由来となり、@えんとつにはいってくつしたにぷれぜんとをいれるという、@煙突に入って靴下にプレゼントを入れるという、@げんだいのでんしょうがうまれた。@現代の伝承が生まれた。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 69,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "せかいのくりすます@「世界のクリスマス」@かとりっくのえいきょうのつよい@カトリックの影響の強い@いたりあやふらんす、すぺいんなどでは、@イタリアやフランス、スペインなどでは、@くりすますは12がつ25にちにはじまり、@クリスマスは12月25日にはじまり、@1がつむいかのこうげんさいにおわる。@1月6日の公現祭に終わる。@いたりあではさんたくろーすのかわりに、@イタリアではサンタクロースの代わりに、@まじょべふぁーながぷれぜんとをもってくる。@魔女ベファーナがプレゼントを持ってくる。@わるいこどものくつしたにはすみがいれられるが、@悪い子供の靴下には炭が入れられるが、@こうしたばつをうけるふうしゅうは@こうした罰を受ける風習は@おらんだやどいつなどにもみられる。@オランダやドイツなどにも見られる。@いぎりすやあめりかでは、@イギリスやアメリカでは、@きほんてきにじたくでかぞくとすごすものとされ、@基本的に自宅で家族と過ごすものとされ、@にほんもいぎりすりゅうのくりすますをうけついでいる。@日本もイギリス流のクリスマスを受け継いでいる。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 70,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "にほんのくりすますのれきし@「日本のクリスマスの歴史」@1552ねんにすおうのくにやまぐちで、@1552年に周防国山口で、@かとりっくきょうかいのせんきょうしが、@カトリック教会の宣教師が、@にほんじんのしんとをまねいて@日本人の信徒を招いて@こうたんさいのみさをおこなったのが、@降誕祭のミサを行なったのが、@れきしじょうにほんはつのくりすますである。@歴史上日本初のクリスマスである。@そのごえどばくふによって@その後江戸幕府によって@きりすときょうはきんしされたが、@キリスト教は禁止されたが、@1900ねんにめいじやがぎんざにしんしゅつし、@1900年に明治屋が銀座に進出し、@そのころからくりすますしょうせんがはじまった。@その頃からクリスマス商戦が始まった。@1926ねんにたいしょうてんのうがほうぎょし、@1926年に大正天皇が崩御し、@しょうわをむかえたさいにきゅうじつとなったそのひが、@昭和を迎えた際に休日となったその日が、@ぐうぜん12がつ25にちだったことから、@偶然12月25日だったことから、@くりすますのふうしゅうがにほんにも@クリスマスの風習が日本にも@ひろくふきゅうしたといわれる。@広く普及したと言われる。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 71,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "にほんにおけるくりすます@「日本におけるクリスマス」@にほんごでせいたんさい、せいやなどとよばれ、@日本語で「聖誕祭」、「聖夜」などと呼ばれ、@おおきないべんととしてていちゃくしたくりすますだが、@大きなイベントとして定着したクリスマスだが、@きりすときょうけんとくらべると@キリスト教圏と比べると@そのきぼはちいさいというしてきもある。@その規模は小さいという指摘もある。@しゅくじつではなく12がつ25にちも@祝日ではなく12月25日も@ふだんどおりしごとをしたり、@普段通り仕事をしたり、@ふらいどちきんなどのじゃんくふーどで@フライドチキンなどのジャンクフードで@くりすますをいわうふうちょうはにほんどくじのもので、@クリスマスを祝う風潮は日本独自のもので、@かいがいではぎもんしされることもある。@海外では疑問視されることもある。@にほんかくちのきょうかいでは@日本各地の教会では@ひろくもんこをひらいており、@広く門戸を開いており、@きりすときょうとでないものも@キリスト教徒でない者も@くりすますのれいはいにしゅっせきすることがかのう。@クリスマスの礼拝に出席することが可能。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 72,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "おおみそか@「大晦日」@いちねんのさいごのひである@一年の最後の日である@12がつ31にちのこと。@12月31日のこと。@にほんではしんとうのかみであるとしがみを@日本では神道の神である年神を@むかえることにちなんだぎょうじがおこなわれる。@迎えることにちなんだ行事が行なわれる。@へいあんじだいにおこなわれていた@平安時代に行なわれていた@おおみそかのきゅうていぎれいのひとつに@大晦日の宮廷儀礼の一つに@ついながある。@「追儺」がある。@おにとひょうげんされるやくを@鬼と表現される厄を@おいはらうためのぎょうじであるが、@追い払うための行事であるが、@これがげんざいもおこなわれる@これが現在も行なわれる@せつぶんのきげんというせつもある。@節分の起源という説もある。@としこしのよるのことをじょやといい、@年越しの夜のことを除夜と言い、@かつてはとしがみをじたくにむかえるために、@かつては年神を自宅に迎えるために、@ひとばんじゅうおきているというならわしがあった。@一晩中起きているという習わしがあった。@これがてんじてげんざいのはつもうでにへんかした。@これが転じて現在の初詣に変化した。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 73,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "おおみそかのきげん@「大晦日の起源」@にほんのきゅうれきでは@日本の旧暦では@まいつきのさいしゅうびをみそかといった。@毎月の最終日を晦日と言った。@ねんないでさいごの12がつのみそかであるから@年内で最後の12月の晦日であるから@おおみそかとなる。@「大晦日」となる。@もともとみそ、はさんじゅうのいみで、@もともとみそ、は三十の意味で、@みそかはさんじゅうにちのことであった。@みそかは三十日のことであった。@しんれきがさいようされるようになっても、@新暦が採用されるようになっても、@12がつ31にちをおおみそかとよぶのは@12月31日を大晦日と呼ぶのは@むかしのなごりである。@昔の名残りである。@べつめいをつごもりともいい、@別名をつごもりとも言い、@これはつきごもりがてんじたもの。@これは「月隠り」が転じたもの。@もとはそのとしいちねんかんをつかさどる@元はその年一年間を司る@かみであるとしがみをむかえるかんしゅうで、@神である年神を迎える慣習で、@としのはじめにくるためしょうがつさまともよばれ、@年の初めに来るため正月様とも呼ばれ、@これがげんざいのしょうがつのゆらい。@これが現在の正月の由来。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 6,
    index: 74,
    img: "tokubetu.webp",
    title: "１年の特別な日",
    text: "おおみそかのぎょうじ@「大晦日の行事」@おおみそかにとしこしそばをたべるふうしゅうは、@大晦日に年越し蕎麦を食べる風習は、@えどじだいがはっしょうといわれる。@江戸時代が発祥と言われる。@そばはほかのめんるいよりきれやすいことから、@蕎麦は他の麺類より切れやすいことから、@いちねんのさいやくをたちきるといういみあいがある。@一年の災厄を断ち切るという意味合いがある。@しんや0じをはさむじかんたいには、@深夜0時を挟む時間帯には、@おおくのてらでじょやのかねがつかれる。@多くの寺で除夜の鐘が撞かれる。@かねのかいすうが108なのは@鐘の回数が108なのは@ぼんのうのかずというせつがいっぱんてきだが、@煩悩の数という説が一般的だが、@てらによってはそれいじょうのばあいもある。@寺によってはそれ以上の場合もある。@ほかにもとしこしをいわうちほうごとのかくしゅぎょうじや、@他にも年越しを祝う地方ごとの各種行事や、@おおみそかのよるとしんねんをまたぐ@大晦日の夜と新年をまたぐ@はつもうでであるにねんまいり、@初詣である二年参り、@かうんとだうんなどのかんしゅうがある。@カウントダウンなどの慣習がある。",
    tag1: "特別な日",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 1,
    img: "yakyu.webp",
    title: "野球用語",
    text: "だりつ@「打率」@やきゅうにおけるだしゃののうりょくをしめす、@野球における打者の能力を示す、@もっともだいひょうてきなしひょうのひとつ。@最も代表的な指標の一つ。@あんだすうわるだすうでもとめられる。@安打数÷打数で求められる。@このばあいのだすうとは、@この場合の打数とは、@もじどおりうってきろくをのこしただせきをしめし、@文字通り打って記録を残した打席を示し、@ししきゅうやぎだひ、@四死球や犠打飛、@すなわちふぉあぼーるやでっどぼーる、@すなわちフォアボールやデッドボール、@おくりばんとやぎせいふらいをうっただせきはふくまれない。@送りバントや犠牲フライを打った打席は含まれない。@いっぱんてきにだりつがたかいせんしゅは@一般的に打率が高い選手は@あんだをうつのうりょくがたかく、@安打を打つ能力が高く、@だりつがさんわりをこえるといちりゅうのだしゃであるといわれている。@打率が三割を超えると一流の打者であると言われている。@ぐたいれいをあげると、@具体例を挙げると、@あんだすうが150、だすうが500だと@安打数が150、打数が500だと@ちょうどさんわり。@ちょうど三割。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 2,
    img: "yakyu.webp",
    title: "野球用語",
    text: "だてん@「打点」@ほーむにそうしゃがかえり、@ホームに走者が返り、@てんすうがはいったばあいにだしゃにきろくされる。@点数が入った場合に打者に記録される。@いかはぐたいれいをだしてせつめいする。@以下は具体例を出して説明する。@らんなー2るいでたいむりーひっとをうったばあいは1だてん。@ランナー2塁でタイムリーヒットを打った場合は1打点。@らんなー1、2るいでほーむらんをうったばあいは3だてん。@ランナー1、2塁でホームランを打った場合は3打点。@らんなー3るいでぎせいふらいをうったばあいは1だてん。@ランナー3塁で犠牲フライを打った場合は1打点。@らんなーまんるいでおしだしししきゅうのばあいも1だてん。@ランナー満塁で押し出し四死球の場合も1打点。@らんなー3るいでないやごろあうとだが@ランナー3塁で内野ゴロアウトだが@そうしゃがせいかんしたばあいも1だてん。@走者が生還した場合も1打点。@まんるいなどでへいさつだ、@満塁などで併殺打、@3るいらんなーはせいかんのばあいだてんはきろくされない。@3塁ランナーは生還の場合打点は記録されない。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 3,
    img: "yakyu.webp",
    title: "野球用語",
    text: "ぼうぎょりつ@「防御率」@とうしゅののうりょくをしめす、@投手の能力を示す、@ふるくからあるしひょう。@古くからある指標。@じせきてんかける9わるとうきゅうかい@自責点×9÷投球回@というしきでもとめられ、@という式で求められ、@しょうすうてんいかふたけたまでひょうじされる。@小数点以下二桁まで表示される。@そのとうしゅが9いにんぐ、@その投手が9イニング、@つまり1しあいなげつづけたら、@つまり1試合投げ続けたら、@へいきんしてなんてんとられるかをあらわしたもの。@平均して何点取られるかを表したもの。@げんざいはきゅうえん、すなわちりりーふとうしゅが@現在は救援、すなわちリリーフ投手が@しあいにとうじょうすることがおおくなったが、@試合に登場することが多くなったが、@むかしはせんぱつかんとうがあたりまえだったため、@昔は先発完投が当たり前だったため、@そのなごりでこのけいさんしきとなっている。@その名残りでこの計算式となっている。@ちーむのつよさにいぞんするしょうはいとちがい、@チームの強さに依存する勝敗と違い、@とうしゅほんにんののうりょくがはんえいされるのがとくちょうてき。@投手本人の能力が反映されるのが特徴的。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 4,
    img: "yakyu.webp",
    title: "野球用語",
    text: "じせきてん@「自責点」@やきゅうのしあいにおいて@野球の試合において@とうしゅのせきにんとされるしってんのこと。@投手の責任とされる失点のこと。@いかはぐたいれいをだしてせつめいする。@以下は具体例を出して説明する。@つーあうとでそろほーむらんをうたれたばあいはじせきてん１。@ツーアウトでソロホームランを打たれた場合は自責点１。@つーあうとらんなー3るいで@2アウトランナー3塁で@たいむりーひっとをうたれたばあいもじせきてん1。@タイムリーヒットを打たれた場合も自責点1。@ただしそのらんなーがやしゅのしっさく、@ただしそのランナーが野手の失策、@すなわちえらーでしゅつるいしたばあい、@すなわちエラーで出塁した場合、@とうしゅのせきにんとはならずじせきてん0。@投手の責任とはならず自責点0。@らんなーまんるいでまうんどにたった@ランナー満塁でマウンドに立った@きゅうえんとうしゅがほーむらんをうたれたばあい、@救援投手がホームランを打たれた場合、@らんなー3にんはそのとうしゅのせきにんではないためじせきてん1。@ランナー三人はその投手の責任ではないため自責点1。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 5,
    img: "yakyu.webp",
    title: "野球用語",
    text: "ほーむらん@「ホームラン」@だしゃがかくるいをへて、@打者が各塁を経て、@ほーむまですすむことができるあんだ。@ホームまで進むことができる安打。@にほんごひょうきではほんるいだ。@日本語表記では本塁打。@いっぱんてきにはがいやふぇんすをこえて、@一般的には外野フェンスを超えて、@ふぇあちいきにだきゅうがとびだす、@フェア地域に打球が飛び出す、@いわゆるおおきなひきゅうがそうとうする。@いわゆる大きな飛球が相当する。@どーむきゅうじょうのばあいは、@ドーム球場の場合は、@やねにあたりおちてきただいひきゅうも、@屋根に当たり落ちてきた大飛球も、@ほーむらんとみなされるばあいがある。@ホームランとみなされる場合がある。@いちどでもじめんにおちただきゅうを、@一度でも地面に落ちた打球を、@しゅびがわがしっさく、すなわちえらーをともなうことなく@守備側が失策、すなわちエラーを伴うことなく@しょりするあいだに、@処理する間に、@だしゃそうしゃがほんるいまではしりぬけると、@打者走者が本塁まで走り抜けると、@らんにんぐほーむらんがきろくされる。@ランニングホームランが記録される。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 6,
    img: "yakyu.webp",
    title: "野球用語",
    text: "あんぜんしんるいけん@「安全進塁権」@きそくによりだしゃをふくむそうしゃが、@規則により打者を含む走者が、@あうとにされることなくしんるいをみとめられること。@アウトにされることなく進塁を認められること。@むずかしいひょうげんだが、@難しい表現だが、@ほーむらんでだしゃがほんるいまでゆっくりはしれるのも、@ホームランで打者が本塁までゆっくり走れるのも、@4このあんぜんしんるいけんがあたえられているから。@4個の安全進塁権が与えられているから。@ほかにもとうきゅうがだしゃにあたり、@他にも投球が打者に当たり、@しきゅうがきろくされたばあいは、@死球が記録された場合は、@1このあんぜんしんるいけんがあたえられる。@1個の安全進塁権が与えられる。@あんだやそうきゅうがふぇんすをこえ、@安打や送球がフェンスを超え、@しゅびがわがほきゅうできなくなったばあい、@守備側が捕球できなくなった場合、@2このあんぜんしんるいけんがあたえられる。@2個の安全進塁権が与えられる。@これらのばあいぼーるでっどとなり、@これらの場合ボールデッドとなり、@しあいがいちじていしじょうたいとなる。@試合が一時停止状態となる。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 7,
    img: "yakyu.webp",
    title: "野球用語",
    text: "おーるすたーげーむ@「オールスターゲーム」@まいとし7がつにおこなわれる、@毎年7月に行なわれる、@せりーぐとぱりーぐのたいこうじあいのこと。@セリーグとパリーグの対抗試合のこと。@ふぁんとうひょうによりせんしゅつされた@ファン投票により選出された@かくぽじしょんのせんしゅと、@各ポジションの選手と、@かんとくすいせんわく、および@監督推薦枠、及び@2008ねんからはせんしゅかんとうひょうによって@及び2008年からは選手間投票によって@えらばれたせんしゅがしゅつじょうできる。@選ばれた選手が出場できる。@ひこうしきせんだが、@非公式戦だが、@こうりゅうせんがかいしされるまえはゆめのきゅうえんとよばれ、@交流戦が開始される前は夢の球宴と呼ばれ、@にりーぐにわかれたせんしゅどうしのたいけつなどがみれる、@二リーグに分かれた選手同士の対決などが見れる、@かずすくないばだった。@数少ない場だった。@げんざいはかんむりたいかいとなり、@現在は冠大会となり、@きょうさんきぎょうのしゃめいがあたまにつく。@協賛企業の社名が頭につく。@2020ねんは@2020年は@しんがたころなういるすのえいきょうでしじょうはつのちゅうし。@新型コロナウイルスの影響で史上初の中止。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 8,
    img: "yakyu.webp",
    title: "野球用語",
    text: "こうりゅうせん@「交流戦」@せりーぐとぱりーぐとのあいだでおこなわれる@セリーグとパリーグとの間で行なわれる@いんたーりーぐのこと。@インターリーグのこと。@にほんでは2005ねんからかいしされた。@日本では2005年から開始された。@こうしきせんであり、@公式戦であり、@ちーむのしょうはいやこじんせいせきは@チームの勝敗や個人成績は@それぞれのさんかりーぐのこうしきせんせいせきにはんえいされる。@それぞれの参加リーグの公式戦成績に反映される。@ゆうしょうちーむにはしょうきんがじゅよされる。@優勝チームには賞金が授与される。@れきしてきにぱりーぐがつよく、@歴史的にパリーグが強く、@つうさんせいせきやねんどごとのかちこしかいすう、@通算成績や年度ごとの勝ち越し回数、@ゆうしょうかいすうなどでせりーぐをうわまわっている。@優勝回数などでセリーグを上回っている。@2020ねんは@2020年は@しんがたころなういるすのえいきょうでちゅうしとなったが、@新型コロナウイルスの影響で中止となったが、@2021ねんはかいさいされおりっくすがゆうしょうした。@2021年は開催されオリックスが優勝した。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 9,
    img: "yakyu.webp",
    title: "野球用語",
    text: "ちょうだりつ@「長打率」@やきゅうにおいてだしゃをひょうかするしひょうのひとつ。@野球において打者を評価する指標の一つ。@るいだわるだすうでもとめられる。@塁打÷打数で求められる。@だりつにおけるあんだを@打率における安打を@すべて1とかぞえるのとはことなり、@すべて1と数えるのとは異なり、@るいだのばあいはたんだを1、@塁打の場合は単打を1、@にるいだを2、さんるいだを3、@二塁打を2、三塁打を3、@ほんるいだを4としてけいさんする。@本塁打を4として計算する。@ちょうだをはなつかくりつのことではなく、@長打を放つ確率のことではなく、@ちょうだりつがたかいほどいちどのだせきで@長打率が高いほど一度の打席で@とおくのるいまですすむのうりょくがあることをしめしている。@遠くの塁まで進む能力があることを示している。@ほんるいだがおおいちょうきょりだしゃや、@本塁打が多い長距離打者や、@しゅんそくであったりちゅうきょりだしゃで@俊足であったり中距離打者で@にるいだやさんるいだがおおいせんしゅが、@二塁打や三塁打が多い選手が、@ちょうだりつのすうちがたかくなるけいこうにある。@長打率の数値が高くなる傾向にある。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 10,
    img: "yakyu.webp",
    title: "野球用語",
    text: "おーぴーえす@「OPS」@おぷすともよむ、@オプスとも読む、@やきゅうにおいてだしゃをひょうかするしひょうのひとつ。@野球において打者を評価する指標の一つ。@しゅつるいりつとちょうだりつを@出塁率と長打率を@たしあわせたあたいでもとめられる。@足し合わせた値で求められる。@しゅつるいりつとはだりつのあんだにくわえて、@出塁率とは打率の安打に加えて、@ししきゅうでるいにでたものをかみしてけいさんしたものである。@四死球で塁に出たものを加味して計算したものである。@だしゃののこすすうじがたきにわたり、@打者の残す数字が多岐に渡り、@すくないこうもくだけではこうへいなひょうかができない@少ない項目だけでは公平な評価が出来ない@というもんだいをかいしょうするため、@という問題を解消するため、@べいこくのすぽーつらいたーがはつあんした。@米国のスポーツライターが発案した。@.900のようにひょうきされるが、@.900のように表記されるが、@たんじゅんなたしざんでさんしゅつされているため、@単純な足し算で算出されているため、@わりあいのすうちではないことにちゅうい。@割合の数値ではないことに注意。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 11,
    img: "yakyu.webp",
    title: "野球用語",
    text: "くおりてぃすたーと@「QS」@やきゅうにおけるとうしゅをひょうかするしひょうのひとつ。@野球における投手を評価する指標の一つ。@せんぱつとうしゅが6いにんぐいじょうをなげ、@先発投手が6イニング以上を投げ、@かつじせきてんを3てんいないにおさえたときにきろくされる。@かつ自責点を3点以内に抑えた時に記録される。@べいこくのすぽーつらいたーがていしょうしたもので、@米国のスポーツライターが提唱したもので、@せんぱつとうしゅののうりょくや@先発投手の能力や@あんていかんをあらわすこうもくとして@安定感を表す項目として@いっぱんてきにりようされ、にほんにもひろまった。@一般的に利用され、日本にも広まった。@しょうはいはかんけいなく、@勝敗は関係なく@きちんとしあいをつくったかどうかのめやすとなる。@きちんと試合を作ったどうかの目安となる。@2013ねんに24しょうまけなしの@2013年に24勝負けなしの@きょういてきなせいせきをきろくしたたなかまさひろとうしゅは、@驚異的な成績を記録した田中将大投手は、@どうねんにせんぱつしたしあいの@同年に先発した試合の@qsりつも100ぱーせんとをきろくした。@QS率も100％を記録した。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 12,
    img: "yakyu.webp",
    title: "野球用語",
    text: "せーぶ@「セーブ」@やきゅうにおいて、@野球において、@りーどしているちーむのきゅうえんとうしゅが@リードしているチームの救援投手が@しあいしゅうりょうまでりーどをまもりきることでつくきろく。@試合終了までリードを守りきることで付く記録。@1969ねんにべいこくでさいようされ、@1969年に米国で採用され、@1974ねんににほんでもこうしききろくとしてどうにゅうされた。@1974年に日本でも公式記録として導入された。@しょうりとうしゅではなく、@勝利投手ではなく、@さいごのとうしゅとしてとうばんし、@最後の投手として登板し、@りーどをまもりきりしあいをしゅうりょうさせることが@リードを守りきり試合を終了させることが@せいりつのぜんていじょうけん。@成立の前提条件。@ほかにもじょうけんはあるがふくざつなため、@他にも条件はあるが複雑なため、@ぐたいれいをひとつあげるにとどめる。@具体例を一つ挙げるに留める。@3てんいないりーどの9かいにとうばんし、@3点以内リードの9回に登板し、@1いにんぐをなげきりしあいにかてばせーぶはきろくされる。@1イニングを投げきり試合に勝てばセーブは記録される。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 13,
    img: "yakyu.webp",
    title: "野球用語",
    text: "ふせいとうきゅう@「不正投球」@おもにぼーるになんらかのさいくをして、@主にボールに何らかの細工をして、@へんかをするどくしたりふきそくなものにして、@変化を鋭くしたり不規則なものにして、@とうしゅがとうきゅうをするふせいこういのそうしょう。@投手が投球をする不正行為の総称。@つばをつけるすぴっとぼーるがだいひょうてき。@唾をつけるスピットボールが代表的。@そのほかにもやすりでぼーるにきずをつけたり、@その他にもヤスリでボールに傷をつけたり、@かくしもったまつやにやひげそりくりーむ、@隠し持った松脂や髭剃りクリーム、@せいはつようじぇるやわせりんなどの@整髪用ジェルやワセリンなどの@ねんちゃくぶつをつけるばあいもある。@粘着物をつける場合もある。@べいこくのめじゃーりーぐでは@米国のメジャーリーグでは@とくにもんだいとなっており、@特に問題となっており、@2021ねんにはふせいとうきゅうのたいさくとして、@2021年には不正投球の対策として、@とうしゅにたいしてしあいちゅうに@投手に対して試合中に@ねんちゃくぶっしつけんさをおこなうじたいとなっている。@粘着物質検査を行なう事態となっている。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 14,
    img: "yakyu.webp",
    title: "野球用語",
    text: "こりじょんるーる@「コリジョンルール」@やきゅうにおけるほんるいでのほしゅとそうしゃのしょうとつ、@野球における本塁での捕手と走者の衝突、@すなわちこりじょんをぼうしするためのきそく。@すなわちコリジョンを防止するための規則。@2016ねんににほんでもてきおうされた。@2016年に日本でも適応された。@がいようはいかのふたつのこうもく。@概要は以下の二つの項目。@とくてんしようとしているそうしゃが、@得点しようとしている走者が、@そうろをぶろっくしていないほしゅややしゅにせっしょくしようとして、@走路をブロックしていない捕手や野手に接触しようとして、@またはさいしょからせっしょくをもくろみ、@または最初から接触を目論み、@そうろをはずれることをきんじる。@走路を外れることを禁じる。@ぼーるをほじしていないほしゅが、@ボールを保持していない捕手が、@とくてんしようとするそうしゃの@得点しようとする走者の@そうろをぶろっくするこういをきんじる。@走路をブロックする行為を禁じる。@いはんがあったばあいはあうととせーふのはんていがくつがえる。@違反があった場合はアウトとセーフの判定が覆る。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 15,
    img: "yakyu.webp",
    title: "野球用語",
    text: "りくえすとせいど@「リクエスト制度」@かんとくがしんぱんのはんていにいぎがあるばあい、@監督が審判の判定に異議がある場合、@びでおえいぞうによるりぷれーけんしょうを@ビデオ映像によるリプレー検証を@もとめることができるというせいど。@求めることができるという制度。@2018ねんからにほんのぷろやきゅうでどうにゅうされた。@2018年から日本のプロ野球で導入された。@おもにだきゅうがほんるいだかふぁうるか、@主に打球が本塁打かファウルか、@るいじょうでのそうしゃのきわどいぷれーが@塁上での走者の際どいプレーが@あうとかせーふかをめぐって、@アウトかセーフかを巡って、@てきおうされることがおおい。@適応されることが多い。@かんとくがりくえすとけんをこうしできるかいすうは@監督がリクエスト権を行使できる回数は@1しあいにつき2かいだが、@1試合につき2回だが、@はんていがくつがえったばあいはこのかいすうをしょうひしない。@判定が覆った場合はこの回数を消費しない。@しあいがえんちょうせんにはいったばあいは、@試合が延長戦に入った場合は、@あらたに1かいのけんりがついかされる。@新たに1回の権利が追加される。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 16,
    img: "yakyu.webp",
    title: "野球用語",
    text: "えんちょうせん@「延長戦」@やきゅうのしあいはげんそくとして9かいまでだが、@野球の試合は原則として9回までだが、@9かいうらのこうげきをおえてもどうてんのばあい、@9回裏の攻撃を終えても同点の場合、@えんちょうせんにとつにゅうする。@延長戦に突入する。@ねんどによってなんかいまでしあいがつづくかはことなるが、@年度によって何回まで試合が続くかは異なるが、@にほんのぷろやきゅうはげんざい12かいまで。@日本のプロ野球は現在12回まで。@それいぜんにこうこうちーむのとくてんが@それ以前に後攻チームの得点が@せんこうちーむをうわまわったばあい、@先行チームを上回った場合、@さよならげーむとなりけっちゃくする。@サヨナラゲームとなり決着する。@こうこうやきゅうやしゃかいじんやきゅうのばあい、@高校野球や社会人野球の場合、@るいじょうにそうしゃをおいた@塁上に走者を置いた@たいぶれーくせいどがどうにゅうされることもある。@タイブレーク制度が導入されることもある。@2021ねんはしんがたころなういるすのえいきょうにより、@2021年は新型コロナウイルスの影響により、@しあいは9かいうちきり。@試合は9回打ち切り。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 17,
    img: "yakyu.webp",
    title: "野球用語",
    text: "とうるい@「盗塁」@やきゅうにおけるこうげきがわの@野球における攻撃側の@そうしゃのしんるいほうほうのひとつ。@走者の進塁方法の一つ。@いっぱんてきにはとうしゅのとうきゅうじに@一般的には投手の投球時に@そうしゃがじるいにむけてすたーとし、@走者が次塁に向けてスタートし、@ほしゅのそうきゅうによってたっちあうとになるまえに@捕手の送球によってタッチアウトになる前に@しんるいするけーすである。@進塁するケースである。@もっともおおいのはにるいへのとうるいだが、@最も多いのは二塁への盗塁だが、@まれにさんるいへのとうるい、@稀に三塁への盗塁、@ごくまれにほんるいへのとうるい、@ごく稀に本塁への盗塁、@すなわちほーむすちーるもこころみられる。@すなわちホームスチールも試みられる。@ふくすうのそうしゃがどうじにとうるいすることを@複数の走者が同時に盗塁することを@だぶるすちーる、にほんごではじゅうとうとひょうきする。@ダブルスチール、日本語では重盗と表記する。@とうるいをきめたせんしゅにはとうるいが、@盗塁を決めた選手には盗塁が、@しっぱいしたばあいはとうるいしがきろくされる。@失敗した場合は盗塁死が記録される。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 18,
    img: "yakyu.webp",
    title: "野球用語",
    text: "へいさつ@「併殺」@やきゅうのしゅびにおけるきろくのひとつ。@野球の守備における記録の一つ。@いちれんのぷれいでにこのあうとがきろくされること。@一連のプレイで二個のアウトが記録されること。@だぶるぷれいやげっつーともいう。@ダブルプレイやゲッツーとも言う。@たとえばむしまたはいっしいちるいで、@例えば無死または一死一塁で、@だしゃがごろのだきゅうをはなち、@打者がゴロの打球を放ち、@やしゅがにるいにそうきゅうしいちるいそうしゃをあうとにし、@野手が二塁に送球して一塁走者をアウトにし、@いちるいにそうきゅうしてだしゃそうしゃもあうとにすればせいりつ。@一塁に送球して打者走者もアウトにすれば成立。@このばあいだしゃにへいさつだがきろくされる。@この場合打者に併殺打が記録される。@ひきゅうによってだしゃそうしゃがあうとになり、@飛球によって打者走者がアウトになり、@べつのそうしゃがきるいできず@別の走者が帰塁できず@さらにあうとになるのもへいさつのいっしゅだが、@さらにアウトになるのも併殺の一種だが、@このばあいへいさつだはきろくされない。@この場合併殺打は記録されない。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 19,
    img: "yakyu.webp",
    title: "野球用語",
    text: "しょうりとうしゅ@「勝利投手」@しょうりちーむのせきにんとうしゅのこと。@勝利チームの責任投手のこと。@せんぱつのばあいは5いにんぐいじょうをなげ、@先発の場合は5イニング以上を投げ、@そのあいだにちーむがてんすうをりーどしていれば@その間にチームが点数をリードしていれば@けんりがはっせいする。@権利が発生する。@そのあときゅうえんとうしゅがどうてんや@その後救援投手が同点や@ぎゃくてんをゆるしたばあいはけんりしょうしつ。@逆転を許した場合は権利消失。@それいがいはきほんてきに、@それ以外は基本的に、@しょうりちーむのけっしょうてんがはいった@勝利チームの決勝点が入った@ちょくぜんのとうしゅがしょうりとうしゅとなるが、@直前の投手が勝利投手となるが、@りーどをゆるしているばめんで3あうとまでなげ、@リードを許している場面で3アウトまで投げ、@ちょくごのこうげきでみかたがぎゃくてんしたばあいも、@直後の攻撃で味方が逆転した場合も、@しょうりとうしゅのけんりははっせいする。@勝利投手の権利は発生する。@1きゅうをなげただけでしょうりとうしゅになる@1球を投げただけで勝利投手になる@ちんきろくもひかくてきおおい。@珍記録も比較的多い。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 20,
    img: "yakyu.webp",
    title: "野球用語",
    text: "うぃっぷ@「WHIP」@やきゅうにおけるとうしゅのせいせきひょうかこうもくのひとつ。@野球における投手の成績評価項目の一つ。@1いにんぐあたりなんにんのそうしゃをだしたかをあらわすすうち。@1イニングあたり何人の走者を出したかを表す数値。@よしきゅうすうとひあんだすうをたしたすうちを、@与四球数と被安打数を足した数値を、@とうきゅうかいすうでわることでもとめられる。@投球回数で割ることで求められる。@しっさくなどとうしゅにせきにんがないしゅつるいは、@失策など投手に責任がない出塁は、@このばあいはかぞえられない。@この場合は数えられない。@にほんぷろやきゅうでは@日本プロ野球では@こうしききろくとしてはさいようされていないが、@公式記録としては採用されていないが、@しひょうとしてとうしゅのせいせきらんに@指標として投手の成績欄に@きさいされていることがおおい。@記載されていることが多い。@ひじょうにすぐれたせんぱつとうしゅや@非常に優れた先発投手や@おさえのきりふだであれば、@抑えの切り札であれば、@このすうちが1.00をきることもめずらしくない。@この数値が1.00を切ることも珍しくない。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 21,
    img: "yakyu.webp",
    title: "野球用語",
    text: "だいだ@「代打」@だじゅんがまわってきただしゃとこうたいして、@打順が回ってきた打者と交代して、@だせきにはいるせんしゅのこと。@打席に入る選手のこと。@えいごではぴんちひったーという。@英語ではピンチヒッターと言う。@だげきがほんしょくではないとうしゅや、@打撃が本職ではない投手や、@しゅびはじょうずだがだげきのうりょくになんがあるせんしゅにかわって、@守備は上手だが打撃能力に難がある選手に代わって、@きようされることがおおい。@起用されることが多い。@しあいがせっせんのまましゅうばんにとつにゅうし、@試合が接戦のまま終盤に突入し、@そうしゃがでてどうてんやぎゃくてん、@走者が出て同点や逆転、@あるいはけっていてきなついかてんを@あるいは決定的な追加点を@うばうきかいをえたばめんなどに、@奪う機会を得た場面などに、@しょうぶづよいだしゃやべてらんのこうだしゃが@勝負強い打者やベテランの好打者が@とうじょうしきゅうじょうがもりあがる。@登場し球場が盛り上がる。@つうさんだいだほんるいだのにほんきろくは、@通算代打本塁打の日本記録は、@はんきゅうのたかいやすひろがきろくした27ほん。@阪急の高井保弘が記録した27本。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 22,
    img: "yakyu.webp",
    title: "野球用語",
    text: "だいそう@「代走」@しゅつるいしているそうしゃにかわって@出塁している走者に代わって@しゅつじょうするせんしゅのこと。@出場する選手のこと。@えいごではぴんちらんなーという。@英語ではピンチランナーと言う。@しあいしゅうばんせっせんのさいに@試合終盤接戦の際に@しゅつるいしたせんしゅにたいしてしゅんそくせんしゅがおくられたり、@出塁した選手に対して俊足選手が送られたり、@たいさのばめんでしゅりょくをやすませるため、@大差の場面で主力を休ませるため、@ひかえのやしゅとこうたいするけーすがおおい。@控えの野手と交代するケースが多い。@へんそくてきなれいとして、@変則的な例として、@ほんるいだをうったもののちょくごにけがをして@本塁打を打ったものの直後に怪我をして@るいじょうをいっしゅうできないばあい、@塁上を一周できない場合、@かわりのせんしゅがだいそうとしてとうじょうすることもある。@代わりの選手が代走として登場することもある。@もとごりんのりくじょうせんしゅがそうりょくをかわれ、@元五輪の陸上選手が走力を買われ、@だいそうせんもんのせんしゅとしてぷろににゅうだんしたれいもある。@代走専門の選手としてプロに入団した例もある。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 23,
    img: "yakyu.webp",
    title: "野球用語",
    text: "けいえん@「敬遠」@せいしきにはこいしきゅうといい、@正式には故意四球といい、@とうしゅがだしゃにたいしていとてきにしきゅうをあたえること。@投手が打者に対して意図的に四球を与えること。@またそのばあいのだしゃのきろく。@またその場合の打者の記録。@とくてんけんにそうしゃをおいて、@得点圏に走者を置いて、@ほんるいだなどのちょうだがけいかいされるきょうだしゃや、@本塁打などの長打が警戒される強打者や、@つぎのだじゅんがだりょくにきたいできないとうしゅなどのばあい、@次の打順が打力に期待できない投手などの場合、@さくせんとしてけいえんがもちいられるばあいがおおい。@作戦として敬遠が用いられる場合が多い。@いぜんはこいしきゅうのばあいも、@以前は故意四球の場合も、@とうしゅはほしゅにたいしてとうきゅうをしていたが、@投手は捕手に対して投球をしていたが、@2018ねんからはぷろやきゅうで@2018年からはプロ野球で@しんこくけいえんせいどがさいようされ、@申告敬遠制度が採用され、@かんとくがきゅうしんにけいえんのいしをつたえれば、@監督が球審に敬遠の意思を伝えれば、@とうきゅうがしょうりゃくされる。@投球が省略される。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 24,
    img: "yakyu.webp",
    title: "野球用語",
    text: "けんせいきゅう@「牽制球」@とうしゅがるいじょうにいるそうしゃの@投手が塁上にいる走者の@とうるいなどをけいかいして、@盗塁などを警戒して、@そうしゃのいるるいにそうきゅうすること。@走者のいる塁に送球すること。@いっぱんてきにけんせいとりゃくされることがおおい。@一般的に牽制と略されることが多い。@けんせいきゅうのかずにるーるじょうのせいげんはないが、@牽制球の数にルール上の制限はないが、@たようするとしあいのちえんこういとなり、@多用すると試合の遅延行為となり、@きゅうじょうのかんきゃくからぶーいんぐがはっせいすることもある。@球場の観客からブーイングが発生することもある。@けんせいきゅうをおこなうさいの@牽制球を行なう際の@どうさはさまざまなきていがあり、@動作は様々な規定があり、@たとえばるいにそうきゅうするまね、@例えば塁に送球する真似、@すなわちぎとうをしてじっさいにけんせいをしなかったばあいなどは、@すなわち偽投をして実際に牽制をしなかった場合などは、@ぼーくがせんこくされるいじょうのすべてのそうしゃに@ボークが宣告され塁上の全ての走者に@1つのあんぜんしんるいけんがあたえられる。@1つの安全進塁権が与えられる。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 25,
    img: "yakyu.webp",
    title: "野球用語",
    text: "しゅいだしゃ@「首位打者」@ぷろやきゅうにおける、@プロ野球における、@だげきぶもんのしゅようたいとるのひとつ。@打撃部門の主要タイトルの一つ。@かくしーずんのきていだせきにとうたつしただしゃのうち、@各シーズンの規定打席に到達した打者のうち、@もっともだりつのたかいせんしゅにあたえられる。@最も打率の高い選手に与えられる。@きていだせきとはりーぐがはっぴょうする、@規定打席とはリーグが発表する、@だげきらんきんぐのたいしょうとなるために@打撃ランキングの対象となるために@ひつようなだせきすうのこと。@必要な打席数のこと。@にほんぷろやきゅうでは、@日本プロ野球では、@しあいすうの3.1ばいとさだめられている。@試合数の3.1倍と定められている。@あめりかでは1941ねんのてっどうぃりあむすをさいごに、@アメリカでは1941年のテッド・ウィリアムスを最後に、@にほんぷろやきゅうではにぐんのせいせきやどくりつりーぐなどをのぞいて、@日本プロ野球では二軍の成績や独立リーグなどを除いて、@だりつ4わりをきろくしただしゃはそんざいしない。@打率4割を記録した打者は存在しない。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 26,
    img: "yakyu.webp",
    title: "野球用語",
    text: "とりぷるすりー@「トリプルスリー」@にほんぷろやきゅうにおいてだしゃがどういつしーずんに@日本プロ野球において打者が同一シーズンに@だりつ3わり、30ほんるいだ、30とうるいいじょうのせいせきを@打率3割、30本塁打、30盗塁以上の成績を@きろくすることをいみするようご。@記録することを意味する用語。@わせいえいごであり、@和製英語であり、@あめりかではねんかん30ほんるいだとどうじに@アメリカでは年間30本塁打と同時に@30とうるいをたっせいすることが@30盗塁を達成することが@せんしゅのこうひょうかのたいしょうとなる。@選手の高評価の対象となる。@これまで10にんのせんしゅによって、@これまで10人の選手によって、@のべ12かいたっせいされている。@延べ12回達成されている。@2015ねんは@2015年は@とうきょうやくるとすわろーずのやまだてつとせんしゅ、@東京ヤクルトスワローズの山田哲人選手、@ふくおかそふとばんくほーくすのやなぎたゆうきせんしゅが@福岡ソフトバンクホークスの柳田悠岐選手が@どうじにせぱでたっせいし、@同時にセパで達成し、@りゅうこうごたいしょうにもえらばれた。@流行語大賞にも選ばれた。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 27,
    img: "yakyu.webp",
    title: "野球用語",
    text: "しんじんおう@「新人王」@せいしきめいしょうはさいゆうしゅうしんじん。@正式名称は最優秀新人。@せぱりょうりーぐごとに、@セパ両リーグごとに、@そのとしのもっともゆうしゅうなしんじんせんしゅにあたえられる。@その年の最も優秀な新人選手に与えられる。@ぷろやきゅうを5ねんいじょうたんとうしている、@プロ野球を5年以上担当している、@しんぶんやほうそうかくしゃのきしゃの@新聞や放送各社の記者の@とうひょうによってせんしゅつされる。@投票によって選出される。@ぷろ1ねんめのせんしゅだけでなく、@プロ1年目の選手だけでなく、@せんしゅとしてとうろくされてから5ねんいない、@選手として登録されてから5年以内、@およびとうしゅとしてぜんねんまでの@及び投手として前年までの@いちぐんでのとうばんいにんぐすうが30いない、@一軍での登板イニング数が30以内、@だしゃとしてぜんねんまでの@打者として前年までの@いちぐんでのだせきすうが60いないのせんしゅもたいしょう。@一軍での打席数が60以内の選手も対象。@ゆうりょくながいとうせんしゅがふくすうにんいるばあい、@有力な該当選手が複数人いる場合、@べつにしんじんとくべつしょうがもうけられる。@別に新人特別賞が設けられる。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 28,
    img: "yakyu.webp",
    title: "野球用語",
    text: "mvp@「MVP」@せいしきめいしょうはさいゆうしゅうせんしゅ。@正式名称は最優秀選手。@かくねんどのにほんぷろやきゅうにおいて、@各年度の日本プロ野球において、@もっともかつやくしたせんしゅにあたえられるひょうしょうのひとつ。@最も活躍した選手に与えられる表彰の一つ。@しんじんおうとおなじく、@新人王と同じく、@5ねんいじょうぷろやきゅうをたんとうしている@5年以上プロ野球を担当している@きしゃによってせんしゅつされる。@記者によって選出される。@そのとしにゆうしょうしたちーむないで、@その年に優勝したチーム内で、@こじんせいせきがゆうしゅうだったせんしゅや、@個人成績が優秀だった選手や、@ゆうしょうがいのちーむでも@優勝外のチームでも@しーずんきろくをこうしんするなど、@シーズン記録を更新するなど、@とくひつしたせいせきをのこしたせんしゅが@特筆した成績を残した選手が@えらばれるけいこうにある。@選ばれる傾向にある。@そのつきにひいでたせいせきをのこしたせんしゅに@その月に秀でた成績を残した選手に@あたえられるげっかんmvpや、@与えられる月間MVPや、@おーるすたーmvpなどもそんざいする。@オールスターMVPなども存在する。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 29,
    img: "yakyu.webp",
    title: "野球用語",
    text: "おーぷんせん@「オープン戦」@わせいえいごであり、@和製英語であり、@ほかのきゅうぎなどのぷれしーずんまっちとどうぎ。@他の球技などのプレシーズンマッチと同義。@にほんぷろやきゅうでは@日本プロ野球では@まいとししゅんききゃんぷのしゅうばんから@毎年春季キャンプの終盤から@しーずんのかいまくまえ、@シーズンの開幕前、@2がつげじゅんから3がつげじゅんにかけておこなわれる。@2月下旬から3月下旬にかけて行なわれる。@せいしきめいしょうはしゅんきひこうしきじあいで、@正式名称は春季非公式試合で、@ちーむのしょうはいやせんしゅのせいせきなどは@チームの勝敗や選手の成績などは@こうしきのせいせきにはんえいされない。@公式の成績に反映されない。@わかてせんしゅにとってはあぴーるのばであり、@若手選手にとってはアピールの場であり、@れぎゅらーやべてらんせんしゅにとっては@レギュラーやベテラン選手にとっては@ちょうせいのいみあいがつよい。@調整の意味合いが強い。@やかんのしあいはどーむきゅうじょうをのぞきすくなく、@夜間の試合はドーム球場を除き少なく、@たいはんはでーげーむでかいさいされる。@大半はデーゲームで開催される。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 30,
    img: "yakyu.webp",
    title: "野球用語",
    text: "にぐん@「二軍」@にほんぷろやきゅうのばあい、@日本プロ野球の場合、@こうしきせんにしゅつじょうできるのは@公式戦に出場できるのは@しはいかせんしゅとうろくのなかで@支配下選手登録の中で@しゅつじょうせんしゅとうろくされたせんしゅである。@出場選手登録された選手である。@それいがいのせんしゅはにぐんしょぞくとなる。@それ以外の選手は二軍所属となる。@わかてをそだてることから、@若手を育てることから、@ぼくじょうになぞらえたふぁーむとこしょうされる。@牧場に擬えたファームと呼称される。@ひがしにほんがおもなきょてんであるいーすたんりーぐと、@東日本が主な拠点であるイースタンリーグと、@にしにほんがおもなきょてんであるうえすたんりーぐにわかれ、@西日本が主な拠点であるウエスタンリーグに別れ、@にぐんでもこうしきせんがおこなわれる。@二軍でも公式戦が行なわれる。@けがやふちょうによりいちぐんからりだつした、@怪我や不調により一軍から離脱した、@しゅりょくせんしゅのちょうせいのばでもある。@主力選手の調整の場でもある。@ちーむによってはさんぐんせいどもさいようされている。@チームによっては三軍制度も採用されている。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 31,
    img: "yakyu.webp",
    title: "野球用語",
    text: "くらいまっくすしりーず@「クライマックスシリーズ」@2007ねんよりにほんのぷろやきゅうで@2007年より日本のプロ野球で@おこなわれているぷれーおふせいど。@行なわれているプレーオフ制度。@ぱりーぐでは2004ねんからどうにゅうされた。@パリーグでは2004年から導入された。@まずりーぐ2いと3いのちーむがたいせんし、@まずリーグ2位と3位のチームが対戦し、@さきに2しょうしたちーむがかちあがる。@先に2勝したチームが勝ち上がる。@これをふぁーすとすてーじとよぶ。@これをファーストステージと呼ぶ。@つぎにかちあがったちーむとりーぐ1いのちーむがたいせんし、@次に勝ち上がったチームとリーグ1位のチームが対戦し、@さきに4しょうしたちーむがにっぽんしりーずにしんしゅつする。@先に4勝したチームが日本シリーズに進出する。@これをふぁいなるすてーじとよび、@これをファイナルステージと呼び、@りーぐ1いのちーむにはあどばんてーじとして@リーグ1位のチームにはアドバンテージとして@1しょうがあらかじめあたえられる。@1勝があらかじめ与えられる。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 32,
    img: "yakyu.webp",
    title: "野球用語",
    text: "にほんしりーず@「日本シリーズ」@せいしきめいしょうはぷろやきゅうにほんせんしゅけんしりーず。@正式名称はプロ野球日本選手権シリーズ。@にほんぷろやきゅうのぺなんとれーすしゅうりょうご、@日本プロ野球のペナントレース終了後、@くらいまっくすしりーずゆうしょうちーむによって@クライマックスシリーズ優勝チームによって@おこなわれる、かくねんのにほんいちをきめるしあい。@行なわれる、各年の日本一を決める試合。@さきに4しょうしたちーむがにほんいちとなる。@先に4勝したチームが日本一となる。@くらいまっくすしりーずどうにゅうまえは、@クライマックスシリーズ導入前は、@せぱりょうりーぐのゆうしょうちーむが@セパ両リーグの優勝チームが@にほんしりーずにしゅつじょうしていた。@日本シリーズに出場していた。@れきだいゆうしょうかいすうは@歴代優勝回数は@よみうりじゃいあんつの22かいがさいただが、@読売ジャイアンツの22回が最多だが、@ここ10ねんかんはそふとばんくほーくすが@ここ10年間はソフトバンクホークスが@4ねんれんぞくをふくむ7かいのゆうしょうをはたしている。@4年連続を含む7回の優勝を果たしている。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 33,
    img: "yakyu.webp",
    title: "野球用語",
    text: "おくりばんと@「送りバント」@ばんとはやきゅうにおけるだげきほうほうのひとつ。@バントは野球における打撃方法の一つ。@ばっとをすいんぐせず、@バットをスイングせず、@いしきてきにだきゅうをないやにゆるくころがすもの。@意識的に打球を内野にゆるく転がすもの。@だしゃがあうとになるだいしょうに、@打者がアウトになる代償に、@いちるいやにるいのそうしゃをすすめるものをぎせい、@一塁や二塁の走者を進めるものを犠牲、@ないしはおくりばんとという。@ないしは送りバントという。@こうしききろくとしてはだしゃにぎだがきろくされる。@公式記録としては打者に犠打が記録される。@さんるいそうしゃをかえすばんとはすくいず、@三塁走者を返すバントはスクイズ、@だしゃそうしゃもしゅつるいすることを@打者走者も出塁することを@いとしたものをせーふてぃーばんととよぶ。@意図したものをセーフティーバントと呼ぶ。@にほんぷろやきゅうではべいこくにくらべ、@日本プロ野球では米国に比べ、@さくせんとしてばんとをもちいるひんどがばいちかいともいわれる。@作戦としてバントを用いる頻度が倍近いとも言われる。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 34,
    img: "yakyu.webp",
    title: "野球用語",
    text: "ぎせいふらい@「犠牲フライ」@やきゅうにおけるだげききろくのいっしゅで、@野球における打撃記録の一種で、@ぎひともいう。@犠飛ともいう。@むしまたはいっしでそうしゃがいるさいに、@無死または一死で走者がいる際に、@だしゃががいやなどにひきゅうやらいなーをはなち、@打者が外野などに飛球やライナーを放ち、@やしゅがこれをほきゅうしあうとになったあと、@野手がこれを捕球しアウトになった後、@そうしゃがほんるいにたっしたばあいに@走者が本塁に達した場合に@ぎせいふらいがきろくされる。@犠牲フライが記録される。@このばあいはだしゃにだてんがきろくされ、@この場合は打者に打点が記録され、@だすうとしてかんさんされないためだりつもさがらない。@打数として換算されないため打率も下がらない。@ばんととはちがい、@バントとは違い、@とくてんをあげたばあいのみぎひはきろくされる。@得点を挙げた場合のみ犠飛は記録される。@たとえばにるいそうしゃがさんるいにしんるいしたばあいなどは、@例えば二塁走者が三塁に進塁した場合などは、@ぎひはきろくされずだりつもさがる。@犠飛は記録されず打率も下がる。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 35,
    img: "yakyu.webp",
    title: "野球用語",
    text: "かんぷうしょうり@「完封勝利」@いっぱんてきなすぽーつようごとしては、@一般的なスポーツ用語としては、@あいてをむとくてんにふうじること。@相手を無得点に封じること。@やきゅうにおけるかんぷうは、@野球における完封は、@せんぱつとうしゅがしょかいからしあいしゅうりょうまでかんとうし、@先発投手が初回から試合終了まで完投し、@あいてちーむにとくてんをあたえず@相手チームに得点を与えず@しょうりとうしゅとなったばあいにきろくされる。@勝利投手となった場合に記録される。@あいてをむしってんにおさえても、@相手を無失点に抑えても、@みかたもむとくてんでひきわけになったばあいは、@味方も無得点で引分になった場合は、@かんぷうはきろくされない。@完封は記録されない。@ひとりのとうしゅだけでなく、@一人の投手だけでなく、@ふくすうにんのとうしゅであいてだせんを@複数人の投手で相手打線を@むとくてんにおさえることをかんぷうりれーとひょうげんする。@無得点に抑えることを完封リレーと表現する。@べいこくでは100きゅうみまんのかんぷうを、@米国では100球未満の完封を、@せんしゅめいにゆらいしまだっくすという。@選手名に由来しマダックスという。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 36,
    img: "yakyu.webp",
    title: "野球用語",
    text: "さいゆうしゅうぼうぎょりつ@「最優秀防御率」@にほんぷろやきゅうのたいとるのひとつ。@日本プロ野球のタイトルの一つ。@きていとうきゅうかいにたっしたとうしゅのうち、@規定投球回に達した投手のうち、@ぼうぎょりつがもっともゆうしゅうなせんしゅにあたえられる。@防御率が最も優秀な選手に与えられる。@きていとうきゅうかいとは、@規定投球回とは、@ぷろやきゅうのこうしきせんにおいて、@プロ野球の公式戦において@とうしゅがこのたいとるをかくとくするさいに@投手がこのタイトルを獲得する際に@ひつようなとうきゅうかいすうのこと。@必要な投球回数のこと。@ちーむのしあいすうとどうすう、@チームの試合数と同数、@すなわち1ばいとさだめられている。@すなわち1倍と定められている。@めやすとしては2てんだいこうはんならややたかめ、@目安としては2点台後半ならやや高め、@2てんだいぜんはんならだとうないしはひくめ、@2点台前半なら妥当ないしは低め、@1てんだいはひじょうにゆうしゅうなせいせきとされる。@1点台は非常に優秀な成績とされる。@さいたしょうりやさいただつさんしんとならぶ、@最多勝利や最多奪三振と並ぶ、@とうしゅのしゅようたいとるのひとつ。@投手の主要タイトルの一つ。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 37,
    img: "yakyu.webp",
    title: "野球用語",
    text: "たっちあっぷ@「タッチアップ」@やきゅうにおけるそうるいせんじゅつのひとつ。@野球における走塁戦術の一つ。@だしゃのひきゅうをやしゅがほきゅうしたあとに、@打者の飛球を野手が捕球した後に、@そうしゃがつぎのるいへのしんるいを@走者が次の塁への進塁を@いとしてきるいすること。@意図して帰塁すること。@もしくはあらかじめきるいしておくこと。@もしくはあらかじめ帰塁しておくこと。@たっぐあっぷともいう。@タッグアップともいう。@ひきゅうがしゅびがわのやしゅにほきゅうされたばあい、@飛球が守備側の野手に捕球された場合、@そうしゃはあうとにならないよう@走者はアウトにならないよう@きるいするひつようがある。@帰塁する必要がある。@これをりたっちという。@これをリタッチという。@そのあとはつぎのるいへのしんるいを@その後は次の塁への進塁を@こころみることができる。@試みることができる。@たとえばがいやにひきゅうがとび、@例えば外野に飛球が飛び、@ほきゅうごにさんるいのそうしゃが@捕球後に三塁の走者が@ほんるいにせいかんしたばあいは、@本塁に生還した場合は、@ぎせいふらいがきろくされる。@犠牲フライが記録される。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 38,
    img: "yakyu.webp",
    title: "野球用語",
    text: "いんふぃーるどふらい@「インフィールドフライ」@やきゅうのしあいにおいて、@野球の試合において、@むしまたはいっしで、そうしゃいち、にるいまたはまんるいのばめんで、@無死または一死で、走者一、二塁または満塁の場面で、@だしゃがうちあげたひきゅうのうち、@打者が打ち上げた飛球のうち、@ないやしゅがふつうのしゅびこういをすれば、@内野手が普通の守備行為をすれば、@ほきゅうできるとしんぱんいんがはんだんしたもの。@捕球できると審判員が判断したもの。@しゅびがわがこいにらっきゅうすると、@守備側が故意に落球すると、@そうしゃがつぎのるいにすすむまえにふくすうのあうとをとられ、@走者が次の塁に進む前に複数のアウトを取られ、@こうげきがわがふとうにふりになるのを@攻撃側が不当に不利になるのを@ふせぐためさだめられたきそく。@防ぐため定められた規則。@よってほきゅうすればそくちぇんじのにしのばあいや、@よって捕球すれば即チェンジの二死の場合や、@むしまたはいっしでそうしゃがにるいなど、@無死または一死で走者が二塁など、@らっきゅうしてもししょうのないばあいはせんこくされない。@落球しても支障のない場合は宣告されない。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 39,
    img: "yakyu.webp",
    title: "野球用語",
    text: "さよならげーむ@「サヨナラゲーム」@やきゅうでこうこうちーむが、@野球で後攻チームが、@さいしゅうかいまたはえんちょうのこうげきで、@最終回または延長の攻撃で、@けっしょうてんをあげるとどうじに@決勝点を上げると同時に@しゅうりょうするしあいのこと。@終了する試合のこと。@どうてんのばめんであんだにより@同点の場面で安打により@てんすうがはいればさよならひっと、@点数が入ればサヨナラヒット、@びはいんどのばめんでぎゃくてんほんるいだがでれば@ビハインドの場面で逆転本塁打が出れば@さよならほーむらんなど、@サヨナラホームランなど、@あたまにさよならをかんするぷれいはかずおおい。@頭にサヨナラを冠するプレイは数多い。@さようなら、にゆらいする@さようなら、に由来する@にほんとくゆうのひょうげんだが、@日本特有の表現だが、@べいこくにもぎゃくゆにゅうされさよなら、@米国にも逆輸入されサヨナラ、@とさけぶかいがいじっきょうしゃもそんざいする。@と叫ぶ海外実況者も存在する。@えいごではうたれたとうしゅが@英語では打たれた投手が@あるいてひきあげるようすから、うぉーくおふという。@歩いて引き揚げる様子から、ウォークオフという。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 40,
    img: "yakyu.webp",
    title: "野球用語",
    text: "こーるどげーむ@「コールドゲーム」@やきゅうのしあいにおいてなんらかのりゆうにより、@野球の試合において何らかの理由により、@しんぱんがうちきりをめいじたしあいのこと。@審判が打ち切りを命じた試合のこと。@たとえばはげしいあめで、@例えば激しい雨で、@ぐらうんどじょうたいがあっかし@グラウンド状態が悪化し@しあいのぞっこうがこんなんになったさいなどに、@試合の続行が困難になった際などに、@こーるどげーむがてきおうされる。@コールドゲームが適応される。@にほんぷろやきゅうでは、@日本プロ野球では、@いにんぐが5かいみまんのばあいはのーげーむで、@イニングが5回未満の場合はノーゲームで、@しあいはむこうとなりこじんきろくなどもすべてはきされる。@試合は無効となり個人記録などもすべて破棄される。@5かいうらがしゅうりょうしているばあいは、@5回裏が終了している場合は、@しあいせいりつとなる。@試合成立となる。@こうこうやきゅうなどでは、@高校野球などでは、@10てんいじょうなどてんさがひらきすぎたばあいも@10点以上など点差が開きすぎた場合も@こーるどげーむとなる。@コールドゲームとなる。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 41,
    img: "yakyu.webp",
    title: "野球用語",
    text: "のーひっとのーらん@「ノーヒットノーラン」@やきゅうのしあいでひとりのとうしゅが、@野球の試合で一人の投手が、@あいてちーむにあんだもとくてんもゆるさず、@相手チームに安打も得点も許さず、@しょうりすること。@勝利すること。@わせいえいごであり、@和製英語であり、@べいこくではのーひったーとよぶ。@米国ではノーヒッターと呼ぶ。@のーらんのらんははしることではなく、@ノーランのランは走ることではなく、@とくてんをいみするやきゅうようご。@得点を意味する野球用語。@ちょくやくするとむあんだむとくてんとなる。@直訳すると無安打無得点となる。@ししきゅうやしっさくなどによる@四死球や失策などによる@そうしゃはゆるしてもかまわないが、@走者は許しても構わないが、@しってんしたばあいはきろくとはみとめられない。@失点した場合は記録とは認められない。@ふくすうにんのとうしゅによるけいとうのばあいは、@複数人の投手による継投の場合は、@ちーむのきろくあつかい。@チームの記録扱い。@にほんぷろやきゅうにおいて、@日本プロ野球において、@2020ねんまでにけい93かいたっせいされている。@2020年までに計93回達成されている。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 42,
    img: "yakyu.webp",
    title: "野球用語",
    text: "かんぜんじあい@「完全試合」@やきゅうのしあいにおけるきろくのひとつ。@野球の試合における記録の一つ。@ひとりのとうしゅがあいてちーむのだしゃを、@一人の投手が相手チームの打者を、@いちどもしゅつるいさせずにしょうりすること。@一度も出塁させずに勝利すること。@のーひっとのーらんとちがい、@ノーヒットノーランと違い、@ししきゅうやしっさくなどのしゅつるいもゆるされない。@四死球や失策などの出塁も許されない。@えんちょうせんをのぞけば@延長戦を除けば@やきゅうは9いにんぐあるため、@野球は9イニングあるため、@さいていでも27にんのだしゃを@最低でも27人の打者を@すべてぼんたいさせるひつようがある。@すべて凡退させる必要がある。@べいこくでは21せいきにはいってからも、@米国では21世紀に入ってからも、@ふくすうにんのとうしゅがかんぜんじあいをきろくしているが、@複数人の投手が完全試合を記録しているが、@にほんぷろやきゅうでは1994ねんの@日本プロ野球では1994年の@まきはらひろみとうしゅをさいごに、@槙原寛己投手を最後に、@25ねんいじょうもたっせいされていない。@25年以上も達成されていない。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 43,
    img: "yakyu.webp",
    title: "野球用語",
    text: "こいらっきゅう@「故意落球」@やきゅうのしあいで、@野球の試合で、@だしゃがないやにひきゅうをうったさいに、@打者が内野に飛球を打った際に、@ないやしゅがいとてきにらっきゅうしたときに@内野手が意図的に落球したときに@てきようされるるーるのこと。@適用されるルールのこと。@たとえばむしいちるいのばめんで、@例えば無死一塁の場面で、@ゆうげきしゅにひくいらいなーがとんだとする。@遊撃手に低いライナーが飛んだとする。@せんしゅはほきゅうするとみせかけて、@選手は捕球すると見せかけて、@らっきゅうしすぐにひろいにるいへそうきゅう、@落球しすぐに拾い二塁へ送球、@さらににるいしゅからいちるいへもそうきゅうされた。@さらに二塁手から一塁へも送球された。@このばあいはへいさつだとはならず、@この場合は併殺打とはならず、@しんぱんによってこいらっきゅうがせんこくされ、@審判によって故意落球が宣告され、@だしゃのみがあうととなる。@打者のみがアウトとなる。@ふとうにあうとがふえることをふせぐため、@不当にアウトが増えることを防ぐため、@このるーるがもうけられている。@このルールが設けられている。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 44,
    img: "yakyu.webp",
    title: "野球用語",
    text: "だげきぼうがい@「打撃妨害」@しゅびがわがだしゃのだげきこういをぼうがいしたばあいに、@守備側が打者の打撃行為を妨害した場合に、@だしゃがいちるいにしゅつるいするきそくのこと。@打者が一塁に出塁できる規則のこと。@おおくのばあいは、@多くの場合は、@ほしゅがだしゃまたはばっとにふれることでおこる。@捕手が打者またはバットに触れることで起こる。@だげきぼうがいがきろくされたばあいは、@打撃妨害が記録された場合は、@だしゃのだすうにはかうんとされず、@打者の打数にはカウントされず、@だりつもさがらない。@打率も下がらない。@だげきぼうがいのこういをしたやしゅには@打撃妨害の行為をした野手には@しっさくがきろくされる。@失策が記録される。@2006ねんにはまんるいのばめんで、@2006年には満塁の場面で、@だしゃのばっとがみっとにふれたという@打者のバットがミットに触れたという@せんしゅのあぴーるがしんぱんにみとめられ、@選手のアピールが審判に認められ、@さよならだげきぼうがいでしあいしゅうりょうとなる@サヨナラ打撃妨害で試合終了となる@ちんきろくがはっせいした。@珍記録が発生した。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 45,
    img: "yakyu.webp",
    title: "野球用語",
    text: "ねくすとばったーずさーくる@「ネクストバッターズサークル」@つぎにだせきにたつだしゃがたいきするために、@次に打席に立つ打者が待機するために、@やきゅうじょうないにもうけられるくかくのこと。@野球場内に設けられる区画のこと。@そのなのとおり@その名の通り@ちょっけいやく1.5めーとるのえん。@直径約1.5メートルの円。@せんしゅはすべりどめのすぷれーをばっとにかけたり、@選手は滑り止めのスプレーをバットにかけたり、@すぶりをしたりして@素振りをしたりして@だせきにむかうじゅんびをしていることがおおい。@打席に向かう準備をしていることが多い。@かならずしもつぎのだしゃが@必ずしも次の打者が@さーくるにいなければならないというきやくはなく、@サークルに居なければならないという規約はなく、@にほんぷろやきゅうでは@日本プロ野球では@それまでしあいにみしゅつじょうのせんしゅが、@それまで試合に未出場の選手が、@だいだとしてとうじょうするかのうせいをしさするために、@代打として登場する可能性を示唆するために、@だみーとしてさーくるないにはいることもある。@ダミーとしてサークル内に入ることもある。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 46,
    img: "yakyu.webp",
    title: "野球用語",
    text: "くりーんなっぷ@「クリーンナップ」@るいじょうのそうしゃをせいかんさせ、@塁上の走者を生還させ、@とくてんがはいることをきたいされるだじゅんのつうしょう。@得点が入ることを期待される打順の通称。@るいをいっそうするといういみから、@塁を一掃するという意味から、@くりーんなっぷまたはくりーんあっぷとよばれる。@クリーンナップまたはクリーンアップと呼ばれる。@にほんでは3ばん、4ばん、5ばんのとりおをしめすが、@日本では3番、4番、5番のトリオを示すが、@えいごでは4ばんだしゃのみをさす。@英語では4番打者のみを指す。@やきゅうではながらく4ばんに、@野球では長らく4番に、@もっともだりょくのすぐれたせんしゅを@最も打力の優れた選手を@はいちするのがせおりーであったが、@配置するのがセオリーであったが、@きんねんのべいこくではそのやくめが@近年の米国ではその役目が@2ばんだしゃにへんせんしつつある。@2番打者に変遷しつつある。@にとうりゅうのおおたにしょうへいせんしゅも、@二刀流の大谷翔平選手も、@しょぞくするえんぜるすでは2ばんをうつことがおおい。@所属するエンゼルスでは2番を打つことが多い。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 47,
    img: "yakyu.webp",
    title: "野球用語",
    text: "まうんど@「マウンド」@ほんらいのいみはもりつちで、@本来の意味は盛り土で、@にほんごのつかもいみあいとしてはちかいが、@日本語の塚も意味合いとしては近いが、@やきゅうではとうしゅがとうきゅうするくいきのことをしめす。@野球では投手が投球する区域のことを示す。@ちょっけいは18ふぃーと、@直径は18フィート、@たかさは10いんちとるーるによってさだめられている。@高さは10インチとルールによって定められている。@うえからみるとえんけいで、@上から見ると円形で、@つちをもってしゅういよりもたかくなっている。@土を盛って周囲よりも高くなっている。@ちゅうおうにはとうしゅばんという@中央には投手板という@しろいいたがうめこまれている。@白い板が埋め込まれている。@ほんるいのごかくけいのせんたんから、@本塁の五角形の先端から、@とうしゅばんのほんるいがわのえんまでのきょりは60.5ふぃーと。@投手板の本塁側の縁までの距離は60.5フィート。@とうしゅはとうきゅうどうさのさいには、@投手は投球動作の際には、@あしがとうしゅばんにふれなければならない。@足が投手板に触れなければならない。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 48,
    img: "yakyu.webp",
    title: "野球用語",
    text: "さわむらしょう@「沢村賞」@せんぜんにかつやくしたさわむらえいじせんしゅにちなみ、@戦前に活躍した沢村栄治選手にちなみ、@かくねんどのにほんぷろやきゅうで@各年度の日本プロ野球で@もっともかつやくしたせんぱつとうしゅを@最も活躍した先発投手を@たいしょうとしておくられるとくべつしょうのこと。@対象として贈られる特別賞のこと。@せんこういいんはいんたいしためいとうしゅ5めいがつとめ、@選考委員は引退した名投手5名が務め、@かいぎによるはなしあいや@会議による話し合いや@たすうけつによってけっていされる。@多数決によって決定される。@せんこうきじゅんは7こうもくあるが、@選考基準は7項目あるが、@たとえばかんとうすう10などは@例えば完投数10などは@げんざいのきじゅんとしてはてきさないため、@現在の基準としては適さないため、@かならずしもすべてみたすひつようはない。@必ずしもすべて満たす必要はない。@にほんぷろやきゅうの@日本プロ野球の@こうしきひょうしょうこうもくではないため、@公式表彰項目ではないため、@2019ねんのようにがいとうしゃなし@2019年のように該当者なし@というけつろんになるばあいもある。@という結論になる場合もある。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 49,
    img: "yakyu.webp",
    title: "野球用語",
    text: "すたーてぃんぐめんばー@「スターティングメンバー」@やきゅうのしあいかいしじより@野球の試合開始時より@しゅつじょうするせんしゅのこと。@出場する選手のこと。@またそのそうしょう。@またその総称。@ほかのきょうぎでもつかわれるすためんは、@他の競技でも使われるスタメンは、@このことばをりゃくしたもの。@この言葉を略したもの。@わせいえいごであり、@和製英語であり、@せいしきにはすたーてぃんぐおーだーや@正式にはスターティングオーダーや@すたーたーとよぶ。@スターターと呼ぶ。@にほんごでひょうきするとせんぱつしゅつじょうせんしゅ。@日本語で表記すると先発出場選手。@むかしはあいてのせんぱつとうしゅの@昔は相手の先発投手の@こうほがふくすうにんいるさい、@候補が複数人いる際、@あてうまとよばれるていさつめんばーをすためんにおき、@当て馬と呼ばれる偵察メンバーをスタメンに置き、@しあいかいしちょくごにこうたいさせる@試合開始直後に交代させる@というせんじゅつがもちいられることもあったが、@という戦術が用いられることもあったが、@げんざいはよこくせんぱつとうしゅのどうにゅうで@現在は予告先発投手の導入で@あてうまはすがたをけした。@当て馬は姿を消した。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 50,
    img: "yakyu.webp",
    title: "野球用語",
    text: "すいっちひったー@「スイッチヒッター」@さゆうりょうほうのだせきで@左右両方の打席で@だげきをおこなうせんしゅのこと。@打撃を行なう選手のこと。@にほんごではりょううちとひょうきする。@日本語では両打ちと表記する。@やきゅうではとうしゅのききうでにたいし、@野球では投手の利き腕に対し、@はんたいがわのだせきにたつほうが@反対側の打席に立つ方が@いっぱんてきにゆうりとされている。@一般的に有利とされている。@りょううちであれば@両打ちであれば@あいてとうしゅがみぎなげでもひだりなげでも、@相手投手が右投げでも左投げでも、@つねにぎゃくがわのだせきにたつことがかのう。@常に逆側の打席に立つことが可能。@またもとはみぎうちのしゅんそくせんしゅが、@また元は右打ちの俊足選手が、@あしをいかすためひだりだせきにもたつよう@足を活かすため左打席にも立つよう@れんしゅうすることもある。@練習することもある。@さいきんのにほんやきゅうでゆうめいなのは、@最近の日本野球で有名なのは、@もとせいぶらいおんずおよびらくてんで、@元西武ライオンズ及び楽天で、@めじゃーでもかつやくしたまついかずおせんしゅなど。@メジャーでも活躍した松井稼頭央選手など。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 51,
    img: "yakyu.webp",
    title: "野球用語",
    text: "しめいだしゃ@「指名打者」@やきゅうのしあいでこうげきじに@野球の試合で攻撃時に@とうしゅにかわってだせきにたつ、@投手に代わって打席に立つ、@だげきせんもんのせんしゅのこと。@打撃専門の選手のこと。@べいこくのあめりかんりーぐや、@米国のアメリカンリーグや、@にほんぷろやきゅうではぱりーぐでどうにゅうされている。@日本プロ野球ではパリーグで導入されている。@だじゅんはにんいのばしょで、@打順は任意の場所で、@しめいだしゃにはいちされたせんしゅは@指名打者に配置された選手は@しゅびにはいっさいつかない。@守備には一切就かない。@このためしゅびりょくにはなんがあるものの、@このため守備力には難があるものの、@だげきはいっきゅうひんといったせんしゅが、@打撃は一級品といった選手が、@うつほうはせんもんではないとうしゅにかわって@打つ方は専門ではない投手に代わって@すためんになをつらねるため、@スタメンに名を連ねるため、@きれめのないだせんをくむことがかのうとなる。@切れ目のない打線を組むことが可能となる。@あるふぁべっとではDHとひょうきする。@アルファベットではDHと表記する。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 52,
    img: "yakyu.webp",
    title: "野球用語",
    text: "さうすぽー@「サウスポー」@やきゅうではひだりなげのとうしゅのこと。@野球では左投げの投手のこと。@ほかのすぽーつきょうぎのひだりききせんしゅや、@他のスポーツ競技の左利き選手や、@ひだりききのがっきえんそうしゃのこともさす。@左利きの楽器演奏者のことも指す。@えいごでさうすはみなみ、@英語でサウスは南、@ぽーはどうぶつのまえあしをいみする。@ポーは動物の前足を意味する。@かつてやきゅうじょうは@かつて野球場は@ごごのひざしがかんせんのさまたげにならないよう、@午後の日差しが観戦の妨げにならないよう、@だしゃからとうしゅをむくほうこうが@打者から投手を向く方向が@とうほくとうになるようせっけいされるのがいっぱんてきだった。@東北東になるよう設計されるのが一般的だった。@このためひだりとうしゅはみなみがわのて@このため左投手は南側の手@つまりさうすぽーでとうきゅうすることになり、@つまりサウスポーで投球することになり、@ここからひだりとうしゅのことを@ここから左投手のことを@さうすぽーとよぶようになった、@サウスポーと呼ぶようになった、@というせつがしゅりゅうである。@という説が主流である。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 53,
    img: "yakyu.webp",
    title: "野球用語",
    text: "すらいだー@「スライダー」@やきゅうにおけるへんかきゅうのしゅるいのひとつ。@野球における変化球の種類の一つ。@ひとさしゆびとなかゆびでぼーるにかいてんをかけ、@人差し指と中指でボールに回転を掛け、@ききうでとはんたいほうこう、@利き腕と反対方向、@またはしたほうこうにへんかする。@または下方向に変化する。@てくびとあくりょくがつよければ、@手首と握力が強ければ、@ぼーるのにぎりかたをかえるだけでもなげられることから、@ボールの握り方を変えるだけでも投げられることから、@へんかきゅうのなかでもしゅうとくがようい。@変化球の中でも習得が容易。@にぎりかたはとうしゅこじんによってびみょうにことなり、@握り方は投手個人によって微妙に異なり、@あまりにもばりえーしょんがほうふであるため、@あまりにもバリエーションが豊富であるため、@ひとくくりにすることはむずかしい。@一括りにすることは難しい。@20せいきしょとうにべいこくのせんしゅがなげ、@20世紀初頭に米国の選手が投げ、@1948ねんにしゅっぱんされたちょしょで@1948年に出版された著書で@すらいだーのながひろまった。@スライダーの名が広まった。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 54,
    img: "yakyu.webp",
    title: "野球用語",
    text: "かーぶ@「カーブ」@やきゅうにおけるへんかきゅうのひとつで、@野球における変化球の一つで、@せかいでもはつのきどうがへんかするとうきゅうとして、@世界でも初の軌道が変化する投球として、@とうじはまきゅうともよばれた。@当時は魔球とも呼ばれた。@ひかくてきおそいきゅうそくで、@比較的遅い球速で、@とうしゅのききうでとはんたいのほうこうに@投手の利き腕と反対の方向に@おおきくまがりながらおちるのがいっぱんてき。@大きく曲がりながら落ちるのが一般的。@19せいきのべいこくでうみだされ、@19世紀の米国で生み出され、@にほんでぷろやきゅうがほっそくするいぜんに、@日本でプロ野球が発足する以前に、@さいしょのやきゅうちーむそうせつしゃといわれている@最初の野球チーム創設者と言われている@ひらおかひろしがかーぶをなげた。@平岡凞がカーブを投げた。@げんざいではこうそくけいへんかきゅうのりゅうこうもあって、@現在では高速系変化球の流行もあって、@なげるせんしゅがげんしょうけいこうにある。@投げる選手が減少傾向にある。@すろーかーぶやどろっぷかーぶなどのしゅるいがある。@スローカーブやドロップカーブなどの種類がある。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 55,
    img: "yakyu.webp",
    title: "野球用語",
    text: "ふぉーくぼーる@「フォークボール」@やきゅうにおけるきゅうしゅのひとつで、@野球における球種の一つで、@なげたぼーるがだしゃのちかくで@投げたボールが打者の近くで@らっかするへんかきゅうである。@落下する変化球である。@ひとさしゆびとなかゆびでぼーるをはさみ、@人差し指と中指でボールを挟み、@おちるへんかきゅうをにほんでは@落ちる変化球を日本では@いっぱんてきにふぉーくとよぶ。@一般的にフォークと呼ぶ。@べいこくではすぷりったーとよばれる。@米国ではスプリッターと呼ばれる。@てくびをこていしてとうきゅうすることから、@手首を固定して投球することから、@かずあるへんかきゅうのなかでも@数ある変化球の中でも@ひじやかたへのふたんがとくにおおきいとされる。@肘や肩への負担が特に大きいとされる。@またらくさがおおきく@また落差が大きく@ぼうとうやほいつをおこしやすい。@暴投や捕逸を起こしやすい。@めじゃーりーぐでもかつやくした@メジャーリーグでも活躍した@のもひでおとうしゅやささきかずひろとうしゅは、@野茂英雄投手や佐々木主浩投手は、@ふぉーくをきめだまとしていた。@フォークを決め球としていた。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 56,
    img: "yakyu.webp",
    title: "野球用語",
    text: "なっくるぼーる@「ナックルボール」@やきゅうにおけるへんかきゅうのひとつ。@野球における変化球の一つ。@ぼーるのかいてんをおさえたかたちでとうじられ、@ボールの回転を抑えた形で投じられ、@ほしゅにとどくまでのあいだに@捕手に届くまでの間に@さゆうにふきそくなへんかをしながらおちる。@左右に不規則な変化をしながら落ちる。@えがくきどうはだしゃはおろか、@描く軌道は打者はおろか、@うけるほしゅやとうしゅほんにんにすら@受ける捕手や投手本人にすら@まったくよそうがつかないもので、@全く予想がつかないもので、@げんだいのまきゅうともよばれる。@現代の魔球とも呼ばれる。@べいこくではなっくるをちゅうしんに@米国ではナックルを中心に@はいきゅうをくみたてるとうしゅはなっくるぼーらーとよばれ、@配球を組み立てる投手はナックルボーラーと呼ばれ、@ほきゅうのむずかしさから@捕球の難しさから@せんぞくのほしゅがよういされるばあいもある。@専属の捕手が用意される場合もある。@げんざいのにほんぷろやきゅうでは、@現在の日本プロ野球では、@なっくるをなげるとうしゅはかいむである。@ナックルを投げる投手は皆無である。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 57,
    img: "yakyu.webp",
    title: "野球用語",
    text: "めいきゅうかい@「名球会」@にほんのぷろやきゅうでぷれーしたせんしゅ@日本のプロ野球でプレーした選手@およびもとせんしゅによる、@および元選手による、@しゃだんほうじんだんたいのこと。@社団法人団体のこと。@1978ねんにほっそく。@1978年に発足。@にほんぷろやきゅうでのきろくをすたーとちてんとして、@日本プロ野球での記録をスタート地点として、@にちべいつうさんでとうしゅは200しょういじょう@日米通算で投手は200勝以上@もしくは250せーぶいじょう、@もしくは250セーブ以上、@だしゃは2000ほんあんだいじょうをたっせいすれば、@打者は2000本安打以上を達成すれば、@にゅうかいのしかくがえられる。@入会の資格が得られる。@2020ねん11がつによみうりじゃいあんつの@2020年11月に読売ジャイアンツの@さかもとはやとせんしゅがつうさん2000ほんあんだをたっせいしたが、@坂本勇人選手が通算2000本安打を達成したが、@このきろくがおおきくとりあげられるのは@この記録が大きく取り上げられるのは@めいきゅうかいのそんざいによるところがおおきい。@名球会の存在に依るところが大きい。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 58,
    img: "yakyu.webp",
    title: "野球用語",
    text: "がいこくじんわく@「外国人枠」@にほんぷろやきゅうにおける、@日本プロ野球における、@にほんこくせきをゆうしないせんしゅにたいする@日本国籍を有しない選手に対する@しゅつじょうわくのこと。@出場枠のこと。@しょうわのじだいはいちぐんにとうろくできる@昭和の時代は一軍に登録できる@がいこくじんせんしゅはにめいだったが、@外国人選手は二名だったが、@げんざいはとうしゅとやしゅをあわせてよんめいとなっている。@現在は投手と野手をあわせて四名となっている。@にほんのちゅうがくやこうこうにさんねんいじょうざいがく、@日本の中学や高校に三年以上在学、@ないしはだいがくによねんいじょうざいがくしたのちに@ないしは大学に四年以上在学したのちに@ぷろいりしたせんしゅは、@プロ入りした選手は、@がいこくじんわくからはずれる。@外国人枠から外れる。@またにほんのぷろやきゅうでながくかつやくし、@また日本のプロ野球で長く活躍し、@ふりーえーじぇんとのしかくをえたせんしゅも@フリーエージェントの資格を得た選手も@にほんじんあつかいとなる。@日本人扱いとなる。@がいこくじんせんしゅはぞくに@外国人選手は俗に@すけっととよぶことがおおい。@助っ人と呼ぶことが多い。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 59,
    img: "yakyu.webp",
    title: "野球用語",
    text: "ふぃるだーすちょいす@「フィルダースチョイス」@やきゅうのきろくじょうのようごで、@野球の記録上の用語で、@にほんごではやしゅせんたく、@日本語では野手選択、@りゃくしてやせんとひょうきする。@略して野選と表記する。@いっぱんてきなぐたいれいをあげてせつめいすると、@一般的な具体例を挙げて説明すると、@むしいちるいでないやにごろがころがったとする。@無死一塁で内野にゴロが転がったとする。@いちるいにそうきゅうすれば@一塁に送球すれば@あうとはかくじつにとれたところ、@アウトは確実に取れたところ、@いちるいそうしゃをにるいであうとにしようとして@一塁走者を二塁でアウトにしようとして@ないやしゅがそうきゅうし、@内野手が送球し、@そのけっかせーふとなり@その結果セーフとなり@ひとつもあうとがとれなかった。@一つもアウトが取れなかった。@このばあいにしゅびがわがほきゅうしそこねたりせず、@この場合に守備側が捕球しそこねたりせず、@けっかてきにあやまったせんたくをしたが@結果的に誤った選択をしたが@しっさくはつかないばあい、@失策はつかない場合、@ふぃるだーすちょいすがきろくされる。@フィルダースチョイスが記録される。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 60,
    img: "yakyu.webp",
    title: "野球用語",
    text: "しふと@「シフト」@やきゅうにおけるとくしゅなしゅびたいけいのこと。@野球における特殊な守備隊形のこと。@たとえばさんるいにらんなーがすすみ、@例えば三塁にランナーが進み、@しゅびがわがいってんもあたえたくないばあい、@守備側が一点も与えたくない場合、@ひっとのかくりつはあがるが@ヒットの確率は上がるが@ないやしゅがつうじょうよりもまえにでて@内野手が通常よりも前に出て@しゅびをおこなうのをぜんしんしふとという。@守備を行なうのを前進シフトと言う。@またばんとにそなえて@またバントに備えて@とうしゅがとうきゅうするとどうじに@投手が投球すると同時に@いちるいしゅとさんるいしゅがぜんしんするばんとしふと、@一塁手と三塁手が前進するバントシフト、@ちょうだにそなえてがいやしゅが@長打に備えて外野手が@ふかめのしゅびいちをとるちょうだけいかいしふと、@深めの守備位置を取る長打警戒シフト、@だきゅうほうこうにそなえて@打球方向に備えて@ないがいやぜんたいがひだりまたはみぎよりに@内外野全体が左または右寄りに@しゅびいちをかえるぶーどろーしふとなどがある。@守備位置を変えるブードローシフトなどがある。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 61,
    img: "yakyu.webp",
    title: "野球用語",
    text: "とうろくまっしょう@「登録抹消」@ぷろやきゅうにおける、@プロ野球における、@いちぐんのしゅつじょうせんしゅとうろくから@一軍の出場選手登録から@はずれることをさすようご。@外れることを指す用語。@いちどとうろくをまっしょうされると、@一度登録を抹消されると、@そのひから10にちのあいだはさいとうろくできず、@その日から10日の間は再登録できず、@いちぐんのしあいにもしゅつじょうできない。@一軍の試合にも出場できない。@せんぱつとうしゅのばあいは@先発投手の場合は@にっていのつごうじょう、@日程の都合上、@10にちいないにとうばんするよていがないばあい、@10日以内に登板する予定がない場合、@やしゅやきゅうえんとうしゅのしゅつじょうわくを@野手や救援投手の出場枠を@かくほするためまっしょうされるばあいもある。@確保するため抹消される場合もある。@2020ねんからはしんがたころなういるすの@2020年からは新型コロナウイルスの@かんせんかくだいぼうしとくれいとして、@感染拡大防止特例として、@けんさのけっかがいんせいはんのうのばあいは@検査の結果が陰性反応の場合は@10にちをまたずにさいとうろくがかのうとなった。@10日を待たずに再登録が可能となった。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 62,
    img: "yakyu.webp",
    title: "野球用語",
    text: "いくせいけいやく@「育成契約」@にほんのぷろやきゅうにおいて、@日本のプロ野球において、@いくせいをもくてきとして@育成を目的として@きゅうだんのせんしゅけいやくわくをかくだいするせいど。@球団の選手契約枠を拡大する制度。@しはいかとうろくせんしゅとしてきゅうだんとけいやくし、@支配下登録選手として球団と契約し、@いちぐんのしあいにしゅつじょうできるにんずうは、@一軍の試合に出場できる人数は、@げんざいさいだいで70めいとさだめられている。@現在最大で70名と定められている。@いくせいけいやくをむすんだせんしゅは、@育成契約を結んだ選手は、@このかずにかぞえられないかわり、@この数に数えられない代わり、@せばんごうがさんけたとなりいちぐんのしあいにしゅつじょうできない。@背番号が三桁となり一軍の試合に出場できない。@わかてせんしゅがにぐんのしあいでかつやくしたり、@若手選手が二軍の試合で活躍したり、@ちょうきのけがをしていくせいけいやくをむすんでいた@長期の怪我をして育成契約を結んでいた@せんしゅがかんちしたばあい、@選手が完治した場合、@しーずんちゅうあらためてしはいかとうろくされるばあいもある。@シーズン中改めて支配下登録される場合もある。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 63,
    img: "yakyu.webp",
    title: "野球用語",
    text: "てきさすひっと@「テキサスヒット」@ないやとがいやのあいだに@内野と外野の間に@ふらふらとゆるくまいあがっておちるあんだ。@フラフラと緩く舞い上がって落ちる安打。@べいこくでてきさすりーぐしゅっしんのせんしゅが@米国でテキサスリーグ出身の選手が@よくうったことにこのながゆらいするというせつがゆうりょく。@よく打ったことにこの名が由来するという説が有力。@いっぱんてきにはぽてんひっとという。@一般的にはポテンヒットと言う。@よみうりじゃいあんつのせんしゅとして、@読売ジャイアンツの選手として、@せんぜんせんごにかつやくし、@戦前戦後に活躍し、@のちにおうさだはるせんしゅやながしましげおせんしゅをようし、@のちに王貞治選手や長嶋茂雄選手を擁し、@めいかんとくとしてもなをはせたかわかみてつはるせんしゅは、@名監督としても名を馳せた川上哲治選手は、@げんえきじだいのばんねんにこしがまわらなくなり、@現役時代の晩年に腰が回らなくなり、@てきさすひっとをおおくうったことから、@テキサスヒットを多く打ったことから、@てきさすのてつといういみょうでよばれていた。@テキサスの哲という異名で呼ばれていた。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 64,
    img: "yakyu.webp",
    title: "野球用語",
    text: "ふりにげ@「振り逃げ」@やきゅうではつうじょう、@野球では通常、@みっつめのすとらいくをとられるとだしゃはさんしんとなり、@三つめのストライクを取られると打者は三振となり、@あうとがせんこくされる。@アウトが宣告される。@ただしだいさんすとらいくがせんこくされたとうきゅうを、@ただし第三ストライクが宣告された投球を、@ほしゅがせいきにほきゅうできず@捕手が正規に捕球できず@はじいたりうしろにそらしたばあい、@弾いたり後ろに逸らした場合、@さんしんでただちにあうとになることはまぬがれ、@三振で直ちにアウトになることは免れ、@だしゃはいちるいへのしんるいをこころみることができる。@打者は一塁への進塁を試みることができる。@このいちれんのぷれいをふりにげとしょうする。@この一連のプレイを振り逃げと称する。@このことばはいっぱんてきによくもちいられるが、@この言葉は一般的によく用いられるが、@せいしきめいしょうはそんざいしない。@正式名称は存在しない。@だしゃがいちるいにとうたつするまえに、@打者が一塁に到達する前に、@しゅびがわがそうきゅうすればあうととなる。@守備側が送球すればアウトとなる。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 65,
    img: "yakyu.webp",
    title: "野球用語",
    text: "さむらいじゃぱん@「侍ジャパン」@わーるどべーすぼーるくらしっくや@ワールドベースボールクラシックや@おりんぴっくしゅつじょうじなどにへんせいされる、@オリンピック出場時などに編成される、@にほんのやきゅうだいひょうちーむのつうしょう。@日本の野球代表チームの通称。@2000ねんまではぜんにほんとなのっていたが、@2000年までは全日本と名乗っていたが、@21せいきにはいってからさむらいじゃぱんが@21世紀に入ってから侍ジャパンが@せいしきめいしょうとしてつかわれるようになった。@正式名称として使われるようになった。@ほっけーにほんだいひょうのさむらいじゃぱんや、@ホッケー日本代表のさむらいジャパンや、@でんつうがしょうひょうとうろくしている@電通が商標登録している@さむらいじゃぱんとはことなるのでちゅうい。@サムライジャパンとは異なるので注意。@きょうえいのとびうおじゃぱんや、@競泳のトビウオジャパンや、@じょしさっかーのなでしこじゃぱんなど、@女子サッカーのなでしこジャパンなど、@きょうぎごとにさまざまなにほんだいひょうのこしょうがある。@競技ごとに様々な日本代表の呼称がある。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 66,
    img: "yakyu.webp",
    title: "野球用語",
    text: "ふりーえーじぇんと@「フリーエージェント」@せんしゅがいっていきかんいじょう@選手が一定期間以上@おなじきゅうだんにざいせきし、@同じ球団に在籍し、@いちぐんとうろくされていたばあい、@一軍登録されていた場合、@せんげんすればげんざいのしょぞくきゅうだんをふくむ、@宣言すれば現在の所属球団を含む、@すべてのきゅうだんとじゆうにけいやくを@すべての球団と自由に契約を@ていけつするけんりをゆうするせいどのこと。@締結する権利を有する制度のこと。@にほんでは1993ねんにどうにゅうされた。@日本では1993年に導入された。@せんしゅがたきゅうだんにいせきしたばあい、@選手が他球団に移籍した場合、@らんくによってはねんぽうにもとづいたきんせんや@ランクによっては年俸に基づいた金銭や@じんてきほしょうをもとめられることもある。@人的補償を求められることもある。@ささきかずひろとうしゅやしんじょうつよしせんしゅ、@佐々木主浩投手や新庄剛志選手、@まついひできせんしゅやくろだひろきとうしゅなど@松井秀喜選手や黒田博樹投手など@べいこくでもかつやくしたせんしゅは、@米国でも活躍した選手は、@かいがいふりーえーじぇんとけんでいせきした。@海外フリーエージェント権で移籍した。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 67,
    img: "yakyu.webp",
    title: "野球用語",
    text: "じゆうけいやく@「自由契約」@にほんぷろやきゅうで、@日本プロ野球で、@せんしゅがとくていちーむのしはいかにおかれておらず、@選手が特定チームの支配下に置かれておらず、@どのきゅうだんともせんしゅけいやくを@どの球団とも選手契約を@むすべるじょうたいをさす。@結べる状態を指す。@きゅうだんからせんりょくがいつうこくをうける、@球団から戦力外通告を受ける、@せんしゅみずからたいだんをもうしでるなどして、@選手自ら退団を申し出るなどして@らいきいこうのけいやくをむすばないばあい、@来季以降の契約を結ばない場合、@そのせんしゅがげんえきぞっこうをきぼうすれば@その選手が現役続行を希望すれば@じゆうけいやくとなる。@自由契約となる。@せんしゅがげんえきをしりぞくことが@選手が現役を退くことが@けっていされているばあいでも、@決定されている場合でも、@ちーむのこーちなどとくていのやくしょくがよういされていて、@チームのコーチなど特定の役職が用意されていて、@ひきつづきおなじきゅうだんにしょぞくできるばあい、@引き続き同じ球団に所属できる場合、@じゆうけいやくとよぶのはごようなのでちゅういがひつよう。@自由契約と呼ぶのは誤用なので注意が必要。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 68,
    img: "yakyu.webp",
    title: "野球用語",
    text: "にんいいんたい@「任意引退」@せんしゅほんにんがきぼうして@選手本人が希望して@げんえきをしりぞくことをきめ、@現役を退くことを決め、@ほんにんやきゅうだんからいんたいがはっぴょうされたばあいは@本人や球団から引退が発表された場合は@にんいいんたいとなる。@任意引退となる。@このばあいはかいがいもふくめ、@この場合は海外も含め、@ほかのきゅうだんにしょぞくすることはふかのう。@他の球団に所属することは不可能。@ただしよくねんにこーちなどとして、@ただし翌年にコーチなどとして、@あらためてけいやくをむすぶことを@改めて契約を結ぶことを@きゅうだんがそうていしているさい、@球団が想定している際、@たきゅうだんとのこうしょうをさせないために@他球団との交渉をさせないために@せんしゅとごういのうえ、@選手と合意の上、@にんいいんたいせんしゅとして@任意引退選手として@こうじするばあいもある。@公示する場合もある。@あらかじめいんたいがこくち、@あらかじめ引退が告知、@はっぴょうされたばあい、@発表された場合@そのせんしゅのいんたいじあいが@その選手の引退試合が@しーずんのさいしゅうばんにおこなわれることがおおい。@シーズンの最終盤に行なれることが多い。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 69,
    img: "yakyu.webp",
    title: "野球用語",
    text: "ばっと@「バット」@やきゅうでだしゃがとうしゅの@野球で打者が投手の@とうきゅうをうつためにもちいられる、@投球を打つために用いられる、@ぼうじょうのようぐ。@棒状の用具。@ざいしつはきやきんぞく、かーぼんなど。@材質は木や金属、カーボンなど。@もくせいばっとはぷろやきゅうで、@木製バットはプロ野球で、@きんぞくばっとはりとるりーぐや@金属バットはリトルリーグや@こうこうやきゅうでおもにつかわれる。@高校野球で主に使われる。@いっぱんせいじんだんせいむけにしはんされているばっとは、@一般成人男性向けに市販されているバットは、@おおくはながさ83から85せんち、@多くは長さ83から85センチ、@おもさは900ぐらむていど。@重さは900グラム程度。@ただしぷろやきゅうせんしゅは@ただしプロ野球選手は@ぐりっぷのけいじょうなどをふくめ、@グリップの形状などを含め、@じぶんようにかすたまいずしていることがおおい。@自分用にカスタマイズしていることが多い。@とくにりょうしつなざいりょうとして、@特に良質な材料として、@ほっかいどうさんのあおだもがしようされる。@北海道産のアオダモが使用される。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 70,
    img: "yakyu.webp",
    title: "野球用語",
    text: "ぐらぶ@「グラブ」@やきゅうでぼーるをほきゅうするため、@野球でボールを捕球するため、@しゅびのさいにせんしゅがききうでとぎゃくのてに@守備の際に選手が利き腕と逆の手に@はめてつかうどうぐのこと。@はめて使う道具のこと。@ぐろーぶともいう。@グローブとも言う。@ほしゅといちるいしゅはみっととよばれる、@捕手と一塁手はミットと呼ばれる、@せんようのほきゅうようぼうぐをつかうのがいっぱんてき。@専用の捕球用防具を使うのが一般的。@だいぶぶんがうしのかわでできていて、@大部分が牛の革で出来ていて、@おやゆびとひとさしゆびのあいだにある@親指と人差し指の間にある@うぇぶとよばれるあみのかしょによって、@ウェブと呼ばれる網の箇所によって、@ほきゅうがしやすくなっている。@捕球がしやすくなっている。@にほんのやきゅうきていでは@日本の野球規定では@いっていのさいずいないにおおきさがきめられており、@一定のサイズ以内に大きさが決められており、@またぼーるのいろとまぎらわしいため、@またボールの色と紛らわしいため、@しろけいとうのいろをつかうことはきんし。@白系統の色を使うことは禁止。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 71,
    img: "yakyu.webp",
    title: "野球用語",
    text: "べーす@「ベース」@やきゅうじょうのないやにせいほうけいのけいじょうで、@野球場の内野に正方形の形状で、@よっつおかれているようぐのこと。@四つ置かれている用具のこと。@にほんごにやくするとるいとなる。@日本語に訳すると塁となる。@ほんるいにおかれているのがごかくけいのほーむべーすで、@本塁に置かれているのが五角形のホームベースで、@とうきゅうがすとらいくかどうかの@投球がストライクかどうかの@はんだんきじゅんのやくわりももつ。@判断基準の役割も持つ。@だしゃそうしゃはさいしょに@打者走者は最初に@ほんるいからみてみぎにいちするいちるいにしゅつるいし、@本塁から見て右に位置する一塁に出塁し、@つづいてほんるいからちょくせんじょうにのびるにるい、@続いて本塁から直線状に伸びる二塁、@ほんるいからひだりにいちする@本塁から左に位置する@さんるいへとじゅんばんにすすみ、@三塁へと順番に進み、@とくてんをえるためさいしゅうてきに@得点を得るため最終的に@ほんるいにとうたつするひつようがある。@本塁に到達する必要がある。@ほーむべーすいがいのみっつのるいはしかくけいのけいじょう。@ホームベース以外の三つの塁は四角形の形状。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 72,
    img: "yakyu.webp",
    title: "野球用語",
    text: "こうしききゅう@「硬式球」@やきゅうにはざいしつやかたさなどがことなる@野球には材質や硬さなどが異なる@いくつかのぼーるがあるが、@いくつかのボールがあるが、@にほんぷろやきゅうのこうしきせんで@日本プロ野球の公式戦で@しようされるものはこうしききゅうとよばれる。@使用されるものは硬式球と呼ばれる。@こるくやごむのしんにいとをまきつけ、@コルクやゴムの芯に糸を巻き付け、@それをうしのかわでおおい、@それを牛の革で覆い、@ぬいあわせてつくられる。@縫い合わせて作られる。@めいしょうどおりひじょうにかたく、@名称通り非常に硬く、@からだにちょくげきすれば@体に直撃すれば@だぼくやこっせつなどのけがをするかのうせいもある。@打撲や骨折などの怪我をする可能性もある。@めーかーによってはんぱつけいすうがことなり、@メーカーによって反発係数が異なり、@だきゅうのひきょりにもえいきょうする。@打球の飛距離にも影響する。@とういつきゅうとよばれた@統一球と呼ばれた@ていはんぱつのぼーるがしようされたじきは、@低反発のボールが使用された時期は、@ほーむらんのかずがげきげんした。@ホームランの数が激減した。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 73,
    img: "yakyu.webp",
    title: "野球用語",
    text: "とうきょうどーむ@「東京ドーム」@1988ねん3がつ18にちにかいじょうした、@1988年3月18日に開場した、@にほんはつのやねつききゅうじょう。@日本初の屋根付き球場。@それいぜんはこうらくえんきゅうじょうで@それ以前は後楽園球場で@しあいをおこなっていた、@試合を行なっていた、@よみうりじゃいあんつがほんきょちとしている。@読売ジャイアンツが本拠地としている。@ほっかいどうにいてんするまえは、@北海道に移転する前は、@にっぽんはむふぁいたーずもきょてんとしていた。@日本ハムファイターズも拠点としていた。@りょうよく100めーとる、ちゅうけん122めーとると、@両翼100メートル、中堅122メートルと、@かんせいとうじはひろいきゅうじょうだった。@完成当時は広い球場だった。@ただしさちゅうかんとうちゅうかんのふくらみがちいさく、@ただし左中間と右中間の膨らみが小さく、@ひかくてきほんるいだがでやすいとされている。@比較的本塁打が出やすいとされている。@やきゅういがいにかくとうぎのこうぎょうやこんさーと、@野球以外に格闘技の興行やコンサート、@しょうひんのてんじかいなどにりようされる。@商品の展示会などに利用される。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 74,
    img: "yakyu.webp",
    title: "野球用語",
    text: "めいじじんぐうやきゅうじょう@「明治神宮野球場」@とうきょうとしんじゅくくの@東京都新宿区の@めいじじんぐうがいえんにしょざいする、@明治神宮外苑に所在する、@1926ねんにかいじょうしたやきゅうじょう。@1926年に開場した野球場。@つうしょうはじんぐう。@通称は神宮。@かんせいとうじからだいがくやきゅうのしあいにしようされ、@完成当時から大学野球の試合に使用され、@げんざいもとうきょうろくだいがくやきゅうなどがかいさいされる。@現在も東京六大学野球などが開催される。@ぷろやきゅうのとうきょうやくるとすわろーずのほんきょちとして、@プロ野球の東京ヤクルトスワローズの本拠地として、@こくてつすわろーずじだいから@国鉄スワローズ時代から@はんせいきいじょうもしたしまれている。@半世紀以上も親しまれている。@ちく90ねんをこえ、@築90年を超え、@ろうきゅうかやたいしんほきょうがかだいとなっており、@老朽化や耐震補強が課題となっており、@かいしゅうこうじなどもあいついでおこなわれている。@改修工事なども相次いで行なわれている。@2030ねんごろには、@2030年ごろには、@たてかえけいかくであらたなきゅうじょうがかんせいよてい。@建て替え計画で新たな球場が完成予定。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 75,
    img: "yakyu.webp",
    title: "野球用語",
    text: "さっぽろどーむ@「札幌ドーム」@ほっかいどうのさっぽろしに@北海道の札幌市に@2001ねん5がつにかんせいした、@2001年5月に完成した、@ほっかいどうにっぽんはむふぁいたーずの@北海道日本ハムファイターズの@ほんきょちとしてしようされているきゅうじょう。@本拠地として使用されている球場。@ぷろやきゅうだけでなく、@プロ野球だけでなく、@Jりーぐのほっかいどうこんさどーれさっぽろの@Jリーグの北海道コンサドーレ札幌の@ほーむすたじあむでもある。@ホームスタジアムでもある。@りょうよく100めーとる、@両翼100メートル、@ちゅうけん122めーとるのひろさにくわえ、@中堅122メートルの広さに加え、@がいやふぇんすが5.75めーとるとひじょうにたかく、@外野フェンスが5.75メートルと非常に高く、@ほんるいだがでにくい。@本塁打が出にくい。@きゅうじょうしようりょうなどのもんだいにより、@球場使用料などの問題により、@ほっかいどうにっぽんはむふぁいたーずは2023ねんに@北海道日本ハムファイターズは2023年に@ほっかいどうきたひろしましのしんきゅうじょうにいてんよてい。@北海道北広島市の新球場に移転予定。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 76,
    img: "yakyu.webp",
    title: "野球用語",
    text: "はんしんこうしえんきゅうじょう@「阪神甲子園球場」@つうしょうはこうしえん。@通称は甲子園。@ひょうごけんにしのみやしに、@兵庫県西宮市に、@ぜんこくこうとうがっこうやきゅうせんしゅけんたいかい、@全国高等学校野球選手権大会、@ぞくにいうはるなつのこうしえんの@俗に言う春夏の甲子園の@かいさいをしゅもくてきとして、1924ねんにかんせいしたきゅうじょう。@開催を主目的として、1924年に完成した球場。@ぷろやきゅうのはんしんたいがーすのほんきょちとしても、@プロ野球の阪神タイガースの本拠地としても、@せんぜんからりようされている。@戦前から利用されている。@かつてはがいやにらっきーぞーんとよばれる@かつては外野にラッキーゾーンと呼ばれる@かなあみふぇんすがせっちされ、@金網フェンスが設置され、@ほんるいだがでやすかったが1992ねんにてっきょされた。@本塁打が出やすかったが1992年に撤去された。@がいへきをおおうつたや、@外壁を覆う蔦や、@らいとかられふとほうこうへふくはまかぜ、@ライトからレフト方向へ吹く浜風、@ないやせきとがいやせきのあいだのあるぷすすたんどなど@内野席と外野席の間のアルプススタンドなど@めいぶつもおおい。@名物も多い。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 77,
    img: "yakyu.webp",
    title: "野球用語",
    text: "げーむさ@「ゲーム差」@ぷろやきゅうのりーぐせんにおいて、@プロ野球のリーグ戦において、@じょういちーむとかいちーむが@上位チームと下位チームが@どのていどはなれているかをあらわすしひょう。@どの程度離れているかを表す指標。@たとえば1いが50しょう40はい、@例えば1位が50勝40敗、@2いが49しょう41はいのばあい、@2位が49勝41敗の場合、@げーむさは1となる。@ゲーム差は1となる。@つぎのしあいに1いのちーむがまけ、@次の試合に1位のチームが負け、@2いのちーむがかてばかちまけのかずがかんぜんにおなじになり、@2位のチームが勝てば勝ち負けの数が完全に同じになり、@げーむさ0となる。@ゲーム差0となる。@ただしにっていのつごうじょう@ただし日程の都合上@しあいすうがおなじとはかぎらない、@試合数が同じとは限らない、@ひきわけがそんざいする、@引き分けが存在する、@じっさいのじゅんいはしょうりつできまるなどのりゆうで、@実際の順位は勝率で決まるなどの理由で、@げーむさがただしくきのうしないばあいもまれにある。@ゲーム差が正しく機能しない場合も稀にある。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 78,
    img: "yakyu.webp",
    title: "野球用語",
    text: "まじっくなんばー@「マジックナンバー」@ほかのちーむのけっかにかかわらず、@他のチームの結果に関わらず、@じちーむがあとなんしょうすれば@自チームがあと何勝すれば@ゆうしょうがけっていするかをしめすすうち。@優勝が決定するかを示す数値。@つうしょうまじっく。@通称マジック。@にほんぷろやきゅうでは、ほかのぜんちーむに@日本プロ野球では、他の全チームに@じりきゆうしょうのかのうせいがなくなったじょうきょうでのみ、@自力優勝の可能性がなくなった状況でのみ、@このあたいがもちいられる。@この値が用いられる。@このじょうけんをみたしたさいに、@この条件を満たした際に、@まじっくなんばーなになにがてんとうといったひょうげんがつかわれる。@マジックナンバー何々が点灯といった表現が使われる。@このばあいそのあとまじっくがてんとうした@この場合その後マジックが点灯した@1いのちーむがかてば、@1位のチームが勝てば、@まじっくはひとつへる。@マジックは一つ減る。@ゆうしょうをあらそう2いのちーむなどが@優勝を争う2位のチームなどが@どうじにまければ、まじっくはふたつへる。@同時に負ければ、マジックは二つ減る。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 79,
    img: "yakyu.webp",
    title: "野球用語",
    text: "じゅんいひょう@「順位表」@ぷろやきゅうのせぱりょうりーぐ@プロ野球のセパ両リーグ@6ちーむずつのしあいけっかを、@6チームずつの試合結果を、@ひょうにまとめたもの。@表にまとめたもの。@しょうりすうとはいぼくすうのさ、@勝利数と敗北数の差、@いわゆるちょきんやしゃっきんが@いわゆる貯金や借金が@おなじちーむがふくすうあるばあい、@同じチームが複数ある場合、@しょうりつのたかいちーむのほうがじゅんいがうえとなる。@勝率の高いチームのほうが順位が上となる。@これはぷろやきゅうのじゅんいが、@これはプロ野球の順位が、@しょうりつによってきまるとさだめられているから。@勝率によって決まると定められているから。@じゅんいひょうにはちーむごとのしあいすうやかちまけ、@順位表にはチームごとの試合数や勝ち負け、@ひきわけのかずやげーむさなどのほかに、@引き分けの数やゲーム差などの他に、@ちーむのとくてんやしつてんすう、@チームの得点や失点数、@ちーむだりつやぼうぎょりつなどの@チーム打率や防御率などの@かくしゅでーたがさんこうとしてきさいされるばあいもある。@各種データが参考として記載される場合もある。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 80,
    img: "yakyu.webp",
    title: "野球用語",
    text: "がいやしゅ@「外野手」@やきゅうにおいて、@野球において、@がいやのしゅびにつくさんにんのやしゅのこと。@外野の守備に就く三人の野手のこと。@ほしゅからみてじゅんにさよくしゅ、@捕手から見て順に左翼手、@ちゅうけんしゅ、@中堅手、@うよくしゅとよぶ。@右翼手と呼ぶ。@えいごひょうきではれふと、せんたー、らいと。@英語表記ではレフト、センター、ライト。@いっぱんてきにがいやしゅびの@一般的に外野守備の@かなめとなるのがちゅうけんしゅで、@要となるのが中堅手で、@あしがはやくだきゅうはんだんのよいことがもとめられる。@足が速く打球判断の良いことが求められる。@うよくしゅもしゅびめんではひかくてきじゅうようで、@右翼手も守備面では比較的重要で、@かたがつよいことがのぞましい。@肩が強いことが望ましい。@べいこくでもかつやくしたいちろーせんしゅは、@米国でも活躍したイチロー選手は、@うよくしゅをつとめることがおおかった。@右翼手を務めることが多かった。@さよくしゅはしゅびのふたんがすくなく、@左翼手は守備の負担が少なく、@だげきのよいがいこくじんせんしゅなどがまもることがおおい。@打撃の良い外国人選手などが守ることが多い。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 81,
    img: "yakyu.webp",
    title: "野球用語",
    text: "いちるいしゅ@「一塁手」@やきゅうにおいていちるいしゅうへんをまもる@野球において一塁周辺を守る@ないやしゅのこと。@内野手のこと。@こうこうやきゅうでのせばんごうは3。@高校野球での背番号は3。@にほんではふぁーすとともよばれ、@日本ではファーストとも呼ばれ、@ぷろやきゅうではおもにだげきに@プロ野球では主に打撃に@じゅうてんをおくせんしゅがまもることがおおい。@重点を置く選手が守ることが多い。@ほかのしゅびいちからいちるいしゅに@他の守備位置から一塁手に@てんこうするばあいもかずおおい。@転向する場合も数多い。@しゅびのおもなやくわりは、@守備の主な役割は、@ほかのやしゅからのそうきゅうをうけて、@他の野手からの送球を受けて、@だしゃそうしゃをあうとにすること。@打者走者をアウトにすること。@そうきゅうをうけるきかいがひじょうにおおいため、@送球を受ける機会が非常に多いため、@かくじつにほきゅうするのうりょくがもとめられる。@確実に捕球する能力が求められる。@ほかのないやしゅとちがって、@他の内野手と違って、@ゆいいつひだりなげのせんしゅも@唯一左投げの選手も@きようされるぽじしょんである。@起用されるポジションである。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 82,
    img: "yakyu.webp",
    title: "野球用語",
    text: "にるいしゅ@「二塁手」@やきゅうにおいて@野球において@いちるいとにるいのあいだをまもるないやしゅのこと。@一塁と二塁の間を守る内野手のこと。@こうこうやきゅうでのせばんごうは4。@高校野球での背番号は4。@にほんではせかんどともよばれる。@日本ではセカンドとも呼ばれる。@ゆうげきしゅやちゅうけんしゅとともに@遊撃手や中堅手とともに@せんたーらいんをけいせいするため、@センターラインを形成するため、@たかいしゅびのうりょくがもとめられる。@高い守備能力が求められる。@きびんなどうさとひろいしゅびはんいがようきゅうされ、@機敏な動作と広い守備範囲が要求され、@しゅんそくせんしゅがきようされることもおおい。@俊足選手が起用されることも多い。@いちるいまでのきょりがみじかいため、@一塁までの距離が短いため、@かたのつよさはかならずしもひっすではない。@肩の強さは必ずしも必須ではない。@げんざいのにほんをだいひょうするにるいしゅは、@現在の日本を代表する二塁手は、@ちょうだりょくもかねそなえたやまだてつとせんしゅ、@長打力も兼ね備えた山田哲人選手、@しゅびのめいしゅであるきくちりょうすけせんしゅなど。@守備の名手である菊池涼介選手など。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 83,
    img: "yakyu.webp",
    title: "野球用語",
    text: "さんるいしゅ@「三塁手」@やきゅうにおいてさんるいしゅうへんを@野球において三塁周辺を@まもるないやしゅのこと。@守る内野手のこと。@こうこうやきゅうでのせばんごうは5。@高校野球での背番号は5。@にほんではさーどともよばれる。@日本ではサードとも呼ばれる。@だしゃにちかいいちでまもるため、@打者に近い位置で守るため、@だきゅうにいきおいがあるじょうたいで@打球に勢いがある状態で@ほきゅうするひつようがあり、@捕球する必要があり、@えいびんなはんしゃしんけいと@鋭敏な反射神経と@かたのつよさがもとめられる。@肩の強さが求められる。@にほんではしょうわきのよみうりじゃいあんつで@日本では昭和期の読売ジャイアンツで@かつやくしたながしましげおせんしゅなど、@活躍した長嶋茂雄選手など、@きょうだしゃのはながたてきぽじしょんとされる。@強打者の花形的ポジションとされる。@げんざいでもやくるとのむらかみむねたかせんしゅや@現在でもヤクルトの村上宗隆選手や@そふとばんくほーくすのまつだのぶひろせんしゅなど、@ソフトバンクホークスの松田宣浩選手など、@ほんるいだをうてるだしゃがまもることもおおい。@本塁打を打てる打者が守ることも多い。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 7,
    index: 84,
    img: "yakyu.webp",
    title: "野球用語",
    text: "ゆうげきしゅ@「遊撃手」@やきゅうにおいてにるいとさんるいのあいだを@野球において二塁と三塁の間を@まもるないやしゅのこと。@守る内野手のこと。@こうこうやきゅうでのせばんごうは6。@高校野球での背番号は6。@にほんではしょーとともよばれる。@日本ではショートとも呼ばれる。@にるいしゅとならびたかいしゅびぎじゅつがようきゅうされ、@二塁手と並び高い守備技術が要求され、@いちるいまでのきょりもながくかたのつよさももとめられる。@一塁までの距離も長く肩の強さも求められる。@だげきのうりょくよりもしゅびりょくが@打撃能力よりも守備力が@ゆうせんされるぽじしょんであり、@優先されるポジションであり、@ちょうだりょくやたいかくにおとっているせんしゅでも、@長打力や体格に劣っている選手でも、@しゅんそくであったりしんたいのうりょくがたかければ@俊足であったり身体能力が高ければ@ていいちをかちとることもおおい。@定位置を勝ち取ることも多い。@とうきょうごりんでゆうげきしゅをつとめた@東京五輪で遊撃手を務めた@さかもとはやとせんしゅは、@坂本勇人選手は、@ちょうだりょくもかねそなえたかずすくないせんしゅのひとり。@長打力も兼ね備えた数少ない選手の一人。",
    tag1: "野球用語",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 1,
    img: "hana.webp",
    title: "花と植物",
    text: "すいせん@「水仙」@ちちゅうかいえんがんちいきをちゅうしんに、@地中海沿岸地域を中心に、@ぎりしゃからちゅうごくにまで@ギリシャから中国にまで@おおくのしゅるいがひろくぶんぷし、@多くの種類が広く分布し、@にほんにもやせいじょうたいでせいいくするはな。@日本にも野生状態で生育する花。@がくめいはなるきっそすで、@学名はナルキッソスで、@ぎりしゃしんわにとうじょうするびしょうねんにゆらいする。@ギリシャ神話に登場する美少年に由来する。@すいせんというなまえは、@スイセンという名前は、@ちゅうごくでのよびなすいせんをおんよみしたもの。@中国での呼び名水仙を音読みしたもの。@たねんそうで、ふゆからはるにかけて@多年草で、冬から春にかけて@しろやきいろのはなをさかせるものがおおい。@白や黄色の花を咲かせるものが多い。@くさたけは15から50せんちめーとるていど。@草丈は15から50センチメートル程度。@ゆうどくしょくぶつであり、@有毒植物であり、@はがにらとこくじしているため、@葉がニラと酷似しているため、@まちがえてたべしょくちゅうどくをおこすじけんもある。@間違えて食べ食中毒を起こす事件もある。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 2,
    img: "hana.webp",
    title: "花と植物",
    text: "しくらめん@「シクラメン」@ちちゅうかいちほうがげんさんの@地中海地方が原産の@たねんそうのきゅうこんしょくぶつのそうしょう。@多年草の球根植物の総称。@かがりびばなや@篝火花や@ぶたのまんじゅうともひょうきされる。@豚の饅頭とも表記される。@しくらめんのなまえはこてんらてんごにゆらいする。@シクラメンの名前は古典ラテン語に由来する。@こらいはしょくようとされていたが、@古来は食用とされていたが、@じゃがいもなどがりゅうつうしはじめた@ジャガイモなどが流通し始めた@だいこうかいじだいいごはそのしゅうかんもすたれた。@大航海時代以後はその習慣も廃れた。@はなことばはうちきなはにかみ。@花言葉は内気なはにかみ。@めいじじだいにつたわり、@明治時代に伝わり、@せんごはひんしゅかいりょうもすすみ@戦後は品種改良も進み@にほんにおけるはちうえしょくぶつとしての@日本における鉢植え植物としての@さいばいりょうはとっぷくらす。@栽培量はトップクラス。@しやくとのごろあわせや、@死や苦との語呂合わせや、@あかいろがちをそうきさせることから、@赤色が血を想起させることから、@びょうにんのみまいにはえんぎがわるい。@病人の見舞いには縁起が悪い。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 3,
    img: "hana.webp",
    title: "花と植物",
    text: "ろうばい@「蠟梅」@くすのきもくろうばいかろうばいぞくにぞくする、@クスノキ目ロウバイ科ロウバイ属に属する、@ちゅうごくげんさんのらくようじゅ。@中国原産の落葉樹。@そうせいしゅは12がつごろに、@早生種は12月頃に、@ばんせいしゅも2がつにかけて@晩生種も2月にかけて@はんとうめいでにぶいつやのある@半透明で鈍いツヤのある@きいろくかおりたかいはながややかをむいてさく。@黄色く香り高い花がやや下を向いて咲く。@そのはなびらがまるでろうざいくのようであり、@その花びらがまるで蝋細工のようであり、@きゅうれきの12がつのべつめいである@旧暦の12月の別名である@ろうげつにさくことに、@臘月に咲くことに、@ろうばいのなまえがゆらいする。@蠟梅の名前が由来する。@べつめいをからうめやなんきんうめなど。@別名を唐梅や南京梅など。@にほんではばんとうのきご。@日本では晩冬の季語。@どじょうをえらばず、@土壌を選ばず、@かなりのひかげでもよくそだちかいかする@かなりの日陰でもよく育ち開花する@じょうぶなかぼくである。@丈夫な花木である。@はなことばはせんどう、せんけん、いつくしみなど。@花言葉は先導、先見、慈しみなど。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 4,
    img: "hana.webp",
    title: "花と植物",
    text: "くりすますろーず@「クリスマスローズ」@がくめいはへれぼるす。@学名はヘレボルス。@ほんらいはくりすますのころにかいかする@本来はクリスマスの頃に開花する@ひんしゅのみをさしたこしょうだが、@品種のみを指した呼称だが、@にほんのえんげいしじょうでは@日本の園芸市場では@ほかのじきにさくものもくりすますろーずのなでりゅうつうする。@他の時期に咲くものもクリスマスローズの名で流通する。@わめいはゆきおこし、かんしゃくやく。@和名は雪起こし、寒芍薬。@はないろはしろやぴんく、@花色は白やピンク、@むらさきやみどりなどたきにわたる。@紫や緑など多岐に渡る。@よわいどくせいがあり、@弱い毒性があり、@きげんぜんのだいいちじしんせいせんそうにおいて、@紀元前の第一次神聖戦争において、@でるぽいとりんこくどうめいは@デルポイと隣国同盟は@きらのすいげんにこのはなをとうにゅうし、@キラの水源にこの花を投入し、@じゅうみんのほとんどがじゅうどのげりをはっしょう。@住民の殆どが重度の下痢を発症。@りんこくどうめいはきらをむていこうのうえでせんきょした。@隣国同盟はキラを無抵抗の上で占拠した。@はなことばはいたわりやついおくなど。@花言葉はいたわりや追憶など。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 5,
    img: "hana.webp",
    title: "花と植物",
    text: "うめ@「梅」@ばらかさくらぞくの@バラ科サクラ属の@ちゅうごくげんさんのらくようこうぼく、@中国原産の落葉高木、@またそのかじつのこと。@またその果実のこと。@じゅもくぜんたいとはなは@樹木全体と花は@かんしょうのたいしょうになり、@鑑賞の対象になり、@にほんにははなみやうめまつりがひらかれる@日本には花見や梅まつりが開かれる@ばいりんやばいえんがかくちにある。@梅林や梅園が各地にある。@かもじんじゃのれいさいに@賀茂神社の例祭に@うめがけんじょうされたこじにゆらいし、@梅が献上された故事に由来し、@6がつ6にちはうめのひとされている。@6月6日は梅の日とされている。@1がつから3がつごろに、@1月から3月ごろに、@5まいのかべんがあるちいさなはながさく。@5枚の花弁がある小さな花が咲く。@いろはしろ、たんこう、べにいろなど。@色は白、淡紅、紅色など。@かじつは6がつや7がつごろにけつじつし、@果実は6月や7月ごろに結実し、@うめぼしやうめしゅなどのざいりょうとなる。@梅干しや梅酒などの材料となる。@ひんしゅはひじょうにほうふで、@品種は非常に豊富で、@500しゅいじょうともいわれる。@500種以上とも言われる。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 6,
    img: "hana.webp",
    title: "花と植物",
    text: "ぱんじー@「パンジー」@すみれかすみれぞくの@スミレ科スミレ属の@こがたのえんげいしょくぶつのいっしゅ。@小型の園芸植物の一種。@1800ねんだいにほくおうで、@1800年代に北欧で、@えんげいかがおおきくあざやかな@園芸家が大きく鮮やかな@ぐんせいのすみれをつくるために、@群性の菫を作るために、@やせいのすみれどうしをこうはいさせてうまれた。@野生の菫同士を交配させて生まれた。@にほんにもえどじだいにつたわっている。@日本にも江戸時代に伝わっている。@さむさにつよいしょくぶつとしてはんばいされることがおおく、@寒さに強い植物として販売されることが多く、@かんれいちでははるに、@寒冷地では春に、@おんだんなちほうではふゆからかいかがはじまる。@温暖な地方では冬から開花が始まる。@にほんのおおくのちいきでは、@日本の多くの地域では、@なつのあつさのためかれてしまう。@夏の暑さのため枯れてしまう。@こうはいがすすみ、@交配が進み、@おうごんやあか、むらさきなど、@黄金や赤、紫など、@さまざまなしきさいをもつ。@様々な色彩を持つ。@はなことばもはなのいろによってかわるためたすう。@花言葉も花の色によって変わるため多数。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 7,
    img: "hana.webp",
    title: "花と植物",
    text: "かんつばき@「寒椿」@つばきのなかでもとくに、@椿の中でも特に、@さむいきせつにはなをさかせるしゅるいのこと。@寒い季節に花を咲かせる種類のこと。@げんざいではさざんかの@現在では山茶花の@えんげいひんしゅのことをいっぱんてきにはさす。@園芸品種のことを一般的には指す。@こらいからにほんじんにあいされ、@古来から日本人に愛され、@にほんしょきにもきろくがのこっているほか、@日本書紀にも記録が残っている他、@まんようしゅうにもつばきがしようされた@万葉集にも椿が使用された@うたは9しゅある。@歌は9首ある。@にほんげんさんのじょうりょくせいのこうぼくで、@日本原産の常緑性の高木で、@つうじょうはたかさ5から10めーとる、@通常は高さ5から10メートル、@じゅこう15めーとるになるばあいもある。@樹高15メートルになる場合もある。@かきはふゆからはるで、@花期は冬から春で、@べにいろの5べんばなをさかせる。@紅色の5弁花を咲かせる。@はながぽとりとおちるようすから、@花がポトリと落ちる様子から、@らくばをれんそうさせるため@落馬を連想させるため@きょうそうばのなまえにはつかわれない。@競走馬の名前には使われない。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 8,
    img: "hana.webp",
    title: "花と植物",
    text: "かねのなるき@「金のなる木」@べんけいそうかくらっすらぞくの@ベンケイソウ科クラッスラ属の@たにくしょくぶつ。@多肉植物。@はがこうかににているのがなまえのゆらい。@葉が硬貨に似ているのが名前の由来。@せいしきめいしょうはくらっすらぽるつらけあ、@正式名称はクラッスラポルツラケア、@わめいはふちべにべんけいだが、@和名は縁紅弁慶だが、@これらのなはあまりつかわれない。@これらの名はあまり使われない。@みなみあふりかとうぶがげんさんで、@南アフリカ東部が原産で、@にほんにはしょうわしょきにとらいした。@日本には昭和初期に渡来した。@えんぎものてきなはんばいほうほうをとったため、@縁起物的な販売方法をとったため、@なりきんそうというぞくしょうでもしられる。@成金草という俗称でも知られる。@じょうぶなかんようしょくぶつで、@丈夫な観葉植物で、@かぶがせいじゅくすると@株が成熟すると@はくしょくからうすももいろのちいさなはなをたすうつける。@白色から薄桃色の小さな花を多数つける。@かいかきはおもにふゆ。@開花期は主に冬。@おうごんかげつやかげつにしき、@黄金花月や花月錦、@ひめかげつなどのひんしゅがある。@姫花月などの品種がある。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 9,
    img: "hana.webp",
    title: "花と植物",
    text: "しんびじうむ@「シンビジウム」@らんかのしょくぶつのいっしゅ。@ラン科の植物の一種。@もとのごぎはしゅのそうしょうであるが、@元の語義は種の総称であるが、@にほんごではとうなんあじあにじせいしているしゅるいのはなを、@日本語では東南アジアに自生している種類の花を、@ひんしゅかいりょうしたようらんにげんていされる。@品種改良した洋ランに限定される。@らんけいのばるぶから@ラン系のバルブから@こんしゅつじょうにほそながいはをのばす。@根出状に細長い葉を伸ばす。@はなはそのきぶからでるくきについて、@花はその基部から出る茎について、@たんどくかふくすうのはなをさかせる。@単独か複数の花を咲かせる。@かべんがはばひろく、@花弁が幅広く、@ぜんたいにかかえぎみにさくものがおおい。@全体に抱え気味に咲くものが多い。@いろはしろやき、ももいろ、あかなど。@色は白や黄、桃色、赤など。@にほんでのにんきがたかく、@日本での人気が高く、@ひんしゅかいりょうやさいばいぎじゅつのしんぽも@品種改良や栽培技術の進歩も@おうべいをしのぐ。@欧米をしのぐ。@ねんまつにはぞうとうように、@年末には贈答用に、@おおくのはちものがでまわる。@多くの鉢物が出回る。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 10,
    img: "hana.webp",
    title: "花と植物",
    text: "じんちょうげ@「沈丁花」@ちゅうごくなんぶをげんさんちとする@中国南部を原産地とする@じょうりょくていぼく。@常緑低木。@にほんでもむろまちじだいごろにはさいばいされていた。@日本でも室町時代頃には栽培されていた。@しゆういしゅだが、@雌雄異株だが、@にほんにあるきはおかぶがおおく、@日本にある木は雄株が多く、@めかぶはほとんどみられない。@雌株はほとんど見られない。@あかくまるいかじつをつけるが、@赤く丸い果実をつけるが、@ゆうどくである。@有毒である。@はなのせんじじるは、@花の煎じ汁は、@しつうやこうないえんなどのみんかんやくとしてつかわれる。@歯痛や口内炎などの民間薬として使われる。@はなのつぼみはのうこうしょくだが、@花のつぼみは濃紅色だが、@ひらいたはなはたんこうしょくで@開いた花は淡紅色で@おしべはきいろ、つよいほうこうをはなつ。@おしべは黄色、強い芳香を放つ。@2がつまつや3がつにはなをさかせることから、@2月末や3月に花を咲かせることから、@はるのきごとしてよくうたわれる。@春の季語としてよく詠われる。@まつとうやゆみのはるよ、こい@松任谷由実の春よ、来い@のかしでもゆうめい。@の歌詞でも有名。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 11,
    img: "hana.webp",
    title: "花と植物",
    text: "まーがれっと@「マーガレット」@きくかのはんたいかんせいたねんそう。@キク科の半耐寒性多年草。@わめいはもくしゅんぎく。@和名は木春菊。@かんしょうようのえんげいしょくぶつとして、@観賞用の園芸植物として、@おもにおんしつやびにーるはうすでさいばいされる。@主に温室やビニールハウスで栽培される。@かなりあしょとうがげんさんちで、@カナリア諸島が原産地で、@にほんにはめいじじだいまっきにつたわり、@日本には明治時代末期に伝わり、@たいしょうじだいからはばひろくさいばいされるようになった。@大正時代から幅広く栽培されるようになった。@3がつから7がつにはなをつける。@3月から7月に花をつける。@はくしょくのひとえざきがふつうであるが、@白色の一重咲きが普通であるが、@きやぴんくのいろ、@黄やピンクの色、@やえざき、ちょうじざきのひんしゅもある。@八重咲き、丁字咲きの品種もある。@ほんらいはしゅっこんそうであるが、@本来は宿根草であるが、@にほんではおんだんちでないとえっとうできない。@日本では温暖地でないと越冬できない。@かんしろぎくやふらんすぎくなどとよくこんどうされる。@寒白菊やフランスギクなどとよく混同される。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 12,
    img: "hana.webp",
    title: "花と植物",
    text: "せつぶんそう@「節分草」@きんぽうげかせつぶんそうぞくのたねんそう。@キンポウゲ科セツブンソウ属の多年草。@にほんのこゆうしゅで、@日本の固有種で、@かんとうちほういせいのとくさん。@関東地方以西の特産。@せっかいがんちをこのむけいこうがあり、@石灰岩地を好む傾向があり、@おもにたいへいようがわの@主に太平洋側の@まばらなおんたいかりょくりんのりんないや、@まばらな温帯夏緑林の林内や、@やますそのはんえいちなどにせいいくする。@山裾の半影地などに生育する。@きんねんはかいはつやらんかく、@近年は開発や乱獲、@かんきょうのへんかのためかずがげきげんし、@環境の変化のため数が激減し、@ほごうんどうもおきている。@保護運動も起きている。@そうしゅんにめをだし、@早春に芽を出し、@そのなまえどおりせつぶんのころにはなをさかせるが、@その名前通り節分のころに花を咲かせるが、@おくがいでのかいかはせつぶんいこうのことがおおい。@屋外での開花は節分以降のことが多い。@はなはくきさきにたんせいし、@花は茎先に単生し、@はくしょくで2せんちきょうほどのおおきさ。@白色で2センチ強ほどの大きさ。@はなことばはほほえみ、こうき。@花言葉は微笑み、光輝。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 13,
    img: "hana.webp",
    title: "花と植物",
    text: "さざんか@「山茶花」@つばきかつばきぞくのじょうりょくこうようじゅ。@ツバキ科ツバキ属の常緑広葉樹。@なまえはちゅうごくごのさんちゃにゆらいし、@名前は中国語の山茶に由来し、@さんさかがなまったものといわれる。@サンサカが訛ったものといわれる。@じゅひははいかっしょくでひょうめんはなめらか、@樹皮は灰褐色で表面は滑らか、@あきのおわりからしょとうにかけてのさむいじきに、@秋の終わりから初冬にかけての寒い時期に、@ごまいのかべんのはなをさかせる。@五枚の花弁の花を咲かせる。@はないろはやせいのこたいは@花色は野生の個体は@あわいももいろをまじえたしろがおおいが、@淡い桃色を交えた白が多いが、@しょくさいされるえんげいひんしゅは@植栽される園芸品種は@あかやぴんく、しろなどさまざま。@赤やピンク、白など様々。@ふゆのきごであり、@冬の季語であり、@さむさにつよいいめーじがあるが、@寒さに強いイメージがあるが、@ひんしゅかいりょうされたけっかによるものである。@品種改良された結果によるものである。@どうようたきびのかしに@童謡たきびの歌詞に@とうじょうすることでもゆうめいなはな。@登場することでも有名な花。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 14,
    img: "hana.webp",
    title: "花と植物",
    text: "おおいぬのふぐり@「オオイヌノフグリ」@ろぼうやはたけのあぜみちなどにみられるざっそう。@路傍や畑の畦道などに見られる雑草。@ふぐりとはいんのうのことで、@フグリとは陰嚢のことで、@いぬのふぐりのかじつのかたちが@イヌノフグリの果実の形が@おすいぬのそれににていることから、@雄犬のそれに似ていることから、@このめいしょうがついた。@この名称がついた。@よーろっぱがげんさんで、@ヨーロッパが原産で、@あじあをふくむひろいくににがいらいしゅ、@アジアを含む広い国に外来種、@きかしょくぶつとしてていちゃくしている。@帰化植物として定着している。@にほんにはめいじしょとうにはいったとすいていされ、@日本には明治初頭に入ったと推定され、@そのはんしょくりょくでぜんこくてきにみられるようになった。@その繁殖力で全国的に見られるようになった。@あきにめをだし、@秋に芽を出し、@ほかのしょくぶつがはんもしないふゆによこにひろがってそだち、@他の植物が繁茂しない冬に横に広がって育ち、@そうしゅんにたすうのはなをつける。@早春に多数の花をつける。@かべんはよんまいで、いろはこばるとぶるー。@花弁は四枚で、色はコバルトブルー。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 15,
    img: "hana.webp",
    title: "花と植物",
    text: "ほとけのざ@「ホトケノザ」@しそかおどりこそうぞくのいちねんそう、@シソ科オドリコソウ属の一年草、@ないしはえつねんそう。@ないしは越年草。@はるのななくさのほとけのざとは@春の七草の仏の座とは@ことなるのでちゅうい。@異なるので注意。@あじあやおうべいしょこくなどにひろくぶんぷ、@アジアや欧米諸国などに広く分布、@にほんではほっかいどういがいにじせいする。@日本では北海道以外に自生する。@みちばたやたはたのあぜなどによくみられるざっそう。@道端や田畑の畦などによく見られる雑草。@かきは3がつから6がつで、@花期は3月から6月で、@じょうぶのはわきにながさ2せんちほどの、@上部の葉脇に長さ2センチほどの、@むらさきでしんけいじょうのはなをつける。@紫で唇形状の花をつける。@しゅしにはしろいぶっしつがふちゃくし、@種子には白い物質が付着し、@これをありがこのむ。@これをアリが好む。@ありによってとおくにはこばれ、めぶくことでしられる。@アリによって遠くに運ばれ、芽吹くことで知られる。@こどもがはなをぬきとり、@子供が花を抜き取り、@みつをあじわってあそぶことがある。@蜜を味わって遊ぶことがある。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 16,
    img: "hana.webp",
    title: "花と植物",
    text: "ろーずまりー@「ローズマリー」@ちちゅうかいえんがんちほうがげんさんの、@地中海沿岸地方が原産の、@しそかにぞくするじょうりょくせいていぼく。@シソ科に属する常緑性低木。@わめいはまんねんろう。@和名は迷迭香。@おうべいではきょうかいやせいじゃを@欧米では教会や生者を@あくまからまもるしんぴてきなちからをもつといわれ、@悪魔から守る神秘的な力を持つといわれ、@またきおくやゆうじょうをいみする。@また記憶や友情を意味する。@しょうようやかんそうばをこうしんりょう、@生葉や乾燥葉を香辛料、@くすりとしてもちいる。@薬として用いる。@はなもかしょく。@花も可食。@すいじょうきじょうりゅうほうでちゅうしゅつしたせいゆも、@水蒸気蒸留法で抽出した精油も、@くすりとしてりようされる。@薬として利用される。@せいちょうするとたかさ1.8めーとるにもたっするかんぼく。@成長すると高さ1.8メートルにも達する灌木。@あつくかんそうしたきこうをこのむが、@暑く乾燥した気候を好むが、@たいかんせいもたかい。@耐寒性も高い。@ふゆからはるにかけて@冬から春にかけて@あおやむらさきがかったしろいはながさき、@青や紫がかった白い花が咲き、@かんしょうようとしてもにんきがある。@観賞用としても人気がある。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 17,
    img: "hana.webp",
    title: "花と植物",
    text: "くろっかす@「クロッカス」@よーろっぱなんぶやちちゅうかいえんがんをげんさんちとする、@ヨーロッパ南部や地中海沿岸を原産地とする、@たいかんせいあきうえきゅうこんしょくぶつ。@耐寒性秋植え球根植物。@あやめかくろっかすぞくのそうしょう。@アヤメ科クロッカス属の総称。@かんしょうようのみにさいばいされる。@観賞用のみに栽培される。@そうしゅんにちじょうすれすれのかしょにはなをさかせる。@早春に地上すれすれの箇所に花を咲かせる。@はないろはきいろやしろ、@花色は黄色や白、@うすむらさき、こうししょくなど。@薄紫、紅紫色など。@ひあたりとみずはけのよいばしょなら、@日当たりと水はけの良い場所なら、@うえっぱなしでもよくせいいくするほどじょうぶである。@植えっぱなしでもよく生育するほど丈夫である。@はなことばはせいしゅんのよろこび、せつぼうなど。@花言葉は青春の喜び、切望など。@はないろによってもことなり、@花色によっても異なり、@むらさきのばあいはあいのこうかい、@紫の場合は愛の公開、@きいろのばあいはわたしをしんじて。@黄色の場合は私を信じて。@かだんやはちうえ、@花壇や鉢植え、@みずさいばいとそだてかたもさまざま。@水栽培と育て方も様々。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 18,
    img: "hana.webp",
    title: "花と植物",
    text: "あろえ@「アロエ」@つるぼらんあかあろえぞくのしょくぶつのそうしょう。@ツルボラン亜科アロエ属の植物の総称。@せかいじゅうにひろくぶんぷするが、@世界中に広く分布するが、@あふりかたいりくなんぶ、@アフリカ大陸南部、@およびまだがすかるとうにおおくみられる。@およびマダガスカル島に多く見られる。@えじぷとやぎりしゃなどできげんぜんからかくにんされ、@エジプトやギリシャなどで紀元前から確認され、@にほんにもかまくらじだいにでんらいしたとされる。@日本にも鎌倉時代に伝来したとされる。@300しゅいじょうのげんしゅがしられ、@300種以上の原種が知られ、@たかさ20めーとるのたいぼくにそだつものから、@高さ20メートルの大木に育つものから、@5せんちていどのこがたたねまでさまざま。@5センチ程度の小型種まで様々。@まふゆにやく2かげつのあいだ、@真冬に約2ヶ月の間、@あかいはなをさかせるのはきだちあろえ。@赤い花を咲かせるのはキダチアロエ。@おもにしょくようとしてもちいられるのは、@主に食用として用いられるのは、@あろえべらというたねである。@アロエベラという種である。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 19,
    img: "hana.webp",
    title: "花と植物",
    text: "ひなぎく@「雛菊」@いたりあのこっかでもある、@イタリアの国花でもある、@きくかのたねんそう。@キク科の多年草。@にほんではなつのあつさにたえられないため、@日本では夏の暑さに耐えられないため、@いちねんそうとしてあつかう。@一年草として扱う。@べつめいはでいじー、@別名はデイジー、@ちょうめいぎく、えんめいぎく。@長命菊、延命菊。@げんさんちはおうしゅうで、@原産地は欧州で、@げんしゅはしばふのざっそうとしてあつかわれている。@原種は芝生の雑草として扱われている。@ほくべいやあじあなどに、@北米やアジアなどに、@がいらいしゅとしてひろくきか。@外来種として広く帰化。@にほんにもめいじじだいしょきにとらいし、@日本にも明治時代初期に渡来し、@ほっかいどうなどのれいりょうなちいきを@北海道などの冷涼な地域を@ちゅうしんにていちゃくしている。@中心に定着している。@やせいしゅのばあいは3がつから5がつに、@野生種の場合は3月から5月に、@あかやしろ、ぴんくいろなどのはなをさかせる。@赤や白、ピンク色などの花を咲かせる。@おおきさはちょっけい2せんちから10せんちほど。@大きさは直径2センチから10センチほど。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 20,
    img: "hana.webp",
    title: "花と植物",
    text: "じゃのめえりか@「ジャノメエリカ」@つつじかのしょくぶつのいっしゅ。@ツツジ科の植物の一種。@じょうりょくせいのていぼくだが、@常緑性の低木だが、@このしゅでは2めーとるほどとひかくてきおおきくそだつ。@この種では2メートルほどと比較的大きく育つ。@はなのいろはぴんくで、@花の色はピンクで、@じゃのめえりかというわめいのとおり、@蛇の目エリカという和名の通り、@はなのまんなかにめのようなくろいやくがめだつ。@花の真ん中に目のような黒い葯が目立つ。@げんさんちはみなみあふりかのけーぷちほう。@原産地は南アフリカのケープ地方。@おうしゅうにしょうかいされたのは1802ねん、@欧州に紹介されたのは1802年、@にほんにもちこまれたのはたいしょうじだいすえとされる。@日本に持ち込まれたのは大正時代末とされる。@だんちせいのしょくぶつだがたいかんせいはつよく、@暖地性の植物だが耐寒性は強く、@いずはんとうやぼうそうはんとうなどでは@伊豆半島や房総半島などでは@ろじさいばいもかのう。@露地栽培も可能。@えんげいひんしゅとしては@園芸品種としては@こいももいろのれっどくいーんがいっぱんてき。@濃い桃色のレッドクイーンが一般的。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 21,
    img: "hana.webp",
    title: "花と植物",
    text: "さんしゅゆ@「山茱萸」@みずきかみずきぞくのらくようしょうこうぼく。@ミズキ科ミズキ属の落葉小高木。@さんしゅゆはげんさんちのちゅうごくのなまえで、@山茱萸は原産地の中国の名前で、@このおんよみがわめいのゆらい。@この音読みが和名の由来。@えどじだいちゅうきのきょうほうのじだいに、@江戸時代中期の享保の時代に、@ちょうせんけいゆでかんしゅのしゅしがもちこまれ、@朝鮮経由で漢種の種子が持ち込まれ、@やくようしょくぶつとしてさいばいされはじめた。@薬用植物として栽培され始めた。@にほんめいははるこがねばなといい、@日本名は春黄金花といい、@そのなのとおりはるに、@その名の通り春に、@はがつくまえにきいちめんにきいろのはなをさかせる。@葉がつく前に木一面に黄色の花を咲かせる。@あきになるとぐみににたあかいみをつける。@秋になるとグミに似た赤い実をつける。@このかじつをさいしゅし、@この果実を採取し、@しゅしをとりのぞいてひぼしかんそうさせたかにくは@種子を取り除いて日干し乾燥させた果肉は@しょうやくにりようされ、@生薬に利用され、@どうめいでにほんやっきょくほうにしゅうろくされている。@同名で日本薬局方に収録されている。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 22,
    img: "hana.webp",
    title: "花と植物",
    text: "なずな@「ナズナ」@あぶらなかなずなぞくのえつねんぐさ。@アブラナ科ナズナ属の越年草。@べつめいはぺんぺんぐさ、しゃみせんぐさ。@別名はペンペン草、三味線草。@はるのななくさのひとつで、@春の七草の一つで、@わかばはしょくようになる。@若葉は食用になる。@たはたやあれち、みちばたなどいたるところにはえ、@田畑や荒れ地、道端など至るところに生え、@はるからなつにかけて@春から夏にかけて@しろいはなとさんかくけいのかじつをつける。@白い花と三角形の果実をつける。@むぎさいばいのでんらいとともに、@麦栽培の伝来とともに、@にほんにとらいしたきかしょくぶつとかんがえられている。@日本に渡来した帰化植物と考えられている。@なまえのゆらいはしょせつあり、@名前の由来は諸説あり、@なつにかれることからなつなきな、@夏に枯れることから夏無き菜、@つまりなつながなまったというせつ、@つまり夏無が訛ったという説、@なでたいちいさくかわいいはなのいみから、@撫でたい小さく可愛い花の意味から、@なでながなまったせつなど。@撫で菜が訛った説など。@はなことばはすべてをきみにささげる。@花言葉は全てを君に捧げる。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 23,
    img: "hana.webp",
    title: "花と植物",
    text: "つるにちにちそう@「蔓日々草」@おうしゅうがげんさんの、@欧州が原産の、@きょうちくとうかのじょうりょくつるせいしょくぶつのいっしゅ。@キョウチクトウ科の常緑蔓性植物の一種。@べいこくやにほんなどにきかしている。@米国や日本などに帰化している。@くきがじめんやいしがきなどをはい、@茎が地面や石垣などを這い、@ふしからねをだしひろがってふえる。@節から根を出し広がって増える。@このせいしつのため、@この性質のため、@つりばちのふちからたらしたり、@吊り鉢の縁から垂らしたり、@ぐらうんどかばーなどにりようされる。@グラウンドカバーなどに利用される。@かきははるからしょか。@花期は春から初夏。@くきのようえきにあおやしろいろのはなをさかせるが、@茎の葉腋に青や白色の花を咲かせるが、@はにしろやきいろのまだらのはいるひんしゅが、@葉に白や黄色の斑の入る品種が、@かんようしょくぶつとしてこのまれる。@観葉植物として好まれる。@たいかんせい、たいいんせい、@耐寒性、耐陰性、@たいかんせいすべてにすぐれ、@耐乾性すべてに優れ、@しゅうねんのかんしょうがかのう。@周年の鑑賞が可能。@はなことばはやさしい、おもいで。@花言葉は優しい、思い出。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 24,
    img: "hana.webp",
    title: "花と植物",
    text: "ひめおどりこそう@「ヒメオドリコソウ」@みちばたやにわなどによくはえている、@道端や庭などによく生えている、@おうしゅうげんさんのえつねんそう。@欧州原産の越年草。@ほくべいやひがしあじあにきかしている。@北米や東アジアに帰化している。@にほんにはめいじじだいちゅうきにきかしたがいらいしゅで、@日本には明治時代中期に帰化した外来種で、@ほんしゅうをちゅうしんにぶんぷする。@本州を中心に分布する。@かんとうちほうでは3がつから5がつにかけてかいか。@関東地方では3月から5月にかけて開花。@あかるいあかむらさきいろのしんけいかで、@明るい赤紫色の唇形花で、@おんだんなちいきではねんかんをつうじてはなをさかせ、@温暖な地域では年間を通じて花を咲かせ、@ほかのはながすくないじきには@他の花が少ない時期には@みつばちにとってじゅうようなみつのきょうきゅうげんとなる。@ミツバチにとって重要な蜜の供給源となる。@ちゅうごく、ちょうせんはんとうから@中国、朝鮮半島から@にほんにぶんぷするおどりこそうのどうぞくだが、@日本に分布する踊り子草の同属だが、@はんぶんいかでちいさいため@半分以下で小さいため@ひめのなをかんしてよばれる。@姫の名を冠して呼ばれる。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 25,
    img: "hana.webp",
    title: "花と植物",
    text: "もも@「桃」@ばらかももぞくのらくようしょうこうぼく、@バラ科モモ属の落葉小高木、@またそのかじつのこと。@またその果実のこと。@7がつや8がつにみのるもものみは@7月や8月に実る桃の実は@あきのきごでもあり、@秋の季語でもあり、@すいぶんをおおくふくんでやわらかく@水分を多く含んで柔らかく@しょくようやいんりょうとなる。@食用や飲料となる。@3がつげじゅんから4がつじょうじゅんごろに、@3月下旬から4月上旬ごろに、@うすももいろのはなをつける。@薄桃色の花をつける。@もものはなははるのきご。@桃の花は春の季語。@ももがさきはじめるじきは、@桃が咲き始める時期は、@しちじゅうにこうにおいて@七十二候において@ももはじめてさくとよばれる。@桃始笑と呼ばれる。@はなはあわいべにいろがおおいが、@花は淡い紅色が多いが、@はくしょくからのうこうしょくまでさまざまなしゅるいがある。@白色から濃紅色まで様々な種類がある。@ごべんまたはたじゅうべんで、@五弁または多重弁で、@おおくのおしべをもつ。@多くの雄しべを持つ。@にわきとして、@庭木として、@あるいはかどうできりばなとしてもちいられる。@あるいは華道で切り花として用いられる。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 26,
    img: "hana.webp",
    title: "花と植物",
    text: "さくら@「桜」@ばらかさくらあかさくらぞくの@バラ科サクラ亜科サクラ属の@らくようこうようじゅのそうしょう。@落葉広葉樹の総称。@おうしゅうやしべりあ、@欧州やシベリア、@ちゅうごくやべいこくなど@中国や米国など@きたはんきゅうのおんたいにこうはんいにじせいしているが、@北半球の温帯に広範囲に自生しているが、@れきしてきににほんぶんかになじみのふかいしょくぶつで、@歴史的に日本文化に馴染みの深い植物で、@へんいしやすいとくしつから@変異しやすい特質から@はなみもくてきにおおくのさいばいひんしゅが@花見目的に多くの栽培品種が@さくしゅつされてきた。@作出されてきた。@はるにさくらいろとひょうげんされる、@春に桜色と表現される、@はくしょくやたんこうしょくから@白色や淡紅色から@のうこうしょくのはなをさかせる。@濃紅色の花を咲かせる。@さくらぜんぱんのはなことばは、@桜全般の花言葉は、@せいしんのび、ゆうびなじょせい。@精神の美、優美な女性。@かじつはさくらんぼまたはちぇりーとよばれる。@果実はさくらんぼまたはチェリーと呼ばれる。@ちってさくらふぶきがまうみやびなさまも@散って桜吹雪が舞う雅な様も@にほんじんにしたしまれる。@日本人に親しまれる。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 27,
    img: "hana.webp",
    title: "花と植物",
    text: "ちゅーりっぷ@「チューリップ」@ゆりかちゅーりっぷぞくのしょくぶつ。@ユリ科チューリップ属の植物。@きゅうこんができ、@球根が出来、@けいたいはゆうひりんけい。@形態は有皮鱗茎。@わめいはうっこんこう。@和名は鬱金香。@いらんやとることいった、@イランやトルコといった、@ちゅうとうやちゅうおうあじあがげんさん。@中東や中央アジアが原産。@せいさんちとしてはおらんだがひじょうにゆうめいで、@生産地としてはオランダが非常に有名で、@にほんではんばいされるきゅうこんも@日本で販売される球根も@ほとんどがゆにゅうひん。@ほとんどが輸入品。@とやまけんやにいがたけんで@富山県や新潟県で@だいきぼなさいばいがおこなわれており、@大規模な栽培が行なわれており、@りょうけんごうけいのこくないしぇあは98ぱーせんと。@両県合計の国内シェアは98パーセント。@ひんしゅかいりょうにより@品種改良により@さまざまなえんげいひんしゅがそんざいし、@様々な園芸品種が存在し、@そのかずはすうひゃく。@その数は数百。@はないろやはなのかたちもたしゅたよう。@花色や花の形も多種多様。@はないろによりかわるが、@花色により変わるが、@ぜんたいのはなことばはおもいやり。@全体の花言葉は思いやり。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 28,
    img: "hana.webp",
    title: "花と植物",
    text: "たんぽぽ@「タンポポ」@きくかたんぽぽぞくのそうしょう。@キク科タンポポ属の総称。@おおくはゆーらしあたいりくにしぜんぶんぷする。@多くはユーラシア大陸に自然分布する。@ちゅうごくのしょくぶつめいはほこうてい。@中国の植物名は蒲公英。@わめいのゆらいはしょせつあり、@和名の由来は諸説あり、@はなごのすがたがめんきゅうのたんぽににているから。@花後の姿が綿球のタンポに似ているから。@またつづみをいみするようじごで、@また鼓を意味する幼児語で、@すでにえんげいかされていたえどじだいに@すでに園芸化されていた江戸時代に@つづみぐさとよばれたものが、@ツヅミグサと呼ばれたものが、@しょくぶつめいにもてんじたというせつなど。@植物名にも転じたという説など。@えいごめいはだんでらいおん。@英語名はダンデライオン。@みちばたやのはら、そうげんにおおいたねんそうで、@道ばたや野原、草原に多い多年草で、@はなはいっぱんにきいろ、しろいばあいもある。@花は一般に黄色、白い場合もある。@わたげをきゅうじょうにてんかいして、@綿毛を球状に展開して、@かぜによってとびちりしゅしをはこぶ。@風によって飛び散り種子を運ぶ。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 29,
    img: "hana.webp",
    title: "花と植物",
    text: "からすのえんどう@「カラスノエンドウ」@そらまめぞくのえつねんそう。@ソラマメ属の越年草。@しょくぶつがくてききょくめんでは@植物学的局面では@やはずえんどうがせいしきなわめい。@ヤハズエンドウが正式な和名。@ほかのぞくしょうにしーびーびーがある。@他の俗称にシービービーがある。@げんさんちはおりえんとからちちゅうかいにかけて。@原産地はオリエントから地中海にかけて。@にほんではほんしゅうからしこく、@日本では本州から四国、@きゅうしゅう、おきなわのろぼうやていぼうなど、@九州、沖縄の路傍や堤防など、@いたるところにせいそくしている。 @至るところに生息している。@ あきにはつがし、@ 秋に発芽し、@はるになるとたかさ60から150せんちほどにたっする。@春になると高さ60から150センチほどに達する。@こだいのむぎさくのうこうのかいしきには、@古代の麦作農耕の開始期には、@しょくようとしてさいばいされていたとされる。@食用として栽培されていたとされる。@げんだいではざっそうあつかい。@現代では雑草扱い。@はるからなつにかけて、@春から夏にかけて、@ごまいべんであかむらさきいろのはなをさかせる。@五枚弁で赤紫色の花を咲かせる。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 30,
    img: "hana.webp",
    title: "花と植物",
    text: "しだれざくら@「枝垂れ桜」@ばらかさくらぞくのしょくぶつのいっしゅ。@バラ科サクラ属の植物の一種。@えだがやわらかくたれるさくらのそうしょう。@枝が柔らかく垂れる桜の総称。@きょうぎではとくていのえどひがんけいとうの、@狭義では特定のエドヒガン系統の、@しだれせいのさいばいひんしゅ。@枝垂れ性の栽培品種。@じゅこう8めーとるいじょうにそだつこうぼくで、@樹高8メートル以上に育つ高木で、@はなはひとえざきのしょうわでたんこうしょく。@花は一重咲きの小輪で淡紅色。@へいあんじだいにはすでに@平安時代には既に@しだりざくらというそせんがそんざいした。@しだり桜という祖先が存在した。@えだがしだれるのはいちょうやかつら、@枝が枝垂れるのは銀杏や桂、@くりやけやきのきなどでもみられるが、@栗や欅の木などでも見られるが、@そのげんいんはとつぜんへんいにより@その原因は突然変異により@いちぶのしょくぶつほるもんがふそくして、@一部の植物ホルモンが不足して、@えだのうえがわのそしきがかたくならず、@枝の上側の組織が硬くならず、@えだのはりがじゅうりょくにたえられないから@枝の張りが重力に耐えられないから@というせつがゆうりょく。@という説が有力。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 31,
    img: "hana.webp",
    title: "花と植物",
    text: "ふくじゅそう@「福寿草」@きんぽうげかのたねんそう。@キンポウゲ科の多年草。@はるをつげるはなのだいひょうで、@春を告げる花の代表で、@がんじつそうなどのべつめいをもつ。@元日草などの別名を持つ。@ふくじゅそうというわめいも、しんしゅんをいわういみがある。@福寿草という和名も、新春を祝う意味がある。@1がつ1にちのたんじょうか。@1月1日の誕生花。@かきもそうしゅんで、@花期も早春で、@3、4せんちのきいろいはなをさかせる。@3、4センチの黄色い花を咲かせる。@かべんをつかってにっこうを@花弁を使って日光を@はなのちゅうしんにあつめ、@花の中心に集め、@そのねつでむしをゆういんする。@その熱で虫を誘引する。@はなはたいようこうにおうじてかいへいする。@花は太陽光に応じて開閉する。@ねはどくせいがつよく、@根は毒性が強く、@きょうしんやりにょうさようがありみんかんやくとしてつかわれるが、@強心や利尿作用があり民間薬として使われるが、@しろうとのりようはきけん。@素人の利用は危険。@しょうじょうはおうとやこきゅうこんなんなど。@症状は嘔吐や呼吸困難など。@じせいちはほっかいどうからきゅうしゅうまではばひろい。@自生地は北海道から九州まで幅広い。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 32,
    img: "hana.webp",
    title: "花と植物",
    text: "もくれん@「木蓮」@もくれんもくもくれんかもくれんぞくのらくようていぼく。@モクレン目モクレン科モクレン属の落葉低木。@むかしははながらんににていることから、@昔は花が蘭に似ていることから、@もくらんとよばれていた。@木蘭と呼ばれていた。@こんにちはらんよりはすのはなににているとして、@今日は蘭より蓮の花に似ているとして、@もくれんのなまえがついた。@木蓮の名前がついた。@しせんしょうなどちゅうごくなんせいぶがげんさんだが、@四川省など中国南西部が原産だが、@えいごけんではにほんがげんさんこくと@英語圏では日本が原産国と@ごかいされるばあいもある。@誤解される場合もある。@こがたでじゅこうは3から5めーとるていど。@小型で樹高は3から5メートル程度。@かきは4がつや5がつ。@花期は4月や5月。@はなはいっぱんてきにはむらさき、@花は一般的には紫、@ないしはこいべにいろからももいろで、@ないしは濃い紅色から桃色で、@じょうひんなつよいほうこうをはなつ。@上品な強い芳香を放つ。@ちゅうごくやにほんだけでなく、@中国や日本だけでなく、@ほくべいやおうべいでひろくさいばいされている。@北米や欧米で広く栽培されている。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 33,
    img: "hana.webp",
    title: "花と植物",
    text: "すみれ@「スミレ」@すみれかすみれぞくのしょくぶつのそうしょう。@スミレ科スミレ属の植物の総称。@にほんぜんこくにみられるはな。@日本全国に見られる花。@しゅるいはひじょうにおおく、@種類は非常に多く、@たとえばつよいどくせいでしられるとりかぶとも、@例えば強い毒性で知られるトリカブトも、@すみれとおなじすみれというかんじひょうきをもちいる。@スミレと同じ菫という漢字表記を用いる。@いっぱんてきには、@一般的には、@みちばたではるにはなをさかせるやそうで、@道ばたで春に花を咲かせる野草で、@そのはないろはふかむらさきいろ。@その花色は深紫色。@これをすみれいろとよぶこともある。@これを菫色と呼ぶこともある。@はなはらっぱのようなどくとくのかたちで、@花はラッパのような独特の形で、@よこむきかななめしたむきにつく。@横向きか斜め下向きにつく。@さんさいとしてもりようされ、@山菜としても利用され、@ははてんぷらにしたり、@葉は天ぷらにしたり、@ゆでてあえものやおひたしになり、@茹でて和え物や御浸しになり、@はなのぶぶんはすのものやすいもののわんだねとなる。@花の部分は酢の物や吸い物の椀種となる。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 34,
    img: "hana.webp",
    title: "花と植物",
    text: "すのーふれーく@「スノーフレーク」@ひがんばなかのしょくぶつのひとつ。@ヒガンバナ科の植物の一つ。@わめいはおおまつゆきそう、@和名は大待雪草、@べつめいはすずらんずいせんともいう。@別名は鈴蘭水仙とも言う。@よーろっぱちゅうなんぶをげんさんとするたねんそうで、@ヨーロッパ中南部を原産とする多年草で、@はなにしろいすずらんのようなはなをさかせる。@花に白い鈴蘭のような花を咲かせる。@かべんのせんたんにはみどりのはんてんがある。@花弁の先端には緑の斑点がある。@あきにうえるきゅうこんそうである。@秋に植える球根草である。@ぶんるいじょうのいちづけにはへんせんがあり、@分類上の位置づけには変遷があり、@くろんきすとたいけいというふるいぶんるいでは、@クロンキスト体系という古い分類では、@ゆりかにぞくしていた。@ユリ科に属していた。@はなことばはそのせいそでかれんなすがたにちなみ、@花言葉はその清楚で可憐な姿にちなみ、@じゅんけつ、けがれなきこころなど。@純潔、汚れなき心など。@にたなまえでどうじきにさくものに、@似た名前で同時期に咲くものに、@すのーどろっぷというはなもある。@スノードロップという花もある。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 35,
    img: "hana.webp",
    title: "花と植物",
    text: "あせび@「アセビ」@つつじかあせびぞくにぞくする@ツツジ科アセビ属に属する@じょうりょくせいのていぼく。@常緑性の低木。@にほんなどひがしあじあがげんさんで、@日本など東アジアが原産で、@ほんしゅうやしこく、@本州や四国、@きゅうしゅうのさんちにじせいする。@九州の山地に自生する。@ゆうどくしょくぶつであり、@有毒植物であり、@うまがはをたべればどくにあたり、@馬が葉を食べれば毒に当たり、@ようがごとくになることにゆらいし、@酔うが如くになることに由来し、@かんじひょうきではあせびとなる。@漢字表記では馬酔木となる。@そうしょくどうぶつもくちにしないため、@草食動物も口にしないため、@ていえんやこうえんのじゅもくとしてしょくさいされる。@庭園や公園の樹木として植栽される。@またはなをさかせるぼんさいとしてもなじみぶかい。@また花を咲かせる盆栽としても馴染み深い。@そうしゅんに10せんちほどの、@早春に10センチほどの、@つぼじょうでしろいはなをつける。@壺状で白い花をつける。@えんげいひんしゅに、@園芸品種に、@ぴんくのはなをつけるあけぼのあせびなどもある。@ピンクの花を付けるアケボノアセビなどもある。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 36,
    img: "hana.webp",
    title: "花と植物",
    text: "かもみーる@「カモミール」@きくかしかぎくぞくのいっしゅである、@キク科シカギク属の一種である、@たいかんせいいちねんそう。@耐寒性一年草。@わめいはかみつれ。@和名は加密列。@おうしゅうでははーぶとしてひろくりようされている。@欧州ではハーブとして広く利用されている。@ろしあのこっかとなっている。@ロシアの国花となっている。@にほんへはえどじだいにでんらいし、@日本へは江戸時代に伝来し、@とっとりけんやおかやまけんなどでさいばいがはじめられた。@鳥取県や岡山県などで栽培が始められた。@げんざいはひろくふきゅうしていて@現在は広く普及していて@にわにうえられることもおおい。@庭に植えられることも多い。@ばんしゅんからしょかにかけて、@晩春から初夏にかけて、@ちゅうおうぶぶんがきいろ、@中央部分が黄色、@そのしゅういのかべんははくしょくのはなをさかせる。@その周囲の花弁は白色の花を咲かせる。@きげんぜんから、@紀元前から、@やくようしょくぶつやみんかんやくとしてもちいられてきた。@薬用植物や民間薬として用いられてきた。@はなことばはくなんのなかのちから、@花言葉は苦難の中の力、@ぎゃっきょうにたえるなど。@逆境に耐えるなど。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 37,
    img: "hana.webp",
    title: "花と植物",
    text: "つつじ@「ツツジ」@おもにあじあにひろくぶんぷし、@主にアジアに広く分布し、@ねぱーるではこっかになっている、@ネパールでは国花になっている、@つつじかつつじぞくのしょくぶつのそうしょう。@ツツジ科ツツジ属の植物の総称。@もっともじゅれいのふるいきは、@最も樹齢の古い木は、@せんねんにおよぶとすいていされる。@千年に及ぶと推定される。@かんじではつつじとかくが、@漢字では躑躅と書くが、@みるひとがあしをとめるほどうつくしい@見る人が足を止めるほど美しい@といういわれにゆらいする。@という言われに由来する。@このかんじはりょうほうとも、@この漢字は両方とも、@たちどまるやたたずむといったいみ。@立ち止まるや佇むといった意味。@はるさきからしょかにかけて、@春先から初夏にかけて、@せんたんがごれつしている、@先端が五裂している、@とくちょうてきなろうとがたのはなをさかせる。@特徴的な漏斗型の花を咲かせる。@はなはおもにぴんくいろだが、@花は主にピンク色だが、@ひんしゅによってあかやしろなどさまざま。@品種によって赤や白など様々。@はなことばはせつど、つつしみなど。@花言葉は節度、慎みなど。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 38,
    img: "hana.webp",
    title: "花と植物",
    text: "ふじ@「藤」@まめかふじぞくのつるせいらくようじゅ。@マメ科フジ属の蔓性落葉樹。@にほんのこゆうしゅで、@日本の固有種で、@ていさんちやへいちのはやしにぶんぷする。@低山地や平地の林に分布する。@はながさく5がつのじきには、@花が咲く5月の時期には、@ふじだながかんしょうのたいしょうとなる。@藤棚が鑑賞の対象となる。@ちょくしゃにっこうのさすばしょをこのむ、@直射日光の差す場所を好む、@こうこうせいしゅしである。@好光性種子である。@ながくしだれたかじょがえだのせんたんにでて、@長くしだれた花序が枝の先端に出て、@したにたれるようにときには100せんちにもたっするほどのび、@下に垂れるように時には100センチにも達するほど伸び、@たすうのはなをつける。@多数の花を付ける。@はないろはむらさきからたんこうしょくで、@花色は紫から淡紅色で、@いわゆるふじいろである。@いわゆる藤色である。@ほかのまめかのしょくぶつどうよう、@他のマメ科の植物同様、@やかんははをすぼめる。@夜間は葉をすぼめる。@つるはじょうぶで、蔓は丈夫で、@いすやかごなどみんぐのそざいとなっていた。@椅子や籠など民具の素材となっていた。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 39,
    img: "hana.webp",
    title: "花と植物",
    text: "はなみずき@「ハナミズキ」@みずきかみずきぞくやまぼうしあぞくのらくようこうぼく。@ミズキ科ミズキ属ヤマボウシ亜属の落葉高木。@なまえのゆらいはみずきのなかまで、@名前の由来はミズキの仲間で、@はながめだつことから。@花が目立つことから。@ほくべいげんさんで、@北米原産で、@べいこくひがしかいがんなどにじせいしている。@米国東海岸などに自生している。@じゅひははいこくしょくで、@樹皮は灰黒色で、@ははだえんけい。@葉は楕円形。@あきにはこうようする。@秋には紅葉する。@かきは4がつげじゅんから5がつじょうじゅんで、@花期は4月下旬から5月上旬で、@はくしょくやうすいぴんくいろのはなをつける。@白色や薄いピンク色の花をつける。@にほんにおけるしょくさいは、@日本における植栽は、@べいこくにさくらをおくったへんれいに、@米国に桜を贈った返礼に、@おくられてきたのがはじまりとされる。@贈られてきたのが始まりとされる。@げんざいではにわきやがいろじゅとしてりようされる。@現在では庭木や街路樹として利用される。@はなことばはえいぞくせいやへんれい、@花言葉は永続性や返礼、@わたしのおもいをうけてください。@私の想いを受けてください。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },
  {
    id: 8,
    index: 40,
    img: "hana.webp",
    title: "花と植物",
    text: "がーべら@「ガーベラ」@きくかがーべらぞくのそうしょう。@キク科ガーベラ属の総称。@たねんせいしょくぶつで、@多年生植物で、@おんたいなちいきやねったいあじあ、@温帯な地域や熱帯アジア、@みなみあふりかなどにぶんぷし、@南アフリカなどに分布し、@やせいしゅが40ほどそんざいする。@野生種が40ほど存在する。@おうしゅうでひんしゅかいりょうされ、@欧州で品種改良され、@きりばなやはちうえようにさいばいされる。@切り花や鉢植え用に栽培される。@はなのもちがよく、@花のもちが良く、@でざいんでもひかくてきおおくもちいられる。@デザインでも比較的多く用いられる。@なまえのゆらいははっけんしゃである@名前の由来は発見者である@どいつのはくぶつがくしゃげるべるから。@ドイツの博物学者ゲルベルから。@にほんでのかきは4がつから9がつとはばひろい。@日本での花期は4月から9月と幅広い。@はないろはぴんくやあか、@花色はピンクや赤、@しろやきいろ、みどり、おれんじなど@白や黄色、緑、オレンジなど@ほうふなしゅるいがそんざいする。@豊富な種類が存在する。@はなことばはすうこうびやきぼう、しんぴなど。@花言葉は崇高美や希望、神秘など。",
    tag1: "花と植物",
    tag2: "ローマ字入力",
  },

  {
    id: 9,
    index: 1,
    img: "tougoku.webp",
    title: "幼馴染が爵位を継ぐことになると婚約破棄されました",
    text: "わかりました。こんやくのはきをうけいれます@「わかりました。婚約の破棄を受け入れます」@わたしはおさななじみのえるにいった。@私は幼馴染のエルに言った。@ほんとうにいいのか？@「本当にいいのか？」@えるはおうこくのらずべりーちほうをおさめるりょうしゅでこうしゃくけのじなん@エルは王国のラズベリー地方を治める領主で侯爵家の次男。@だが、おにいさまがそうせいしたためいえをつぐことになる。@だが、お兄様が早世したため家を継ぐことになる。@まさか、そのおそうしきのばでこんやくをはきしたいといわれるとは。@まさか、そのお葬式の場で婚約を破棄したいと言われるとは。@ゆめにもおもわなかった。@夢にも思わなかった。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 9,
    index: 2,
    img: "tougoku.webp",
    title: "幼馴染が爵位を継ぐことになると婚約破棄されました",
    text: "しかし、みぶんのさをかんがえればとうぜんだ。@しかし、身分の差を考えれば当然だ。@はい。しょうけのむすめであるわたしではつりあいませんから。@「はい。商家の娘である私では釣り合いませんから」@しょせん、わたしはかれのいえのでいりぎょうしゃのむすめにすぎなかったのだ。@所詮、私は彼の家の出入り業者の娘に過ぎなかったのだ。@すまない@「すまない」@すまないというえるのかおはきしょくがかくしきれていなかった。@すまないと言うエルの顔は喜色を隠しきれていなかった。@わたしがこんやくはきをすぐにうけいれたことがうれしかったのだろう。@私が婚約破棄をすぐに受け入れたことが嬉しかったのだろう。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 9,
    index: 3,
    img: "tougoku.webp",
    title: "幼馴染が爵位を継ぐことになると婚約破棄されました",
    text: "うわさにはきいていた。@噂には聞いていた。@こうしゃくけをつぐことになったえるはきぞくのしゃこうかいでひくてあまたとか。@侯爵家を継ぐことになったエルは貴族の社交界で引く手あまたとか。@うわさをきいてもえるなら@噂を聞いてもエルなら……@ひょっとしてとおもっていたじぶんのおろかしさがうらめしい。@ひょっとしてと思っていた自分の愚かしさが恨めしい。@ひつぎがつちにうめられていく。@棺が土に埋められていく。@わたしのめからながれるなみだはたにんからみれば、えるのおにいさまへのかなしみとうつっているだろう。@私の目から流れる涙は他人から見れば、エルのお兄様への悲しみと写っているだろう。@えるのことをあいしていたんだな@「エルのことを愛していたんだな」",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 9,
    index: 4,
    img: "tougoku.webp",
    title: "幼馴染が爵位を継ぐことになると婚約破棄されました",
    text: "きゅうにだれかにこえをかけられる。@急に誰かに声をかけられる。@じょあんさま@「ジョアン様」@いゔぁおうこくのじょあんおうたいしでんかだった。@イヴァ王国のジョアン王太子殿下だった。@えるはじょうりゅうかいきゅうのきぞくとしてとしのちかいじょあんでんかとこうりゅうしていた。@エルは上流の貴族として年の近いジョアン殿下と交流していた。@わたしもじょあんでんかとえるのしょくじのせきになんかいかどうせきしたことがある。@私もジョアン殿下とエルの食事の席に何回か同席したことがある。@しょくじのせきでもじょあんでんかはしょうけのむすめのわたしにもきさくにはなしかけてくれた。@食事の席でもジョアン殿下は商家の娘の私にも気さくに話しかけてくれた。@どうやらじょあんでんかはわたしのなみだのりゆうをしっているらしい。@どうやらジョアン殿下は私の涙の理由を知っているらしい。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 9,
    index: 5,
    img: "tougoku.webp",
    title: "幼馴染が爵位を継ぐことになると婚約破棄されました",
    text: "えるからはなしをきいたのですか？@「エルから話を聞いたのですか？」@きいた。はくじょうなおとこだ。わたしがふたりのなかを、いまいちど、とりもとうか？@「聞いた。薄情な男だ。私が二人の仲を、今一度、取り持とうか？」@いいえ。べつにかなしくはないですから。@「いいえ。別に悲しくはないですから」@すくなくともえがおにはみえないぞ？@「少なくとも笑顔には見えないぞ？」@かなしくてないているなんていいたくない。@悲しくて泣いているなんて言いたくない。@えるのおにいさまがしんでかなしいといううそをつくのも、こじんにたいしてのぼうとくだろう。@エルのお兄様が死んで悲しいという嘘をつくのも、故人に対しての冒涜だろう。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 9,
    index: 6,
    img: "tougoku.webp",
    title: "幼馴染が爵位を継ぐことになると婚約破棄されました",
    text: "だからわたしはじょうだんをいう。@だから私は冗談を言う。@ないているのはでんかといっしょにかいしょくができなくなってさびしいからです。@「泣いているのは殿下と一緒に会食ができなくなって寂しいからです」@じょうだんだけど、まったくのうそでもない。@冗談だけど、まったくの嘘でもない。@どういうことだ？@「どういうことだ？」@みらいのこうしゃくふじんとはかいしょくできてもしょうかのむすめとはかいしょくなんてしてくれないでしょ@「未来の侯爵夫人とは会食できても商家の娘とは会食なんてしてくれないでしょ」@じぶんでいっていてほんとうにかなしくなってきた。@自分で言っていて本当に悲しくなってきた。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 9,
    index: 7,
    img: "tougoku.webp",
    title: "幼馴染が爵位を継ぐことになると婚約破棄されました",
    text: "ならきみをえがおにするのはかんたんだ。ちかぢか、きみをおうきゅうのしょくじにしょうたいするよ。@「なら君を笑顔にするのは簡単だ。近々、君を王宮に食事に招待するよ」@でんかはほんとうにやさしい。@殿下は本当に優しい。@またまた、ごじょうだんを@「またまた、ご冗談を」@もちろんしょうたいするのはきみだけだ。もともとえるはじゃまものだったのだよ@「もちろん招待をするのは君だけだ。もともとエルは邪魔者だったのだよ」@でんかのじょうだんにわたしはこころのそこからわらうことができた。@殿下の冗談に私は心の底から笑うことができた。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 9,
    index: 8,
    img: "tougoku.webp",
    title: "幼馴染が爵位を継ぐことになると婚約破棄されました",
    text: "ほんとうにおうきゅうからかいしょくのしょうたいじょうがきたときはわらうどころかひやあせがながれたものだ。@本当に王宮から会食の招待状が来たときは笑うどころか冷や汗が流れたものだ。@すうじゅうねんごのいま、あのときのでんかとわたしはともしらがのおしどりふうふといわれている。@数十年後の今、あの時の殿下と私は共白髪のおしどり夫婦と言われている。",
    tag1: "ライトノベル",
    tag2: "ローマ字入力",
  },
  {
    id: 99999,
    index: 1,
    img: "freestyle.webp",
    title: "自由練習",
    text: "",
    tag1: "自由練習",
    tag2: "ローマ字入力",
  },
  {
    id: 99999,
    index: 2,
    img: "freestyle.webp",
    title: "自由練習",
    text: "",
    tag1: "自由練習",
    tag2: "ローマ字入力",
  },
];

export { TC };
