import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,


  Redirect,
} from "react-router-dom";
import{
  AppBar,

Toolbar,

Typography,  ThemeProvider,
} from "@material-ui/core";
import Typing from "./Typing";
import TypingForBeginers from "./TypingForBeginers";
import App from "./App";

import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme({
  palette: {
    secondary: {
      light: "fffff9",
      main: "#350500",
      dark: "cbb895",
      contrastText: "#eec",
    } /*
    primary: {
      light: "#773431",
      main: "#47080a",
      dark: "2a0000",
      contrastText: "#fff",
    },*/,
    primary: {
      light: "#773431",
      main: "#0000e0",
      dark: "2a0000",
      contrastText: "#fff",
    },
    color3: {
      light: "#773431",
      main: "#e00000",
      dark: "2a0000",
      contrastText: "#fff",
    },
  },
});
export {theme}
/*
<AppBar color="secondary" position="static">
  <Toolbar>
    <Typography variant="h5">TypingCafe</Typography>
  </Toolbar>
</AppBar>
<Route exact path="/">
    <Redirect to="/App/-1/-1" />
</Route>

*/
//import App from "./App";


function RouterSystem(props){

  return(
<Router>

    <ThemeProvider theme={theme}>
    <AppBar color="secondary" position="static">
      <Toolbar>
      <Typography variant="h5">TypingCafe</Typography>

      </Toolbar>
    </AppBar>



</ThemeProvider>


        <Switch>
        <Route path="/" exact >
            <Redirect to="/Menu/" />
        </Route>
              <Route path="/Menu/">
                  <App />
              </Route>

              <Route path="/Typing/:itemId/:itemIndex/:freeText">
                <Typing />
              </Route>
              <Route path="/TypingForBeginers/:itemId/:itemIndex/:freeText">
                <TypingForBeginers />
              </Route>

        </Switch>
</Router>




  )





}

export default RouterSystem;
